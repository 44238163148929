import { AppRoutes } from 'config';
import { StaffForm } from './forms/staff';
import { TariffForm } from './forms/tariff';
import { PersonalDataForm } from './forms/personal-data';
import { NotificationForm } from './forms/notification';
const { Home, Profile } = AppRoutes.Dashboard;
export const ProfileIndividualTypes = {
    Rate: 'rate',
    PersonalData: 'personalData',
    Notifications: 'notifications',
};
export const ProfileLegalTypes = {
    Rate: 'rate',
    Staff: 'staff',
    PersonalData: 'personalData',
    Notifications: 'notifications',
};
export const TabIndividualItems = [
    {
        label: 'Personal data',
        Content: PersonalDataForm,
        id: ProfileIndividualTypes.PersonalData,
        value: Home + Profile.Home + Profile.PersonalData,
    },
    {
        label: 'Tariff',
        Content: TariffForm,
        id: ProfileIndividualTypes.Rate,
        value: Home + Profile.Home + Profile.Tariff,
    },
    {
        label: 'Notifications',
        Content: NotificationForm,
        id: ProfileIndividualTypes.Notifications,
        value: Home + Profile.Home + Profile.Notifications,
    },
];
export const TabLegalItems = [
    {
        label: 'Personal data',
        Content: PersonalDataForm,
        id: ProfileLegalTypes.PersonalData,
        value: Home + Profile.Home + Profile.PersonalData,
    },
    {
        label: 'Tariff',
        Content: TariffForm,
        id: ProfileLegalTypes.Rate,
        value: Home + Profile.Home + Profile.Tariff,
    },
    {
        label: 'Staff',
        Content: StaffForm,
        id: ProfileLegalTypes.Staff,
        value: Home + Profile.Home + Profile.Staff,
    },
    {
        label: 'Notifications',
        Content: NotificationForm,
        id: ProfileLegalTypes.Notifications,
        value: Home + Profile.Home + Profile.Notifications,
    },
];
