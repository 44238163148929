import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1y99ho6" }) `
  display: flex;
  flex-direction: column;
`;
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-gsr11a" }) `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 30px;
  width: fit-content;
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-3xvq9t" }) ``;
export const WrapperTariff = styled.div.withConfig({ displayName: "WrapperTariff", componentId: "sc-192x176" }) `
  display: flex;
  gap: 16px;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-uvos5w" }) `
  margin: -16px 0px 16px;
  white-space: nowrap;
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-ccnrce" }) `
  display: flex;
  align-items: center;

  gap: 24px;
  padding-top: 24px;
  margin: 78px 0px 32px;
  border-top: ${({ theme }) => theme.config.radio.border.default};
`;
export const WrapperSwitch = styled.div.withConfig({ displayName: "WrapperSwitch", componentId: "sc-35ysqn" }) ``;
