import { zIndex } from 'constants/enums';
import styled, { css, keyframes } from 'styled-components';
import { smallMedium } from 'styles/mixins/fonts';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const DropdownContainer = styled.div.withConfig({ displayName: "DropdownContainer", componentId: "sc-1uiiymp" }) `
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: ${zIndex.Dropdown + 1};
`;
export const DropdownButton = styled.button.withConfig({ displayName: "DropdownButton", componentId: "sc-gvaot2" }) `
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;
export const DropdownMenu = styled.ul.withConfig({ displayName: "DropdownMenu", componentId: "sc-ewq5pw" }) `
  position: absolute;
  top: ${({ $top }) => ($top ? `${$top}px` : '0')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0')};
  //left: 0;
  padding: 0.25rem;
  margin-top: 0.5rem;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
  background: ${({ theme }) => theme.config.toggle.bg.active};
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  animation: ${({ $isVisible }) => ($isVisible ? fadeIn : fadeOut)} 0.3s
    ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;

  overflow-y: auto;
  max-height: ${({ $isVisible }) => $isVisible ? 'calc(100vh - 2rem)' : '10px'};
`;
export const DropdownMenuItem = styled.li.withConfig({ displayName: "DropdownMenuItem", componentId: "sc-kvabm6" }) `
  ${smallMedium};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${zIndex.Modal};
  opacity: 1;
  margin-bottom: 4px;
  border-radius: 7px;
  transition: opacity 0.4s ease-in-out, background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.config.dropdown.background.hover};
  }

  ${({ $paddingRight }) => css `
    padding: 8px ${$paddingRight !== null && $paddingRight !== void 0 ? $paddingRight : 25}px 8px 4px;
  `};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-14cvbfr" }) `
  margin: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.config.dropdown.border.line};
`;
export const WrapperItem = styled.div.withConfig({ displayName: "WrapperItem", componentId: "sc-19e164r" }) `
  display: flex;
  gap: 8px;
`;
