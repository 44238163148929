import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SideLink } from 'common/side-link';
import { linksMain } from 'pages/dashboard/links';
import styled from 'styled-components';
const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-1iouaw6" }) `
  margin-top: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.config.line.secondary};
`;
export const MainAside = () => {
    return (_jsx(_Fragment, { children: linksMain.map((link) => (_jsxs("div", { children: [_jsx(SideLink, { id: link.id, icon: link.icon, path: link.path, label: link.label, iconStyle: link.iconStyle }, link.id.toString()), link.id === 0 && _jsx(Line, {})] }, link.id))) }));
};
