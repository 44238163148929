import { Button as ButtonComponent } from 'common';
import { Icon } from 'common/icon';
import styled, { css, keyframes } from 'styled-components';
import { heading3Medium, smallReqular, smallSemiBold, ultraSmallMedium, } from 'styles/mixins/fonts';
const fadeIn = keyframes `
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;
const fadeOut = keyframes `
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10px);
    }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-i91tg" }) `
  position: relative;
  display: inline-block;
  width: 100%;
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1fm04jq" }) ``;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-1d0tp2q" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 4px 8px 10px;
  border-radius: 24px;

  ${({ $isOpen }) => $isOpen &&
    css `
      background: ${({ theme }) => theme.config.dropdown.background.default};
    `}
`;
export const MenuBalance = styled.div.withConfig({ displayName: "MenuBalance", componentId: "sc-1g2fd22" }) `
  position: absolute;
  top: ${({ $isOpen, $top }) => ($isOpen && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};

  padding: 0.25rem;
  margin-top: 0.5rem;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow};
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.dropdown.background.active};
  border-radius: 16px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  animation: ${({ $isOpen }) => ($isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;

  max-height: ${({ $isOpen }) => ($isOpen ? 'calc(100vh - 2rem)' : '10px')};
  z-index: 100;

  @media screen and (max-width: 420px) {
    left: -80px;
  }

  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-hdcqj6" }) `
  ${smallReqular};

  letter-spacing: -0.01em;
  padding: 16px 0px 4px 16px;
  color: ${({ theme }) => theme.config.dashboard.text};
`;
export const WrapperMessage = styled.div.withConfig({ displayName: "WrapperMessage", componentId: "sc-1ajwwmd" }) `
  margin: 0px 16px 0px;
`;
export const ContainerMessage = styled.div.withConfig({ displayName: "ContainerMessage", componentId: "sc-1nv7xrv" }) `
  display: flex;
  flex-direction: column;

  padding: 0px 16px 16px 0px;
  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const Message = styled.span.withConfig({ displayName: "Message", componentId: "sc-wkt3rt" }) `
  ${heading3Medium}

  letter-spacing: -0.01em;
`;
export const Coast = styled.span.withConfig({ displayName: "Coast", componentId: "sc-pxase8" }) `
  ${ultraSmallMedium}

  color: ${({ theme }) => theme.config.dropdown.text.darkGreen};
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "sc-1b66r5d" }) `
  margin: 16px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-1dtpvqr" }) `
  ${smallSemiBold}
  width: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.01em;
`;
export const Arrow = styled(Icon.ArrowDown).withConfig({ displayName: "Arrow", componentId: "sc-dzmdty" }) `
  ${({ $isOpen }) => css `
    transform: rotate(${$isOpen ? '180deg' : '0deg'});
    transition: transform 0.3s ease-in-out;
  `}

  margin-left: 2px;
`;
export const Info = styled.div.withConfig({ displayName: "Info", componentId: "sc-1hg0j60" }) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextInfo = styled.div.withConfig({ displayName: "TextInfo", componentId: "sc-9czdih" }) `
  padding: 8px 20.5px;
  white-space: nowrap;
`;
