import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-11446nb" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
export const FieldsRow = styled.div.withConfig({ displayName: "FieldsRow", componentId: "sc-111p5ap" }) `
  display: flex;
  flex-direction: row;

  gap: 16px;
`;
export const Section = styled.div.withConfig({ displayName: "Section", componentId: "sc-15b3qgz" }) `
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 24px 0px;
  border-top: ${({ theme }) => theme.config.switch.border.default};
  border-bottom: ${({ theme }) => theme.config.switch.border.default};
`;
