import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ugp1j" }) `
  width: 100%;
`;
export const WrapperForm = styled.div.withConfig({ displayName: "WrapperForm", componentId: "sc-1a7vgj2" }) `
  display: flex;
  flex-direction: column;
`;
export const WrapperFields = styled.div.withConfig({ displayName: "WrapperFields", componentId: "sc-17x02y6" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const MainFields = styled.div.withConfig({ displayName: "MainFields", componentId: "sc-1d9e4oz" }) `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 24px;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-pp0ghd" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
  margin: 24px 0px;
`;
export const CreateButtonContainer = styled.div.withConfig({ displayName: "CreateButtonContainer", componentId: "sc-1h1ad2s" }) `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  gap: 24px;
  margin: 40px 0px 10px 0px;
`;
export const Close = styled.div.withConfig({ displayName: "Close", componentId: "sc-n4ik0v" }) `
  position: absolute;
  top: 10%;
  right: 25%;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.modal.bg.default};
  cursor: pointer;
  transition: background 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.config.modal.bg.hover};
  }

  @media screen and (max-width: 1440px) {
    right: 15%;
    transition: right 0.2s cubic-bezier(0.25, 0, 0.3, 1);
  }

  @media screen and (max-width: 768px) {
    right: 2%;
    transition: right 0.2s cubic-bezier(0.25, 0, 0.3, 1);
  }
`;
