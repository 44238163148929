const Auth = {
    Home: '/auth',
    Login: '/login',
    SignUp: '/sign-up',
    ForgotPassword: '/forgot-password',
    ConfirmEmail: '/confirm-email',
};
const Vkontakte = {
    Home: '/vkontakte',
    VkontaktePosts: '/posts',
    VkontakteGroups: '/groups',
};
const Parsing = {
    Vkontakte,
    Home: '/parsing',
    Avito: '/avito',
    Yandex: '/yandex',
    AvitoResult: '/avito/result',
};
const VoiceRobot = {
    Home: '/voice-robot',
    Mailing: '/mailing',
    Audioclips: '/audio-clips',
};
const AI = {
    Home: '/ai',
    Chat: '/chat',
    ImageGenerate: '/image-generate',
    AudioTranslate: '/audio-translate',
    DescribeImage: '/describe-image',
    GeneratePost: '/generate-post',
    GenerateMusic: '/generate_music',
    RemoveBackground: '/remove-background',
    BlurImage: '/blur-image',
};
const Mailing = {
    Home: '/mailing',
    Email: '/mail',
    // Viber: '/viber',
    Telegram: '/telegram',
    // WhatsApp: '/whatsApp',
    History: '/history',
};
const Marketplaces = {
    Home: '/marketplaces',
    Wildberries: '/wb',
    Ozon: '/ozon',
};
const Advertisement = {
    Home: '/advertisement',
    YandexDirect: '/yandex-direct',
    GoogleAdwords: '/google-adwords',
    Telegram: '/telegram',
    Instagram: '/instagram',
    Vkontakte: '/vkontakte',
};
const Payment = {
    Card: '/card',
    Qiwi: '/qiwi',
    Alfa: '/alfa',
    Home: '/payment',
    Yandex: '/yandex',
};
const Profile = {
    Payment,
    Home: '/profile',
    Staff: '/staff',
    Tariff: '/tariff',
    PersonalData: '/personal-data',
    Notifications: '/notification',
};
const MyCompany = {
    Home: '/my-company',
    // Statistics: '/statistics',
    CompanyData: '/company-data',
    LegalData: '/legal-data',
};
const Dashboard = {
    Parsing,
    Mailing,
    Profile,
    MyCompany,
    VoiceRobot,
    AI,
    Advertisement,
    Marketplaces,
    Main: '/home',
    Home: '/dashboard',
    Analytics: '/analytics',
    MyDatabase: '/my-database',
    SettingUpAds: '/setting-up-ads',
    SocialNetwork: '/social-network',
    CreateCompany: '/create-company',
    ReportDesigner: '/report-designer',
    CollectingInformation: '/collecting-information',
    Reports: '/reports',
};
export const AppRoutes = {
    Auth,
    Dashboard,
    NotFound: '/not-found',
};
