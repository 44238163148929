import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1j1yuke" }) `
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 47px;

  cursor: pointer;
`;
export const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1rr3srl" }) `
  margin-left: 10px;

  font-size: 32px;
  text-edge: cap;
  leading-trim: both;
  letter-spacing: -0.02em;

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
export const LogoTextBold = styled.span.withConfig({ displayName: "LogoTextBold", componentId: "sc-1nzs2ls" }) `
  font-weight: 600;
  margin-right: 10px;
`;
export const LogoTextLight = styled.span.withConfig({ displayName: "LogoTextLight", componentId: "sc-1b8fejm" }) `
  font-weight: 200;
`;
