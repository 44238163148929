import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState, useEffect, useContext, useCallback, } from 'react';
import { ThemeProvider } from 'styled-components';
import { DARK_THEME, LIGHT_THEME } from 'config/themes';
import { getIsDarkTheme, saveDarkTheme } from 'utils/storage';
import { isMobile, isTablet, isDesktop, isTabletUp } from 'hooks';
import { DefaultFontsConfig } from 'config/themes/types';
const getIsCurrentThemeDark = () => {
    var _a;
    const isDarkTheme = (_a = getIsDarkTheme()) !== null && _a !== void 0 ? _a : (window.matchMedia &&
        !!window.matchMedia('(prefers-color-scheme: dark)').matches);
    return isDarkTheme;
};
const getInitialState = () => {
    const isDarkTheme = getIsCurrentThemeDark();
    return {
        isDarkTheme: getIsCurrentThemeDark(),
        theme: isDarkTheme ? DARK_THEME : LIGHT_THEME,
    };
};
export const ThemeContext = React.createContext(getInitialState());
export const ThemeContextProvider = ({ children, isDarkMode, fontsConfig, }) => {
    const [isDarkTheme, setDarkTheme] = useState(getInitialState().isDarkTheme);
    useEffect(() => {
        if (typeof isDarkMode === 'boolean') {
            setDarkTheme(isDarkMode);
        }
    }, [isDarkMode]);
    const responsive = {
        isDesktop: isDesktop(),
        isMobile: isMobile(),
        isTabletUp: isTabletUp(),
        isTablet: isTablet(),
    };
    const selectTheme = useCallback((isDark) => {
        saveDarkTheme(isDark);
        setDarkTheme(getIsCurrentThemeDark());
    }, [isDarkTheme]);
    const value = useMemo(() => {
        const theme = isDarkTheme ? DARK_THEME : LIGHT_THEME;
        return {
            isDarkTheme,
            selectTheme,
            theme: Object.assign(Object.assign({}, theme), { responsive, fonts: Object.assign(Object.assign({}, DefaultFontsConfig), fontsConfig) }),
        };
    }, [isDarkTheme, selectTheme, responsive]);
    return (_jsx(ThemeContext.Provider, Object.assign({ value: value }, { children: children })));
};
export const ThemeWrap = ({ children }) => {
    const { theme } = useContext(ThemeContext);
    return _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: children }));
};
