import { Button as ComponentButton, Image as ImageComponent } from 'common';
import { Icon } from 'common/icon';
import styled, { css } from 'styled-components';
import { smallSemiBold } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-15xc6sz" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;
export const WrapperWallet = styled.div.withConfig({ displayName: "WrapperWallet", componentId: "sc-95jz9t" }) `
  display: flex;
`;
export const TextWallet = styled.div.withConfig({ displayName: "TextWallet", componentId: "sc-19w4od8" }) `
  margin-left: 10px;
  margin-right: 2px;

  @media screen and (max-width: 1023px) {
    display: none;
    margin: 0px;
    transition: all 0.3s ease-in-out;
  }
`;
export const WrapperNotification = styled.div.withConfig({ displayName: "WrapperNotification", componentId: "sc-16wemk4" }) `
  display: flex;

  margin: 0px 24px;
  padding: 8px 4px 8px 8px;
  border-radius: 20px;

  ${({ $isVisible }) => $isVisible &&
    css `
      background: ${({ theme }) => theme.config.dropdown.background.default};
    `}
}

@media screen and (max-width: 420px) {
  margin: 0px 10px;
}
`;
export const Arrow = styled(Icon.ArrowDown).withConfig({ displayName: "Arrow", componentId: "sc-1w8yi3k" }) `
  ${(props) => css `
    transform: rotate(${props.$isVisible ? '180deg' : '0deg'});
    transition: transform 0.3s ease-in-out;
  `}

  margin-left: 2px;
`;
export const Image = styled(ImageComponent).withConfig({ displayName: "Image", componentId: "sc-uvie6r" }) `
  border-radius: 50px;
`;
export const EmptyImage = styled.div.withConfig({ displayName: "EmptyImage", componentId: "sc-1nfbgqp" }) `
  border-radius: 50px;
  height: 40px;
  width: 40px;

  ${({ theme }) => css `
    background: ${theme.config.button.dark.background.default};
  `}
`;
export const ContentWallet = styled.div.withConfig({ displayName: "ContentWallet", componentId: "sc-18vb1bl" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
export const Button = styled(ComponentButton).withConfig({ displayName: "Button", componentId: "sc-wy3e52" }) `
  ${smallSemiBold};

  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: -0.01em;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-1p5qch2" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 16px;
`;
