import * as Yup from 'yup';
import { dispatchFormModel } from '../config';
const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
export const EmailValidationSchema = (error) => Yup.object().shape({
    [dispatchFormModel.manualInput.name]: Yup.string().matches(emailRegex, error),
});
export function isValidEmail(email) {
    return emailRegex.test(email);
}
export const EmailDispatchFormInitialValues = {
    chips: {
        inputChips: [],
        baseChips: [],
    },
    historyChips: [],
    dropdown: [],
    mailingText: '',
    mailingName: '',
    name: 'E-mail',
};
