import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Button, ButtonVariant, Text, TextVariant, } from '../../../../../../../../common';
import IconWrapper from '../../../../../../../../common/icon-wrapper/icon-wrapper';
import { Tooltip } from '../../../../../../../../common/tooltip';
import { useUpdateTariffMutation, } from '../../../../../../../../store/api/profile';
import { categories, tariffsModel } from './config';
import * as Styles from './styles';
import { useAlert } from 'react-alert';
import { handleMutation } from '../../../../../../../../utils/handleMutation';
export const TariffBlock = ({ tariffData, isSelected, setShowConstructor, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const alert = useAlert();
    const [updateTariff, responseUpdateTariff] = useUpdateTariffMutation();
    const additionalInfo = (desc) => {
        return (_jsx(Styles.SectionWrapper, { children: _jsx(Tooltip, Object.assign({ position: "right", text: desc }, { children: _jsx(Styles.Price, { children: _jsx(Styles.IconWrapper, { children: _jsx(Icon.Info, {}) }) }) })) }));
    };
    const statusIcon = (checked) => {
        if (checked) {
            return (_jsx(IconWrapper, Object.assign({ "$fillColor": theme.config.icon.green.active }, { children: _jsx(Icon.CheckmarkDrop, {}) })));
        }
        else {
            return (_jsx(IconWrapper, Object.assign({ "$fillColor": theme.config.icon.secondary }, { children: _jsx(Icon.Close, {}) })));
        }
    };
    const Category = ({ title, desc, name }) => {
        const isChecked = tariffData[name];
        const textColor = (checked) => {
            return checked ? 'primary' : 'tetriery';
        };
        return (_jsxs(_Fragment, { children: [_jsxs(Styles.FieldsRow, { children: [statusIcon(!!isChecked), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": textColor(tariffData.ai) }, { children: t(title) })), additionalInfo(desc)] }), name == 'marketplaces' && _jsx(Styles.Separator, {}), name == 'reports' && _jsx(Styles.Separator, {})] }));
    };
    const onSubmit = () => {
        updateTariff({
            ai: tariffData.ai,
            name: tariffData.name,
            parsers: tariffData.parsers,
            reports: tariffData.reports,
            advertising: tariffData.advertising,
            marketplaces: tariffData.marketplaces,
            text_mailing: tariffData.text_mailing,
            voice_mailing: tariffData.voice_mailing,
        });
    };
    const onSuccess = () => {
        alert.success(t('Tariff changed successfully'));
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseUpdateTariff), { onSuccess,
        onError }));
    return (_jsxs(Styles.Container, Object.assign({ "$isSelected": isSelected }, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium, "$colorVariant": "primary" }, { children: t(tariffData.name) })), categories.map((cat) => {
                return _createElement(Category, Object.assign({}, cat, { key: cat.name }));
            }), isSelected ? (_jsx(Button, { variant: ButtonVariant.TETRIERY, padding: "24px", text: 'Изменить тариф', onClick: setShowConstructor })) : (_jsx(Button, { variant: ButtonVariant.TETRIERY, padding: "24px", text: 'Выбрать тариф', onClick: onSubmit }))] })));
};
export const Tariffs = ({ selectedTariffName, setShowConstructor, }) => {
    return (_jsxs(Styles.Tariffs, { children: [_jsx(TariffBlock, { tariffData: tariffsModel.tariff1, isSelected: tariffsModel.tariff1.name === selectedTariffName, setShowConstructor: setShowConstructor }), _jsx(TariffBlock, { tariffData: tariffsModel.tariff2, isSelected: tariffsModel.tariff2.name === selectedTariffName, setShowConstructor: setShowConstructor }), _jsx(TariffBlock, { tariffData: tariffsModel.tariff3, isSelected: tariffsModel.tariff3.name === selectedTariffName, setShowConstructor: setShowConstructor })] }));
};
