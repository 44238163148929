import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { multiValueStyles } from './components';
import { scrollLight } from 'styles/mixins/scroll';
export const useStyles = (large, error, priority, country) => {
    const theme = useTheme();
    const customStyles = useMemo(() => ({
        menuPortal: (css) => (Object.assign(Object.assign({}, css), { zIndex: 9999 })),
        container: (css) => (Object.assign(Object.assign({}, css), { '.rs-option-icon, .rs-search-icon, .rs-searchable-option-icon': {
                lineHeight: 0,
            } })),
        valueContainer: (css) => (Object.assign({}, css)),
        multiValue: (css) => (Object.assign(Object.assign({}, css), multiValueStyles(theme))),
        placeholder: (css) => (Object.assign(Object.assign({}, css), { color: theme.config.select.text.disabled, fontWeight: 400, fontSize: '14px', lineHeight: '24px' })),
        control: (css, state) => (Object.assign(Object.assign({}, css), { width: '100%', minHeight: large ? '48px' : '24px', padding: '0 10px 0 0px', borderRadius: '30px', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)', background: state.isDisabled
                ? theme.config.select.bg
                : theme.config.select.bg, border: 'none', transition: 'border-color 0.2s ease-in-out', height: '40px', '&:hover': {
                borderColor: state.isFocused
                    ? theme.config.select.border.active
                    : (error && theme.config.select.border.errorHover) ||
                        theme.config.select.border.hover,
            }, '@media screen and (max-width: 500px)': {
                width: '320px',
            }, cursor: 'pointer', '.rs-search-icon': {
                display: state.menuIsOpen ? 'block' : 'none',
                transform: state.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
                marginRight: '8px',
                '& path': {
                    fill: theme.config.select.text.default,
                },
            }, '.rs-searchable-option-icon': {
                display: state.menuIsOpen ? 'none' : 'inherit',
            } })),
        singleValue: (css, state) => (Object.assign(Object.assign({}, css), { columnGap: 8, marginRight: 0, marginLeft: 0, color: state.isDisabled
                ? theme.config.select.text.disabled
                : theme.config.select.text.default })),
        input: (css) => (Object.assign(Object.assign({}, css), { color: theme.config.select.text.default, '& input': {
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                caretColor: theme.config.select.text.default,
            } })),
        indicatorsContainer: (css) => {
            return Object.assign(Object.assign({}, css), { display: 'flex', alignItems: 'center', maxHeight: large ? '58px' : '48px' });
        },
        indicatorContainer: (css) => {
            return Object.assign(Object.assign({}, css), { padding: 0 });
        },
        indicatorSeparator: (css) => {
            return Object.assign(Object.assign({}, css), { display: 'none' });
        },
        dropdownIndicator: (css, state) => {
            const { isDisabled, menuIsOpen } = state.selectProps;
            return Object.assign(Object.assign({}, css), { padding: '0', svg: {
                    transform: `rotate(${menuIsOpen ? '180deg' : '0deg'})`,
                    path: Object.assign({ fill: theme.config.select.text.default }, (isDisabled && { fill: theme.config.select.text.disabled })),
                    transition: 'transform 0.3s ease-out',
                } });
        },
        clearIndicator: (css) => {
            return Object.assign(Object.assign({}, css), { '.close-circle-icon': {
                    path: { fill: theme.config.select.clearIcon },
                } });
        },
        menu: (css, state) => {
            const { menuIsOpen } = state.selectProps;
            return Object.assign(Object.assign({}, css), { marginTop: 10, marginBottom: 0, borderRadius: theme.borderRadius, overflow: 'hidden', opacity: menuIsOpen ? '1' : '0', boxShadow: '0px 8px 24px 0px #001B4714', backgroundColor: theme.config.select.bg, zIndex: 2, transition: 'opacity 2s ease-out' });
        },
        menuList: (css, state) => {
            const { menuIsOpen } = state.selectProps;
            return Object.assign(Object.assign(Object.assign({}, css), { margin: '16px', padding: 0, opacity: menuIsOpen ? '1' : '0', transition: 'opacity 0.2s ease-out', scrollBehavior: 'smooth' }), scrollLight);
        },
        option: (css, state) => {
            const { isSelected, isDisabled } = state;
            let color;
            if (isDisabled) {
                color = theme.config.select.text.disabled;
            }
            else if (isSelected) {
                color = theme.config.select.text.active;
            }
            else {
                color = theme.config.select.text.default;
            }
            return Object.assign(Object.assign({}, css), { display: 'flex', alignItems: 'center', columnGap: 8, padding: '8px 16px', fontWeight: 400, fontSize: '14px', lineHeight: '24px', color: color, cursor: 'pointer', transition: 'background 0.2s ease-out', background: (state.isSelected && theme.config.select.option.selected) ||
                    theme.config.select.bg, ':hover': {
                    color: theme.config.select.text.active,
                    background: theme.config.select.option.hover,
                } });
        },
    }), [theme, large, error]);
    const countryStyles = useMemo(() => (Object.assign(Object.assign({}, customStyles), { multiValue: (css) => (Object.assign(Object.assign({}, css), { borderRadius: theme.borderRadius, backgroundColor: theme.config.select.countryMultiValue.bg, display: 'flex', alignItems: 'center', "div[role='button']": {
                paddingLeft: '0px',
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: 'transparent' },
                '&:active': { backgroundColor: 'transparent' },
                svg: {
                    width: '14px',
                    height: '14px',
                    path: { fill: theme.config.select.multiValue.text },
                },
            } })) })), [theme, country]);
    //  priorityStyles
    const priorityStyles = useMemo(() => ({
        container: (css) => (Object.assign(Object.assign({}, css), { '.rs-option-icon, .rs-search-icon, .rs-searchable-option-icon': {
                lineHeight: 0,
            } })),
        placeholder: (css) => (Object.assign(Object.assign({}, css), { color: theme.config.text.primary, fontWeight: 400, fontSize: '12px', lineHeight: '16px', padding: '0' })),
        control: (css, state) => {
            const background = theme.config.select.bg;
            return Object.assign(Object.assign({}, css), { minHeight: large ? '24px' : '20px', width: '80px', padding: '0', borderRadius: '4px', boxShadow: 'none', background, border: 'none', transition: 'border-color 0.2s ease-in-out', '& svg': {
                    width: '16px',
                    height: '16px',
                    '& path': {
                        fill: state.hasValue
                            ? theme.config.select.text.default
                            : theme.config.text.primary,
                    },
                    transform: state.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
                }, cursor: 'pointer' });
        },
        valueContainer: (css) => (Object.assign(Object.assign({}, css), { height: '100%', padding: '0 8px' })),
        singleValue: (css, state) => {
            var _a, _b;
            const name = (_b = (_a = state.getValue()) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value;
            const customTextColor = state.hasValue && name && theme.config.background.primary;
            const style = Object.assign(Object.assign({}, css), { display: 'flex', alignItems: 'center', fontWeight: 400, fontSize: '12px', lineHeight: '16px', columnGap: 8, marginRight: 0, marginLeft: 0, color: state.hasValue
                    ? theme.config.text.primary
                    : theme.config.text.primary });
            if (customTextColor) {
                style.color = customTextColor;
            }
            return style;
        },
        input: (css) => (Object.assign(Object.assign({}, css), { color: theme.config.select.text.default, '& input': {
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
            } })),
        indicatorsContainer: (css) => (Object.assign(Object.assign({}, css), { display: 'flex', background: 'transparent', paddingRight: '4px' })),
        dropdownIndicator: (css) => (Object.assign(Object.assign({}, css), { padding: 0 })),
        clearIndicator: (css) => (Object.assign(Object.assign({}, css), { display: 'none' })),
        menu: (css) => (Object.assign(Object.assign({}, css), { left: '-25px', width: '236px', marginTop: '10px', marginBottom: 0, borderRadius: theme.borderRadius, boxShadow: '0px 8px 24px 0px #001B4714', backgroundColor: theme.config.select.bg, zIndex: 2, '&:before': {
                content: '""',
                position: 'absolute',
                top: '-3px',
                right: '55px',
                width: '9px',
                height: '9px',
                transform: 'rotate(45deg)',
                boxShadow: '8px 8px 24px #001b47',
                background: theme.config.select.bg,
            } })),
        menuList: (css) => (Object.assign(Object.assign({}, css), { padding: 0, borderRadius: theme.borderRadius })),
        indicatorSeparator: (css) => (Object.assign(Object.assign({}, css), { display: 'none' })),
        option: (css, state) => (Object.assign(Object.assign({}, css), { display: 'flex', alignItems: 'center', width: '236px', height: '40px', padding: 12, fontWeight: 400, fontSize: '14px', lineHeight: '24px', color: theme.config.select.text.default, cursor: 'pointer', backgroundColor: (state.isSelected && theme.config.select.option.selected) ||
                theme.config.select.bg, ':hover': {
                backgroundColor: theme.config.select.option.selected,
            } })),
    }), [theme, large, error, priority]);
    return priority ? priorityStyles : country ? countryStyles : customStyles;
};
