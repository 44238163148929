import { Button as ButtonComponent } from 'common';
import styled, { css } from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-1yuk0j5" }) `
  padding: 24px 12px;
  border-radius: 20px;
  border-collapse: separate;

  border: ${({ theme }) => theme.config.dropdown.border.all};
  background-color: ${({ theme }) => theme.config.background.tetriery};
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1v4igtl" }) `
  width: 100%;

  ${({ isScrollable }) => css `
    ${scrollMedium};
    overflow-y: ${isScrollable ? 'scroll' : 'hidden'};
    max-height: ${isScrollable ? 'calc(100vh - 230px)' : 'auto'};
    padding-right: ${isScrollable ? '16px' : '0px'};
  `}
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-oepgtl" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  margin: 6px 0px 0px 16px;
  border-radius: 50%;

  ${({ theme }) => css `
    border: ${theme.config.uploadImage.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}
  &:hover {
    background: none;
  }
`;
export const WrapperTableMenu = styled.div.withConfig({ displayName: "WrapperTableMenu", componentId: "sc-1xgni19" }) `
  position: absolute;
  right: 30%;
  z-index: 100;
`;
export const CheckboxContainer = styled.div.withConfig({ displayName: "CheckboxContainer", componentId: "sc-1i96uh1" }) `
  display: flex;
  align-items: center;
  margin-right: 16px;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-ygdw1p" }) ``;
export const TableHeaderParserResult = styled.div.withConfig({ displayName: "TableHeaderParserResult", componentId: "sc-idotse" }) `
  display: flex;
  flex-direction: row;

  margin-bottom: 12px;
  padding: 8px 0px 8px 8px;
`;
export const TableRowParserResult = styled.div.withConfig({ displayName: "TableRowParserResult", componentId: "sc-1hcl27a" }) `
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
export const WrapperTableItemParsingResult = styled.div.withConfig({ displayName: "WrapperTableItemParsingResult", componentId: "sc-1w8u5oe" }) `
  border-radius: 16px;
  padding: 16px 0px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background-color: ${({ theme }) => theme.config.background.secondary};
`;
export const TableRowContentParerResult = styled.div.withConfig({ displayName: "TableRowContentParerResult", componentId: "sc-xwpxjj" }) `
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 12px 0px 12px 12px;

  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.config.input.green.hover};
  }

  ${({ $isShortParser }) => $isShortParser &&
    css `
      flex: none;
    `}
`;
export const TableColumnParserResult = styled.div.withConfig({ displayName: "TableColumnParserResult", componentId: "sc-1mwmevb" }) `
  display: flex;
  align-items: center;
  height: 100%;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  ${({ $isMinWidth }) => $isMinWidth
    ? css `
          width: fit-content;
          padding-right: 12px;
        `
    : css `
          flex: 1;
        `}
  &:nth-child(n + 2) {
    padding-left: 16px;
    border-left: ${({ theme }) => theme.config.dropdown.border.all};
  }
`;
