import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Tooltip } from 'common/tooltip';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from 'common/button';
import { AppRoutes } from 'config';
import * as Styles from './styles';
export const Modal = ({ templates }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { Home, CreateCompany } = AppRoutes.Dashboard;
    const templateInfo = useMemo(() => {
        return templates === null || templates === void 0 ? void 0 : templates.find((template) => template.path === pathname);
    }, [templates, pathname]);
    return (_jsx(Styles.WrapperModal, { children: _jsxs(Styles.Container, { children: [_jsxs(Styles.WrapperText, { children: [_jsx(Styles.Title, { children: t((_a = templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.title) !== null && _a !== void 0 ? _a : '') }), _jsx(Styles.SubTitle, { children: t((_b = templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.subTitle) !== null && _b !== void 0 ? _b : '') })] }), _jsx(Tooltip, Object.assign({ position: "top", text: "This is Tooltip" }, { children: _jsx(Button, { text: t('Create a company'), to: Home + CreateCompany }) }))] }) }));
};
