import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiQuery } from 'store/query';
import { getToken } from '../../utils/storage';
const tagTypes = ['Me', 'Companies', 'CompaniesMain'];
export const profileApi = createApi({
    tagTypes,
    reducerPath: 'profileApi',
    baseQuery: apiQuery,
    endpoints: (build) => ({
        me: build.query({
            query: () => ({
                url: `${URLS.ME}`,
                method: 'GET',
            }),
            providesTags: ['Me'],
        }),
        updateProfile: build.mutation({
            query: ({ email, phone, first_name, last_name, }) => ({
                method: 'PATCH',
                url: URLS.ME,
                data: {
                    email,
                    phone,
                    first_name,
                    last_name,
                },
            }),
            invalidatesTags: ['Me'],
        }),
        avatar: build.query({
            query: (url) => ({
                url: `${url}`,
                method: 'GET',
            }),
        }),
        updateAvatar: build.mutation({
            query: ({ avatar }) => {
                const formData = new FormData();
                formData.append('file', avatar);
                return {
                    method: 'PATCH',
                    url: URLS.UPDATE_AVATAR,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData,
                };
            },
            invalidatesTags: ['Me'],
        }),
        deleteMe: build.mutation({
            query: () => ({
                url: URLS.ME,
                method: 'DELETE',
            }),
        }),
        selectCompany: build.mutation({
            query: (values) => {
                return {
                    url: URLS.USER_SELECT_COMPANY,
                    method: 'POST',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: ['Me'],
        }),
        updateTariff: build.mutation({
            query: (values) => {
                return {
                    url: URLS.USER_UPDATE_TARIFF,
                    method: 'POST',
                    params: {
                        tariff: JSON.stringify(values),
                    },
                };
            },
            invalidatesTags: ['Me'],
        }),
        createTicket: build.mutation({
            query: (values) => {
                return {
                    url: URLS.NEW_TICKET,
                    method: 'POST',
                    params: {
                        text: values.text,
                    },
                };
            },
        }),
        confirmEmail: build.mutation({
            query: (values) => {
                return {
                    url: URLS.ACTIVATE_EMAIL,
                    method: 'POST',
                    params: {
                        code: values.code,
                    },
                };
            },
        }),
        checkInn: build.mutation({
            query: (values) => {
                return {
                    url: URLS.CHECK_INN,
                    method: 'POST',
                    params: {
                        inn: values.inn,
                    },
                };
            },
        }),
    }),
});
export const useMeQuery = () => {
    return profileApi.useMeQuery(getToken());
};
export const { useAvatarQuery, useDeleteMeMutation, useUpdateAvatarMutation, useUpdateProfileMutation, useSelectCompanyMutation, useUpdateTariffMutation, useCreateTicketMutation, useCheckInnMutation, useConfirmEmailMutation, } = profileApi;
