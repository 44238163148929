import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ResultPage } from 'components/parser-results/result-page';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, ButtonVariant } from '../../../../common';
import { CommentsDialog } from './commentsDialog';
import dayjs from 'dayjs';
export const VkPostsResult = ({ data }) => {
    var _a;
    const { t } = useTranslation();
    const parsedData = data === null || data === void 0 ? void 0 : data.parser_data;
    const [selectedPost, setSelectedPost] = useState();
    const [isMoreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
    const handleOpenInfoModal = () => {
        setMoreInfoModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ResultPage, { filterField: "post_text", title: (data === null || data === void 0 ? void 0 : data.parser_type) || 'VK Groups', dataHeader: [
                    t('Text'),
                    t('Created'),
                    t('Likes'),
                    t('Reposts'),
                    t('Views'),
                    t('Number comments'),
                    parsedData[0].comments && 'Комментарии',
                ], data: parsedData, dataRows: (row, index) => {
                    var _a, _b, _c;
                    return [
                        _jsx(Link, Object.assign({ to: `https://vk.com/public${row.owner_id.split('-')[1]}?w=wall${row.owner_id}_${row.post_id}`, target: '_blank' }, { children: row.post_text.length > 0 ? row.post_text : 'No text available' })),
                        (_a = dayjs(row.crated_at).locale('ru').format('HH:mm, D MMM, YYYY')) !== null && _a !== void 0 ? _a : 'None',
                        row.post_likes,
                        row.post_reposts,
                        row.post_views,
                        row.number_of_comments,
                        parsedData[0].comments && (_jsx(Button, { text: 'Просмотр', variant: ButtonVariant.LIGHT, disabled: ((_c = (_b = parsedData[index]) === null || _b === void 0 ? void 0 : _b.comments) === null || _c === void 0 ? void 0 : _c.length) === 0, onClick: () => {
                                handleOpenInfoModal();
                                setSelectedPost(parsedData[index]);
                            } }, index)),
                    ];
                } }), _jsx(CommentsDialog, { postName: selectedPost === null || selectedPost === void 0 ? void 0 : selectedPost.post_text, isModalOpen: isMoreInfoModalOpen, setModalOpen: setMoreInfoModalOpen, commentsData: (_a = selectedPost === null || selectedPost === void 0 ? void 0 : selectedPost.comments) === null || _a === void 0 ? void 0 : _a.split('\nid') })] }));
};
