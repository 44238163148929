import { InputColorType, InputSize } from 'common/input/types';
import * as Yup from 'yup';
import { InputMasks } from 'common/fields/input-field';
export const model = {
    inn: {
        label: '',
        type: 'text',
        name: 'inn',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.inn,
        placeholder: 'INN',
    },
    email: {
        label: '',
        type: 'text',
        name: 'email',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'E-mail',
        colorType: InputColorType.GREEN,
    },
    name: {
        label: '',
        type: 'text',
        name: 'name',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Name',
        colorType: InputColorType.GREEN,
    },
    phone: {
        label: '',
        type: 'text',
        name: 'phone',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Phone',
        colorType: InputColorType.GREEN,
    },
    address: {
        label: '',
        type: 'text',
        name: 'address',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Address',
        colorType: InputColorType.GREEN,
    },
    bankDetails: {
        label: '',
        type: 'text',
        name: 'bankDetails',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Bank Details',
        color: 'lightgreen',
        colorType: InputColorType.LIGHT,
    },
    password: {
        label: '',
        type: 'password',
        name: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Password',
        colorType: InputColorType.LIGHT,
    },
    confirmPassword: {
        label: '',
        type: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        name: 'confirmPassword',
        placeholder: 'Confirm Password',
        colorType: InputColorType.LIGHT,
    },
};
export const validationSchema = (step, t) => {
    if (step === 1) {
        return Yup.object().shape({
            [model.inn.name]: Yup.string()
                .required(t('INN is required'))
                .min(4, t('Invalid INN')),
        });
    }
    if (step === 2) {
        return Yup.object().shape({
            [model.email.name]: Yup.string()
                .email('Invalid E-Mail format')
                .required(t('INN is required')),
            [model.phone.name]: Yup.string()
                .required(t('Phone is required'))
                .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, t('Invalid phone format')),
        });
    }
    if (step === 3) {
        return Yup.object().shape({
            [model.bankDetails.name]: Yup.string().required(),
        });
    }
    if (step === 4) {
        return Yup.object().shape({
            [model.password.name]: Yup.string()
                .required()
                .min(8)
                .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
                .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
                .matches(/^(?=.*\d)/, 'Password must contain at least one digit'),
            [model.confirmPassword.name]: Yup.string()
                .required(t('Password is required'))
                .test('passwords-match', t('Passwords do not match'), function (value) {
                return value === this.resolve(Yup.ref(model.password.name));
            }),
        });
    }
};
export const initialValues = {
    [model.inn.name]: '',
};
