import * as Yup from 'yup';
export const helpModalValidationSchema = () => Yup.object().shape({
    selectedTab: Yup.object()
        .shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
    })
        .required(),
    userInput: Yup.string().required().min(10),
});
export const helpModalInitialValues = {
    selectedTab: null,
    userInput: '',
};
