import { Button as ComponentButton } from 'common';
import { Icon } from 'common/icon';
import styled, { css } from 'styled-components';
import { heading1Medium, heading2Medium, smallSemiBold, } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-196zhi8" }) `
  width: 100%;
  height: 280px;
  border-radius: 16px;
  background: ${({ theme }) => theme.config.background.tetriery};
  border: ${({ theme }) => theme.config.dropdown.border.all};
  @media screen and (max-width: 1600px) {
    min-height: 200px;
    height: 100%;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1rm9gai" }) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 22px;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-myg80p" }) `
  ${heading1Medium}

  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.config.text.primary};

  @media screen and (max-width: 560px) {
    ${heading2Medium}
  }
`;
export const Button = styled(ComponentButton).withConfig({ displayName: "Button", componentId: "sc-d41qta" }) `
  width: 158px;
  display: flex;
  margin-left: 72px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 200px;
    margin-left: 0px;
  }
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-1ge81d0" }) `
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  flex-basis: 464px;

  @media screen and (max-width: 378px) {
    flex-wrap: nowrap;
    max-width: 280px;
  }
`;
export const Company = styled.div.withConfig({ displayName: "Company", componentId: "sc-t922g3" }) `
  ${smallSemiBold};

  letter-spacing: -0.01em;
  margin-left: 16px;
  color: ${({ theme }) => theme.config.text.primary};
`;
export const IconBefore = styled(Icon.Building).withConfig({ displayName: "IconBefore", componentId: "sc-mw35v3" }) ``;
export const IconAfter = styled(Icon.Right).withConfig({ displayName: "IconAfter", componentId: "sc-qx6e7e" }) `
  ${({ theme }) => css `
    path,
    fill {
      fill: ${theme.config.slider.icon.default};
    }
  `}
`;
export const ContainerButton = styled.div.withConfig({ displayName: "ContainerButton", componentId: "sc-1yyw79w" }) `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 16px 15px 24px;
  border-radius: 278px;
  cursor: pointer;
  background: ${({ theme }) => theme.config.background.secondary};
`;
export const BottomContent = styled.div.withConfig({ displayName: "BottomContent", componentId: "sc-2z7fkj" }) `
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  margin-top: 12px;

  @media screen and (max-width: 1600px) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 948px) {
    height: 180px;
    flex-direction: column;
    justify-content: flex-end;
  }

  @media screen and (max-width: 560px) {
    justify-content: center;
  }

  /* @media screen and (max-width: 760px) {
    height: 180px;
    flex-direction: column;
  } */
`;
export const Left = styled.div.withConfig({ displayName: "Left", componentId: "sc-mbrear" }) `
  display: flex;
`;
export const BottomRight = styled.div.withConfig({ displayName: "BottomRight", componentId: "sc-10styoi" }) `
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 948px) {
    justify-content: flex-end;
  }

  @media screen and (max-width: 560px) {
    align-items: center;
    justify-content: center;
  }
`;
export const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-hgzrzj" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 15px 16px 15px 24px;
  border-radius: 278px;
  background: ${({ theme }) => theme.config.background.secondary};
`;
