import { InputColorType, InputSize } from 'common/input/types';
import * as Yup from 'yup';
export const model = {
    email: {
        label: '',
        type: 'text',
        inputType: 'text',
        name: 'email',
        withBorder: false,
        placeholder: 'E-mail',
        size: InputSize.SMALL,
        colorType: InputColorType.GREEN,
    },
    password: {
        label: '',
        type: 'password',
        name: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Password',
    },
};
export const validationSchema = (t) => Yup.object().shape({
    [model.email.name]: Yup.string()
        .required()
        .email(t('Invalid E-Mail format')),
    [model.password.name]: Yup.string()
        .required()
        .min(8)
        .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
        .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
        .matches(/^(?=.*\d)/, 'Password must contain at least one digit'),
});
export const initialValues = {
    [model.email.name]: '',
    [model.password.name]: '',
};
