import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant } from 'common';
import { Icon } from 'common/icon';
import { Table } from 'components';
import { TableRowVariant } from 'components/table';
import { AppRoutes } from 'config';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import 'dayjs/locale/ru';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { formatId } from 'utils/formatId';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import { useCompaniesListQuery, useCompanyDeleteMutation, } from '../../../../../store/api/companies';
import { useMeQuery, useSelectCompanyMutation, } from '../../../../../store/api/profile';
import { handleMutation } from '../../../../../utils/handleMutation';
import * as Styles from './styles';
import { DeleteModal } from '../../../../../components/modal/delete-modal';
export const TableCompany = () => {
    const { t } = useTranslation();
    const { data: me, refetch: refetchMe } = useMeQuery();
    const alert = useAlert();
    const { Home, MyCompany } = AppRoutes.Dashboard;
    const [companies, setCompanies] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [deleteCompany, responseDeleteCompany] = useCompanyDeleteMutation();
    const [selectCompany, responseSelectCompany] = useSelectCompanyMutation();
    const { data: companiesList } = useCompaniesListQuery();
    const [isDeleteCompanyVisible, setIsDeleteCompanyVisible] = useState(false);
    const handleDeleteCompanyVisible = () => {
        setIsDeleteCompanyVisible(!isDeleteCompanyVisible);
    };
    const [selectedItemMenuDelete, setSelectedItemMenuDelete] = useState();
    useEffect(() => {
        if (companiesList) {
            setCompanies(companiesList);
        }
    }, [companiesList]);
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    const onSuccessSelect = () => {
        alert.success(t('Company selected successfully'));
    };
    handleMutation(Object.assign(Object.assign({}, responseSelectCompany), { onSuccess: () => {
            onSuccessSelect();
            refetchMe();
        }, onError }));
    const handleButtonPress = (index) => {
        if (me && me._id && companies[index].company_id) {
            setSelectedItemMenuDelete(index);
            handleDeleteCompanyVisible();
        }
    };
    const handleDeleteCompany = () => {
        var _a;
        if (me &&
            me._id &&
            typeof selectedItemMenuDelete === 'number' &&
            companies[selectedItemMenuDelete].company_id) {
            deleteCompany({
                user_id: me._id.toString(),
                company_id: (_a = companies[selectedItemMenuDelete]) === null || _a === void 0 ? void 0 : _a.company_id.toString(),
            });
        }
    };
    const onSuccessDelete = () => {
        alert.success(t('Company deleted successfully'));
    };
    handleMutation(Object.assign(Object.assign({}, responseDeleteCompany), { onSuccess: onSuccessDelete, onError }));
    const handleCompanySelect = (companyId) => {
        selectCompany({
            user_id: me._id.toString(),
            company_id: companyId,
        });
    };
    const handleAllRowsChecked = () => {
        const isAllChecked = checkedRows.length === companies.length;
        if (isAllChecked) {
            setCheckedRows([]);
        }
        else {
            setCheckedRows(companies.map((_, index) => index));
        }
    };
    const handleRowChecked = (index) => {
        if (checkedRows.includes(index)) {
            setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
        }
        else {
            setCheckedRows([...checkedRows, index]);
        }
    };
    const renderName = (name) => {
        return (_jsxs(Styles.NameCompany, { children: [_jsx(Styles.WrapperIcon, { children: _jsx(Icon.Building, {}) }), name] }));
    };
    const renderButton = (companyId) => {
        return (_jsx(Styles.WrapperButton, { children: _jsx(Styles.Button, { padding: "12px 24px", text: t('Go over'), variant: ButtonVariant.TETRIERY, to: Home + MyCompany.Home + `/${companyId}` + MyCompany.CompanyData }) }));
    };
    const renderButtonSelect = (companyId) => {
        if (me && me.selected_company !== companyId.toString()) {
            return (_jsx(Styles.WrapperButton, { children: _jsx(Styles.Button, { padding: "12px 24px", text: t('Select'), variant: ButtonVariant.TETRIERY, onClick: () => handleCompanySelect(companyId.toString()) }) }));
        }
        else {
            return (_jsx(Styles.WrapperButton, { children: _jsx(Styles.Button, { padding: "12px 24px", text: t('Unselect'), variant: ButtonVariant.LIGHT, onClick: () => handleCompanySelect('-') }) }));
        }
    };
    return (_jsxs(Styles.ContainerTable, { children: [_jsx(Table, { icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.Delete, {}) })), isHiddenLast: true, checkedRows: checkedRows, onRowChecked: handleRowChecked, rowVariant: TableRowVariant.SMALL, onAllRowsChecked: handleAllRowsChecked, handleButton: handleButtonPress, dataHeader: [
                    '№',
                    t('Name'),
                    t('Last activity'),
                    t('Date of creation'),
                    '\u00A0',
                    '\u00A0',
                ].filter((item) => item), data: companies.map((row, index) => [
                    formatId(index + 1),
                    renderName(row.company_name),
                    dayjs(new Date()).locale('ru').format('D MMM, YYYY'),
                    dayjs(new Date()).locale('ru').format('D MMM, YYYY'),
                    renderButton(row.company_id),
                    renderButtonSelect(row.company_id),
                ].filter((item) => item)) }), _jsx(DeleteModal, { isVisible: isDeleteCompanyVisible, onClick: handleDeleteCompanyVisible, message: 'Вы точно хотите удалить выбранную компанию?', modalTitle: 'Удалить компанию', buttonText: 'Да, удалить', deleteFunc: handleDeleteCompany })] }));
};
