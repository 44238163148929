import { createSelector, createSlice } from '@reduxjs/toolkit';
import { authApi } from 'store/api/auth';
import { clearAll, getToken, saveToken } from 'utils/storage';
import { logout } from '../thunks/auth/auth';
const getInitialValue = () => {
    const isLoggedIn = getToken() !== undefined;
    return {
        isLoggedIn,
    };
};
const reset = (state) => {
    return Object.assign(Object.assign({}, state), { isLoggedIn: false });
};
export const authSlice = createSlice({
    name: 'authSlice',
    initialState: getInitialValue(),
    reducers: {
        clearAuth: (state) => {
            return reset(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => {
            state.isLoggedIn = false;
        });
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
            const { access_token: accessToken } = payload;
            const isLoggedIn = accessToken !== undefined;
            if (isLoggedIn) {
                saveToken(accessToken);
            }
            else {
                clearAll();
            }
            return Object.assign(Object.assign({}, state), { isLoggedIn });
        });
    },
});
export const { actions: { clearAuth }, } = authSlice;
const authStore = (store) => store.authSlice;
export const selectIsLoggedIn = () => createSelector([authStore], (store) => { var _a; return (_a = store === null || store === void 0 ? void 0 : store.isLoggedIn) !== null && _a !== void 0 ? _a : false; });
