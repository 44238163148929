import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import * as Styles from './styles';
export const Tooltip = ({ top, right, bottom, left, text, children, position, translateX, translateY, }) => {
    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const handleMouseOver = () => {
        setIsShowTooltip(true);
    };
    const handleMouseOut = () => {
        setIsShowTooltip(false);
    };
    return (_jsxs(_Fragment, { children: [React.cloneElement(children, {
                onMouseOver: handleMouseOver,
                onMouseOut: handleMouseOut,
            }), _jsx(Styles.ContainerTooltip, { children: _jsx(Styles.WrapperTooltip, Object.assign({ "$top": top, "$right": right, "$bottom": bottom, "$left": left, "$position": position || 'top', "$translateX": translateX, "$translateY": translateY, "$isVisible": isShowTooltip }, { children: text })) })] }));
};
