var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { useLocation, useNavigate as useRouteNavigate, } from 'react-router-dom';
export const getNavigationState = () => ({
    backPath: window.location.pathname + window.location.search,
});
export const useNavigate = () => {
    const routeNavigate = useRouteNavigate();
    const navigate = useCallback((to, options) => {
        if (typeof to === 'number') {
            routeNavigate(to);
            return;
        }
        const _a = options !== null && options !== void 0 ? options : {}, { state } = _a, opts = __rest(_a, ["state"]);
        routeNavigate(to, Object.assign({ state: Object.assign(Object.assign({}, state), getNavigationState()) }, opts));
    }, [routeNavigate]);
    return navigate;
};
export const useBackPath = (defaultPath) => {
    var _a;
    const location = useLocation();
    return ((_a = (location.state && location.state.backPath)) !== null && _a !== void 0 ? _a : defaultPath);
};
