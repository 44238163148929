import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState, } from 'react';
import { Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { Search, Web } from '../../../../../../common/icon/icons';
import * as Styles from './styles';
const mockSelectItems = [
    {
        description: 'Люди, посещающие сайты, схожие с brendboost.ru',
        title: 'brendboost.ru',
        icon: _jsx(Web, {}),
    },
    {
        description: 'Люди, посещающие сайты, схожие с yandex.ru',
        title: 'yandex.ru',
        icon: _jsx(Web, {}),
    },
    {
        description: 'Люди, посещающие сайты, схожие с google.com',
        title: 'google.com',
        icon: _jsx(Web, {}),
    },
    {
        description: 'Люди, посещающие сайты, схожие с youtube.ru',
        title: 'youtube.ru',
        icon: _jsx(Web, {}),
    },
    {
        description: 'Люди, посещающие сайты, схожие с spotify.com',
        title: 'spotify.com',
        icon: _jsx(Web, {}),
    },
    {
        description: 'Люди, посещающие сайты, схожие с kinopoisk.ru',
        title: 'kinopoisk.ru',
        icon: _jsx(Web, {}),
    },
];
const SelectItem = ({ item, setChips, setSelectItems, setSearchInput, }) => {
    return (_jsxs(Styles.SelectItemContainer, Object.assign({ onClick: () => {
            setChips((prevState) => [
                ...prevState,
                {
                    label: item.title,
                    value: item.title,
                },
            ]);
            setSelectItems((prevState) => prevState.filter((prev) => prev.title !== item.title));
            setSearchInput('');
        } }, { children: [_jsx(Styles.SelectItemIconContainer, { children: _jsx(IconWrapper, Object.assign({ "$strokeColor": 'white', "$fillColor": 'white' }, { children: item.icon })) }), _jsxs(Styles.SelectItemTextContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'primary' }, { children: item.title })), _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'tetriery' }, { children: item.description }))] })] })));
};
export const AdvSelect = ({}) => {
    const [chips, setChips] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectItems, setSelectItems] = useState(mockSelectItems);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const AdvSelectRef = useRef(null);
    useEffect(() => {
        const handler = (event) => {
            if (!event.composedPath().includes(AdvSelectRef.current)) {
                setIsSelectOpen(false);
            }
        };
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);
    return (_jsxs("div", Object.assign({ ref: AdvSelectRef }, { children: [_jsxs(Styles.InputContainer, Object.assign({ onClick: () => setIsSelectOpen(true) }, { children: [chips === null || chips === void 0 ? void 0 : chips.map((chip) => (_jsxs(Styles.Chip, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'secondary' }, { children: chip.label })), _jsx(Styles.ChipCloseIcon, { onClick: () => setChips(chips.filter((c) => c !== chip)) })] }, chip.value))), _jsx(Styles.SelectInput, { "$isValue": !!searchInput, onChange: (event) => {
                            setSearchInput(event.target.value);
                        }, html: searchInput })] })), isSelectOpen && (searchInput || !!chips.length) && (_jsx(Styles.AdvSelectWrapper, { children: _jsxs(Styles.AdvSelectContainer, { children: [searchInput && (_jsx(Styles.SearchItemWrapper, { children: _jsx(SelectItem, { item: {
                                    title: searchInput,
                                    description: `Люди, которые ищут ${searchInput} в поиске`,
                                    icon: _jsx(Search, {}),
                                }, setChips: setChips, setSelectItems: setSelectItems, setSearchInput: setSearchInput }) })), selectItems.map((item) => (_jsx(SelectItem, { item: {
                                title: item.title,
                                description: item.description,
                                icon: item.icon,
                            }, setChips: setChips, setSelectItems: setSelectItems, setSearchInput: setSearchInput }, item.title)))] }) }))] })));
};
