import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { HeaderTabsInfo } from 'components';
import { AppRoutes } from 'config';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { CompanyDataPage } from '../company-data';
import { LegalDataPage } from '../legal-data';
import { useCompanyDeleteMutation } from '../../../../../../store/api/companies';
import { handleMutation } from '../../../../../../utils/handleMutation';
import { useAlert } from 'react-alert';
import { useMeQuery } from '../../../../../../store/api/profile';
import { useNavigate } from '../../../../../../hooks';
import { DeleteModal } from '../../../../../../components/modal/delete-modal';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1h0gdll" }) `
  margin-bottom: 24px;
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-15fypza" }) `
  display: flex;
  align-items: center;
`;
const NameCompany = styled.div.withConfig({ displayName: "NameCompany", componentId: "sc-701132" }) `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 16px;
  margin-right: 20px;
  white-space: nowrap;
`;
export const WrapperIcon = styled(IconWrapper).withConfig({ displayName: "WrapperIcon", componentId: "sc-1d7fvsj" }) `
  ${({ theme }) => css `
    path,
    fill {
      fill: ${theme.config.slider.icon.secondary};
    }
  `}
`;
export const ButtonDelete = styled(Button).withConfig({ displayName: "ButtonDelete", componentId: "sc-115wpdv" }) `
  white-space: nowrap;
  margin-left: 20px;
`;
const { Home, MyCompany } = AppRoutes.Dashboard;
const CompanyInfoTypes = {
    LegalData: 'legal-data',
    Statistics: 'statistics',
    CompanyData: 'company-data',
};
const headerValue = [
    // {
    //   label: 'Statistics',
    //   Content: StatisticsPage,
    //   id: CompanyInfoTypes.Statistics,
    //   value: Home + MyCompany.Home,
    // },
    {
        label: 'General company data',
        Content: CompanyDataPage,
        id: CompanyInfoTypes.CompanyData,
        value: Home + MyCompany.Home,
    },
    {
        label: 'Legal data',
        Content: LegalDataPage,
        id: CompanyInfoTypes.LegalData,
        value: Home + MyCompany.Home,
    },
];
export const HeaderMyCompany = ({ name }) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const alert = useAlert();
    const [deleteCompany, responseDeleteCompany] = useCompanyDeleteMutation();
    const { data: me } = useMeQuery();
    const navigate = useNavigate();
    const [updatedHeaderValue, setUpdatedHeaderValue] = useState([]);
    const [isDeleteCompanyVisible, setIsDeleteCompanyVisible] = useState(false);
    const handleDeleteCompanyVisible = () => {
        setIsDeleteCompanyVisible(!isDeleteCompanyVisible);
    };
    const onHandleButtonPress = () => {
        handleDeleteCompanyVisible();
    };
    const onDelete = () => {
        if (me && me._id) {
            deleteCompany({
                user_id: me._id.toString(),
                company_id: id.toString(),
            });
        }
    };
    const onSuccess = () => {
        alert.success(t('Company deleted successfully'));
        navigate(`/dashboard/my-company/`);
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseDeleteCompany), { onSuccess,
        onError }));
    useEffect(() => {
        const updatedValues = headerValue.map((item) => {
            const updatedItem = Object.assign({}, item);
            // if (updatedItem.id === CompanyInfoTypes.Statistics) {
            //   updatedItem.value += `/${id}` + MyCompany.Statistics;
            // } else
            if (updatedItem.id === CompanyInfoTypes.LegalData) {
                updatedItem.value += `/${id}` + MyCompany.LegalData;
            }
            else if (updatedItem.id === CompanyInfoTypes.CompanyData) {
                updatedItem.value += `/${id}` + MyCompany.CompanyData;
            }
            return updatedItem;
        });
        setUpdatedHeaderValue(updatedValues);
    }, [id]);
    return (_jsxs(Container, { children: [_jsxs(Wrapper, { children: [_jsxs(NameCompany, { children: [_jsx(WrapperIcon, { children: _jsx(Icon.Building, {}) }), _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: name }))] }), _jsx(HeaderTabsInfo, { itemsTab: updatedHeaderValue }), _jsx(ButtonDelete, { text: t('Delete company'), icon: _jsx(Icon.Delete, {}), variant: ButtonVariant.TETRIERY, onClick: onHandleButtonPress })] }), _jsx(DeleteModal, { isVisible: isDeleteCompanyVisible, onClick: handleDeleteCompanyVisible, message: 'Вы точно хотите удалить компанию?', modalTitle: 'Удалить компанию', buttonText: 'Да, удалить', deleteFunc: onDelete })] }));
};
