var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import React, { useRef } from 'react';
// import { Props as CreatableSelectProps } from 'react-select';
// import { StyledComponent } from 'styled-components';
import ReactSelect from 'react-select';
import { components as ReactSelectComponents } from 'react-select';
import { ClearIndicator, Control, MultiValue, PriorityOption, } from './components';
import { useStyles } from './helpers';
import { Error, Label, Required, Wrapper } from './styles';
// type StateManagedSelect = typeof ReactSelect;
const { ValueContainer: DefaultValueContainer, Placeholder } = ReactSelectComponents;
const ValueContainer = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsxs(DefaultValueContainer, Object.assign({}, props, { children: [!props.selectProps.inputValue && (_jsx(Placeholder, Object.assign({}, props, { isFocused: props.isFocused }, { children: props.selectProps.placeholder }))), React.Children.map(children, (child) => child && child.type !== Placeholder ? child : null)] })));
};
const Select = (_a) => {
    var { name, large, label, error, options, country, priority, onChange, className, isDisabled, components, isCreatable, placeholder, isClearable, requiredText, defaultValue, isSearchable, defaultControl, containerStyle, errorOnlyBorder, isPlaceholderPermanent } = _a, props = __rest(_a, ["name", "large", "label", "error", "options", "country", "priority", "onChange", "className", "isDisabled", "components", "isCreatable", "placeholder", "isClearable", "requiredText", "defaultValue", "isSearchable", "defaultControl", "containerStyle", "errorOnlyBorder", "isPlaceholderPermanent"]);
    const styles = useStyles(large, error, priority, country);
    const selectRef = useRef(null);
    // const Component: React.ComponentType<any> = isCreatable
    //   ? CreatableSelect
    //   : ReactSelect;
    const getComponents = () => {
        let values = {};
        if (priority) {
            values = { Option: PriorityOption };
        }
        else if (country) {
            values = {
                Control,
                ClearIndicator,
            };
        }
        else if (isPlaceholderPermanent) {
            values = {
                ClearIndicator,
                Control,
                MultiValue,
                ValueContainer,
            };
        }
        else {
            values = {
                ClearIndicator,
                Control,
                MultiValue,
            };
        }
        if (defaultControl) {
            delete values.Control;
        }
        return values;
    };
    const onMenuOpen = () => {
        setTimeout(() => {
            if (selectRef.current) {
                const menu = selectRef.current.menuListRef;
                menu === null || menu === void 0 ? void 0 : menu.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'nearest',
                    block: 'nearest',
                });
            }
        }, 100);
    };
    return (_jsxs(Wrapper, Object.assign({ style: containerStyle, className: className }, { children: [label && (_jsxs(Label, Object.assign({ disabled: isDisabled }, { children: [t(label), _jsx(Required, { children: requiredText })] }))), _jsx(ReactSelect, Object.assign({ ref: selectRef, styles: styles, options: options, onChange: onChange, isDisabled: isDisabled, inputId: name !== null && name !== void 0 ? name : label, placeholder: placeholder ? t(placeholder) : t('Select'), isClearable: isClearable, defaultValue: defaultValue, isSearchable: isSearchable, onMenuOpen: () => {
                    onMenuOpen();
                }, components: Object.assign(Object.assign({}, getComponents()), components) }, props)), error && !errorOnlyBorder && _jsx(Error, { children: error })] })));
};
Select.defaultProps = {
    error: '',
    label: '',
    large: false,
    components: {},
    disabled: false,
    priority: false,
    requiredText: '',
    defaultValue: undefined,
    isSearchable: false,
};
export { Select };
