import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import { useQueryNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Styles from './styles';
const { Home, Parsing } = AppRoutes.Dashboard;
const OPTIONS = [
    {
        value: Home +
            Parsing.Home +
            Parsing.Vkontakte.Home +
            Parsing.Vkontakte.VkontakteGroups,
        label: 'Groups',
    },
    {
        value: Home +
            Parsing.Home +
            Parsing.Vkontakte.Home +
            Parsing.Vkontakte.VkontaktePosts,
        label: 'Posts',
    },
];
export const TabsFormsVkParser = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useQueryNavigate();
    return (_jsx(Styles.Container, { children: _jsxs(Styles.TabsHeader, { children: [OPTIONS === null || OPTIONS === void 0 ? void 0 : OPTIONS.map((item) => (_jsx(Styles.TabLink, Object.assign({ id: item.value, "$isActive": pathname === item.value, onClick: () => navigate(item.value, { replace: true }) }, { children: _jsx(Styles.Label, Object.assign({ "$isActive": pathname === item.value }, { children: t(item.label) })) }), item.value.toString()))), _jsx(Styles.TabBackground, { "$isActive": pathname ===
                        Home +
                            Parsing.Home +
                            Parsing.Vkontakte.Home +
                            Parsing.Vkontakte.VkontakteGroups })] }) }));
};
