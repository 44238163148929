import styled from 'styled-components';
import { Text } from '../../../../../common';
import { ChevronLeft } from '../../../../../common/icon/icons';
export const Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-bamv7b" }) `
  display: flex;
  flex-direction: column;
  min-width: 0;
`;
export const TableHeader = styled.div.withConfig({ displayName: "TableHeader", componentId: "sc-fxxwsl" }) `
  display: flex;
  flex-direction: row;
  height: 40px;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.config.input.light.label};
`;
export const TableRow = styled.div.withConfig({ displayName: "TableRow", componentId: "sc-14nbaf4" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.config.input.light.label};

  :hover {
    backdrop-filter: invert(0.1);
  }
`;
export const TableItemTitle = styled.div.withConfig({ displayName: "TableItemTitle", componentId: "sc-e8fg3o" }) `
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  overflow: hidden;
  padding: 5px 10px;
  box-shadow: 1px 0px 0px 0px ${({ theme }) => theme.config.input.light.label};
  flex-shrink: 1;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
`;
export const TitleText = styled.a.withConfig({ displayName: "TitleText", componentId: "sc-1k99dkb" }) `
  color: ${({ theme }) => theme.config.text.secondary};
  cursor: pointer;
  white-space: nowrap;

  :hover {
    color: ${({ theme }) => theme.config.text.primary};
  }
`;
export const TableHeaderTitle = styled.div.withConfig({ displayName: "TableHeaderTitle", componentId: "sc-xfstpg" }) `
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-basis: 0;
  flex-grow: 1;
  gap: 5px;
  width: 100%;
  padding: 5px 10px;
  box-shadow: 1px 0px 0px 0px ${({ theme }) => theme.config.input.light.label};
  transition: all 0.1s ease-in-out;

  :hover {
    backdrop-filter: invert(0.1);
  }

  :active {
    backdrop-filter: invert(0.3);
  }
`;
export const TableItemRevenue = styled.div.withConfig({ displayName: "TableItemRevenue", componentId: "sc-1twt3od" }) `
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
  justify-content: flex-end;
  padding: 5px 10px;
  white-space: nowrap;
  flex-shrink: 0;
`;
export const TableHeaderRevenue = styled.div.withConfig({ displayName: "TableHeaderRevenue", componentId: "sc-7z88u5" }) `
  display: flex;
  align-items: center;
  width: 200px;
  padding: 5px 10px;
  justify-content: flex-end;
  transition: all 0.1s ease-in-out;

  :hover {
    backdrop-filter: invert(0.1);
  }

  :active {
    backdrop-filter: invert(0.3);
  }
`;
export const ChevronTop = styled(ChevronLeft).withConfig({ displayName: "ChevronTop", componentId: "sc-1wv6rca" }) `
  transform: rotateZ(90deg);
  stroke: white;
`;
export const ChevronBottom = styled(ChevronLeft).withConfig({ displayName: "ChevronBottom", componentId: "sc-ec9qoo" }) `
  transform: rotateZ(-90deg);
  stroke: white;
`;
export const ProductImageWrapper = styled.div.withConfig({ displayName: "ProductImageWrapper", componentId: "sc-1yzrktk" }) `
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 10px;
`;
export const ProductImage = styled.img.withConfig({ displayName: "ProductImage", componentId: "sc-19v2p2w" }) `
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  clip-path: circle(50% at center);
  object-fit: cover;
  background-color: #807b7b;
  cursor: pointer;
  transition: width 0.3s, height 0.3s, left 0.3s, top 0.3s,
    clip-path 0.3s ease-in-out;

  :hover {
    z-index: 999;
    left: -35px;
    top: -35px;
    width: 100px;
    height: 100px;
    clip-path: circle(100% at center);
  }
`;
export const CategoriesWrapper = styled.div.withConfig({ displayName: "CategoriesWrapper", componentId: "sc-1oh89ji" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const CategoryWrapper = styled.div.withConfig({ displayName: "CategoryWrapper", componentId: "sc-lj5xg5" }) `
  display: flex;
  height: 20px;
  gap: 10px;
`;
export const Category = styled.div.withConfig({ displayName: "Category", componentId: "sc-1d1sa52" }) `
  color: ${({ theme }) => theme.colors.white.tertiary};
  height: 20px;
  width: fit-content;
  white-space: nowrap;

  :hover {
    color: ${({ theme }) => theme.colors.white.primary};
  }
`;
export const CategorySeparator = styled(Text).withConfig({ displayName: "CategorySeparator", componentId: "sc-m3oa3x" }) `
  margin: 0 10px;
`;
