import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon as IconComponent } from 'common/icon';
import { useTranslation } from 'react-i18next';
import { Text, TextVariant } from '../../../../common';
import IconWrapper from '../../../../common/icon-wrapper/icon-wrapper';
import { SideLink } from '../../../../common/side-link';
import { AppRoutes } from '../../../../config';
import { linksAI } from '../../links';
import { BackMain, Line, Link, Title } from './styles';
export const AIAside = () => {
    const { t } = useTranslation();
    const { Main, Home } = AppRoutes.Dashboard;
    return (_jsxs(_Fragment, { children: [_jsx(Link, Object.assign({ to: Home + Main }, { children: _jsxs(BackMain, { children: [_jsx(IconWrapper, Object.assign({ "$fillColor": "none", "$sidenav": 'sidemenu' }, { children: _jsx(IconComponent.Back, {}) })), t('Main page')] }) })), _jsx(Line, {}), _jsx(Title, { children: _jsx(Text, Object.assign({ "$colorVariant": "secondary", "$variant": TextVariant.smallSemiBold }, { children: t('Artificial intelligence') })) }), linksAI.map((link) => (_jsxs("div", { children: [_jsx(SideLink, { id: link.id, icon: link.icon, path: link.path, label: link.label, iconStyle: link.iconStyle }, link.id.toString()), link.id === 0 && _jsx(Line, {})] }, link.id)))] }));
};
