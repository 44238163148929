import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { heading2Medium, heading3Medium, smallLight, ultraSmallMedium, } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1wyjvgh" }) ``;
export const FormContainer = styled.div.withConfig({ displayName: "FormContainer", componentId: "sc-gpmnew" }) `
  margin: 0 50px;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1pj5wxw" }) `
  display: flex;
  flex-direction: column;

  margin: 32px 0 40px 0px;

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      width: 100%;
      align-items: center;
      margin: 32px 0 40px 0;
    `}
`;
export const Title = styled.span.withConfig({ displayName: "Title", componentId: "sc-950kz6" }) `
  ${heading2Medium}

  color: ${({ theme }) => theme.config.text.primary};
  margin-bottom: 10px;

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      ${heading3Medium}
    `}
`;
export const SubTitle = styled.span.withConfig({ displayName: "SubTitle", componentId: "sc-1e098mv" }) `
  ${smallLight}

  color: ${({ theme }) => theme.config.link.secondary};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      ${ultraSmallMedium}
    `}
`;
export const FormSection = styled.div.withConfig({ displayName: "FormSection", componentId: "sc-w2dx1s" }) ``;
export const ContainerField = styled.div.withConfig({ displayName: "ContainerField", componentId: "sc-zm4tez" }) ``;
export const IconUser = styled.div.withConfig({ displayName: "IconUser", componentId: "sc-tc95lz" }) ``;
export const IconLock = styled.div.withConfig({ displayName: "IconLock", componentId: "sc-f7jxe8" }) ``;
export const IconEyes = styled.div.withConfig({ displayName: "IconEyes", componentId: "sc-ulv57c" }) ``;
export const FooterLink = styled(Link).withConfig({ displayName: "FooterLink", componentId: "sc-1643axf" }) `
  text-decoration: none;
`;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-tqwb5a" }) `
  flex: 0;
  display: inline-flex;
  padding-bottom: 32px;
  margin-top: 48px;
`;
