import styled from 'styled-components';
import { bodyRegular, heading2Medium, smallReqular } from 'styles/mixins/fonts';
export const WrapperModal = styled.div.withConfig({ displayName: "WrapperModal", componentId: "sc-1pwach9" }) `
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ktf0qu" }) `
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 56px 148px;
  border-radius: 16px;
  background: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 767px) {
    margin: 16px;
    padding: 20px 50px;
    transition: all 0.3s ease-in-out;
  }
`;
export const WrapperText = styled.div.withConfig({ displayName: "WrapperText", componentId: "sc-irvw02" }) `
  text-align: center;
  letter-spacing: -0.01em;
  max-width: 700px;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1nfznd3" }) `
  ${bodyRegular};

  color: ${({ theme }) => theme.config.dashboard.textModal};

  @media screen and (max-width: 375px) {
    ${heading2Medium};
  }
`;
export const SubTitle = styled.div.withConfig({ displayName: "SubTitle", componentId: "sc-1hck2y2" }) `
  ${bodyRegular}

  margin: 32px 0px 64px;
  color: ${({ theme }) => theme.config.text.secondary};

  @media screen and (max-width: 375px) {
    ${smallReqular}

    margin-bottom: 32px;
  }
`;
