import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from 'common/checkbox';
import * as Styles from './styles';
export var TableRowVariant;
(function (TableRowVariant) {
    TableRowVariant["DEFAULT"] = "DEFAULT";
    TableRowVariant["SMALL"] = "SMALL";
})(TableRowVariant || (TableRowVariant = {}));
export const TableItemParserResult = ({ data, onChange, checked = false, rowVariant, hasItemCheckbox, isShortParser = false, hasIndexColumn, rowIndex, }) => {
    return (_jsxs(Styles.TableRowParserResult, { children: [hasItemCheckbox && (_jsx(Styles.CheckboxContainer, { children: _jsx(Checkbox, { checked: checked, onChange: onChange }) })), _jsx(Styles.TableRowContentParerResult, Object.assign({ checked: checked, "$rowVariant": rowVariant !== null && rowVariant !== void 0 ? rowVariant : TableRowVariant.DEFAULT }, { children: data.map((item, index) => (_jsxs(_Fragment, { children: [index == 0 && hasIndexColumn && (_jsx(Styles.TableColumnParserResult, Object.assign({ "$isShortParser": isShortParser, "$isMinWidth": true }, { children: rowIndex + 1 }), index + 'index')), _jsx(Styles.TableColumnParserResult, Object.assign({ "$isShortParser": isShortParser }, { children: item }), index)] }))) }))] }));
};
export const TableHeaderParserResult = ({ data, isShortParser = false, hasIndexColumn, }) => {
    return (_jsxs(Styles.TableHeaderParserResult, { children: [hasIndexColumn && (_jsx(Styles.TableColumnParserResult, Object.assign({ "$isShortParser": isShortParser, "$isMinWidth": true }, { children: '№' }))), data === null || data === void 0 ? void 0 : data.map((item, index) => (_jsx(Styles.TableColumnParserResult, Object.assign({ "$isShortParser": isShortParser }, { children: item }), index)))] }));
};
export const TableBodyParsingResult = ({ data, icon, rowVariant, checkedRows, onRowChecked, isShortParser, hasIndexColumn, }) => {
    return (_jsx(_Fragment, { children: _jsx(Styles.WrapperTableItemParsingResult, { children: data.map((row, index) => {
                return (_jsx("div", { children: _jsx(TableItemParserResult, { data: row, icon: icon, rowVariant: rowVariant, isShortParser: isShortParser, checked: checkedRows === null || checkedRows === void 0 ? void 0 : checkedRows.includes(index), onChange: () => onRowChecked && onRowChecked(index), rowIndex: index, hasIndexColumn: hasIndexColumn }, index.toString()) }, index));
            }) }) }));
};
export const TableParserResult = ({ data, icon, dataHeader, checkedRows, onRowChecked, isScrollable, isShortParser = false, hasItemCheckbox = false, rowVariant = TableRowVariant.DEFAULT, hasIndexColumn, }) => {
    return (_jsx(Styles.Table, { children: _jsxs(Styles.Container, Object.assign({ isScrollable: isScrollable }, { children: [_jsx(TableHeaderParserResult, { data: dataHeader, isShortParser: isShortParser, hasIndexColumn: hasIndexColumn }), _jsx(TableBodyParsingResult, { data: data, icon: icon, rowVariant: rowVariant, checkedRows: checkedRows, onRowChecked: onRowChecked, isShortParser: isShortParser, hasItemCheckbox: hasItemCheckbox, hasIndexColumn: hasIndexColumn })] })) }));
};
