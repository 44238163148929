import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AudioTranslate } from '../audio-translate';
import { Chat } from '../chat';
import { DescribeImage } from '../describe-image';
import { MusicGenerate } from '../generate-music';
import { GeneratePostPage } from '../generate-post';
import { ImageGenerate } from '../image-generate';
import { RemoveBackground } from '../remove-bg';
import { BlurImage } from '../blur-image';
export const AIPage = () => {
    const { AI } = AppRoutes.Dashboard;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: AI.DescribeImage, element: _jsx(DescribeImage, {}) }), _jsx(Route, { path: AI.AudioTranslate, element: _jsx(AudioTranslate, {}) }), _jsx(Route, { path: AI.ImageGenerate, element: _jsx(ImageGenerate, {}) }), _jsx(Route, { path: AI.GenerateMusic, element: _jsx(MusicGenerate, {}) }), _jsx(Route, { path: AI.GeneratePost, element: _jsx(GeneratePostPage, {}) }), _jsx(Route, { path: AI.Chat, element: _jsx(Chat, {}) }), _jsx(Route, { path: AI.RemoveBackground, element: _jsx(RemoveBackground, {}) }), _jsx(Route, { path: AI.BlurImage, element: _jsx(BlurImage, {}) })] }));
};
export default AIPage;
