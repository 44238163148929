import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { addManualInputInitialValues, validationSchema } from '../config';
import { FullDispatchForm } from '../fullForm';
import { EmailDispatchFormInitialValues, EmailValidationSchema, isValidEmail, } from './config';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export const MailingEmail = () => {
    const location = useLocation();
    const data = location.state && location.state.data;
    const { t } = useTranslation();
    return (_jsx(FullDispatchForm, { dispatchFormSchema: validationSchema(t('Enter the text of the message'), t('Enter the name of the mailing'), t('The length of the text for the newsletter should not exceed 30840 characters')), initialDispatchFormValues: EmailDispatchFormInitialValues, dataFromHistory: data, manualInputSchema: EmailValidationSchema(t('Invalid email')), manualInputFormValues: addManualInputInitialValues, manualInputFormPlaceholder: t('Email address'), validationFunc: isValidEmail, mailingMessenger: 'Email' }));
};
