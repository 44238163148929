import styled, { css } from 'styled-components';
import { ultraSmallMedium } from 'styles/mixins/fonts';
export const Error = styled.div.withConfig({ displayName: "Error", componentId: "sc-1mru7im" }) `
  ${ultraSmallMedium}
  font-style: italic;
  padding: 0px 0px 8px;
  ${({ theme }) => css `
    color: ${theme.config.input.light.error};
  `}
`;
