import { t } from 'i18next';
import * as Yup from 'yup';
import { EMarketplaces } from './index';
export const connectionModalModel = {
    marketName: {
        type: 'text',
        name: 'marketName',
        placeholder: `${t('Example')}: ${t('My cosmetics store')}`,
        label: t('Company Name'),
    },
    marketToken: {
        type: 'text',
        name: 'marketToken',
        placeholder: `${t('Example')}: fnriwjkfnwo2308jf923fn408fk2fj24jf0f`,
        label: t('Token API'),
    },
    marketClientId: {
        type: 'text',
        name: 'marketClientId',
        placeholder: `${t('Example')}: 1868764196243`,
        label: t('Client ID'),
    },
};
export const validationSchema = (marketType) => {
    switch (marketType) {
        case EMarketplaces.WILDBERRIES:
            {
                return Yup.object().shape({
                    [connectionModalModel.marketName.name]: Yup.string().required("I'ts required field"),
                    [connectionModalModel.marketToken.name]: Yup.string().required("I'ts required field"),
                });
            }
            break;
        case EMarketplaces.OZON:
            {
                return Yup.object().shape({
                    [connectionModalModel.marketName.name]: Yup.string().required("I'ts required field"),
                    [connectionModalModel.marketToken.name]: Yup.string().required("I'ts required field"),
                    [connectionModalModel.marketClientId.name]: Yup.string().required("I'ts required field"),
                });
            }
            break;
    }
};
export const initialValues = {
    [connectionModalModel.marketName.name]: '',
    [connectionModalModel.marketToken.name]: '',
    [connectionModalModel.marketClientId.name]: '',
};
