import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Text, TextVariant } from 'common';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
const Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-ye2f9" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const Row = styled.div.withConfig({ displayName: "Row", componentId: "sc-97xl25" }) `
  display: flex;
`;
const ColumnOne = styled.div.withConfig({ displayName: "ColumnOne", componentId: "sc-1ugu0wv" }) `
  padding: 8px 24px 8px 0px;
`;
const ColumnTwo = styled.div.withConfig({ displayName: "ColumnTwo", componentId: "sc-1jxzhmy" }) `
  display: flex;
  width: 100%;
  white-space: nowrap;
  align-items: flex-end;
`;
export const RowPayment = ({ columnOne, columnTwo, variant, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(Table, { children: [_jsx(Row, { children: _jsx(ColumnOne, { children: _jsxs(Text, Object.assign({ "$variant": theme.responsive.isMobile
                            ? TextVariant.smallReqular
                            : variant === null || variant === void 0 ? void 0 : variant.columnOne }, { children: [t(columnOne), ":"] })) }) }), _jsx(Row, { children: _jsx(ColumnTwo, { children: _jsx(Text, Object.assign({ "$variant": theme.responsive.isMobile
                            ? TextVariant.smallReqular
                            : variant === null || variant === void 0 ? void 0 : variant.columnTwo }, { children: t(columnTwo) })) }) })] }));
};
