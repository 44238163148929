import * as Yup from 'yup';
export const model = {
    cities: {
        type: 'text',
        name: 'cities',
        containerStyle: { flex: 1 },
        placeholder: 'Write the city',
        label: 'Write one or more cities',
    },
    categories: {
        type: 'text',
        name: 'categories',
        label: 'Write a category',
        containerStyle: { flex: 1 },
        placeholder: 'Write a category',
    },
    hasEmail: {
        label: 'E-mail',
        type: 'checkbox',
        withBorder: false,
        name: 'hasEmail',
    },
    hasPhone: {
        label: 'Phones',
        type: 'checkbox',
        withBorder: false,
        name: 'hasPhone',
    },
    withoutDepartments: {
        type: 'checkbox',
        withBorder: false,
        label: 'Without Branches',
        name: 'withoutDepartments',
    },
    hasMobilePhone: {
        type: 'checkbox',
        withBorder: false,
        label: 'Mobile phones',
        name: 'hasMobilePhone',
    },
};
export const yandexValidationSchema = (t) => Yup.object().shape({
    [model.cities.name]: Yup.string()
        .required()
        .matches(/^[^\d]+$/, t('Invalid city'))
        .matches(/^[a-zA-Zа-яА-Я\s-]*[a-zA-Zа-яА-Я]+[a-zA-Zа-яА-Я\s-]*$/, t('Invalid city')),
    [model.categories.name]: Yup.string()
        .required()
        .min(2, t('Invalid category'))
        .matches(/^[a-zA-Zа-яА-Я\s-]*[a-zA-Zа-яА-Я]+[a-zA-Zа-яА-Я\s-]*$/, t('Invalid category')),
});
export const yandexInitialValues = {
    cities: '',
    categories: '',
    hasEmail: true,
    hasPhone: true,
    hasMobilePhone: true,
    withoutDepartments: true,
};
export const filtersCheckbox = [
    {
        id: 0,
        name: 'Name',
        defaultStatus: false,
    },
    {
        id: 1,
        name: 'Country',
        defaultStatus: false,
    },
    {
        id: 2,
        name: 'Region',
        defaultStatus: false,
    },
    {
        id: 3,
        name: 'City',
        defaultStatus: false,
    },
    {
        id: 4,
        name: 'Phone',
        defaultStatus: false,
    },
    {
        id: 5,
        name: 'Mobile phone',
        defaultStatus: false,
    },
    {
        id: 6,
        name: 'Email',
        defaultStatus: false,
    },
    {
        id: 7,
        name: 'Address',
        defaultStatus: false,
    },
    {
        id: 8,
        name: 'Index',
        defaultStatus: false,
    },
    {
        id: 9,
        name: 'Website',
        defaultStatus: false,
    },
    {
        id: 10,
        name: 'Category',
        defaultStatus: false,
    },
    {
        id: 11,
        name: 'Subheading',
        defaultStatus: false,
    },
    {
        id: 12,
        name: 'Working hours',
        defaultStatus: false,
    },
    {
        id: 13,
        name: 'Instagram',
        defaultStatus: false,
    },
];
