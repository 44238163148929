import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiAIQuery } from '../query';
export const AIApi = createApi({
    reducerPath: 'AIApi',
    baseQuery: apiAIQuery,
    endpoints: (build) => ({
        generateImage: build.mutation({
            query: ({ prompts }) => {
                return {
                    url: URLS.AI_IMAGE_GENERATE + `?request_text=${prompts}`,
                    method: 'GET',
                };
            },
        }),
        describeImage: build.mutation({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: URLS.AI_DESCRIBE_IMAGE,
                    method: 'POST',
                    data: formData,
                };
            },
        }),
        generatePost: build.mutation({
            query: ({ postDescription, media }) => ({
                url: URLS.AI_GENERATE_POST +
                    `?media=${media}&post_description=${postDescription}`,
                method: 'GET',
            }),
        }),
        // generateMusic: build.mutation<{ file: File }, GenerateMusicParams>({
        //   query: ({ key, file, octave, scale }: GenerateMusicParams) => {
        //     const formData = new FormData();
        //     formData.append('file', file);
        //     return {
        //       url: URLS.AI_GENERATE_MUSIC,
        //       method: 'POST',
        //       params: {
        //         octave,
        //         key,
        //         scale,
        //       },
        //       data: formData,
        //     };
        //   },
        // }),
        audioTranslate: build.mutation({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: URLS.AI_AUDIO_TRANSLATE,
                    method: 'POST',
                    data: formData,
                };
            },
        }),
        makeRequest: build.mutation({
            query: ({ user_id, request_text, role }) => {
                return {
                    url: URLS.AI_MAKE_REQUEST +
                        `?user_id=${user_id}&request_text=${request_text}&role=${role}`,
                    method: 'GET',
                };
            },
        }),
        getRequestsHistory: build.query({
            query: ({ user_id }) => {
                return {
                    url: URLS.AI_GET_REQUESTS_HISTORY + `?user_id=${user_id}`,
                    method: 'GET',
                };
            },
        }),
        clearHistory: build.mutation({
            query: ({ user_id }) => {
                return {
                    url: URLS.AI_CLEAR_HISTORY + `?user_id=${user_id}`,
                    method: 'GET',
                };
            },
        }),
    }),
});
export const { useDescribeImageMutation, useGeneratePostMutation, useAudioTranslateMutation, useMakeRequestMutation, useGetRequestsHistoryQuery, useClearHistoryMutation, } = AIApi;
