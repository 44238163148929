import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { Button, Input } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ck6u7q" }) `
  height: calc(100vh - 150px);
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  padding: 0px 40px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-12nknwk" }) `
  width: 100%;
  padding: 0px 48px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  max-height: 100vh;
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-17x09et" }) `
  flex: 1;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding-bottom: 32px;
`;
export const Grid = styled.div.withConfig({ displayName: "Grid", componentId: "sc-ai1cqp" }) `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.5fr 1fr auto;
  grid-template-areas:
    'company company'
    'settings site'
    'footer footer';
  height: 100%;
  gap: 40px;

  transition: grid-template-columns 0.3s ease-in-out;

  @media screen and (max-width: 1900px) {
    grid-template-areas:
      'company company'
      'settings settings'
      'site site'
      'footer footer';
    gap: 80px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'company'
      'site'
      'settings'
      'footer';
    gap: 60px;
  }
`;
export const CompanyWrapper = styled.div.withConfig({ displayName: "CompanyWrapper", componentId: "sc-thqpyr" }) `
  padding-top: 10px;
  grid-area: company;
`;
export const FooterWrapper = styled.div.withConfig({ displayName: "FooterWrapper", componentId: "sc-q1fun3" }) `
  grid-area: footer;
  display: flex;
  justify-content: flex-start;

  gap: 21px;
`;
export const SiteWrapper = styled.div.withConfig({ displayName: "SiteWrapper", componentId: "sc-15es1jw" }) `
  grid-area: site;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
export const SettingsWrapper = styled.div.withConfig({ displayName: "SettingsWrapper", componentId: "sc-1r8v3n" }) `
  width: 100%;
  height: fit-content;
  grid-area: settings;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1b06ikb" }) `
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;
export const HeaderInnContainer = styled.div.withConfig({ displayName: "HeaderInnContainer", componentId: "sc-pmtirf" }) `
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const CompanyInfoContainer = styled.div.withConfig({ displayName: "CompanyInfoContainer", componentId: "sc-1lh28cc" }) `
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 24px;
  }
`;
export const MainInfoContainer = styled.div.withConfig({ displayName: "MainInfoContainer", componentId: "sc-18m4349" }) `
  display: flex;
  flex-direction: column;
  width: 100%;
  //min-width: 500px;
`;
export const AdditionalInfoContainer = styled.div.withConfig({ displayName: "AdditionalInfoContainer", componentId: "sc-iyb0s" }) `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  //min-width: 500px;
`;
export const InputBlock = styled.div.withConfig({ displayName: "InputBlock", componentId: "sc-1j47pm" }) `
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-bottom: 10px;
`;
export const DirectorBlock = styled.div.withConfig({ displayName: "DirectorBlock", componentId: "sc-11vp4jg" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
`;
export const WrapperSettingsCompany = styled.div.withConfig({ displayName: "WrapperSettingsCompany", componentId: "sc-u58l2o" }) `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;
export const WrapperCheckbox = styled.div.withConfig({ displayName: "WrapperCheckbox", componentId: "sc-1pt8azq" }) `
  display: flex;
  align-items: center;
  gap: 32px;
`;
export const InfoWrapper = styled.div.withConfig({ displayName: "InfoWrapper", componentId: "sc-1w35v2d" }) `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  width: 300px;
`;
export const LegalInformationButton = styled(Button).withConfig({ displayName: "LegalInformationButton", componentId: "sc-1lugz84" }) `
  margin-top: 20px;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-1m6iz6k" }) `
  display: flex;
  align-items: flex-start;

  margin-top: 12px;
`;
export const ActionWrapper = styled.div.withConfig({ displayName: "ActionWrapper", componentId: "sc-uxrklc" }) `
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;
export const CompanyNameInput = styled(Input).withConfig({ displayName: "CompanyNameInput", componentId: "sc-8ja7jh" }) `
  width: 100%;
`;
