import styled from 'styled-components';
import { Button } from '../../../../../../../common';
import IconWrapper from '../../../../../../../common/icon-wrapper/icon-wrapper';
export const LinkFieldsWrapper = styled.div.withConfig({ displayName: "LinkFieldsWrapper", componentId: "sc-17zr7wb" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const LinksActions = styled.div.withConfig({ displayName: "LinksActions", componentId: "sc-166xk6m" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const LinksContainer = styled.div.withConfig({ displayName: "LinksContainer", componentId: "sc-4jqgpc" }) `
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const AddFieldButton = styled(Button).withConfig({ displayName: "AddFieldButton", componentId: "sc-9vrdfq" }) `
  width: 300px;
`;
export const ClosedLinkContainer = styled.div.withConfig({ displayName: "ClosedLinkContainer", componentId: "sc-1pfpw4c" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 10px;
  border-radius: 20px;
  gap: 7px;
  background: ${({ theme, $isContainError }) => $isContainError
    ? theme.colors.red.secondary
    : theme.config.background.secondary};
`;
export const ClosedLinkInfo = styled.div.withConfig({ displayName: "ClosedLinkInfo", componentId: "sc-n0gpq9" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-grow: 1;
`;
export const ClosedLinkLink = styled.div.withConfig({ displayName: "ClosedLinkLink", componentId: "sc-13y3nmh" }) ``;
export const ClosedLinkTitle = styled.div.withConfig({ displayName: "ClosedLinkTitle", componentId: "sc-127t841" }) ``;
export const ClosedLinkDragIconContainer = styled(IconWrapper).withConfig({ displayName: "ClosedLinkDragIconContainer", componentId: "sc-ip2ehg" }) `
  width: 25px;
  height: 25px;
  padding: 5px;
  box-sizing: border-box;
`;
export const ClosedLinkRemove = styled(IconWrapper).withConfig({ displayName: "ClosedLinkRemove", componentId: "sc-8qxes4" }) `
  width: 25px;
  height: 25px;
`;
