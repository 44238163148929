import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './i18n';
import { interceptor } from 'api/instance';
import { App } from 'app';
import { AlertProvider, LoaderRedux } from 'components';
import { ThemeContextProvider, ThemeWrap } from 'context';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import * as Styles from 'styles';
import reportWebVitals from './reportWebVitals';
dayjs.extend(isYesterday);
dayjs.extend(isToday);
interceptor(store.dispatch);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(Provider, Object.assign({ store: store }, { children: _jsx(BrowserRouter, { children: _jsx(ThemeContextProvider, { children: _jsxs(ThemeWrap, { children: [_jsx(Styles.GlobalStyles, {}), _jsx(AlertProvider, { children: _jsx(App, {}) }), _jsx(LoaderRedux, {})] }) }) }) })));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
