import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, TextVariant } from 'common';
import { Switch } from 'common/switch';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { model } from '../config';
import * as Styles from './styles';
export const Settings = () => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext();
    const handleSwitchPassword = () => {
        setFieldValue('changePassword', !values.changePassword);
    };
    const handleSwitchTariff = () => {
        setFieldValue('changeTariff', !values.changeTariff);
    };
    const handleSwitchPayment = () => {
        setFieldValue('changePayment', !values.changePayment);
    };
    const handleSwitchMailing = () => {
        setFieldValue('changeMailing', !values.changeMailing);
    };
    const handleSwitchReport = () => {
        setFieldValue('changeReport', !values.changeReport);
    };
    const handleSwitchAvailable = () => {
        setFieldValue('changeAvailable', !values.changeAvailable);
    };
    return (_jsxs(Styles.Container, { children: [_jsxs(Styles.FieldsRow, { children: [_jsx(Switch, Object.assign({ checked: values.changePassword, onChange: handleSwitchPassword }, model.changePassword)), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "primary" }, { children: t('Changing the password') }))] }), _jsxs(Styles.FieldsRow, { children: [_jsx(Switch, Object.assign({ checked: values.changeTariff, onChange: handleSwitchTariff }, model.changeTariff)), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "primary" }, { children: t('Confirmation of the tariff change') }))] }), _jsxs(Styles.FieldsRow, { children: [_jsx(Switch, Object.assign({ checked: values.changePayment, onChange: handleSwitchPayment }, model.changePayment)), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "primary" }, { children: t('Approaching payment deadlines') }))] }), _jsxs(Styles.Section, { children: [_jsxs(Styles.FieldsRow, { children: [_jsx(Switch, Object.assign({ checked: values.changeMailing, onChange: handleSwitchMailing }, model.changeMailing)), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "primary" }, { children: t('Beginning/The end of the mailing list') }))] }), _jsxs(Styles.FieldsRow, { children: [_jsx(Switch, Object.assign({ checked: values.changeReport, onChange: handleSwitchReport }, model.changeReport)), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "primary" }, { children: t('Automatic report generation') }))] })] }), _jsxs(Styles.FieldsRow, { children: [_jsx(Switch, Object.assign({ checked: values.changeAvailable, onChange: handleSwitchAvailable }, model.changeAvailable)), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "primary" }, { children: t('An update is available') }))] })] }));
};
