import { jsx as _jsx } from "react/jsx-runtime";
import { Loader } from 'common';
import { AvitoResultPage, VkGroupsResult, VkPostsResult, YandexResultPage, } from 'components';
import { ParserType } from 'constants/enums';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetByIdQuery } from 'store/api/databases';
import styled from 'styled-components';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-17qmcbt" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const DatabaseContent = () => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { id } = useParams();
    const { data: database, isFetching } = useGetByIdQuery({ id: id }, { skip: !id });
    if (isFetching) {
        return _jsx(Loader, {});
    }
    if (((_a = database === null || database === void 0 ? void 0 : database.parser_type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ParserType.YANDEX.toLowerCase()) {
        return _jsx(YandexResultPage, { data: database });
    }
    if (((_b = database === null || database === void 0 ? void 0 : database.parser_type) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === ParserType.VK_GROUPS.toLowerCase()) {
        return _jsx(VkGroupsResult, { data: database });
    }
    if (((_c = database === null || database === void 0 ? void 0 : database.parser_type) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === ParserType.VK_POSTS.toLowerCase()) {
        return _jsx(VkPostsResult, { data: database });
    }
    if (((_d = database === null || database === void 0 ? void 0 : database.parser_type) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === ParserType.AVITO.toLowerCase()) {
        return _jsx(AvitoResultPage, { data: database });
    }
    return _jsx(Container, { children: t('databasebase Unavailable') });
};
