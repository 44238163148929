var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField } from 'common';
import { Icon } from 'common/icon';
import { AppRoutes } from 'config';
import { useFormikContext } from 'formik';
import { useQueryNavigate } from 'hooks';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from 'store/api/auth';
import { handleMutation } from 'utils/handleMutation';
import { Form } from '../../../../components/form/Form';
import { initialValues, model, validationSchema } from './config';
import * as Styles from './styles';
const LoginForm = () => {
    const { Home, ForgotPassword } = AppRoutes.Auth;
    const { t } = useTranslation();
    const navigate = useQueryNavigate();
    const { isSubmitting, dirty, validateForm, submitForm } = useFormikContext();
    return (_jsxs(Styles.FormContainer, { children: [_jsxs(Styles.Header, { children: [_jsx(Styles.Title, { children: t('Welcome!') }), _jsx(Styles.SubTitle, { children: t('We are glad to see you again.') })] }), _jsxs(Styles.FormSection, { children: [_jsx(InputField, Object.assign({ icon: _jsx(Icon.User, {}) }, model.email)), _jsx(InputField, Object.assign({ icon: _jsx(Icon.Lock, {}) }, model.password))] }), _jsx(Styles.FooterLink, Object.assign({ onClick: () => {
                    navigate(Home + ForgotPassword, {
                        replace: true,
                    });
                } }, { children: t('Forgot your password?') })), _jsx(Styles.ButtonContainer, { children: _jsx(Button, { text: t('Sign In'), variant: ButtonVariant.PRIMARY, disabled: isSubmitting || !dirty, onClick: (e) => {
                        e.stopPropagation();
                        validateForm();
                        submitForm();
                    } }) })] }));
};
export const LoginPage = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const [login, mutation] = useLoginMutation();
    const onSuccess = useCallback(() => {
        alert.success(t('Success'));
    }, [alert]);
    const onError = useCallback((error) => {
        var _a;
        alert.error(t((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutation), { onSuccess,
        onError }));
    const onSubmit = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        yield login(values);
    }), []);
    return (_jsx(Styles.Container, { children: _jsx(Form, Object.assign({ onSubmit: onSubmit, initialValues: initialValues, validationSchema: validationSchema(t) }, { children: _jsx(LoginForm, {}) })) }));
};
