import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputField, Text, TextVariant } from 'common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocialNetworksForm } from '../forms/settings';
import { WebsitesCompanyForm } from '../forms/website';
import * as Styles from './styles';
export const CompanyForm = ({ handleLegalInfo }) => {
    const { t } = useTranslation();
    return (_jsxs(Styles.Grid, { children: [_jsxs(Styles.CompanyWrapper, { children: [_jsx(InputField, { name: 'fullName', colorType: 'LIGHT' }), _jsxs(Styles.CompanyInfoContainer, { children: [_jsxs(Styles.MainInfoContainer, { children: [_jsxs(Styles.InputBlock, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "\u041A\u043E\u0440\u043E\u0442\u043A\u043E\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })), _jsx(InputField, { name: 'shortName' })] }), _jsxs(Styles.InputBlock, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "\u0410\u0434\u0440\u0435\u0441\u0441" })), _jsx(InputField, { name: 'address', inputType: 'textarea' })] }), handleLegalInfo && (_jsx(Styles.LegalInformationButton, { text: t('Legal information'), variant: 'DARK', onClick: handleLegalInfo }))] }), _jsx(Styles.AdditionalInfoContainer, { children: _jsx(Styles.WrapperSettingsCompany, { children: _jsxs(Styles.DirectorBlock, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: "\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044C" })), _jsxs("div", { children: [_jsxs(Styles.InputBlock, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: "\u0424\u0418\u041E" })), _jsx(InputField, { name: 'director.name' })] }), _jsxs(Styles.InputBlock, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C" })), _jsx(InputField, { name: 'director.position' })] })] })] }) }) })] })] }), _jsx(Styles.SettingsWrapper, { children: _jsx(SocialNetworksForm, {}) }), _jsx(Styles.SiteWrapper, { children: _jsx(WebsitesCompanyForm, {}) })] }));
};
