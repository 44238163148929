import styled from 'styled-components';
import { Button as ButtonComponent } from 'common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-h8z9t4" }) `
  margin-top: 40px;
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1h4d34q" }) ``;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-14vo7bh" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1di081l" }) ``;
export const Settings = styled.div.withConfig({ displayName: "Settings", componentId: "sc-81xzc0" }) `
  display: flex;
  align-items: center;

  gap: 32px;
`;
export const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "sc-1vel7pu" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const ButtonDelete = styled(ButtonComponent).withConfig({ displayName: "ButtonDelete", componentId: "sc-wzbxb0" }) `
  color: ${({ theme }) => theme.config.button.secondary.text};
`;
export const ContainerItem = styled.div.withConfig({ displayName: "ContainerItem", componentId: "sc-b7huov" }) `
  display: flex;
  align-items: center;

  cursor: pointer;
`;
export const LeftContent = styled.div.withConfig({ displayName: "LeftContent", componentId: "sc-1wjeec6" }) `
  gap: 16px;
  display: flex;
  align-items: center;
`;
export const WrapperTable = styled.div.withConfig({ displayName: "WrapperTable", componentId: "sc-w1d9m6" }) `
  margin-top: 12px;
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-ur77by" }) `
  white-space: nowrap;
  margin: 2px 0px;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-1gsczqx" }) `
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
`;
