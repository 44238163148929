import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import { redirectPath } from './config';
import * as Styles from './styles';
export const SideLink = ({ label, path, icon: Icon, iconStyle }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const isActive = useMemo(() => {
        return pathname === path;
    }, [pathname, path]);
    useEffect(() => {
        if (pathname === redirectPath.dashboard) {
            navigate(redirectPath.home);
        }
    }, [pathname, navigate]);
    return (_jsxs(Styles.Container, Object.assign({ "$isActive": isActive }, { children: [Icon && (_jsx(Styles.IconLink, Object.assign({ to: path || redirectPath.home }, { children: _jsx(IconWrapper, Object.assign({ "$isActive": isActive, "$fillColor": "transparent", "$sidenav": 'sidemenu' }, iconStyle, { children: _jsx(Icon, {}) })) }))), _jsx(Styles.Link, Object.assign({ to: path || redirectPath.home, "$isActive": isActive }, { children: t(label) }))] })));
};
