import styled, { css } from 'styled-components';
import * as Styles from 'styles';
import { ultraSmallMedium } from 'styles/mixins/fonts';
import { IconSize, InputColorType, InputSize, LabelSize } from './types';
const { smallSemiBold, smallReqular } = Styles.Font;
export const LeftIcon = styled.div.withConfig({ displayName: "LeftIcon", componentId: "sc-kqigef" }) `
  ${({ $sizeIcon }) => {
    switch ($sizeIcon) {
        case IconSize.SMALL:
            return css `
          width: 16px;
          height: 16px;
        `;
        case IconSize.MEDIUM:
            return css `
          width: 24px;
          height: 24px;
        `;
    }
}}

  left: 16px;
  position: absolute;
`;
export const RightIcon = styled.div.withConfig({ displayName: "RightIcon", componentId: "sc-ojf75" }) `
  ${({ $sizeIcon }) => {
    switch ($sizeIcon) {
        case IconSize.SMALL:
            return css `
          width: 16px;
          height: 16px;
        `;
        case IconSize.MEDIUM:
            return css `
          width: 24px;
          height: 24px;
        `;
    }
}}

  right: 16px;
  position: absolute;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'cursor')};
`;
export const InputWrapper = styled.div.withConfig({ displayName: "InputWrapper", componentId: "sc-1w0lx9c" }) `
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
`;
export const StyledInput = styled.input.withConfig({ displayName: "StyledInput", componentId: "sc-wvbys9" }) `
  width: 100%;
  border: none;
  outline: none;
  font-weight: 400;
  border-radius: 26px;
  box-sizing: border-box;
  padding-left: ${({ $isIcon }) => ($isIcon ? 48 : 16)}px;
  padding-right: ${({ $rightIcon }) => ($rightIcon ? 48 : 16)}px;
  // transition: 250ms linear;

  border-width: ${({ $withBorder }) => ($withBorder ? 2 : 0)}px;
  border: ${({ $withBorder }) => ($withBorder ? 'solid' : 'none')};

  ${({ disabled }) => disabled &&
    css `
      opacity: 0.5;
      transition: 0.25s ease-in;
    `};

  ${({ $sizeType, theme }) => {
    switch ($sizeType) {
        case InputSize.ULTRA_SMALL:
            return css `
          height: ${theme.responsive.isMobile ? 16 : 20}px;
        `;
        case InputSize.DEFAULT:
            return css `
          height: ${theme.responsive.isMobile ? 38 : 40}px;
          font-size: 16px;
        `;
        case InputSize.SMALL:
            return css `
          height: ${theme.responsive.isMobile ? 42 : 52}px;
          font-size: 14px;
        `;
        case InputSize.MEDIUM:
            return css `
          height: ${theme.responsive.isMobile ? 40 : 56}px;
          font-size: 24px;
        `;
        case InputSize.BIG:
            return css `
          height: ${theme.responsive.isMobile ? 48 : 72}px;
          font-size: 32px;
        `;
        case InputSize.BIGGER:
            return css `
          width: ${theme.responsive.isMobile ? 60 : 236}px;
          height: ${theme.responsive.isMobile ? 60 : 236}px;
          border-radius: 50%;
          opacity: 0;
        `;
        default:
            return css `
          height: ${theme.responsive.isMobile ? 40 : 48}px;
          font-size: 16px;
        `;
    }
}}

  ${({ $colorType, theme }) => {
    switch ($colorType) {
        case InputColorType.DEFAULT:
            return css `
          color: ${theme.config.input.default.text};
          border-color: ${theme.config.input.default.text};
          background: ${theme.config.input.default.background};
        `;
        case InputColorType.GREEN:
            return css `
          color: ${theme.config.input.green.text};
          border-color: ${theme.config.input.default.text};
          background: ${theme.config.input.default.background};
        `;
        case InputColorType.LIGHT:
            return css `
          color: ${theme.config.input.default.text};
          border-color: ${theme.config.input.light.text};
          background: ${theme.config.input.light.background};
          box-shadow: 0px 2px 6px ${theme.config.input.light.shadow};
        `;
        default:
            return css `
          color: ${theme.config.input.default.text};
          border-color: ${theme.config.input.default.text};
          background: ${theme.config.input.default.background};
        `;
    }
}}

  ${({ type }) => type === 'number' &&
    css `
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
      }
    `}
`;
export const TextArea = styled.textarea.withConfig({ displayName: "TextArea", componentId: "sc-1i8y5db" }) `
  flex: 1;
  resize: none;
  display: flex;
  padding: 16px;
  min-height: 122px;
  border-radius: 16px;
  outline: none;

  ${({ theme, $colorType }) => {
    switch ($colorType) {
        case InputColorType.DEFAULT:
            return css `
          color: ${theme.config.input.default.text};
          border-color: ${theme.config.input.default.text};
          background: ${theme.config.input.default.background};
        `;
        case InputColorType.LIGHT:
            return css `
          color: ${theme.config.input.light.text};
          border-color: ${theme.config.input.light.text};
          background: ${theme.config.input.light.background};
          box-shadow: 0px 2px 6px ${theme.config.input.light.shadow};
        `;
        default:
            return css `
          color: ${theme.config.input.default.text};
          border: 1px solid ${theme.config.input.light.text};
          background: ${theme.colors.white.primary};
        `;
    }
}}
`;
export const ErrorContainer = styled.div.withConfig({ displayName: "ErrorContainer", componentId: "sc-1meexj8" }) `
  position: relative;
  min-height: 3px;
`;
export const Label = styled.div.withConfig({ displayName: "Label", componentId: "sc-197c9p" }) `
  ${({ $sizeLabel }) => {
    switch ($sizeLabel) {
        case LabelSize.SMALL:
            return css `
          ${smallReqular}
        `;
        case LabelSize.MEDIUM:
            return css `
          ${smallSemiBold}
        `;
    }
}} //font-size: 13.5px;
  padding: 0px 0px 8px;
  ${({ theme, isError }) => css `
    color: ${isError
    ? theme.config.input.light.error
    : theme.config.input.default.label};
  `}
`;
export const Error = styled.div.withConfig({ displayName: "Error", componentId: "sc-1ocd9dr" }) `
  position: absolute;
  top: 0;
  left: 5px;
  ${ultraSmallMedium};
  font-style: italic;
  padding: 2px 10px 0px 10px;
  ${({ theme }) => css `
    color: ${theme.config.input.light.error};
  `}
`;
export const Icon = styled.div.withConfig({ displayName: "Icon", componentId: "sc-1rwuo2" }) ``;
export const IconEyes = styled.div.withConfig({ displayName: "IconEyes", componentId: "sc-rwjxca" }) `
  right: 16px;
  z-index: 10;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
