import styled from 'styled-components';
import { zIndex } from '../../../constants/enums';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-smqrzt" }) `
  grid-area: navbar;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-right: 24px;
  z-index: ${zIndex.Modal};

  border-radius: 20px;
  border-color: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.toggle.bg.active};

  @media screen and (max-width: 500px) {
    margin-left: 24px;
  }

  @media screen and (max-width: 375px) {
    margin-left: 16px;
  }
`;
