import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from 'common/checkbox';
import { Icon } from 'common/icon';
import { Table } from 'components/table';
import { ButtonTable } from 'constants/enums';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import { formatId } from 'utils/formatId';
import { DropdownMenu } from '../../../../../components/dropdown/menu';
import { AudioListeningModal } from '../../../../../components/modal/audio-listening';
import { useClipDeleteMutation, useClipsListQuery, } from '../../../../../store/api/audio-clips';
import { useMeQuery } from '../../../../../store/api/profile';
import { handleMutation } from '../../../../../utils/handleMutation';
import { audioClipMenuElements, AudioClipVariantMenuItems, filtersInitialValues, } from './config';
import { HeaderAudioClips } from './header';
import { AudioClipModal } from './modal';
import * as Styles from './styles';
import { DeleteModal } from '../../../../../components/modal/delete-modal';
export const AudioclipsPage = () => {
    const { data: me } = useMeQuery();
    const alert = useAlert();
    const { t } = useTranslation();
    const [filter, setFilter] = useState('');
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);
    const [data, setData] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [filters, setFilters] = useState(filtersInitialValues);
    const [isIDVisible, setIsIDVisible] = useState(true);
    const [isCreatedVisible, setIsCreatedVisible] = useState(true);
    const [isNameVisible, setIsNameVisible] = useState(true);
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const { data: clipsList, refetch } = useClipsListQuery();
    const [requestRemove, responseRemove] = useClipDeleteMutation();
    const debounceValue = useDebounce(filter, 500);
    const [isAudioListenModalOpen, setIsAudioListenModalOpen] = useState(false);
    const [lastSelectedAudio, setLastSelectedAudio] = useState(null);
    const [isDeleteClipVisible, setIsDeleteClipVisible] = useState(false);
    const handleDeleteClipVisible = () => {
        setIsDeleteClipVisible(!isDeleteClipVisible);
        setSelectedElementIndex(null);
    };
    const [selectedItemMenuDelete, setSelectedItemMenuDelete] = useState();
    const getAudioList = () => {
        if (me && me._id) {
            refetch();
        }
    };
    useEffect(() => {
        if (clipsList) {
            setData(clipsList);
            setFilteredData(clipsList);
        }
    }, [clipsList]);
    useEffect(() => {
        getAudioList();
    }, [me]);
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseRemove), { onSuccess: getAudioList, onError }));
    const dataAfterSearch = useMemo(() => {
        return data.filter((row) => {
            return row.clip_name.toLowerCase().includes(debounceValue.toLowerCase());
        });
    }, [debounceValue]);
    const handleRowChecked = (index) => {
        if (checkedRows.includes(index)) {
            setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
        }
        else {
            setCheckedRows([...checkedRows, index]);
        }
    };
    const handleAllRowsChecked = () => {
        const isAllChecked = checkedRows.length === filteredData.length;
        if (isAllChecked) {
            setCheckedRows([]);
        }
        else {
            setCheckedRows(filteredData.map((_, index) => index));
        }
    };
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };
    const handleOpenModal = () => {
        setCreateModalOpen(true);
    };
    const handleButtonPress = (index, element, action) => {
        switch (action) {
            case ButtonTable.Delete:
                const newData = filteredData.filter((_, i) => i !== index);
                setData(newData);
                break;
            case ButtonTable.Menu:
                setSelectedElementIndex((prevIndex) => prevIndex === index ? null : index);
                break;
        }
    };
    useEffect(() => {
        setFilteredData(dataAfterSearch);
    }, [dataAfterSearch]);
    useEffect(() => {
        setFilteredData(dataAfterSearch.filter(() => {
            var _a;
            const lowercaseStatusValues = (_a = filters.status) === null || _a === void 0 ? void 0 : _a.map((filterValue) => filterValue.value.toLowerCase());
            return (lowercaseStatusValues === null || lowercaseStatusValues === void 0 ? void 0 : lowercaseStatusValues.length) <= 0;
        }));
    }, [filters.status]);
    const handleRemoveClip = () => {
        if (me && selectedItemMenuDelete) {
            requestRemove({
                user_id: me._id.toString(),
                clip_id: selectedItemMenuDelete.audioclip_id.toString(),
            });
            setSelectedElementIndex(null);
        }
    };
    const handleOpenListenModal = (item) => {
        console.log(item);
        setLastSelectedAudio(item);
        setIsAudioListenModalOpen(true);
        setSelectedElementIndex(null);
    };
    const handleDropdownMenuItem = (action, id) => {
        const item = filteredData[+id];
        switch (action) {
            case AudioClipVariantMenuItems.Remove:
                setSelectedItemMenuDelete(item);
                handleDeleteClipVisible();
                break;
            case AudioClipVariantMenuItems.Listen:
                handleOpenListenModal(item);
                break;
            default:
                break;
        }
    };
    const handleDropdownMenu = (selectedId, row, tableRef) => {
        return (_jsx(DropdownMenu, { tableRef: tableRef, isVisible: true, dataItem: row, items: audioClipMenuElements, selectedId: selectedElementIndex === null || selectedElementIndex === void 0 ? void 0 : selectedElementIndex.toString(), setVisible: () => setSelectedElementIndex(null), onItemClick: handleDropdownMenuItem }));
    };
    return (_jsxs(Styles.Container, { children: [_jsx(AudioListeningModal, { isOpen: isAudioListenModalOpen, closeModal: () => setIsAudioListenModalOpen(false), info: lastSelectedAudio }), _jsxs(Styles.Wrapper, { children: [_jsx(HeaderAudioClips, { search: filter, onApplyFilters: setFilters, onClick: handleOpenModal, searchFilter: handleFilterChange }), _jsx(Styles.WrapperCheckbox, Object.assign({ onClick: () => () => { } }, { children: _jsxs(Styles.LeftContent, { children: [_jsx(Checkbox, { label: "ID", checked: isIDVisible, onChange: () => setIsIDVisible(!isIDVisible) }), _jsx(Checkbox, { label: "Created", checked: isCreatedVisible, onChange: () => setIsCreatedVisible(!isCreatedVisible) }), _jsx(Checkbox, { label: "Name", checked: isNameVisible, onChange: () => setIsNameVisible(!isNameVisible) }), _jsx(Checkbox, { label: "Description", checked: isDescriptionVisible, onChange: () => setIsDescriptionVisible(!isDescriptionVisible) })] }) })), _jsx(Table
                    // isTertiary
                    , { 
                        // isTertiary
                        isScrollable: true, 
                        // hasItemCheckbox
                        // hasHeaderCheckbox
                        icon: _jsx(Icon.More, {}), checkedRows: checkedRows, onRowChecked: handleRowChecked, buttonAction: ButtonTable.Menu, handleButton: handleButtonPress, handleDropdownMenu: handleDropdownMenu, selectedElement: selectedElementIndex, onAllRowsChecked: handleAllRowsChecked, dataHeader: [
                            isIDVisible && '№',
                            isNameVisible && t('Name'),
                            isDescriptionVisible && t('Description'),
                            isCreatedVisible && t('Created'),
                        ].filter((item) => item), data: filteredData.map((row, index) => [
                            isIDVisible && formatId(index + 1),
                            isNameVisible && row.clip_name,
                            // isStatusVisible && (
                            //   <ItemStatus
                            //     text={t(row.status.text)}
                            //     variant={
                            //       row.status.variant as keyof typeof ItemStatusVariants
                            //     }
                            //   />
                            // ),
                            (isDescriptionVisible && row.clip_description) ||
                                t('No description'),
                            isCreatedVisible &&
                                dayjs(row.created).locale('ru').format('D MMM, YYYY'),
                        ].filter((item) => item)) }), _jsx(AudioClipModal, { isDialogOpen: isCreateModalOpen, setIsDialogOpen: setCreateModalOpen, trigger: getAudioList })] }), _jsx(DeleteModal, { isVisible: isDeleteClipVisible, onClick: handleDeleteClipVisible, message: 'Вы точно хотите удалить выбранный аудиоклип?', modalTitle: 'Удалить аудиоклип', buttonText: 'Да, удалить', deleteFunc: handleRemoveClip })] }));
};
