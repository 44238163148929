import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField } from 'common';
import { ModalMain } from '../main';
import { useTranslation } from 'react-i18next';
import { initialValues, model, validationSchema } from './config';
import styled from 'styled-components';
import { Formik } from 'formik';
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-5su88l" }) `
  display: flex;
  flex-direction: column;

  gap: 24px;
`;
export const ChangePassword = ({ isVisible, isLoading, onClick, sendPassword, }) => {
    const { t } = useTranslation();
    return (_jsx(Formik, Object.assign({ initialValues: initialValues, validationSchema: validationSchema, onSubmit: () => void 0 }, { children: ({ values, isValid }) => {
            return (_jsx(ModalMain, Object.assign({ title: "Changing the password", isVisible: isVisible, onClick: onClick, footer: _jsxs(_Fragment, { children: [_jsx(Button, { padding: "24px", disabled: isLoading || !isValid, text: t('Change Password'), variant: ButtonVariant.PRIMARY, onClick: () => {
                                sendPassword(+values.code, values.newPassword, values.confirmPassword);
                            } }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, onClick: onClick })] }) }, { children: _jsxs(Body, { children: [_jsx(InputField, Object.assign({}, model.code)), _jsx(InputField, Object.assign({}, model.newPassword)), _jsx(InputField, Object.assign({}, model.confirmPassword))] }) })));
        } })));
};
