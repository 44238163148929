import { ItemStatusVariants } from 'components';
//  white
export const white = '#ffffff';
export const whiteAction = '#EBEBEB';
export const grayLine = 'rgba(108, 114, 117, 0.5)';
export const lightGrayTab = '#FEFEFE';
export const purple = '#531DB4';
export const purplePrimary = '#531DAA';
export const purpleAction = '#535DB4';
export const black = '#202225';
export const blackLink = '#373737';
export const blackSecondary = '#5C5C5C';
export const grey = '#36393F';
export const greySecondary = '#B9BBBE';
export const greyTetriery = '#828589';
export const blue = '#5865f2';
export const blueSecondary = '#4752c4';
export const blueTetriery = '#3c45a5';
export const blueLight = '#00AEF3';
export const redSecondary = '#B73E3E';
export const redTetriery = 'D2001A';
export const green = '#30c61c';
export const greenPressed = 'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), linear-gradient(180deg, #22A913 0%, #106609 145.83%)';
export const greenTetriery = '#1b7912';
export const backgroundImage = 'hsla(0, 0%, 100%, 80%)';
export const saladPrimary = '#22A913';
export const saladSecondary = 'rgba(34, 169, 19, 0.1)';
export const saladPrimaryOpacity = 'rgba(34, 169, 19, 0.10)';
export const saladDarkSecondary = '#106609';
export const orange = '#FF6700';
export const greenCharts = '#106609';
export const violaCharts = '#7985ED';
export const orangeCharts = '#F4A84F';
export const yellowCharts = '#F7DE5B';
export const redAccent = '#D84C10';
export const orangeAccent = '#FF6700';
export const neutralPrimary = '#FEFEFE';
export const neutralSecondary = '#F3F5F7';
export const neutralTertiary = '#E8ECEF';
export const neutralQuaternary = '#6C7275';
export const neutralQuinary = '#343839';
export const neutralSenary = '#232627';
export const neutralSeptenary = '#141718';
export const neutralOctonary = '#111111';
export const gradientPrimary = `linear-gradient(180deg, ${saladPrimary} 0%, ${saladDarkSecondary} 145.83%)`;
export const gradientSecondary = `linear-gradient(180deg, ${saladPrimary} 40%, ${saladDarkSecondary} 145%)`;
export const buttonTetriery = 'linear-gradient(90deg, rgba(34, 169, 19, 0.15) 0%, rgba(16, 102, 9, 0) 61.81%), #FFFFFF';
export const linearGradientStepLine = `linear-gradient(90deg, ${neutralTertiary} 0%, ${neutralTertiary} 98.82%)`;
export const gradientSecondaty = `linear-gradient(180deg, #7F8689 0%, #6C7275 100%)`;
export const boxShadowLogout = '0px 2px 4px rgba(0, 0, 0, 0.15)';
export const boxShadowDropdown = '0px 2px 6px rgba(0, 0, 0, 0.08)';
export const boxShadowLight = 'inset 0px -3px 5px rgba(0, 0, 0, 0.25)';
export const boxShadowForm = '0px 32px 48px -4px rgba(0, 0, 0, 0.2), 0px 0px 24px 4px rgba(0, 0, 0, 0.05)';
export const boxShadowButton = '0px 8px 16px rgba(0, 0, 0, 0.05)';
export const LIGHT_CONFIG = Object.freeze({
    background: {
        primary: white,
        secondary: lightGrayTab,
        tetriery: neutralSecondary,
    },
    checkbox: Object.freeze({
        mark: neutralPrimary,
        primary: saladPrimary,
        tetriery: neutralQuaternary,
        secondary: neutralQuaternary,
        field: {
            bg: {
                default: 'transparent',
                hover: saladPrimaryOpacity,
                active: saladPrimaryOpacity,
            },
        },
    }),
    text: Object.freeze({
        primary: black,
        salad: saladPrimary,
        secondary: neutralQuinary,
        tetriery: greyTetriery,
        quaternary: neutralQuaternary,
        stableBlack: neutralTertiary,
    }),
    link: {
        primary: black,
        secondary: neutralSenary,
    },
    header: {
        background: white,
    },
    tab: {
        background: {
            primary: neutralSecondary,
            secondary: neutralPrimary,
        },
    },
    button: Object.freeze({
        primary: {
            text: white,
            shadow: {
                default: boxShadowLight,
                secondary: `0px 3px 8px rgba(0, 0, 0, 0.05)`,
            },
            background: {
                default: gradientPrimary,
                hover: gradientSecondary,
                active: greenPressed,
            },
        },
        secondary: {
            text: neutralSeptenary,
            shadow: { default: 'rgba(0, 0, 0, 0.05)' },
            border: {
                default: neutralSecondary,
                hover: neutralSecondary,
            },
            background: {
                default: neutralPrimary,
                hover: neutralSecondary,
            },
        },
        tertiary: {
            background: {
                default: lightGrayTab,
                primary: neutralSecondary,
                secondary: gradientSecondaty,
                tertiary: neutralSeptenary,
            },
            border: {
                default: `1px solid ${neutralTertiary}`,
            },
        },
        dark: {
            text: white,
            background: {
                default: neutralSeptenary,
                hover: neutralSenary,
                active: neutralQuinary,
            },
        },
        light: {
            text: grey,
            background: {
                default: neutralTertiary,
                hover: lightGrayTab,
                active: neutralSecondary,
            },
        },
    }),
    input: Object.freeze({
        default: Object.freeze({
            text: black,
            label: neutralSeptenary,
            error: redAccent,
            background: neutralSecondary,
            backgrounLight: neutralSecondary,
            border: {
                default: `2px solid ${neutralTertiary}`,
                greenDashed: `2px dashed ${green}`,
            },
        }),
        light: Object.freeze({
            text: black,
            label: black,
            error: redAccent,
            background: white,
            shadow: 'rgba(0, 0, 0, 0.08)',
            backgrounLight: neutralSecondary,
        }),
        green: Object.freeze({
            text: green,
            label: black,
            error: redAccent,
            background: neutralSecondary,
            hover: saladPrimaryOpacity,
        }),
    }),
    select: Object.freeze({
        bg: neutralPrimary,
        clearIcon: neutralQuinary,
        label: {
            disabled: white,
            default: neutralSeptenary,
        },
        text: {
            disabled: neutralQuinary,
            default: neutralSeptenary,
            active: neutralSeptenary,
        },
        multiValue: {
            text: white,
        },
        option: {
            selected: neutralTertiary,
            hover: neutralTertiary,
        },
        countryMultiValue: {
            bg: neutralSecondary,
        },
        border: {
            error: redAccent,
            errorHover: redAccent,
            hover: neutralQuinary,
            active: neutralOctonary,
            default: neutralQuaternary,
        },
    }),
    calendar: Object.freeze({
        container: {
            background: 'transparent',
            primaryText: neutralSeptenary,
            secondaryText: neutralQuinary,
            border: {
                default: `1px solid ${neutralTertiary}`,
            },
        },
        day: {
            default: {
                opacity: '1.0',
                textColor: neutralSeptenary,
                hoverTextColor: neutralQuinary,
                backgroundColor: 'transparent',
                hoverBackgroundColor: saladPrimary,
            },
            currentDay: {
                textColor: blue,
                hoverTextColor: saladPrimary,
            },
            selectedDay: {
                textColor: white,
                hoverTextColor: whiteAction,
                borderColor: saladPrimary,
                backgroundColor: saladPrimary,
                backgroundOpacity: saladPrimaryOpacity,
                hoverBackgroundColor: saladPrimary,
            },
            dateBetween: {
                textColor: black,
                backgroundColor: saladPrimary,
            },
            borderRadius: {
                all: '8px 8px 8px 8px',
                none: '0px 0px 0px 0px',
                right: '0px 8px 8px 0px',
                left: '8px 0px 0px 8px',
            },
            border: {
                default: `1px solid ${neutralSecondary}`,
            },
        },
    }),
    time: Object.freeze({
        background: {
            default: neutralSecondary,
        },
        text: {
            default: neutralSeptenary,
            primary: saladDarkSecondary,
        },
        arrow: {
            default: neutralQuaternary,
            active: neutralSeptenary,
        },
    }),
    form: Object.freeze({
        background: neutralPrimary,
        boxShadowForm: boxShadowForm,
        backgroundImage: backgroundImage,
    }),
    shadow: Object.freeze({
        primary: boxShadowLight,
    }),
    linear: Object.freeze({
        primary: gradientPrimary,
        hover: saladPrimary,
        pressed: greenPressed,
        stepLine: linearGradientStepLine,
    }),
    icon: Object.freeze({
        primary: neutralQuaternary,
        secondary: blackSecondary,
        tetriary: neutralSeptenary,
        hover: neutralTertiary,
        green: {
            default: saladDarkSecondary,
            active: saladPrimary,
        },
    }),
    line: Object.freeze({
        primary: saladPrimary,
        secondary: grayLine,
        dashboardLine: neutralSecondary,
    }),
    sideLink: Object.freeze({
        background: {
            active: buttonTetriery,
        },
        text: {
            default: neutralQuaternary,
            hover: neutralSeptenary,
        },
        shadow: boxShadowButton,
    }),
    tooltip: Object.freeze({
        background: neutralQuaternary,
        shadow: boxShadowButton,
    }),
    dashboard: Object.freeze({
        text: neutralQuinary,
        textModal: neutralSeptenary,
        background: neutralSecondary,
        darkGreen: saladDarkSecondary,
    }),
    toggle: Object.freeze({
        menu: neutralSeptenary,
        wrapper: boxShadowDropdown,
        border: {
            all: `1px solid ${lightGrayTab}`,
        },
        bg: {
            active: lightGrayTab,
            default: neutralSeptenary,
            disabled: neutralQuaternary,
        },
    }),
    dropdown: Object.freeze({
        background: {
            active: lightGrayTab,
            hover: neutralSecondary,
            default: neutralSecondary,
            black: neutralSeptenary,
            secondaryActive: neutralQuinary,
            tertiaryActive: neutralSeptenary,
        },
        border: {
            default: neutralTertiary,
            all: `1px solid ${neutralTertiary}`,
            line: `1px solid ${neutralSecondary}`,
            green: `1px solid ${saladPrimary}`,
        },
        boxShadow: {
            default: boxShadowButton,
            active: boxShadowDropdown,
        },
        text: {
            default: '',
            green: saladPrimary,
            darkGreen: saladDarkSecondary,
            timestamp: neutralQuaternary,
        },
    }),
    slider: Object.freeze({
        bg: {
            default: neutralSecondary,
        },
        border: {
            all: `1px solid ${neutralTertiary}`,
        },
        icon: {
            default: neutralSeptenary,
            secondary: orange,
        },
        dots: {
            default: neutralQuaternary,
            active: neutralQuinary,
        },
    }),
    toggleTabs: Object.freeze({
        bg: {
            default: 'transparent',
            active: saladPrimary,
        },
        border: {
            default: `1px solid ${neutralTertiary}`,
            active: `1px solid ${saladPrimary}`,
        },
        text: {
            default: neutralQuaternary,
            active: neutralSeptenary,
        },
    }),
    uploadImage: Object.freeze({
        bg: {
            default: neutralPrimary,
        },
        border: {
            default: `1px solid ${neutralTertiary}`,
        },
        text: {
            default: neutralSeptenary,
        },
        voiceImage: {
            boxShadow: `0px 0px 0px 4px #fff, 0px 0px 8px 2px rgba(0, 0, 0, 0.1),
      0px 0px 0px 6px ${saladPrimary}`,
            secondaryBoxShadow: boxShadowButton,
        },
    }),
    switch: Object.freeze({
        bg: {
            default: neutralTertiary,
            active: saladPrimary,
        },
        border: {
            default: `1px solid ${neutralTertiary}`,
        },
        thumb: {
            default: lightGrayTab,
            active: neutralPrimary,
        },
    }),
    modal: Object.freeze({
        bgBlur: {
            default: 'rgba(0, 0, 0, 0.5)',
        },
        bg: {
            default: neutralPrimary,
            hover: neutralSenary,
        },
        border: {
            default: '',
        },
        boxShadow: {
            default: '0px 32px 48px -4px rgba(0, 0, 0, 0.1), 0px 0px 24px 4px rgba(0, 0, 0, 0.05)',
        },
    }),
    tags: Object.freeze({
        background: {
            default: saladPrimaryOpacity,
        },
        text: {
            default: neutralQuinary,
        },
    }),
    radio: Object.freeze({
        background: {
            default: 'transparent',
        },
        border: {
            default: `1px solid ${neutralTertiary}`,
        },
        checked: {
            default: neutralTertiary,
            active: saladPrimary,
            border: {
                default: `2px solid ${neutralTertiary}`,
                active: `2px solid ${saladPrimary}`,
            },
        },
    }),
    charts: Object.freeze({
        histogram: {
            color: {
                primary: greenCharts,
                secondary: orangeCharts,
            },
            line: {
                primary: neutralTertiary,
                secondary: neutralQuaternary,
            },
            text: {
                primary: neutralQuaternary,
            },
        },
        doughnut: {
            color: {
                primary: greenCharts,
                secondary: violaCharts,
                tetriery: orangeCharts,
                quaternary: yellowCharts,
                quinary: neutralQuinary,
            },
        },
    }),
    voiceRecorder: Object.freeze({
        visualVoice: neutralTertiary,
        color: {
            mic: {
                default: neutralTertiary,
                active: saladPrimary,
            },
        },
    }),
    scroll: Object.freeze({
        background: {
            default: neutralTertiary,
        },
        thumb: {
            default: neutralQuaternary,
        },
    }),
    alert: Object.freeze({
        close: black,
        border: green,
        info: neutralPrimary,
        error: neutralPrimary,
        success: neutralPrimary,
    }),
    itemStatus: Object.freeze({
        [ItemStatusVariants.Completed]: green,
        [ItemStatusVariants.Failed]: redAccent,
        [ItemStatusVariants.Paused]: orangeAccent,
        [ItemStatusVariants.Cancelled]: redAccent,
        [ItemStatusVariants.Active]: saladPrimary,
        [ItemStatusVariants.Inactive]: neutralTertiary,
        [ItemStatusVariants.Pending]: orangeAccent,
        [ItemStatusVariants.Updating]: greenTetriery,
    }),
});
