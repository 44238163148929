var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_AI_URL } from '../../../../../api/constants';
import { InputField, Loader } from '../../../../../common';
import { Download } from '../../../../../common/icon/icons';
import { Form } from '../../../../../components/form/Form';
import { LoaderContainer } from '../generate-post/styles';
import { generateImageInitialValues, generateImageValidationSchema, } from './config';
import { HeaderImageGenerate } from './header';
import * as Styles from './styles';
import { Generated, GeneratedImage } from './styles';
const ImageGenerateForm = ({ generateImage, isLoading, }) => {
    const { t } = useTranslation();
    const { values, submitForm } = useFormikContext();
    return (_jsxs(Styles.ImageGenerateFormWrapper, { children: [_jsx(HeaderImageGenerate, { generateImage: () => generateImage(values), isButtonDisabled: isLoading }), _jsx("div", Object.assign({ onKeyDown: (event) => __awaiter(void 0, void 0, void 0, function* () {
                    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
                        submitForm();
                    }
                }) }, { children: _jsx(InputField, { name: "prompt", placeholder: t('Paste the keywords here'), inputType: "textarea", disabled: isLoading }) }))] }));
};
export const ImageGenerate = () => {
    const { t } = useTranslation();
    // const [changePrompt, mutationPrompt] = useGenerateImageMutation();
    const [isLoading, setIsLoading] = useState(false);
    // const onSuccess = () => {
    //   setIsLoading(false);
    //   alert.success(t('Success'));
    // };
    // const onError = (error: any) => {
    //   setIsLoading(false);
    //   alert.error(error?.data?.detail || t('Error'));
    // };
    // handleMutation({
    //   ...mutationPrompt,
    //   onSuccess,
    //   onError,
    // });
    const [lastUrl, setLastUrl] = useState('');
    const [generatedImage, setGeneratedImage] = useState('');
    const generateImage = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (!values.prompt) {
            return;
        }
        const url = `${BASE_AI_URL}/image_generate?request_text=${values.prompt}`;
        if (lastUrl !== url) {
            setLastUrl(url);
            setIsLoading(true);
            try {
                const response = (yield fetch(url));
                const file = yield response.blob();
                setGeneratedImage(URL.createObjectURL(file));
            }
            catch (_) {
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const downloadImage = (url) => {
        const aElement = document.createElement('a');
        const time = new Date();
        aElement.setAttribute('download', `${time.getTime()}.jpg`);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    };
    // const generateImage = async (values: FormikValues) => {
    //   setIsLoading(true);
    //   await changePrompt({ prompts: values.prompt });
    // };
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Form, Object.assign({ initialValues: generateImageInitialValues, validationSchema: generateImageValidationSchema, onSubmit: generateImage }, { children: _jsx(ImageGenerateForm, { generateImage: generateImage, isLoading: isLoading }) })), isLoading && (_jsx(LoaderContainer, { children: _jsx(Loader, { text: t('Generating a image...') }) })), generatedImage && (_jsxs(Generated, { children: [_jsx(GeneratedImage, { alt: "Generated image", src: generatedImage }), _jsx(Styles.DownloadButtonWrapper, { children: _jsx(Styles.DownloadButton, { text: t('Download'), icon: _jsx(Download, {}), onClick: () => downloadImage(generatedImage) }) })] }))] }) }));
};
