import { Button as ButtonComponent } from 'common';
import { Icon } from 'common/icon';
import { zIndex } from 'constants/enums';
import styled, { css, keyframes } from 'styled-components';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-cyl035" }) `
  position: relative;
  display: inline-block;
  width: 100%;
  border: transparent;
`;
export const SelectButton = styled.div.withConfig({ displayName: "SelectButton", componentId: "sc-7c2m3r" }) `
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 8px 8px 8px 16px;

  @media screen and (max-width: 1023px) {
    display: none;
    margin: 0px;
    transition: all 0.3s ease-in-out;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1gron2n" }) `
  display: flex;
  flex: 1;
  flex-direction: column;

  padding: 24px;
`;
export const Button = styled.div.withConfig({ displayName: "Button", componentId: "sc-37z5mt" }) `
  z-index: ${zIndex.Modal};
`;
export const WrapperDate = styled.div.withConfig({ displayName: "WrapperDate", componentId: "sc-1kdp8xa" }) `
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-bottom: 16px;

  gap: 16px;
  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const FieldColumn = styled.div.withConfig({ displayName: "FieldColumn", componentId: "sc-kh10zq" }) `
  width: 100%;
  padding-bottom: 20px;
  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const FieldRow = styled.div.withConfig({ displayName: "FieldRow", componentId: "sc-e7wfv6" }) `
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 16px;
`;
export const FiledTime = styled.div.withConfig({ displayName: "FiledTime", componentId: "sc-r0vjvv" }) `
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  gap: 16px;
  width: 100%;
`;
export const Menu = styled.ul.withConfig({ displayName: "Menu", componentId: "sc-hm3myp" }) `
  position: absolute;
  top: ${({ $isOpen, $top }) => ($isOpen && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};

  padding: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow};
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.toggle.bg.active};
  border-radius: 16px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  animation: ${({ $isOpen }) => ($isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out, max-height 0.5s ease-in-out;

  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  overflow-y: auto;
  z-index: ${({ $isOpen }) => ($isOpen ? zIndex.Dropdown : zIndex.None)};
`;
export const MenuItem = styled.li.withConfig({ displayName: "MenuItem", componentId: "sc-1k4zkjs" }) `
  padding: 8px 28px 8px 16px;
  border-radius: 8px;

  ${({ $isActive }) => $isActive &&
    css `
      flex: 1;
      width: 100%;
      background: ${({ theme }) => theme.config.dropdown.background.default};
      border: ${({ theme }) => theme.config.dropdown.border.green};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
export const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-1c55yui" }) `
  flex: 1;
  width: 100%;
  padding: 8px 16px;
  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const WrapperItem = styled.div.withConfig({ displayName: "WrapperItem", componentId: "sc-1jexeg7" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  width: 100%;
  cursor: pointer;
`;
export const Check = styled(Icon.CheckmarkDrop).withConfig({ displayName: "Check", componentId: "sc-1va4i0j" }) ``;
export const WrapperIcon = styled(IconWrapper).withConfig({ displayName: "WrapperIcon", componentId: "sc-10ge9ac" }) `
  margin-right: 8px;
  visibility: hidden;

  ${({ $isActive }) => $isActive &&
    css `
      visibility: visible;
      fill: ${({ theme }) => theme.config.line.primary};
    `}
`;
export const Arrow = styled(Icon.ArrowDown).withConfig({ displayName: "Arrow", componentId: "sc-1kzicof" }) `
  ${({ $isOpen }) => css `
    transform: rotate(${$isOpen ? '180deg' : '0deg'});
    transition: transform 0.25s ease-in;
  `}
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-t76pjv" }) `
  display: flex;
  align-items: center;

  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
  border-radius: 30px;
  padding-right: 16px;
  background: ${({ theme }) => theme.config.toggle.wrapper};
  transition: padding 0.3s ease-in-out;

  @media screen and (max-width: 1023px) {
    padding: 4px;
    border-radius: 10px;
    transition: padding 0.3s ease-in-out;
  }
`;
export const ButtonFilters = styled(ButtonComponent).withConfig({ displayName: "ButtonFilters", componentId: "sc-1po655w" }) `
  box-shadow: ${({ theme }) => theme.config.button.primary.shadow.secondary};
`;
export const ContainerButton = styled.div.withConfig({ displayName: "ContainerButton", componentId: "sc-sbyvnf" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin: 40px 0px 20px;
`;
