var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant, InputField } from 'common';
import { AppRoutes } from 'config';
import { Formik } from 'formik';
import { useQueryNavigate } from 'hooks';
import React, { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutation, useResetPasswordMutation, } from 'store/api/auth';
import { handleMutation } from 'utils/handleMutation';
import { initialValues, model, validationSchema } from './config';
import * as Styles from './styles';
const STEP = {
    EMAIL: 1,
    FINISH: 2,
};
export const RecoverPasswordForms = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const [step, setStep] = useState(STEP.EMAIL);
    const navigate = useQueryNavigate();
    const { Home, Login } = AppRoutes.Auth;
    const nextStep = () => {
        if (step < STEP.FINISH)
            setStep(step + 1);
        else
            setStep(STEP.EMAIL);
    };
    const [forgotPassword, forgotPasswordMutation] = useForgotPasswordMutation();
    const [resetPassword, resetPasswordMutation] = useResetPasswordMutation();
    const onSuccessEmail = useCallback(() => {
        alert.success(t('Password recovery email sent successfully'));
        nextStep();
    }, [alert]);
    const onSuccessReset = useCallback(() => {
        alert.success(t('Password changed successfully'));
        navigate(Home + Login, { replace: true });
    }, [alert]);
    const onError = useCallback((error) => {
        alert.error((error === null || error === void 0 ? void 0 : error.data) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, forgotPasswordMutation), { onSuccess: onSuccessEmail, onError }));
    handleMutation(Object.assign(Object.assign({}, resetPasswordMutation), { onSuccess: onSuccessReset, onError }));
    const backStep = () => {
        if (step > STEP.EMAIL) {
            setStep(step - 1);
        }
        else {
            navigate(Home + Login, { replace: true });
        }
    };
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (step === STEP.EMAIL) {
            const { email } = values;
            yield forgotPassword({ email });
        }
        if (step === STEP.FINISH) {
            yield resetPassword(values);
        }
    });
    return (_jsx(Formik, Object.assign({ onSubmit: onSubmit, initialValues: initialValues, validationSchema: () => validationSchema(t, step) }, { children: ({ dirty, submitForm }) => (_jsxs(Styles.Container, { children: [_jsxs(Styles.WrapperForm, { children: [step === STEP.EMAIL && (_jsxs(_Fragment, { children: [_jsx(Styles.Header, { children: t('Password Recovery') }), _jsx(InputField, Object.assign({}, model.email))] })), step === STEP.FINISH && (_jsxs(_Fragment, { children: [_jsx(Styles.Header, { children: t('Password Recovery') }), _jsxs(Styles.FieldsColumn, { children: [_jsx(InputField, Object.assign({}, model.code)), _jsx(InputField, Object.assign({}, model.password)), _jsx(InputField, Object.assign({}, model.confirmPassword))] })] }))] }), _jsxs(Styles.ContainerButton, { children: [_jsx(Styles.Button, { text: t('Next'), onClick: submitForm, disabled: !dirty }), step > 0 && (_jsx(Styles.Button, { variant: ButtonVariant.SECONDARY, text: t('Back'), onClick: backStep }))] })] })) })));
};
