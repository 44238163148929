var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField, Text, TextVariant } from '../../../../../common';
import { Delete } from '../../../../../common/icon/icons';
import { Form } from '../../../../../components/form/Form';
import { useClearHistoryMutation, useGetRequestsHistoryQuery, useMakeRequestMutation, } from '../../../../../store/api/ai';
import { useMeQuery } from '../../../../../store/api/profile';
import { chatInitialValues, chatValidationSchema } from './chatConfig';
import * as Styles from './styles';
import { DeleteModal } from '../../../../../components/modal/delete-modal';
import { ChatRole } from './chat-role';
import { RolesObject } from './chat-role/config';
function pause(delayMs) {
    return new Promise((r) => {
        setTimeout(r, delayMs);
    });
}
function timeTravelText(text, setText, setIsTyping) {
    return __awaiter(this, void 0, void 0, function* () {
        let totalText = '';
        setIsTyping(true);
        const append = (letter, trimEnd = false) => {
            if (trimEnd) {
                totalText = totalText.trimEnd();
            }
            totalText += letter;
            setText(totalText);
        };
        for (let line of text.split('.')) {
            for (let word of line.split(' ')) {
                for (let letter of word.split('')) {
                    append(letter);
                    yield pause(25);
                }
                append(' ');
                yield pause(word.length * 5);
            }
            append('.', true);
            yield pause(300);
        }
        setIsTyping(false);
    });
}
function useLocalStorageState(key, defaultValue) {
    const [, rerender] = useState(0);
    if (!localStorage.getItem(key)) {
        localStorage.setItem(key, JSON.stringify(defaultValue));
    }
    return {
        value: JSON.parse(localStorage.getItem(key)),
        setValue: (value) => {
            localStorage.setItem(key, JSON.stringify(value));
            rerender((i) => i + 1);
        },
    };
}
const ChatInput = ({ isLoading }) => {
    const { t } = useTranslation();
    const { submitForm } = useFormikContext();
    return (_jsx(InputField, { name: "message", placeholder: t('Type your message here'), onRightIconClick: () => submitForm(), disabled: isLoading, rightIcon: _jsx(Styles.AIIconWrapper, Object.assign({ disabled: isLoading }, { children: _jsx(Styles.SendIcon, { disabled: isLoading }) })) }));
};
export const Chat = () => {
    const { t } = useTranslation();
    const { data: user } = useMeQuery();
    const chatRef = useRef(null);
    const { value: messages, setValue: setMessages } = useLocalStorageState('chat', []);
    const { value: lastRole, setValue: setLastRole } = useLocalStorageState('lastSelectedRole', RolesObject[0]);
    const [changeRequest, mutationRequest] = useMakeRequestMutation();
    const { data: history, refetch } = useGetRequestsHistoryQuery({
        user_id: user === null || user === void 0 ? void 0 : user._id,
    });
    const [changeClearHistory, mutationClearHistory] = useClearHistoryMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [selectedRole, setSelectedRole] = useState(lastRole);
    // const [avatarUrl, setAvatarUrl] = useState(DefaultAvatar);
    // useEffect(() => {
    //   if (user?.avatar_link && user?.avatar_link !== 'none') {
    //     setAvatarUrl(BASE_URL + '/v1/' + user?.avatar_link);
    //   } else {
    //     setAvatarUrl(DefaultAvatar);
    //   }
    // }, [user]);
    useEffect(() => {
        setLastRole(selectedRole);
    }, [selectedRole]);
    const [isClearHistoryVisible, setIsClearHistoryVisible] = useState(false);
    const handleClearHistoryVisible = () => {
        setIsClearHistoryVisible(!isClearHistoryVisible);
    };
    useEffect(() => {
        if (user) {
            refetch();
        }
    }, [user]);
    const updateMessages = (newMessages) => {
        setMessages(newMessages);
    };
    useEffect(() => {
        setTimeout(() => {
            var _a;
            (_a = chatRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: 999999 * 999999,
                behavior: 'smooth',
            });
        }, 100);
    }, [messages]);
    useEffect(() => {
        if (history) {
            updateMessages(history.messages.map((m) => {
                return {
                    text: m.content,
                    role: m.role,
                    system_role: m.system_role,
                };
            }));
        }
    }, [history]);
    useEffect(() => {
        if (mutationRequest.isSuccess) {
            const oldMessages = [...messages];
            timeTravelText(mutationRequest.data.message, (text) => {
                updateMessages([
                    ...oldMessages,
                    {
                        text,
                        role: 'assistant',
                        system_role: selectedRole.rolePrompt,
                    },
                ]);
            }, setIsTyping);
            setIsLoading(false);
        }
    }, [mutationRequest]);
    useEffect(() => {
        if (mutationClearHistory.isSuccess && mutationClearHistory.data.status) {
            updateMessages([]);
        }
    }, [mutationClearHistory]);
    const sendMessage = (values, { setFieldValue }) => __awaiter(void 0, void 0, void 0, function* () {
        if (values.message || isLoading) {
            const message = {
                role: 'user',
                text: values.message,
            };
            const newMessages = [...messages, message];
            setFieldValue('message', '');
            updateMessages(newMessages);
            setIsLoading(true);
            yield changeRequest({
                request_text: message.text,
                user_id: user._id,
                role: selectedRole.rolePrompt,
            });
        }
    });
    const clearHistory = () => {
        changeClearHistory({
            user_id: user._id,
        });
    };
    const findRoleByPrompt = (prompt) => {
        var _a;
        return (_a = RolesObject.find((role) => role.rolePrompt === prompt)) === null || _a === void 0 ? void 0 : _a.roleIconUrl;
    };
    return (_jsxs(Styles.Container, { children: [_jsx(ChatRole, { selectedRole: selectedRole, setSelectedRole: setSelectedRole }), _jsxs(Styles.Chat, { children: [messages.length ? (_jsx(Styles.ChatDialog, Object.assign({ ref: chatRef }, { children: messages.map((message, index) => {
                            return (_jsxs(Styles.Message, Object.assign({ isChat: message.role == 'assistant' }, { children: [_jsx(Styles.MessageMeta, Object.assign({ isChat: message.role == 'assistant' }, { children: message.role == 'assistant' ? (
                                        // <Logo />
                                        _jsx(Styles.Image, { width: 24, alt: "image", height: 24, borderRadius: 50, objectFit: "cover", src: findRoleByPrompt(message.system_role) })) : (_jsx(Styles.UserIcon, {})
                                        // <Styles.Image
                                        //   width={24}
                                        //   alt="image"
                                        //   height={24}
                                        //   borderRadius={50}
                                        //   objectFit="cover"
                                        //   src={avatarUrl}
                                        // />
                                        ) })), _jsxs(Styles.MessageText, { children: [message.text, message.role === 'assistant' &&
                                                messages.length - 1 === index &&
                                                isTyping && _jsx(Styles.Caret, { children: "I" })] })] }), index));
                        }) }))) : (_jsxs(Styles.EmptyDialog, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: "Brendboost GPT" })), _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: t('Ask AI something') }))] })), _jsxs(Styles.ChatInput, { children: [isLoading && (_jsx(Styles.AITypingMessage, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: t('AI is typing') })) })), !!messages.length && (_jsxs(Styles.ClearHistory, Object.assign({ onClick: handleClearHistoryVisible }, { children: [_jsx(Delete, {}), _jsx(Text, Object.assign({ "$colorVariant": "tetriery", "$variant": TextVariant.smallLight }, { children: t('Clear history') }))] }))), _jsx(Form, Object.assign({ initialValues: chatInitialValues, validationSchema: chatValidationSchema, onSubmit: sendMessage }, { children: _jsx(ChatInput, { isLoading: isLoading }) }))] })] }), _jsx(DeleteModal, { isVisible: isClearHistoryVisible, onClick: handleClearHistoryVisible, message: 'Вы точно хотите очистить историю чата с ИИ?', modalTitle: 'Очистить историю', buttonText: 'Да, очистить', deleteFunc: clearHistory })] }));
};
