import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-r8los3" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-77kmra" }) `
  width: 100%;
  padding: 40px 16px 10px 40px;

  overflow-y: auto;
  max-height: 100vh;

  ${scrollMedium};

  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1jmyto0" }) `
  display: flex;
  flex-direction: column;
  margin: 40px;

  gap: 24px;
`;
export const WrapperCheckbox = styled.div.withConfig({ displayName: "WrapperCheckbox", componentId: "sc-1vmcyqt" }) `
  display: flex;
  align-items: center;
`;
export const LeftContent = styled.div.withConfig({ displayName: "LeftContent", componentId: "sc-m8eskp" }) `
  display: flex;
  align-items: center;

  gap: 16px;
`;
