var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loader } from 'common';
import { AppRoutes } from 'config';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { useVkGroupsMutation, useVkPostsMutation } from 'store/api/parser';
import { handleMutation } from 'utils/handleMutation';
import { useNavigate } from '../../../../../hooks';
import { FormsVkParserGroups } from './forms/groups';
import { FormsVkParserPosts } from './forms/posts';
import { VkontakteHomePage } from './home';
import * as Styles from './styles';
var VkParserVariant;
(function (VkParserVariant) {
    VkParserVariant["Posts"] = "posts";
    VkParserVariant["Groups"] = "groups";
})(VkParserVariant || (VkParserVariant = {}));
export const VkontaktePage = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const { t } = useTranslation();
    const { Parsing } = AppRoutes.Dashboard;
    const [postsParse, postsMutation] = useVkPostsMutation();
    const [groupsParse, groupsMutation] = useVkGroupsMutation();
    const isLoadingPosts = postsMutation.isLoading;
    const isLoadingGroups = groupsMutation.isLoading;
    const onSuccess = useCallback((data) => {
        navigate(`/dashboard/my-database/${data._id}`);
    }, []);
    const onError = useCallback((error) => {
        alert.error((error === null || error === void 0 ? void 0 : error.data) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, groupsMutation), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, postsMutation), { onSuccess,
        onError }));
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { key, count, groupScreenName, comments, is_members_ids, hasUsersLimit, country_id, searchPromt, } = values;
        if (key === VkParserVariant.Groups) {
            yield groupsParse(hasUsersLimit
                ? { count, is_members_ids, searchPromt, country_id }
                : { count, is_members_ids: 'False', searchPromt, country_id });
        }
        else if (key === VkParserVariant.Posts) {
            const regex = /https?:\/\/vk\.com\/([^\/]+)/;
            yield postsParse({
                count,
                groupScreenName: groupScreenName.match(regex)[1],
                comments,
            });
        }
    });
    if (isLoadingPosts || isLoadingGroups) {
        return (_jsx(Styles.Container, { children: _jsx(Styles.WrapperLoader, { children: _jsx(Loader, {}) }) }));
    }
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: Parsing.Vkontakte.VkontakteGroups, element: _jsx(VkontakteHomePage, { children: _jsx(FormsVkParserGroups, { parse: handleSubmit }) }) }), _jsx(Route, { path: Parsing.Vkontakte.VkontaktePosts, element: _jsx(VkontakteHomePage, { children: _jsx(FormsVkParserPosts, { parse: handleSubmit }) }) })] }));
};
