export const model = {
    ai: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'ai',
    },
    advertising: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'advertising',
    },
    marketplaces: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'marketplaces',
    },
    parsers: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'parsers',
    },
    reports: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'reports',
    },
    text_mailing: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'text_mailing',
    },
    voice_mailing: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'voice_mailing',
    },
};
export const initialValues = {
    advertising: false,
    ai: false,
    marketplaces: false,
    name: '',
    parsers: false,
    reports: false,
    text_mailing: false,
    voice_mailing: false,
};
export const sectionsPrice = {
    advertising: 1,
    ai: 2,
    marketplaces: 3,
    parsers: 4,
    reports: 5,
    text_mailing: 6,
    voice_mailing: 7,
};
export const sectionsDescription = {
    ai: 'Описание раздела ИИ..',
    advertising: 'Описание раздела Реклама..',
    marketplaces: 'Описание раздела Марекетплейсы..',
    parsers: 'Описание раздела Парсинг..',
    reports: 'Описание раздела Отчеты..',
    text_mailing: 'Описание раздела Рассылка..',
    voice_mailing: 'Описание раздела Голосовой робот..',
};
