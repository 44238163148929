import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiNotificationQuery } from '../query';
const tagTypes = ['NotificationList'];
export const NotificationApi = createApi({
    tagTypes,
    reducerPath: 'NotificationApi',
    baseQuery: apiNotificationQuery,
    endpoints: (build) => ({
        notificationList: build.query({
            query: (limit) => {
                return {
                    method: 'GET',
                    url: `${URLS.NOTIFICATION_LIST}`,
                    params: limit,
                };
            },
            providesTags: () => ['NotificationList'],
        }),
        notificationAdd: build.mutation({
            query: (values) => {
                return {
                    url: URLS.NOTIFICATION_ADD,
                    method: 'POST',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: () => ['NotificationList'],
        }),
        notificationDelete: build.mutation({
            query: ({ notificationId }) => {
                return {
                    url: URLS.NOTIFICATION_DELETE,
                    method: 'POST',
                    params: {
                        notification_id: notificationId,
                    },
                };
            },
            invalidatesTags: () => ['NotificationList'],
        }),
        notificationClear: build.mutation({
            query: (values) => {
                return {
                    url: URLS.NOTIFICATION_CLEAR,
                    method: 'POST',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: () => ['NotificationList'],
        }),
    }),
});
export const { useNotificationListQuery, useNotificationAddMutation, useNotificationDeleteMutation, useNotificationClearMutation, } = NotificationApi;
