import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiBalanceQuery } from '../query';
export const BalanceApi = createApi({
    reducerPath: 'BalanceApi',
    baseQuery: apiBalanceQuery,
    endpoints: (build) => ({
        refillBalance: build.mutation({
            query: ({ user_id, amount }) => {
                return {
                    url: URLS.REFILL_BALANCE,
                    method: 'POST',
                    params: {
                        user_id,
                        amount,
                    },
                };
            },
        }),
    }),
});
export const { useRefillBalanceMutation } = BalanceApi;
