import { Icon } from 'common/icon';
import { t } from 'i18next';
export const mailingHistoryFiltersInitialValues = {
    status: [],
    mailingType: [],
};
export const menuElements = [
    {
        icon: Icon.Reload,
        label: 'Repeat',
        labelAction: 'repeat',
    },
    {
        icon: Icon.Delete,
        label: 'Remove',
        labelAction: 'remove',
    },
];
// const statusOptions = Object.keys(ItemStatusVariants).map((optionKey) => ({
//   label: t(optionKey),
//   value: optionKey,
// }));
export const mailingHistoryFilterModel = [
    {
        name: 'mailingType',
        label: t('Mailing type'),
        placeholder: t('Select type'),
        options: [
            'Email',
            'Telegram',
            // 'Viber', 'WhatsApp'
        ].map((label) => ({
            label: t(label),
            value: label.toString(),
        })),
    },
    // {
    //   name: 'status',
    //   label: t('Status'),
    //   placeholder: t('Select status'),
    //   options: statusOptions,
    // },
];
