import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { DevStub } from '../../../../components/Dev-stub/DevStub';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1nf72mi" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 15px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};
  padding: 20px;
  position: relative;

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const ReportsContent = () => {
    return (_jsx(Container, { children: _jsx(DevStub, {}) }));
};
