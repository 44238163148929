var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import AudioPlayer from 'react-h5-audio-player';
import { BASE_AUDIO_CLIPS_URL } from '../../../api/constants';
import { Dialog, Text, TextVariant } from '../../../common';
import * as Styles from './styles';
export const AudioListeningModal = ({ isOpen, closeModal, info, fileUrl, }) => {
    const [audio, setAudio] = useState('');
    const getAudio = (audioclip_id) => __awaiter(void 0, void 0, void 0, function* () {
        const url = `${BASE_AUDIO_CLIPS_URL}/v1/audioclips/clip_get?audioclip_id=${audioclip_id}`;
        {
            try {
                const response = (yield fetch(url, {
                    method: 'POST',
                }));
                const file = yield response.blob();
                setAudio(URL.createObjectURL(file));
            }
            catch (_) {
            }
            finally {
            }
        }
    });
    const audioRef = useRef(null);
    useEffect(() => {
        console.log(info);
    }, [info]);
    useEffect(() => {
        if (info) {
            getAudio(info.audioclip_id);
        }
    }, [info]);
    useEffect(() => {
        console.log(audioRef.current);
    }, [audioRef]);
    return (_jsx(_Fragment, { children: _jsxs(Dialog, Object.assign({ isOpen: isOpen, width: '600px', topMargin: '200px', onClose: closeModal, zIndex: 999 }, { children: [_jsx(Styles.DialogHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u041F\u0440\u043E\u0441\u043B\u0443\u0448\u0438\u0432\u0430\u043D\u0438\u0435 \u0430\u0443\u0434\u0438\u043E\u0440\u043E\u043B\u0438\u043A\u0430" })) }), _jsxs(Styles.DialogContent, { children: [info && (_jsxs(Styles.AudioInfo, { children: [_jsxs(Styles.AudioInfoRow, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": 'tetriery' }, { children: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" })), _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: info === null || info === void 0 ? void 0 : info.clip_name }))] }), _jsxs(Styles.AudioInfoRow, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": 'tetriery' }, { children: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435" })), _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: info === null || info === void 0 ? void 0 : info.clip_description }))] }), _jsxs(Styles.AudioInfoRow, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": 'tetriery' }, { children: "\u0421\u043E\u0437\u0434\u0430\u043D" })), _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: dayjs(info === null || info === void 0 ? void 0 : info.created)
                                                .locale('ru')
                                                .format('HH:MM, D MMM YYYY') }))] })] })), _jsx(Styles.AudioPlayerContainer, { children: _jsx(AudioPlayer, { src: info ? audio : fileUrl, autoPlay: false, ref: audioRef, autoPlayAfterSrcChange: false }) })] })] })) }));
};
