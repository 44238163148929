import styled, { css } from 'styled-components';
import { Button } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ykzunc" }) `
  overflow: auto;
  border-radius: 20px;
  margin: 32px 24px 24px 0;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};
  //
  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-xivcvr" }) `
  margin: 40px;
`;
export const Generated = styled.div.withConfig({ displayName: "Generated", componentId: "sc-2vz6zn" }) `
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 40px;
`;
export const ImageGenerateFormWrapper = styled.div.withConfig({ displayName: "ImageGenerateFormWrapper", componentId: "sc-i23fls" }) `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const FullForm = styled.div.withConfig({ displayName: "FullForm", componentId: "sc-1jivnia" }) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;
export const FileInput = styled.input.withConfig({ displayName: "FileInput", componentId: "sc-rmsrkj" }) `
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;
  padding: 0;
`;
export const UploadImageButton = styled(Button).withConfig({ displayName: "UploadImageButton", componentId: "sc-a4i8pk" }) `
  max-width: fit-content;
`;
export const ImageContainer = styled.div.withConfig({ displayName: "ImageContainer", componentId: "sc-hxhc4c" }) `
  position: relative;
  max-height: 400px;
  max-width: 400px;
  max-width: 400px;
`;
export const ImagePreview = styled.img.withConfig({ displayName: "ImagePreview", componentId: "sc-14o49xp" }) `
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;
export const ImageContainer2 = styled.div.withConfig({ displayName: "ImageContainer2", componentId: "sc-1xhusyw" }) `
  position: relative;
  max-height: 400px;
  //max-width: 32%;
  width: 32%;
  background-color: ${({ theme }) => theme.config.background.tetriery};
  //background-image: url('https://st.depositphotos.com/3265665/4462/i/450/depositphotos_44627471-stock-photo-transparent.jpg');
  border-radius: 10px;
`;
export const LoaderContainer = styled.div.withConfig({ displayName: "LoaderContainer", componentId: "sc-1blc6gs" }) `
  max-height: 400px;
  width: 32%;
  border-radius: 10px;
  //background-color: ${({ theme }) => theme.config.background.tetriery};
`;
export const RangeInput = styled.input.withConfig({ displayName: "RangeInput", componentId: "sc-10v592y" }) `
  ${({ theme }) => {
    return css `
      accent-color: ${theme.config.input.green.text};
      border-color: ${theme.config.input.default.text};
      background: ${theme.config.input.default.background};
    `;
}};
  width: 83%;
`;
export const RangeInputContainer = styled.div.withConfig({ displayName: "RangeInputContainer", componentId: "sc-1suigvg" }) `
  width: 400px;
  display: flex;
  gap: 10px;
`;
export const ChangeImageButton = styled.div.withConfig({ displayName: "ChangeImageButton", componentId: "sc-mdukbh" }) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: opacity(0);
  transition: filter 0.1s ease-in-out, backdrop-filter 0.4s ease-in-out;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    filter: opacity(1);
    backdrop-filter: blur(3px) brightness(0.2);
  }
`;
export const ParamsContainer = styled.div.withConfig({ displayName: "ParamsContainer", componentId: "sc-znv1g" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;
export const GenerateButton = styled(Button).withConfig({ displayName: "GenerateButton", componentId: "sc-152we89" }) ``;
