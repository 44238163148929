var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Dialog, Text, TextVariant } from '../../../../../common';
import { Dropdown } from '../../../../../components';
import { Form } from '../../../../../components/form/Form';
import { useCreateTicketMutation } from '../../../../../store/api/profile';
import { handleMutation } from '../../../../../utils/handleMutation';
import { helpModalInitialValues, helpModalValidationSchema, } from './config';
import * as Styles from './styles';
const dropdownItems = [
    {
        label: 'Проблемы с аккаунтом',
        value: 'Аккаунт',
    },
    {
        label: 'Не могу разобраться с интерфейсом',
        value: 'Интерфейс',
    },
    {
        label: 'Нет нужного функционала',
        value: 'Функционал',
    },
    {
        label: 'Другая',
        value: 'Другое',
    },
];
export const HelpModalContent = ({ isModalOpen, setIsModalOpen, }) => {
    const { setFieldValue, values, submitForm, dirty, isValid, isSubmitting, resetForm, } = useFormikContext();
    const { t } = useTranslation();
    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (_jsxs(Dialog, Object.assign({ isOpen: isModalOpen, topMargin: '15vh', onClose: closeModal }, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u041F\u043E\u043C\u043E\u0449\u044C" })), _jsxs(Styles.Container, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "\u0412\u044B\u0431\u0435\u0438\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u044B \u0438\u0437 \u0441\u043F\u0438\u0441\u043A\u0430:" })), _jsx(Dropdown, { items: dropdownItems.map((item) => {
                            return {
                                label: item.label.toString(),
                                onClick: () => setFieldValue('selectedTab', item),
                            };
                        }), button: _jsx(Styles.DropdownButton, { children: values.selectedTab === null ? (_jsx(Text, Object.assign({ "$colorVariant": 'tetriery', "$variant": TextVariant.bodyRegular }, { children: "\u0412\u044B\u0431\u0435\u0440\u0435\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E" }))) : (_jsx(Text, Object.assign({ "$colorVariant": 'primary', "$variant": TextVariant.bodySemiBold }, { children: values.selectedTab.label }))) }) }), _jsxs(Styles.HelpModalInputContainer, { children: [_jsx(Text, { children: "\u041E\u043F\u0438\u0448\u0438\u0442\u0435 \u0432\u0430\u0448\u0443 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u0443:" }), _jsx(Styles.HelpModalInput, { inputType: 'textarea', name: 'userInput', value: values.userInput, placeholder: 'Введите проблему, с которой вам необходима помощь', onChange: (e) => setFieldValue('userInput', e.target.value) }), _jsx(Text, Object.assign({ "$variant": TextVariant.ultraSmallMedium, "$colorVariant": 'tetriery' }, { children: "* \u041C\u0438\u043D\u0438\u043C\u0443\u043C 10 \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432" }))] }), _jsx(Styles.SubmitButtonContainer, { children: _jsx(Styles.SubmitButton, { text: t('Send'), disabled: !dirty || !isValid || isSubmitting, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                closeModal();
                                yield submitForm();
                                resetForm();
                            }) }) })] })] })));
};
export const HelpModal = ({ isModalOpen, setIsModalOpen }) => {
    const [createTicket, responseCreateTicket] = useCreateTicketMutation();
    const alert = useAlert();
    const { t } = useTranslation();
    const submit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        console.log(values);
        const text = values.selectedTab.value + ': ' + values.userInput;
        yield createTicket({ text });
    });
    const onSuccess = () => {
        alert.success(t('Your request for help has been sent'));
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseCreateTicket), { onSuccess,
        onError }));
    return (_jsx(Form, Object.assign({ initialValues: helpModalInitialValues, onSubmit: submit, validationSchema: helpModalValidationSchema }, { children: _jsx(HelpModalContent, { isModalOpen: isModalOpen, setIsModalOpen: setIsModalOpen }) })));
};
