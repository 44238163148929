import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiLoaderQuery } from 'store/query';
export const authApi = createApi({
    tagTypes: [],
    reducerPath: 'authApi',
    baseQuery: apiLoaderQuery,
    endpoints: (build) => ({
        login: build.mutation({
            query: ({ email, password }) => ({
                method: 'POST',
                url: URLS.LOGIN,
                data: { email, password },
            }),
            invalidatesTags: [],
        }),
        registerPrivatePerson: build.mutation({
            query: ({ email, phone, password, firstName, lastName, }) => ({
                method: 'POST',
                url: URLS.REGISTER_PERSONAL,
                data: {
                    email,
                    phone,
                    password,
                    additional_info: {
                        first_name: firstName,
                        last_name: lastName,
                    },
                },
            }),
            invalidatesTags: [],
        }),
        registerLegalPerson: build.mutation({
            query: ({ email, inn, phone, lastName, password, firstName, companyName, }) => ({
                method: 'POST',
                url: URLS.REGISTER_LEGAL,
                data: {
                    email,
                    phone,
                    password,
                    additional_info: {
                        inn,
                        last_name: lastName,
                        first_name: firstName,
                        company_name: companyName,
                    },
                },
            }),
            invalidatesTags: [],
        }),
        forgotPassword: build.mutation({
            query: ({ email, isChange }) => {
                const data = Object.assign({ email }, (isChange ? { is_change: true } : {}));
                return {
                    method: 'POST',
                    url: URLS.FORGOT_PASSWORD,
                    data: data,
                };
            },
            invalidatesTags: [],
        }),
        resetPassword: build.mutation({
            query: ({ code, password, confirmPassword }) => {
                return {
                    method: 'POST',
                    url: URLS.RESET_PASSWORD,
                    data: {
                        secure_code: code,
                        password,
                        confirm_password: confirmPassword,
                    },
                };
            },
            invalidatesTags: [],
        }),
    }),
});
export const { useLoginMutation, useResetPasswordMutation, useForgotPasswordMutation, useRegisterLegalPersonMutation, useRegisterPrivatePersonMutation, } = authApi;
