import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiMailingHistoryQuery } from '../query';
export const MailingHistoryApi = createApi({
    reducerPath: 'MailingHistoryApi',
    baseQuery: apiMailingHistoryQuery,
    endpoints: (build) => ({
        getHistory: build.mutation({
            query: ({ user_id }) => {
                return {
                    url: URLS.GET_MAILING_HISTORY,
                    method: 'POST',
                    data: {
                        user_id,
                    },
                };
            },
        }),
        deleteMailing: build.mutation({
            query: ({ user_id, newsletter_ids }) => {
                return {
                    url: URLS.DELETE_MAILING,
                    method: 'POST',
                    params: {
                        user_id,
                        newsletter_ids,
                    },
                };
            },
        }),
    }),
});
export const { useGetHistoryMutation, useDeleteMailingMutation } = MailingHistoryApi;
