import { InputSize } from 'common/input/types';
import * as Yup from 'yup';
import { InputMasks } from 'common/fields/input-field';
export const model = {
    email: {
        label: '',
        type: 'email',
        name: 'email',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'E-mail',
    },
    code: {
        label: '',
        type: 'text',
        name: 'code',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.inn,
        placeholder: 'Code',
    },
    password: {
        label: '',
        type: 'password',
        name: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'New password',
    },
    confirmPassword: {
        label: '',
        type: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        name: 'confirmPassword',
        placeholder: 'Password Confirmation',
    },
};
export const validationSchema = (t, step) => {
    if (step === 1) {
        return Yup.object().shape({
            [model.email.name]: Yup.string()
                .required(t('Email is required'))
                .email('Invalid E-Mail format'),
        });
    }
    if (step === 2) {
        return Yup.object().shape({
            [model.code.name]: Yup.string().required(t('Code is required')),
            [model.password.name]: Yup.string()
                .required()
                .min(8)
                .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
                .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
                .matches(/^(?=.*\d)/, 'Password must contain at least one digit'),
            [model.confirmPassword.name]: Yup.string()
                .required(t('Password is required'))
                .test('passwords-match', t('Passwords do not match'), function (value) {
                return value === this.resolve(Yup.ref(model.password.name));
            }),
        });
    }
};
export const initialValues = {
    [model.email.name]: '',
    [model.code.name]: '',
    [model.password.name]: '',
    [model.confirmPassword.name]: '',
};
