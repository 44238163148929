import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { DeleteProfile } from 'components/modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';
export const HeaderProfile = () => {
    const { t } = useTranslation();
    const [isDeleteProfileVisible, setIsDeleteProfileVisible] = useState(false);
    const handleDeleteProfile = () => {
        setIsDeleteProfileVisible(!isDeleteProfileVisible);
    };
    return (_jsxs(Styles.WrapperHeader, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: t('My Profile') })) }), _jsx(Button, { icon: _jsx(Icon.Delete, {}), text: t('Delete Profile'), variant: ButtonVariant.TETRIERY, onClick: handleDeleteProfile }), _jsx(DeleteProfile, { isVisible: isDeleteProfileVisible, onClick: handleDeleteProfile })] }));
};
