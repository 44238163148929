import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './styles';
import { Formik } from 'formik';
import { useMeQuery, useUpdateTariffMutation, } from '../../../../../../../store/api/profile';
import { handleMutation } from '../../../../../../../utils/handleMutation';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Tariffs } from './tariffBlock/TariffBlock';
import { Button, ButtonVariant } from '../../../../../../../common';
import { TariffConstructor } from './tariff-constructor';
export const TariffForm = () => {
    const { data: me } = useMeQuery();
    const alert = useAlert();
    const { t } = useTranslation();
    const [updateTariff, responseUpdateTariff] = useUpdateTariffMutation();
    const [tariffName, setTariffName] = useState('');
    const [showConstructor, setShowConstructor] = useState();
    const onSubmit = (values) => {
        updateTariff({
            ai: values.ai,
            name: 'Индивидуальный',
            parsers: values.parsers,
            reports: values.reports,
            advertising: values.advertising,
            marketplaces: values.marketplaces,
            text_mailing: values.text_mailing,
            voice_mailing: values.voice_mailing,
        });
    };
    const onSuccess = () => {
        alert.success(t('Tariff changed successfully'));
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    useEffect(() => {
        if (me && me.tariff) {
            const tariff = JSON.parse(me.tariff.toString());
            setTariffName(tariff.name);
            // if (tariff.name === 'Индивидуальный') {
            // setShowConstructor(true);
            // }
        }
    }, [me]);
    handleMutation(Object.assign(Object.assign({}, responseUpdateTariff), { onSuccess,
        onError }));
    return (_jsx(Styles.Container, { children: _jsxs(Styles.WrapperContent, { children: [_jsx(Styles.WrapperSwitch, { children: showConstructor && (_jsx(Formik, Object.assign({ onSubmit: onSubmit, initialValues: JSON.parse(me.tariff.toString()) }, { children: _jsx(TariffConstructor, {}) }))) }), !showConstructor && (_jsx(Styles.WrapperSwitch, { children: _jsx(Tariffs, { selectedTariffName: tariffName, setShowConstructor: () => {
                            setShowConstructor(true);
                        } }) })), _jsx(Button, { variant: ButtonVariant.DARK, onClick: () => {
                        setShowConstructor(!showConstructor);
                    }, text: !showConstructor
                        ? 'Собрать свой индивидуальный тариф'
                        : 'Выбрать готовые' })] }) }));
};
