import styled, { css } from 'styled-components';
import { Button, Input } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1nsjp2u" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-x38386" }) `
  display: flex;
  align-items: center;
  padding: 20px 40px;
`;
export const Tabs = styled.div.withConfig({ displayName: "Tabs", componentId: "sc-1xwdfic" }) `
  display: flex;
  flex-direction: row;
`;
export const TabItem = styled.div.withConfig({ displayName: "TabItem", componentId: "sc-44ckcs" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 50px;
  box-shadow: 1px 0px 0px 0px rgba(255, 255, 255, 0.2);

  cursor: pointer;

  ${({ $isActive, theme }) => $isActive &&
    css `
      //border-top-right-radius: 10px;
      //border-bottom-right-radius: 10px;
      background: ${theme.config.background.primary};
      border-bottom: 1px solid ${theme.colors.green.tetriery};
    `}
  :last-child {
    box-shadow: none;
  }
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1j04aue" }) `
  padding: 30px;
`;
export const SearchBarWrapper = styled.div.withConfig({ displayName: "SearchBarWrapper", componentId: "sc-j9m2iy" }) `
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;
export const SearchBar = styled(Input).withConfig({ displayName: "SearchBar", componentId: "sc-ho9uee" }) `
  width: 100%;
  max-width: 500px;
`;
export const ConnectButton = styled(Button).withConfig({ displayName: "ConnectButton", componentId: "sc-1xicnml" }) `
  margin-top: 30px;
  width: fit-content;
`;
