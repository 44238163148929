var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AutocompleteSelect, Select } from 'common/select';
import { useField } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { controlError } from 'utils/controlError';
import { getSelectValue } from 'utils/helpers';
export const SelectField = (_a) => {
    var { name, options, onChange, onlyValue, isCreatable, placeholder, isAutocomplete } = _a, props = __rest(_a, ["name", "options", "onChange", "onlyValue", "isCreatable", "placeholder", "isAutocomplete"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const value = useMemo(() => getSelectValue({
        onlyValue,
        options,
        value: field.value,
        isCreatable,
    }), [onlyValue, options, field.value, isCreatable]);
    const error = t(controlError(meta, name, t(placeholder !== null && placeholder !== void 0 ? placeholder : '')));
    const Component = isAutocomplete ? AutocompleteSelect : Select;
    return (_jsx(Component, Object.assign({}, field, props, { placeholder: placeholder, error: error, value: value, options: options, isCreatable: isCreatable, noOptionsMessage: () => t('No options'), onChange: (option, action) => {
            const newValue = onlyValue
                ? Array.isArray(option)
                    ? option.map((item) => item.value)
                    : // @ts-ignore
                        option === null || option === void 0 ? void 0 : option.value
                : option;
            onChange === null || onChange === void 0 ? void 0 : onChange(option, action);
            helpers.setValue(newValue);
        } })));
};
