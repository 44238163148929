import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiMailingQuery } from '../query';
export const MailingApi = createApi({
    reducerPath: 'MailingApi',
    baseQuery: apiMailingQuery,
    endpoints: (build) => ({
        sendTelegram: build.mutation({
            query: ({ name, to, text }) => {
                return {
                    url: URLS.MAILING_TELEGRAM,
                    method: 'POST',
                    data: {
                        name,
                        to,
                        text,
                    },
                };
            },
        }),
        sendViber: build.mutation({
            query: ({ name, to, text }) => {
                return {
                    url: URLS.MAILING_VIBER,
                    method: 'POST',
                    data: {
                        name,
                        to,
                        text,
                    },
                };
            },
        }),
        sendWhatsApp: build.mutation({
            query: ({ name, to, text }) => {
                return {
                    url: URLS.MAILING_WHATSAPP,
                    method: 'POST',
                    data: {
                        name,
                        to,
                        text,
                    },
                };
            },
        }),
        sendEmail: build.mutation({
            query: ({ name, to, text }) => {
                return {
                    url: URLS.MAILING_EMAIL,
                    method: 'POST',
                    data: {
                        name,
                        to,
                        text,
                    },
                };
            },
        }),
    }),
});
export const { useSendTelegramMutation, useSendEmailMutation, useSendViberMutation, useSendWhatsAppMutation, } = MailingApi;
