import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, TextVariant } from '../../common';
import { useNavigate } from '../../hooks';
import { useBackdropClick } from '../../hooks/useBackdropClick';
import { useNotificationListQuery } from '../../store/api/notifications';
import * as Styles from './styles';
export const NotificationDropdown = ({ button, onClick, isVisible, }) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const { data: notificationList } = useNotificationListQuery();
    const navigate = useNavigate();
    dayjs.locale('ru');
    const closeDropdown = () => {
        onClick();
    };
    useBackdropClick(dropdownRef, closeDropdown, isVisible);
    useEffect(() => {
        console.log(notificationList);
    }, [notificationList]);
    return (_jsxs(Styles.Container, { children: [_jsx(Styles.ButtonContainer, { children: button }), _jsx(Styles.Wrapper, { children: _jsxs(Styles.MenuNotification, Object.assign({ "$isOpen": isVisible, "$left": -230, ref: dropdownRef }, { children: [_jsx(Styles.Title, { children: t('Notifications') }), (notificationList === null || notificationList === void 0 ? void 0 : notificationList.length) ? (notificationList === null || notificationList === void 0 ? void 0 : notificationList.map((item) => (_jsx(Styles.WrapperMessage, { children: _jsxs(Styles.ContainerMessage, { children: [_jsx(Styles.Message, { children: t(item.text) }), _jsx(Styles.Timestamp, { children: dayjs(item.data).format('MMM, D, YYYY') })] }) }, item.notification_id)))) : (_jsx(Styles.WrapperEmptyMessage, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: "\u041D\u0435\u0442 \u0443\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u0439" })) })), _jsx(Styles.ButtonWrapper, { children: _jsx(Button, { text: t('View all'), variant: 'LIGHT', onClick: () => navigate('/dashboard/profile/notification') }) })] })) })] }));
};
