import styled, { css } from 'styled-components';
import { heading2Medium, heading3Medium, smallLight, ultraSmallMedium, } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-12fdyit" }) `
  display: flex;
  flex-direction: column;
`;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-1u2mnit" }) `
  flex: 0;
  display: inline-flex;
`;
export const FormContainer = styled.div.withConfig({ displayName: "FormContainer", componentId: "sc-1mjhr02" }) `
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.responsive.isMobile
    ? css `
          margin: 0rem 4rem 1rem;
        `
    : css `
          margin: 0px 120px 32px;
        `}
`;
export const Header = styled.header.withConfig({ displayName: "Header", componentId: "sc-18ijwpr" }) `
  gap: 10px;
  display: flex;
  margin: 32px 0 24px;
  flex-direction: column;

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      gap: 4px;
      align-items: center;
      margin: 8px 0 24px;
    `}
`;
export const Title = styled.span.withConfig({ displayName: "Title", componentId: "sc-17js2ff" }) `
  ${heading2Medium}

  color: ${({ theme }) => theme.config.text.primary};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      ${heading3Medium}
    `}
`;
export const SubTitle = styled.span.withConfig({ displayName: "SubTitle", componentId: "sc-1jvpjn9" }) `
  ${smallLight}

  color: ${({ theme }) => theme.config.link.secondary};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      ${ultraSmallMedium}
    `}
`;
export const FormSection = styled.div.withConfig({ displayName: "FormSection", componentId: "sc-1pzx3zp" }) `
  display: flex;
  flex-direction: column;

  ${({ theme }) => css `
      gap: ${theme.responsive.isMobile ? '20px' : '34px'};
    `}
`;
export const FooterLink = styled.div.withConfig({ displayName: "FooterLink", componentId: "sc-2nerbq" }) `
  ${smallLight}

  cursor: pointer;
  margin: 16px 0 48px;
  text-decoration: none;
  color: ${({ theme }) => theme.config.link.secondary};
`;
