import styled from 'styled-components';
import { Button } from '../../../common';
export const Test = styled(Button).withConfig({ displayName: "Test", componentId: "sc-bx6vvx" }) ``;
export const DialogHeader = styled.div.withConfig({ displayName: "DialogHeader", componentId: "sc-yrcs82" }) `
  width: 100%;
  height: fit-content;
`;
export const DialogContent = styled.div.withConfig({ displayName: "DialogContent", componentId: "sc-1dt36ag" }) `
  display: flex;
  flex-direction: column;
`;
export const AudioInfo = styled.div.withConfig({ displayName: "AudioInfo", componentId: "sc-1gq761o" }) `
  display: flex;
  flex-direction: column;
`;
export const AudioInfoRow = styled.div.withConfig({ displayName: "AudioInfoRow", componentId: "sc-bqcekp" }) `
  display: flex;
  flex-direction: column;
`;
export const AudioPlayerContainer = styled.div.withConfig({ displayName: "AudioPlayerContainer", componentId: "sc-vit61r" }) `
  margin-top: 20px;
`;
