import ContentEditable from 'react-contenteditable';
import styled, { css } from 'styled-components';
import { Close } from '../../../../../../common/icon/icons';
export const InputContainer = styled.div.withConfig({ displayName: "InputContainer", componentId: "sc-ohs3s5" }) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  padding: 5px 10px;
  background: ${({ theme }) => theme.config.input.light.background};
  border-radius: 20px;
  gap: 7px;
`;
export const Chip = styled.div.withConfig({ displayName: "Chip", componentId: "sc-6btvdu" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 30px;
  padding: 5px 10px;
  border-radius: 15px;
  background: rgba(0, 255, 0, 0.29);
  gap: 5px;
`;
export const ChipCloseIcon = styled(Close).withConfig({ displayName: "ChipCloseIcon", componentId: "sc-3eo5aa" }) `
  width: 15px;
  height: 15px;
  stroke: white;
  fill: white;
  cursor: pointer;
`;
export const SelectInput = styled(ContentEditable).withConfig({ displayName: "SelectInput", componentId: "sc-148idmk" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 30px;
  height: fit-content;
  max-width: 100%;
  width: fit-content;
  min-width: 150px;
  padding: 5px;
  text-wrap: normal;
  background: inherit;

  :focus {
    outline: none;

    ::after {
      display: none;
    }
  }

  ${({ $isValue }) => !$isValue &&
    css `
      ::after {
        position: absolute;
        content: 'Например, смартфоны';
        color: ${({ theme }) => theme.config.text.tetriery};
        pointer-events: none;
      }
    `}
`;
export const AdvSelectWrapper = styled.div.withConfig({ displayName: "AdvSelectWrapper", componentId: "sc-18ythh" }) `
  position: relative;
  width: 100%;
`;
export const AdvSelectContainer = styled.div.withConfig({ displayName: "AdvSelectContainer", componentId: "sc-1efr7f" }) `
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 5px;
  border-radius: 10px;

  background: ${({ theme }) => theme.config.input.light.background};
  max-height: 300px;
  overflow-y: scroll;
`;
export const SearchItemWrapper = styled.div.withConfig({ displayName: "SearchItemWrapper", componentId: "sc-7utpjn" }) `
  padding: 5px 0;
  margin-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.green.primary};
`;
export const SelectItemContainer = styled.div.withConfig({ displayName: "SelectItemContainer", componentId: "sc-1ahctiw" }) `
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 3px;

  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.config.background.secondary};
  }
`;
export const SelectItemIconContainer = styled.div.withConfig({ displayName: "SelectItemIconContainer", componentId: "sc-2izhlt" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;
export const SelectItemTextContainer = styled.div.withConfig({ displayName: "SelectItemTextContainer", componentId: "sc-vz2xyj" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  height: fit-content;
`;
