import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import { useQueryNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styles from './styles';
const TAB_ITEMS = [
    {
        value: AppRoutes.Auth.Home + AppRoutes.Auth.Login,
        label: 'Sign In',
    },
    {
        value: AppRoutes.Auth.Home + AppRoutes.Auth.SignUp,
        label: 'Registration',
    },
];
export const HeaderTabs = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useQueryNavigate();
    return (_jsx(Styles.Container, { children: _jsxs(Styles.TabHeader, { children: [TAB_ITEMS === null || TAB_ITEMS === void 0 ? void 0 : TAB_ITEMS.map((item) => (_jsx(Styles.TabLink, Object.assign({ theme: theme, id: item.value, "$isActive": pathname === item.value, onClick: () => navigate(item.value, { replace: true }) }, { children: _jsx(Styles.TabLinkLabel, Object.assign({ "$isActive": pathname === item.value }, { children: t(item.label) })) }), item.value.toString()))), _jsx(Styles.TabBackground, { "$isActive": pathname === AppRoutes.Auth.Home + AppRoutes.Auth.Login })] }) }));
};
