import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-r7y9jx" }) `
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const WrapperLoader = styled.div.withConfig({ displayName: "WrapperLoader", componentId: "sc-1hptsyo" }) `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
