import * as Yup from 'yup';
export const model = {
    countGroups: {
        label: 'Ограничение по кол-ву групп для парсинга',
        name: 'countGroups',
        placeholder: 'Введите количество',
    },
    searchPromt: {
        label: 'Фильтр для поиска групп',
        name: 'searchPromt',
        placeholder: 'Введите запрос для поиск',
    },
    groupCountry: {
        name: 'groupCountry',
        label: 'Страна групп',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Выберите страну групп',
        options: [
            {
                label: 'Не учитывать',
                value: '',
            },
            { label: 'Австралия', value: '19' },
            { label: 'Австрия', value: '20' },
            { label: 'Азербайджан', value: '5' },
            { label: 'Албания', value: '21' },
            { label: 'Алжир', value: '22' },
            { label: 'Виргинские острова, США', value: '53' },
            { label: 'Американское Самоа', value: '23' },
            { label: 'Ангилья', value: '24' },
            { label: 'Ангола', value: '25' },
            { label: 'Андорра', value: '26' },
            { label: 'Антигуа и Барбуда', value: '27' },
            { label: 'Аргентина', value: '28' },
            { label: 'Армения', value: '6' },
            { label: 'Аруба', value: '29' },
            { label: 'Афганистан', value: '30' },
            { label: 'Багамы', value: '31' },
            { label: 'Бангладеш', value: '32' },
            { label: 'Барбадос', value: '33' },
            { label: 'Бахрейн', value: '34' },
            { label: 'Белиз', value: '35' },
            { label: 'Беларусь', value: '3' },
            { label: 'Бельгия', value: '36' },
            { label: 'Бенин', value: '37' },
            { label: 'Бермуды', value: '38' },
            { label: 'Болгария', value: '39' },
            { label: 'Боливия', value: '40' },
            { label: 'Босния и Герцеговина', value: '41' },
            { label: 'Ботсвана', value: '42' },
            { label: 'Бразилия', value: '43' },
            { label: 'Великобритания', value: '49' },
            { label: 'Виргинские острова, Великобритания', value: '52' },
            { label: 'Бруней', value: '44' },
            { label: 'Буркина-Фасо', value: '45' },
            { label: 'Бурунди', value: '46' },
            { label: 'Вануату', value: '48' },
            { label: 'Великобритания', value: '49' },
            { label: 'Венгрия', value: '50' },
            { label: 'Венесуэла', value: '51' },
            { label: 'Восточный Тимор', value: '54' },
            { label: 'Вьетнам', value: '55' },
            { label: 'Габон', value: '56' },
            { label: 'Гаити', value: '57' },
            { label: 'Гайана', value: '58' },
            { label: 'Гваделупа', value: '61' },
            { label: 'Гватемала', value: '62' },
            { label: 'Гвинея', value: '63' },
            { label: 'Гвинея-Бисау', value: '64' },
            { label: 'Германия', value: '65' },
            { label: 'Гибралтар', value: '66' },
            { label: 'Гондурас', value: '67' },
            { label: 'Гренада', value: '69' },
            { label: 'Гренландия', value: '70' },
            { label: 'Греция', value: '71' },
            { label: 'Грузия', value: '7' },
            { label: 'Гуам', value: '72' },
            { label: 'Дания', value: '73' },
            { label: 'Конго, демократическая республика', value: '101' },
            { label: 'Джибути', value: '231' },
            { label: 'Доминика', value: '74' },
            { label: 'Доминиканская Республика', value: '75' },
            { label: 'Египет', value: '76' },
            { label: 'Замбия', value: '77' },
            { label: 'Зимбабве', value: '79' },
            { label: 'Израиль', value: '8' },
            { label: 'Индия', value: '80' },
            { label: 'Индонезия', value: '81' },
            { label: 'Иордания', value: '82' },
            { label: 'Ирак', value: '83' },
            { label: 'Иран', value: '84' },
            { label: 'Ирландия', value: '85' },
            { label: 'Исландия', value: '86' },
            { label: 'Испания', value: '87' },
            { label: 'Италия', value: '88' },
            { label: 'Йемен', value: '89' },
            { label: 'Северная Корея', value: '173' },
            { label: 'Кабо-Верде', value: '90' },
            { label: 'Казахстан', value: '4' },
            { label: 'Острова Кайман', value: '149' },
            { label: 'Камбоджа', value: '91' },
            { label: 'Камерун', value: '92' },
            { label: 'Канада', value: '10' },
            { label: 'Катар', value: '93' },
            { label: 'Кения', value: '94' },
            { label: 'Кипр', value: '95' },
            { label: 'Кыргызстан', value: '11' },
            { label: 'Кирибати', value: '96' },
            { label: 'Китай', value: '97' },
            { label: 'Колумбия', value: '98' },
            { label: 'Коморы', value: '99' },
            { label: 'Коста-Рика', value: '102' },
            { label: "Кот-д'Ивуар", value: '103' },
            { label: 'Куба', value: '104' },
            { label: 'Кувейт', value: '105' },
            { label: 'Лаос', value: '106' },
            { label: 'Латвия', value: '12' },
            { label: 'Лесото', value: '107' },
            { label: 'Либерия', value: '108' },
            { label: 'Ливан', value: '109' },
            { label: 'Ливия', value: '110' },
            { label: 'Литва', value: '13' },
            { label: 'Лихтенштейн', value: '111' },
            { label: 'Люксембург', value: '112' },
            { label: 'Маврикий', value: '113' },
            { label: 'Мавритания', value: '114' },
            { label: 'Мадагаскар', value: '115' },
            { label: 'Северная Македония', value: '117' },
            { label: 'Малави', value: '118' },
            { label: 'Малайзия', value: '119' },
            { label: 'Мали', value: '120' },
            { label: 'Мальдивы', value: '121' },
            { label: 'Мальта', value: '122' },
            { label: 'Марокко', value: '123' },
            { label: 'Мартиника', value: '124' },
            { label: 'Маршалловы Острова', value: '125' },
            { label: 'Мексика', value: '126' },
            { label: 'Мозамбик', value: '128' },
            { label: 'Молдова', value: '15' },
            { label: 'Монако', value: '129' },
            { label: 'Монголия', value: '130' },
            { label: 'Монтсеррат', value: '131' },
            { label: 'Мьянма', value: '132' },
            { label: 'Намибия', value: '133' },
            { label: 'Науру', value: '134' },
            { label: 'Непал', value: '135' },
            { label: 'Нигер', value: '136' },
            { label: 'Нигерия', value: '137' },
            { label: 'Нидерланды', value: '139' },
            { label: 'Никарагуа', value: '140' },
            { label: 'Ниуэ', value: '141' },
            { label: 'Новая Каледония', value: '143' },
            { label: 'Новая Зеландия', value: '142' },
            { label: 'Норвегия', value: '144' },
            { label: 'Объединенные Арабские Эмираты', value: '145' },
            { label: 'Оман', value: '146' },
            { label: 'Острова Кука', value: '150' },
            { label: 'Пакистан', value: '152' },
            { label: 'Палау', value: '153' },
            { label: 'Палестинская автономия', value: '154' },
            { label: 'Панама', value: '155' },
            { label: 'Папуа — Новая Гвинея', value: '156' },
            { label: 'Парагвай', value: '157' },
            { label: 'Перу', value: '158' },
            { label: 'Польша', value: '160' },
            { label: 'Португалия', value: '161' },
            { label: 'Пуэрто-Рико', value: '162' },
            { label: 'Конго', value: '100' },
            { label: 'Реюньон', value: '163' },
            { label: 'Россия', value: '1' },
            { label: 'Руанда', value: '164' },
            { label: 'Румыния', value: '165' },
            { label: 'США', value: '9' },
            { label: 'Сальвадор', value: '166' },
            { label: 'Самоа', value: '167' },
            { label: 'Сан-Марино', value: '168' },
            { label: 'Сан-Томе и Принсипи', value: '169' },
            { label: 'Саудовская Аравия', value: '170' },
            { label: 'Эсватини', value: '171' },
            { label: 'Северные Марианские острова', value: '174' },
            { label: 'Сейшелы', value: '175' },
            { label: 'Сенегал', value: '176' },
            { label: 'Сент-Винсент и Гренадины', value: '177' },
            { label: 'Сент-Китс и Невис', value: '178' },
            { label: 'Сент-Люсия', value: '179' },
            { label: 'Сент-Пьер и Микелон', value: '180' },
            { label: 'Сербия', value: '181' },
            { label: 'Сингапур', value: '182' },
            { label: 'Сирия', value: '183' },
            { label: 'Словакия', value: '184' },
            { label: 'Словения', value: '185' },
            { label: 'Соломоновы Острова', value: '186' },
            { label: 'Сомали', value: '187' },
            { label: 'Судан', value: '188' },
            { label: 'Суринам', value: '189' },
            { label: 'Сьерра-Леоне', value: '190' },
            { label: 'Таджикистан', value: '16' },
            { label: 'Таиланд', value: '191' },
            { label: 'Тайвань', value: '192' },
            { label: 'Танзания', value: '193' },
            { label: 'Того', value: '194' },
            { label: 'Токелау', value: '195' },
            { label: 'Тонга', value: '196' },
            { label: 'Тринидад и Тобаго', value: '197' },
            { label: 'Тувалу', value: '198' },
            { label: 'Тунис', value: '199' },
            { label: 'Туркменистан', value: '17' },
            { label: 'Турция', value: '200' },
            { label: 'Уганда', value: '201' },
            { label: 'Узбекистан', value: '18' },
            { label: 'Украина', value: '2' },
            { label: 'Уругвай', value: '203' },
            { label: 'Фарерские острова', value: '204' },
            { label: 'Микронезия, федеративные штаты', value: '127' },
            { label: 'Фиджи', value: '205' },
            { label: 'Филиппины', value: '206' },
            { label: 'Финляндия', value: '207' },
            { label: 'Фолклендские острова', value: '208' },
            { label: 'Франция', value: '209' },
            { label: 'Французская Гвиана', value: '210' },
            { label: 'Французская Полинезия', value: '211' },
            { label: 'Хорватия', value: '212' },
            { label: 'Центральноафриканская Республика', value: '213' },
            { label: 'Чад', value: '214' },
            { label: 'Черногория', value: '230' },
            { label: 'Чехия', value: '215' },
            { label: 'Чили', value: '216' },
            { label: 'Швейцария', value: '217' },
            { label: 'Швеция', value: '218' },
            { label: 'Шри-Ланка', value: '220' },
            { label: 'Эквадор', value: '221' },
            { label: 'Экваториальная Гвинея', value: '222' },
            { label: 'Эритрея', value: '223' },
            { label: 'Эстония', value: '14' },
            { label: 'Эфиопия', value: '224' },
            { label: 'Южно-Африканская Республика', value: '227' },
            { label: 'Южная Корея', value: '226' },
            { label: 'Ямайка', value: '228' },
            { label: 'Япония', value: '229' },
            { label: 'Остров Норфолк', value: '148' },
            { label: 'Святая Елена', value: '172' },
            { label: 'Острова Теркс и Кайкос', value: '151' },
            { label: 'Уоллис и Футуна', value: '202' },
        ],
    },
    countUsers: {
        placeholder: 'Введите лимит участников',
        name: 'countUsers',
    },
    hasUsersLimit: {
        label: 'Лимит на парсинг участников',
        type: 'checkbox',
        withBorder: false,
        name: 'hasUsersLimit',
    },
};
//TODO дописать логику для обязательности
export const validationSchema = Yup.object().shape({
    countGroups: Yup.number()
        .required('Количество групп обязательно')
        .integer('Must be an integer')
        .positive('Must be a positive number'),
    searchPromt: Yup.string().required('Фильр поиска обязателен'),
    countUsers: Yup.number()
        .integer('Must be an integer')
        .positive('Must be a positive number'),
});
export const initialValues = {
    countGroups: '',
    countUsers: '',
    searchPromt: '',
    groupCountry: {
        label: 'Не учитывать',
        value: '',
    },
    hasUsersLimit: false,
};
