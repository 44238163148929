import { jsx as _jsx } from "react/jsx-runtime";
import { Button, ButtonVariant } from 'common';
import { Icon } from 'common/icon';
import styled, { css } from 'styled-components';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
const DeleteBtn = styled(Button).withConfig({ displayName: "DeleteBtn", componentId: "sc-180k3di" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  border-radius: 50%;
  margin-top: 2px;

  ${({ theme }) => css `
    border: ${theme.config.input.default.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}
  &:hover {
    background: none;
  }
`;
export const DeleteButton = ({ onDelete }) => {
    const handleClick = () => {
        onDelete();
    };
    return (_jsx(DeleteBtn, { text: "", icon: _jsx(IconWrapper, { children: _jsx(Icon.Delete, {}) }), variant: ButtonVariant.CANCEL, onClick: handleClick }));
};
