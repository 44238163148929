import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import { Route, Routes } from 'react-router-dom';
import { Ozon } from '../ozon';
import { Wildberries } from '../wildberries';
export const MarketPlacesPage = () => {
    const { Marketplaces } = AppRoutes.Dashboard;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: Marketplaces.Wildberries, element: _jsx(Wildberries, {}) }), _jsx(Route, { path: Marketplaces.Ozon, element: _jsx(Ozon, {}) })] }));
};
export default MarketPlacesPage;
