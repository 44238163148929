import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiParserQuery } from 'store/query';
export const parserApi = createApi({
    tagTypes: [],
    reducerPath: 'parserApi',
    baseQuery: apiParserQuery,
    endpoints: (build) => ({
        avito: build.mutation({
            query: ({ category, region, limit }) => {
                const params = new URLSearchParams({
                    category,
                    region,
                    limit,
                });
                return {
                    method: 'POST',
                    url: `${URLS.PARSER_AVITO}?${params.toString()}`,
                    data: {
                        fields: [
                            'price',
                            'item_url',
                            'location',
                            'category',
                            'user_name',
                            'item_title',
                            'created_at',
                            'description',
                            'number_of_views',
                        ],
                    },
                };
            },
            invalidatesTags: [],
        }),
        parserFilters: build.query({
            query: ({ type = 'Avito' }) => {
                const params = new URLSearchParams({
                    parser_type: type,
                });
                return {
                    method: 'GET',
                    url: `${URLS.PARSER_FILTERS}?${params.toString()}`,
                };
            },
        }),
        yandex: build.mutation({
            query: ({ cities, categories, hasEmail, hasPhone, hasMobilePhone, withoutDepartments, }) => {
                const params = new URLSearchParams({
                    cities,
                    categories,
                });
                return {
                    method: 'POST',
                    url: `${URLS.PARSER_YANDEX}?${params.toString()}`,
                    data: {
                        email: hasEmail,
                        phones: hasPhone,
                        cities: cities.split(','),
                        categories: categories.split(','),
                        only_mobile_phones: hasMobilePhone,
                        without_departments: withoutDepartments,
                    },
                };
            },
            invalidatesTags: [],
        }),
        vkGroups: build.mutation({
            query: ({ count, is_members_ids, searchPromt, country_id, }) => {
                const params = new URLSearchParams({
                    count,
                    is_members_ids,
                    q: searchPromt,
                    country_id,
                });
                return {
                    method: 'POST',
                    url: `${URLS.PARSER_VK_GROUPS}?${params.toString()}`,
                    data: {
                        fields: [
                            'id',
                            'group_screen_name',
                            'group_description',
                            'group_articles_number',
                            'group_videos_number',
                            'group_country',
                            'members_count',
                            'members_ids',
                            'contacts',
                        ],
                    },
                };
            },
        }),
        vkPosts: build.mutation({
            query: ({ count, groupScreenName, comments }) => {
                const params = new URLSearchParams({
                    count,
                    group_screen_name: groupScreenName,
                    comments,
                });
                return {
                    method: 'POST',
                    url: `${URLS.PARSER_VK_POSTS}?${params.toString()}`,
                    data: {
                        fields: [
                            'post_reposts',
                            'number_of_comments',
                            'post_likes',
                            'post_text',
                            'post_views',
                            'crated_at',
                            'comments',
                            'owner_id',
                            'post_id',
                        ],
                    },
                };
            },
        }),
    }),
});
export const { useAvitoMutation, useYandexMutation, useVkPostsMutation, useVkGroupsMutation, useParserFiltersQuery, } = parserApi;
