export var TextVariant;
(function (TextVariant) {
    TextVariant["h1Medium"] = "h1Medium";
    TextVariant["h2Medium"] = "h2Medium";
    TextVariant["h3Medium"] = "h3Medium";
    TextVariant["bodyRegular"] = "bodyRegular";
    TextVariant["bodySemiBold"] = "bodySemiBold";
    TextVariant["smallLight"] = "smallLight";
    TextVariant["smallMedium"] = "smallMedium";
    TextVariant["smallReqular"] = "smallReqular";
    TextVariant["smallSemiBold"] = "smallSemiBold";
    TextVariant["ultraSmallMedium"] = "ultraSmallMedium";
})(TextVariant || (TextVariant = {}));
export var TextVariantColor;
(function (TextVariantColor) {
    TextVariantColor["primary"] = "primary";
    TextVariantColor["secondary"] = "secondary";
    TextVariantColor["tetriery"] = "tetriery";
    TextVariantColor["stableBlack"] = "stableBlack";
})(TextVariantColor || (TextVariantColor = {}));
