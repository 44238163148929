import styled, { keyframes } from 'styled-components';
import { heading3Medium, smallMedium, smallSemiBold, ultraSmallMedium, } from 'styles/mixins/fonts';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1727ys4" }) `
  position: relative;
  display: inline-block;
  width: 100%;
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-xpm72c" }) ``;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-14atnk3" }) ``;
export const Message = styled.li.withConfig({ displayName: "Message", componentId: "sc-6ej977" }) `
  list-style-type: none;
  letter-spacing: -0.01em;
  min-width: 295px;

  ${smallMedium};
`;
export const WrapperMessage = styled.div.withConfig({ displayName: "WrapperMessage", componentId: "sc-1si4gus" }) `
  position: relative;
  padding: 0px 16px 12px 0px;
  border-top: ${({ theme }) => theme.config.dropdown.border.all};

  &:before {
    content: '';
    position: absolute;
    top: 21px;
    left: 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ theme }) => theme.config.dropdown.text.green};
  }
`;
export const Indicator = styled.div.withConfig({ displayName: "Indicator", componentId: "sc-nb7dxn" }) ``;
export const Timestamp = styled.div.withConfig({ displayName: "Timestamp", componentId: "sc-1ft6jn2" }) `
  ${ultraSmallMedium}
  color: ${({ theme }) => theme.config.dropdown.text.timestamp};
`;
export const MenuNotification = styled.div.withConfig({ displayName: "MenuNotification", componentId: "sc-f6pej2" }) `
  position: absolute;
  top: ${({ $isOpen, $top }) => ($isOpen && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};

  padding: 16px;
  margin-top: 30px;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow};
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.dropdown.background.active};
  border-radius: 16px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  animation: ${({ $isOpen }) => ($isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;

  max-height: ${({ $isOpen }) => ($isOpen ? 'calc(100vh - 2rem)' : '10px')};
  z-index: 100;

  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};

  @media screen and (max-width: 420px) {
    left: -180px;
  }
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-44tw1p" }) `
  ${heading3Medium};

  flex: 1;
  margin-bottom: 5px;

  letter-spacing: -0.01em;
  width: 100%;
`;
export const ContainerMessage = styled.div.withConfig({ displayName: "ContainerMessage", componentId: "sc-8lqkra" }) `
  margin-left: 16px;
  padding-top: 12px;
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "sc-1vnn5um" }) ``;
export const ButtonAllMessage = styled.div.withConfig({ displayName: "ButtonAllMessage", componentId: "sc-rfi1cs" }) `
  ${smallSemiBold}

  letter-spacing: -0.01em;
  padding: 8px 16px 8px 16px;
  border-top: ${({ theme }) => theme.config.dropdown.border.all};

  cursor: pointer;
`;
export const WrapperEmptyMessage = styled.div.withConfig({ displayName: "WrapperEmptyMessage", componentId: "sc-190rsgu" }) `
  padding: 5px 10px;
`;
