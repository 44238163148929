import { jsx as _jsx } from "react/jsx-runtime";
import { DropCompany } from 'components/dropdown/drop-company';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompaniesListForMainQuery, } from '../../../../store/api/companies';
import { useMeQuery } from '../../../../store/api/profile';
import * as Styles from './styles';
const BUTTONS = {
    Hints: 'Hints',
    Company: 'Company not selected',
};
export const LeftContent = () => {
    const [isCompany, setIsCompany] = useState(false);
    const { data: me } = useMeQuery();
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const { data: mainCompanies, refetch: refetchMain } = useCompaniesListForMainQuery();
    useEffect(() => {
        refetchMain();
    }, [me]);
    useEffect(() => {
        if (mainCompanies) {
            const result = mainCompanies.companies.map((company) => {
                return {
                    id: company.company_id,
                    label: company.company_name,
                    onClick: () => { },
                };
            });
            if (mainCompanies.selected_company &&
                mainCompanies.selected_company.company_id) {
                const selectedUserCompany = {
                    id: mainCompanies.selected_company.company_id,
                    label: mainCompanies.selected_company.company_name,
                    onClick: () => { },
                };
                if (!result.some((company) => company.id === mainCompanies.selected_company.company_id)) {
                    result.unshift(selectedUserCompany);
                }
            }
            setItems(result);
        }
    }, [mainCompanies]);
    const handleCompany = () => {
        setIsCompany((prevHint) => !prevHint);
    };
    return (_jsx(Styles.Container, { children: _jsx(DropCompany, { isVisible: isCompany, onClick: handleCompany, button: _jsx(Styles.TextCompany, Object.assign({ onClick: handleCompany }, { children: t(BUTTONS.Company) })), items: items }) }));
};
