import styled from 'styled-components';
import { Close } from '../../../../../common/icon/icons';
import { Dropdown } from '../../../../../components';
export const ModalWrapper = styled.div.withConfig({ displayName: "ModalWrapper", componentId: "sc-7tcd75" }) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px) contrast(1.1) brightness(0.6);
`;
export const Modal = styled.div.withConfig({ displayName: "Modal", componentId: "sc-t2m629" }) `
  width: 400px;

  border-radius: 20px;
  background: ${({ theme }) => theme.config.background.secondary};
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-unyka0" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  box-shadow: 0px 1px 0px 0px #3f3f3f;
`;
export const CloseIcon = styled(Close).withConfig({ displayName: "CloseIcon", componentId: "sc-o32szt" }) `
  stroke: ${({ theme }) => theme.colors.white.primary};
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;

  :hover {
    background: #111111;
  }
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1naafea" }) `
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
`;
export const Form = styled.div.withConfig({ displayName: "Form", componentId: "sc-15vwzm2" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const DropdownWrapper = styled.div.withConfig({ displayName: "DropdownWrapper", componentId: "sc-12ubswl" }) `
  width: 100%;

  background: ${({ theme }) => theme.config.background.tetriery};
  border-radius: 20px;
`;
export const CustomDropDown = styled(Dropdown).withConfig({ displayName: "CustomDropDown", componentId: "sc-5h131v" }) `
  height: 40px;
  width: 100%;
`;
export const DropdownButton = styled.div.withConfig({ displayName: "DropdownButton", componentId: "sc-1ywdgnw" }) `
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 40px;
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-7duzav" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
