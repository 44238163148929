export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["LIGHT"] = "LIGHT";
    ButtonVariant["DARK"] = "DARK";
    ButtonVariant["CANCEL"] = "CANCEL";
    ButtonVariant["PRIMARY"] = "PRIMARY";
    ButtonVariant["SECONDARY"] = "SECONDARY";
    ButtonVariant["TRANSPARENT"] = "TRANSPARENT";
    ButtonVariant["TETRIERY"] = "TETRIERY";
})(ButtonVariant || (ButtonVariant = {}));
