import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TabItems } from '../../config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1l02eav" }) ``;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-pipt5f" }) ``;
const HeaderTabs = styled.div.withConfig({ displayName: "HeaderTabs", componentId: "sc-11rdyut" }) `
  display: flex;
  flex-wrap: wrap;

  gap: 16px;
`;
const WrapperTab = styled.div.withConfig({ displayName: "WrapperTab", componentId: "sc-57c9hd" }) `
  display: flex;
  align-items: center;

  gap: 12px;
  width: 236px;
  height: 88px;
  padding-left: 16px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.config.line.secondary};
  cursor: pointer;

  ${({ $isActive, theme }) => $isActive &&
    css `
      border: 2px solid ${theme.config.line.primary};
    `}
`;
const Icon = styled.div.withConfig({ displayName: "Icon", componentId: "sc-1stmgsm" }) `
  display: flex;
  align-items: center;
`;
const Label = styled.div.withConfig({ displayName: "Label", componentId: "sc-12c1jaw" }) ``;
export const TabsPayment = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useQueryNavigate();
    return (_jsx(Container, { children: _jsx(Wrapper, { children: _jsx(HeaderTabs, { children: TabItems === null || TabItems === void 0 ? void 0 : TabItems.map((item) => (_jsxs(WrapperTab, Object.assign({ "$isActive": pathname === item.value, onClick: () => navigate(item.value, { replace: true }) }, { children: [_jsx(Icon, { children: _jsx(item.icon, {}) }), _jsx(Label, { children: t(item.label) })] }), item.id))) }) }) }));
};
