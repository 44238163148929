import styled from 'styled-components';
import { smallSemiBold, ultraSmallMedium } from 'styles/mixins/fonts';
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1vosf3p" }) `
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  height: 100%;
`;
export const Label = styled.label.withConfig({ displayName: "Label", componentId: "sc-138e6wp" }) `
  display: flex;
  white-space: nowrap;
  padding: 0 0 8px;
  ${smallSemiBold};
  color: ${(p) => p.disabled
    ? p.theme.config.select.label.disabled
    : p.theme.config.select.label.default};
`;
export const Required = styled.span.withConfig({ displayName: "Required", componentId: "sc-1k2tgq5" }) `
  color: ${(p) => p.theme.config.select.border.error};
`;
// Stories Containers
export const StoriesContainer = styled.div.withConfig({ displayName: "StoriesContainer", componentId: "sc-144ol97" }) `
  width: 100%;
  max-width: 840px;
  gap: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 400px));
`;
export const Error = styled.div.withConfig({ displayName: "Error", componentId: "sc-1t2tp64" }) `
  ${ultraSmallMedium}
  color: ${(p) => p.theme.config.select.border.error};
`;
