import { Button } from 'common/button';
import { zIndex } from 'constants/enums';
import styled, { keyframes } from 'styled-components';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const DropdownContainer = styled.div.withConfig({ displayName: "DropdownContainer", componentId: "sc-eci4kq" }) `
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: ${zIndex.Modal};
`;
export const DropdownButton = styled.div.withConfig({ displayName: "DropdownButton", componentId: "sc-12jttme" }) `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 16px;
`;
export const DropdownTime = styled.ul.withConfig({ displayName: "DropdownTime", componentId: "sc-2ombfm" }) `
  position: absolute;
  top: ${({ $open, $top }) => ($open && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};

  padding: 0.25rem;
  margin-top: 0.5rem;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
  background: ${({ theme }) => theme.config.toggle.bg.active};
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  animation: ${({ $open }) => ($open ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;

  max-height: ${({ $open }) => ($open ? '100vh' : '10px')};
`;
export const ButtonTime = styled(Button).withConfig({ displayName: "ButtonTime", componentId: "sc-pb4btf" }) `
  box-shadow: none;
  background: ${({ theme }) => theme.config.button.secondary.background.hover};
`;
