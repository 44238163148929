var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField, Loader, Text, TextVariant, } from 'common';
import { SwitchField } from 'common/fields/switch-field';
import { Icon } from 'common/icon';
import { AppRoutes } from 'config';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useYandexMutation } from 'store/api/parser';
import { handleMutation } from 'utils/handleMutation';
import { Form } from '../../../../../components/form/Form';
import { useNavigate } from '../../../../../hooks';
import { model, yandexInitialValues, yandexValidationSchema, } from './config';
import * as Styles from './styles';
const YandexParserForm = () => {
    const { t } = useTranslation();
    const { Home, Parsing } = AppRoutes.Dashboard;
    const { submitForm, isSubmitting, dirty, setFieldValue, isValid } = useFormikContext();
    return (_jsxs(_Fragment, { children: [_jsxs(Styles.Left, { children: [_jsx(InputField, Object.assign({}, model.cities)), _jsx(Styles.WrapperSelect, { children: _jsx(Button, { icon: _jsx(Icon.Delete, {}), text: t('Clear the entire list'), variant: ButtonVariant.TETRIERY, onClick: () => setFieldValue('cities', '') }) }), _jsxs(Styles.WrapperSettings, { children: [_jsxs(Styles.FieldsColumn, { children: [_jsx(SwitchField, Object.assign({}, model.hasEmail)), _jsx(SwitchField, Object.assign({}, model.hasPhone))] }), _jsxs(Styles.FieldsColumn, { children: [_jsx(SwitchField, Object.assign({}, model.hasMobilePhone)), _jsx(SwitchField, Object.assign({}, model.withoutDepartments))] })] })] }), _jsxs(Styles.Right, { children: [_jsx(InputField, Object.assign({}, model.categories)), _jsx(Styles.WrapperSelect, { children: _jsx(Button, { icon: _jsx(Icon.Delete, {}), text: t('Clear the entire list'), variant: ButtonVariant.TETRIERY, onClick: () => setFieldValue('categories', '') }) })] }), _jsxs(Styles.Footer, { children: [_jsx(Button, { text: t('Cancel'), to: Home + Parsing.Home, variant: ButtonVariant.TETRIERY }), _jsx(Button, { onClick: submitForm, text: t('Prepare the database'), disabled: !dirty || !isValid || isSubmitting })] })] }));
};
export const YandexParserPage = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [parse, mutation] = useYandexMutation();
    const [result, setResult] = useState();
    const isLoading = mutation.isLoading;
    const onSuccess = useCallback((data) => {
        setResult(data);
    }, []);
    const onError = useCallback((error) => {
        alert.error((error === null || error === void 0 ? void 0 : error.data) || t('Error'));
    }, []);
    handleMutation(Object.assign(Object.assign({}, mutation), { onSuccess,
        onError }));
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { cities, phones, hasEmail, hasPhone, categories, hasMobilePhone, withoutDepartments, } = values;
        yield parse({
            cities,
            phones,
            hasEmail,
            hasPhone,
            categories,
            hasMobilePhone,
            withoutDepartments,
        });
    });
    if (isLoading) {
        return (_jsx(Styles.Container, { children: _jsx(Styles.WrapperLoading, { children: _jsx(Loader, {}) }) }));
    }
    if (result) {
        navigate(`/dashboard/my-database/${result._id}`);
    }
    return (_jsx(Styles.Container, { children: _jsxs(Styles.ScrollContent, { children: [_jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: t('Yandex Maps Database') })), _jsx(Form, Object.assign({ onSubmit: onSubmit, initialValues: yandexInitialValues, validationSchema: yandexValidationSchema(t) }, { children: _jsx(Styles.Content, { children: _jsx(YandexParserForm, {}) }) }))] }) }));
};
