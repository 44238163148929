import styled, { css } from 'styled-components';
import { heading2Medium } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-13oxunl" }) ``;
export const ContentForm = styled.div.withConfig({ displayName: "ContentForm", componentId: "sc-1whc9m5" }) ``;
export const ContainerHeader = styled.header.withConfig({ displayName: "ContainerHeader", componentId: "sc-r19dth" }) `
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.config.line.secondary};
`;
export const TabHeader = styled.div.withConfig({ displayName: "TabHeader", componentId: "sc-3ffclh" }) `
  display: flex;
  justify-content: space-between;
`;
export const Tab = styled.div.withConfig({ displayName: "Tab", componentId: "sc-7z6of1" }) `
  flex: 1;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: ${({ $isActive }) => ($isActive ? '#22A913' : 'gray')};
    transform: scaleX(${({ $isActive }) => ($isActive ? 1 : 0)});
    transition: transform 0.3s ease-out;
  }
`;
export const TabLabel = styled.div.withConfig({ displayName: "TabLabel", componentId: "sc-s83lbu" }) `
  ${heading2Medium};

  opacity: 0.5;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-bottom: 12px;
  justify-content: center;
  color: ${({ theme }) => theme.config.link.secondary};

  ${({ $isActive }) => $isActive &&
    css `
      opacity: 1;
      transition: transform 0.25s ease-out;
      color: ${({ theme }) => theme.config.link.primary};
    `}

  ${({ theme }) => theme &&
    css `
      font-size: ${theme.responsive.isMobile ? '12px' : '14px'};
    `}
`;
