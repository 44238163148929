import { Icon } from 'common/icon';
import { AppRoutes } from 'config';
const { Home, Audioclips } = AppRoutes.Dashboard.VoiceRobot;
export const Main = {
    id: 0,
    label: 'Main',
    icon: Icon.Home,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Main,
};
export const VoiceRobot = {
    id: 1,
    label: 'Voice Robot',
    icon: Icon.RobotVoice,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.VoiceRobot.Home +
        AppRoutes.Dashboard.VoiceRobot.Mailing,
};
export const Parsing = {
    id: 2,
    label: 'Parsing',
    icon: Icon.Parsing,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Parsing.Home,
};
export const Mailing = {
    id: 'MailingPage',
    label: 'Mailing',
    icon: Icon.Mailing,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Mailing.Home,
};
export const MailingTelegram = {
    id: 'MailingTelegram',
    label: 'Telegram',
    icon: Icon.TelegramMonocolor,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Mailing.Home +
        AppRoutes.Dashboard.Mailing.Telegram,
};
export const MailingEmail = {
    id: 'MailingEmail',
    label: 'E-mail',
    icon: Icon.Mail,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Mailing.Home +
        AppRoutes.Dashboard.Mailing.Email,
};
// export const MailingViber = {
//   id: 'MailingViber',
//   label: 'Viber',
//   icon: Icon.Viber,
//   path:
//     AppRoutes.Dashboard.Home +
//     AppRoutes.Dashboard.Mailing.Home +
//     AppRoutes.Dashboard.Mailing.Viber,
// };
// export const MailingWhatsApp = {
//   id: 'MailingWhatsApp',
//   label: 'WhatsApp',
//   icon: Icon.WhatsApp,
//   iconStyle: {
//     $reverseStyles: true,
//   },
//   path:
//     AppRoutes.Dashboard.Home +
//     AppRoutes.Dashboard.Mailing.Home +
//     AppRoutes.Dashboard.Mailing.WhatsApp,
// };
export const MailingHistory = {
    id: 'MailingHistory',
    label: 'Mailing history',
    icon: Icon.History,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Mailing.Home +
        AppRoutes.Dashboard.Mailing.History,
};
export const MailingVoiceRobot = {
    id: 'MailingPage',
    label: 'Mailing',
    icon: Icon.Mailing,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.VoiceRobot.Home +
        AppRoutes.Dashboard.VoiceRobot.Mailing,
};
export const AI = {
    id: 3,
    label: 'Artificial intelligence',
    icon: Icon.IIntelligence,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.Chat,
};
export const AIChat = {
    id: 'ChatPage',
    label: 'Chat',
    icon: Icon.Chat,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.Chat,
};
export const AIImageGenerate = {
    id: 'ImageGeneratePage',
    label: 'Image generate',
    icon: Icon.ImageGenerate,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.ImageGenerate,
};
export const AIAudioTranslate = {
    id: 'AudioTranslatePage',
    label: 'Audio translate',
    icon: Icon.AudioToText,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.AudioTranslate,
};
export const AIDescribeImage = {
    id: 'DescribeImagePage',
    label: 'Describe image',
    icon: Icon.DescribeImage,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.DescribeImage,
};
export const AIGeneratePost = {
    id: 'GeneratePostPage',
    label: 'Generate post',
    icon: Icon.Post,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.GeneratePost,
};
export const AIGenerateMusic = {
    id: 'GenerateMusicPage',
    label: 'Music generate',
    icon: Icon.Note,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.GenerateMusic,
};
export const AIRemoveBackground = {
    id: 'RemoveBackgroundPage',
    label: 'Remove background',
    icon: Icon.Remover,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.RemoveBackground,
};
export const AIBlurImage = {
    id: 'BlurImagePage',
    label: 'Blur image',
    icon: Icon.Blurer,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.AI.Home +
        AppRoutes.Dashboard.AI.BlurImage,
};
export const Advertisement = {
    id: 'Advertisement',
    label: 'Advertisement',
    icon: Icon.Advertisement,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Advertisement.Home +
        AppRoutes.Dashboard.Advertisement.GoogleAdwords,
};
export const AdGoogleAdwords = {
    id: 'GoogleAdwords',
    label: 'Google Adwords',
    icon: Icon.GoogleAdwords,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Advertisement.Home +
        AppRoutes.Dashboard.Advertisement.GoogleAdwords,
};
export const AdYandexDirect = {
    id: 'YandexDirect',
    label: 'Yandex direct',
    icon: Icon.Yandex,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Advertisement.Home +
        AppRoutes.Dashboard.Advertisement.YandexDirect,
};
export const AdTelegram = {
    id: 'Telegram',
    label: 'Telegram',
    icon: Icon.TelegramMonocolor,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Advertisement.Home +
        AppRoutes.Dashboard.Advertisement.Telegram,
};
export const AdInstagram = {
    id: 'Instagram',
    label: 'Instagram',
    icon: Icon.InstagramMonocolor,
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Advertisement.Home +
        AppRoutes.Dashboard.Advertisement.Instagram,
};
export const AdVkontakte = {
    id: 'Vkontakte',
    label: 'Vkontakte',
    icon: Icon.Vk,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Advertisement.Home +
        AppRoutes.Dashboard.Advertisement.Vkontakte,
};
export const Marketplaces = {
    id: 'Marketplaces',
    label: 'Marketplaces',
    icon: Icon.Marketplace,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Marketplaces.Home +
        AppRoutes.Dashboard.Marketplaces.Wildberries,
};
export const MarketWb = {
    id: 'Wildberries',
    label: 'Wildberries',
    icon: Icon.GoogleAdwords,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Marketplaces.Home +
        AppRoutes.Dashboard.Marketplaces.Wildberries,
};
export const MarketOzon = {
    id: 'Ozon',
    label: 'Ozon',
    icon: Icon.GoogleAdwords,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.Marketplaces.Home +
        AppRoutes.Dashboard.Marketplaces.Ozon,
};
export const Reports = {
    id: 'Reports',
    label: 'Reports',
    icon: Icon.Report,
    iconStyle: {
        $reverseStyles: true,
    },
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Reports,
};
export const Analytics = {
    id: 4,
    label: 'Analytics',
    icon: Icon.Analytics,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.Analytics,
};
export const SocialNetwork = {
    id: 5,
    label: 'Social network',
    icon: Icon.SocialNetwork,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.SocialNetwork,
};
export const SettingUpAds = {
    id: 6,
    label: 'Setting up Ads',
    icon: Icon.SettingUpAds,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.SettingUpAds,
};
export const ReportDesigner = {
    id: 7,
    label: 'Report Designer',
    icon: Icon.ReportDeveloper,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.ReportDesigner,
};
export const CollectingInformation = {
    id: 8,
    label: 'Collecting information',
    icon: Icon.CollectingInformation,
    path: AppRoutes.Dashboard.Home + AppRoutes.Dashboard.CollectingInformation,
};
export const AudioClips = {
    id: 9,
    label: 'Audio clips',
    icon: Icon.VoiceEmail,
    path: AppRoutes.Dashboard.Home + Home + Audioclips,
};
export const linksMain = [
    Main,
    VoiceRobot,
    Parsing,
    Mailing,
    AI,
    Advertisement,
    Marketplaces,
    Reports,
];
export const linksVoiceRobot = [MailingVoiceRobot, AudioClips];
export const linksAI = [
    AIChat,
    AIImageGenerate,
    AIAudioTranslate,
    AIDescribeImage,
    AIGeneratePost,
    AIGenerateMusic,
    AIRemoveBackground,
    AIBlurImage,
];
export const linksMailing = [
    MailingTelegram,
    MailingEmail,
    // MailingViber,
    // MailingWhatsApp,
    MailingHistory,
];
export const linksAdvertisement = [
    AdGoogleAdwords,
    AdYandexDirect,
    AdTelegram,
    AdInstagram,
    AdVkontakte,
];
export const linksMarketPlaces = [MarketWb, MarketOzon];
