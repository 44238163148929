import { InputMasks } from 'common/fields/input-field';
import { IconSize, InputSize, LabelSize } from 'common/input/types';
import * as Yup from 'yup';
export const model = {
    avatar: {
        label: '',
        type: 'file',
        name: 'avatar',
        size: InputSize.BIGGER,
        style: { cursor: 'pointer' },
    },
    first_name: {
        type: 'text',
        name: 'first_name',
        label: 'First name',
        withBorder: false,
        placeholder: 'Name',
        size: InputSize.SMALL,
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    last_name: {
        type: 'text',
        name: 'last_name',
        label: 'Surname',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Surname',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    phone: {
        name: 'phone',
        type: 'number',
        withBorder: false,
        placeholder: 'Phone',
        mask: InputMasks.phone,
        size: InputSize.SMALL,
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
        label: 'Contact phone number',
    },
    email: {
        type: 'email',
        name: 'email',
        label: 'Email',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'E-mail',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    password: {
        type: 'password',
        name: 'password',
        label: 'Password',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Password',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    companyName: {
        label: 'Name of the organization',
        type: 'text',
        withBorder: false,
        name: 'companyName',
        size: InputSize.SMALL,
        placeholder: 'Company Name',
    },
    inn: {
        label: 'INN',
        type: 'text',
        name: 'inn',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.inn,
        placeholder: 'INN',
    },
};
export const validationSchema = (t) => Yup.object().shape({
    [model.companyName.name]: Yup.string().min(2).required().matches(/^\w+$/),
    [model.inn.name]: Yup.string()
        .required(t('INN is required'))
        .matches(/^\d{10}$/, t('Invalid INN')),
    [model.first_name.name]: Yup.string()
        .min(2, t('Invalid name'))
        .required(t('Name is required'))
        .matches(/^[a-zA-Zа-яА-Я]+$/, t('Invalid name')),
    [model.last_name.name]: Yup.string()
        .min(2, t('Invalid surname'))
        .required(t('Surname is required'))
        .matches(/^[a-zA-Zа-яА-Я]+$/, t('Invalid surname')),
    [model.phone.name]: Yup.string()
        .required(t('Phone is required'))
        .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, t('Invalid phone format')),
    [model.email.name]: Yup.string()
        .required(t('Email is required'))
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, t('Invalid E-Mail format')),
    [model.password.name]: Yup.string()
        .required()
        .min(8)
        .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
        .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
        .matches(/^(?=.*\d)/, 'Password must contain at least one digit'),
});
export const initialValues = (data) => {
    var _a, _b, _c, _d;
    return {
        [model.inn.name]: '',
        [model.companyName.name]: '',
        [model.password.name]: '',
        [model.avatar.name]: '',
        [model.phone.name]: (_a = data === null || data === void 0 ? void 0 : data.phone) !== null && _a !== void 0 ? _a : '',
        [model.email.name]: (_b = data === null || data === void 0 ? void 0 : data.email) !== null && _b !== void 0 ? _b : '',
        [model.last_name.name]: (_c = data === null || data === void 0 ? void 0 : data.last_name) !== null && _c !== void 0 ? _c : '',
        [model.first_name.name]: (_d = data === null || data === void 0 ? void 0 : data.first_name) !== null && _d !== void 0 ? _d : '',
    };
};
