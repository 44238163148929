import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Carousel } from 'components/carousel';
import { Activity } from './main-modal/activity';
import { Company } from './main-modal/company';
import * as Styles from './styles';
import banner4 from '../../../../assets/images/img_2.png';
import banner5 from '../../../../assets/images/img_3.png';
export const MainContent = () => {
    const data = [
        {
            id: 0,
            imageUrl: banner4,
        },
        {
            id: 1,
            imageUrl: banner5,
        },
    ];
    return (_jsxs(Styles.Content, { children: [_jsx(Carousel, { data: data }), _jsxs(Styles.BottomContent, { children: [_jsx(Activity, {}), _jsx(Company, {})] })] }));
};
