var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from 'common/input';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { controlError } from 'utils/controlError';
export const InputMasks = {
    inn: (value) => {
        return value.replace(/\s/g, '').slice(0, 10);
    },
    phone: (value) => {
        const cleanedValue = value.replace(/\D/g, '');
        const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return value;
        }
        return value;
    },
    card: (value) => {
        return value
            .replace(/\s/g, '')
            .replace(/(\d{4})/g, '$1 ')
            .slice(0, 19);
    },
    date: (value) => {
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})/, '$1/')
            .slice(0, 5);
    },
    cvv: (value) => {
        return value.replace(/\D/g, '').slice(0, 3);
    },
};
export const InputField = (_a) => {
    var _b;
    var { name, mask, label, onChange, placeholder } = _a, props = __rest(_a, ["name", "mask", "label", "onChange", "placeholder"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = t(controlError(meta, name, t(placeholder !== null && placeholder !== void 0 ? placeholder : '')));
    return (_jsx(Input, Object.assign({}, props, { name: name, error: error, value: field.value, onBlur: () => helpers.setTouched(true), label: label ? (_b = t(label !== null && label !== void 0 ? label : '')) !== null && _b !== void 0 ? _b : '' : undefined, placeholder: placeholder ? t(placeholder) : '', onChange: (e) => {
            var _a, _b;
            helpers.setTouched(true);
            if (onChange) {
                onChange(e);
            }
            else {
                if (mask) {
                    helpers.setValue(mask((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value));
                }
                else {
                    helpers.setValue((_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value);
                }
            }
        } })));
};
