import { jsx as _jsx } from "react/jsx-runtime";
import { types } from 'react-alert';
import { Icon as IconComponent } from 'common/icon';
export const Icon = {
    [types.SUCCESS]: _jsx(IconComponent.AlertSuccess, { width: 24, height: 24 }),
    [types.ERROR]: _jsx(IconComponent.AlertError, { width: 24, height: 24 }),
    [types.INFO]: null,
};
export const Title = {
    [types.SUCCESS]: 'Success',
    [types.ERROR]: 'Error',
    [types.INFO]: 'Info',
};
