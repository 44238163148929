var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { filterEmptyFields } from './object';
export const addParamsToUrl = (url, params) => {
    if (!params)
        return url;
    const urlParams = new URLSearchParams();
    Object.keys(filterEmptyFields(params)).forEach((key) => urlParams.append(key, params[key]));
    return `${url}?${urlParams.toString()}`;
};
export const mapQuery = (_a) => {
    var { error, isFetching } = _a, props = __rest(_a, ["error", "isFetching"]);
    return Object.assign(Object.assign({}, props), { error: error === null || error === void 0 ? void 0 : error.message, isLoading: isFetching });
};
