import { t } from 'i18next';
import * as Yup from 'yup';
export const model = {
    tagsMailing: {
        name: 'tagsMailing',
        label: 'Mailing tags',
        placeholder: t('Select tags'),
        options: [
            'Voice command menu',
            'Interactive voice menu',
            'Voice-activated menu',
            'Voice navigation menu',
            'Automated voice menu',
            'Voice-driven menu system',
            'Vocal menu interface',
            'Hands-free menu control',
            'Speech-based menu selection',
            'Voice-responsive menu options',
        ].map((label) => ({
            label: t(label.toString()),
            value: label.toString(),
        })),
        isColumn: true,
    },
    status: {
        name: 'status',
        label: 'Status',
        placeholder: 'Select status',
        options: [
            'Voice command menu',
            'Interactive voice menu',
            'Voice-activated menu',
            'Voice navigation menu',
            'Automated voice menu',
            'Voice-driven menu system',
            'Vocal menu interface',
            'Hands-free menu control',
            'Speech-based menu selection',
            'Voice-responsive menu options',
        ].map((label) => ({
            label: t(label.toString()),
            value: label.toString(),
        })),
        isColumn: true,
    },
    dateFrom: {
        name: 'dateFrom',
        label: t('From'),
        placeholderText: t('From'),
    },
    dateTo: {
        name: 'dateTo',
        label: t('To'),
        type: 'date',
        placeholderText: t('To'),
    },
    timeFrom: {
        name: 'timeFrom',
    },
    timeTo: {
        name: 'timeTo',
    },
};
export const validationSchema = Yup.object().shape({});
export const initialValues = {};
