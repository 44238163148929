import * as config from './config';
const white = Object.freeze({
    primary: config.white,
    secondary: config.neutralOctonary,
    tertiary: config.whiteAction,
});
const purple = Object.freeze({
    primary: config.purple,
    secondary: config.purpleAction,
    tertiary: config.purplePrimary,
});
const black = Object.freeze({
    primary: config.black,
    secondary: config.blackSecondary,
});
const grey = Object.freeze({
    primary: config.grey,
    secondary: config.greySecondary,
    tetriery: config.greyTetriery,
});
const blue = Object.freeze({
    primary: config.blue,
    secondary: config.blueSecondary,
    tertiary: config.blueTetriery,
});
const blueLight = Object.freeze({
    primary: config.blueLight,
});
const red = Object.freeze({
    primary: config.redAccent,
    secondary: config.redSecondary,
    tetriery: config.redTetriery,
});
const green = Object.freeze({
    primary: config.green,
    secondary: config.saladPrimary,
    tetriery: config.greenTetriery,
});
const orange = Object.freeze({
    primary: config.orange,
    accent: config.orangeAccent,
    charts: config.orangeCharts,
});
export const LIGHT_COLORS = Object.freeze({
    red,
    grey,
    blue,
    green,
    white,
    black,
    purple,
    blueLight,
    orange,
});
