import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputField, Text, TextVariant } from '../../../../../common';
import { connectionModalModel, initialValues, validationSchema, } from './config';
import * as Styles from './styles';
export var EMarketplaces;
(function (EMarketplaces) {
    EMarketplaces["WILDBERRIES"] = "WILDBERRIES";
    EMarketplaces["OZON"] = "OZON";
    EMarketplaces["EMPTY"] = "EMPTY";
})(EMarketplaces || (EMarketplaces = {}));
export const ConnectionModal = ({ setIsOpen, marketplace, }) => {
    const { t } = useTranslation();
    const modalWrapperRef = useRef(null);
    const modalRef = useRef(null);
    useEffect(() => {
        const handler = (event) => {
            if (!event.composedPath().includes(modalRef.current) &&
                event.composedPath().includes(modalWrapperRef.current)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);
    return (_jsx(Styles.ModalWrapper, Object.assign({ ref: modalWrapperRef }, { children: _jsxs(Styles.Modal, Object.assign({ ref: modalRef }, { children: [_jsxs(Styles.Title, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: t('Shop connecting') })), _jsx(Styles.CloseIcon, { onClick: () => setIsOpen(false) })] }), _jsx(Styles.Content, { children: _jsx(Formik, Object.assign({ initialValues: initialValues, validationSchema: () => validationSchema(marketplace), onSubmit: () => void 0 }, { children: ({ isValid, dirty }) => (_jsxs(_Fragment, { children: [_jsxs(Styles.Form, { children: [_jsx(InputField, Object.assign({}, connectionModalModel.marketName)), _jsx(InputField, Object.assign({}, connectionModalModel.marketToken)), marketplace === EMarketplaces.OZON && (_jsx(InputField, Object.assign({}, connectionModalModel.marketClientId)))] }), _jsxs(Styles.Actions, { children: [_jsx(Button, { text: t('Cancel'), variant: 'TETRIERY', onClick: () => setIsOpen(false) }), _jsx(Button, { text: t('Confirm'), disabled: !isValid || !dirty, onClick: () => {
                                                setIsOpen(false);
                                            } })] })] })) })) })] })) })));
};
