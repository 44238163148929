import styled, { css } from 'styled-components';
import { Button as ButtonComponent, Button, InputField, } from '../../../../../../common';
import { scrollMedium } from '../../../../../../styles/mixins/scroll';
export const Scroll = styled.div.withConfig({ displayName: "Scroll", componentId: "sc-1jqxlgi" }) `
  overflow-y: auto;
  height: fit-content;
  ${scrollMedium};
`;
export const TextAreaContainer = styled.div.withConfig({ displayName: "TextAreaContainer", componentId: "sc-1g5ni93" }) `
  width: 100%;
  height: 100%;
  padding-right: 6px;
`;
export const TextAreaSection = styled.div.withConfig({ displayName: "TextAreaSection", componentId: "sc-10m3mc5" }) `
  width: 100%;
`;
export const PhoneNumbers = styled.div.withConfig({ displayName: "PhoneNumbers", componentId: "sc-174s0gw" }) `
  width: 100%;
`;
export const PhoneNumberContainer = styled.div.withConfig({ displayName: "PhoneNumberContainer", componentId: "sc-umw3rh" }) `
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
`;
export const WrapperField = styled.div.withConfig({ displayName: "WrapperField", componentId: "sc-fa90p" }) `
  display: grid;
  grid-template-columns: 10fr 1fr;
  gap: 5px;
`;
export const PhoneInput = styled(InputField).withConfig({ displayName: "PhoneInput", componentId: "sc-h44dxu" }) `
  border-radius: 16px;
  width: 100%;
  padding-bottom: 15px;
  ${({ theme }) => css `
    //border: ${theme.config.input.default.border.default};
  `}
`;
export const RemovePhoneNumberButton = styled(Button).withConfig({ displayName: "RemovePhoneNumberButton", componentId: "sc-1mtjrcj" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  border-radius: 50%;
  margin-top: 2px;

  ${({ theme }) => css `
    border: ${theme.config.input.default.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}
  &:hover {
    background: none;
  }
`;
export const PhonesChips = styled.div.withConfig({ displayName: "PhonesChips", componentId: "sc-1hbmm8o" }) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  gap: 15px;
  //padding-top: 10px;
  max-height: 146px;
  overflow-y: auto;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.input.light.background};
`;
export const PhoneChip = styled.div.withConfig({ displayName: "PhoneChip", componentId: "sc-b5jgle" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 20px;
  justify-content: space-between;

  height: 32px;
  width: 45%;

  transition: 0.3s ease-out;

  ${({ theme, selected }) => css `
    border-color: ${selected
    ? theme.colors.blue.primary
    : theme.colors.black.primary};
    background: ${theme.config.tags.background.default};
    color: ${theme.config.tags.text};
  `}
  &:hover {
    transition: 0.3s ease-out;
    transform: scale(105%, 105%);
  }
`;
export const PhoneChipButton = styled(ButtonComponent).withConfig({ displayName: "PhoneChipButton", componentId: "sc-1oafooa" }) `
  width: 16px;
  height: 16px;
  margin-left: 8px;
  padding: 9px;
`;
