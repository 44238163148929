import styled from 'styled-components';
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-c4sdlb" }) `
  display: flex;
  flex-direction: column;

  gap: 12px;

  max-width: 400px;
  height: 100%;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1kb6xms" }) `
  display: flex;
  gap: 24px;

  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    gap: 8px;
    flex-direction: column;
  }
`;
export const Status = styled.div.withConfig({ displayName: "Status", componentId: "sc-1upnw9d" }) `
  display: flex;
  align-items: flex-end;
  opacity: 0.2;
`;
export const WrapperHeader = styled.div.withConfig({ displayName: "WrapperHeader", componentId: "sc-1t6ny25" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;
