import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { ThemeContext } from 'context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import * as Styles from './styles';
export const ToggleTheme = () => {
    const { t } = useTranslation();
    const { isDarkTheme, selectTheme } = useContext(ThemeContext);
    const handleThemeToggle = () => { var _a; return selectTheme && selectTheme((_a = !isDarkTheme) !== null && _a !== void 0 ? _a : false); };
    return (_jsxs(Styles.Container, { children: [_jsxs(Styles.Wrapper, { children: [_jsxs(Styles.TabTheme, Object.assign({ onClick: () => {
                            if (isDarkTheme) {
                                handleThemeToggle();
                            }
                        } }, { children: [_jsx(Styles.IconThemeContainer, { children: _jsx(IconWrapper, Object.assign({ "$isCursor": true, "$fillColor": "tranparent", "$strokeColor": "tranparent" }, { children: isDarkTheme ? _jsx(Icon.SunNoFill, {}) : _jsx(Icon.SunFill, {}) })) }), _jsx(Styles.TabThemeLabel, Object.assign({ "$isActive": isDarkTheme }, { children: t('Light') }))] })), _jsxs(Styles.TabTheme, Object.assign({ onClick: () => {
                            if (!isDarkTheme) {
                                handleThemeToggle();
                            }
                        } }, { children: [_jsx(Styles.IconThemeContainer, { children: _jsx(IconWrapper, Object.assign({ "$isCursor": true, "$fillColor": "tranparent", "$strokeColor": "tranparent" }, { children: isDarkTheme ? _jsx(Icon.MoonFill, {}) : _jsx(Icon.MoonNoFill, {}) })) }), _jsx(Styles.TabThemeLabel, Object.assign({ "$isActive": isDarkTheme }, { children: t('Dark') }))] }))] }), _jsx(Styles.BackgroundActive, { "$isActiveDark": isDarkTheme })] }));
};
