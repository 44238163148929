import { Icon } from 'common/icon';
import styled, { css } from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ash4me" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  //height: 480px;
  border-radius: 16px;
  margin: 0px 40px;

  @media screen and (max-width: 480px) {
    margin: 0px 16px;
  }
`;
export const CarouselWrapper = styled.div.withConfig({ displayName: "CarouselWrapper", componentId: "sc-1uscuy9" }) `
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
`;
export const ImageWrapper = styled.img.withConfig({ displayName: "ImageWrapper", componentId: "sc-1ibr080" }) `
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;
export const Slide = styled.div.withConfig({ displayName: "Slide", componentId: "sc-apb0li" }) `
  flex: ${({ $isActive }) => ($isActive ? '1 0 100%' : '0 0 100%')};
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => theme.config.slider.border.all};
  background: ${({ theme }) => theme.config.slider.bg.default};
  transition: opacity 0.5s ease, transform 0.5s ease;

  ${({ $isActive, $activeIndex }) => $isActive
    ? css `
          opacity: 1;
          transform: translateX(${$activeIndex * -100}%);
        `
    : css `
          opacity: 0;
          transform: translateX(${$activeIndex * -100}%);
          pointer-events: none;
        `}
`;
export const DotsContainer = styled.div.withConfig({ displayName: "DotsContainer", componentId: "sc-1snxrqe" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 88px;

  @media screen and (max-width: 480px) {
    margin: 0px 40px;
  }
`;
export const DotsWrapper = styled.li.withConfig({ displayName: "DotsWrapper", componentId: "sc-1tsfh3f" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin-top: 8px;

  &:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: 480px) {
    &:not(:last-child) {
      margin-right: 14px;
    }
  }
`;
export const Dot = styled.div.withConfig({ displayName: "Dot", componentId: "sc-crj2la" }) `
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.6;
  background: ${({ theme }) => theme.config.slider.dots.default};

  transition: background 0.3s ease-in;
  ${({ $isActive, theme }) => $isActive &&
    css `
      opacity: 1;
      background: ${theme.config.slider.dots.active};
    `}
`;
export const ArrowsContainer = styled.div.withConfig({ displayName: "ArrowsContainer", componentId: "sc-19b8gn0" }) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Left = styled(Icon.Left).withConfig({ displayName: "Left", componentId: "sc-szn44z" }) `
  ${({ theme }) => css `
    path,
    fill {
      fill: ${theme.config.slider.icon.default};
    }
  `}
`;
export const Right = styled(Icon.Right).withConfig({ displayName: "Right", componentId: "sc-1ddyu6l" }) `
  ${({ theme }) => css `
    path,
    fill {
      fill: ${theme.config.slider.icon.default};
    }
  `}
`;
