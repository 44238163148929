import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, TextVariant } from 'common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsFormsNumberCreateMailing } from '../../headers-tabs/number';
import { DatabaseNumberForms } from '../sub-forms/database-number';
import { ManualForm } from '../sub-forms/manual-input';
const TYPE = {
    MANUALINPUT: 1,
    DATABASEROOMS: 2,
};
export const FormsNumberCreateMailing = () => {
    const { t } = useTranslation();
    const [step, setStep] = useState(TYPE.MANUALINPUT);
    const handleStep = (index) => {
        setStep(index);
    };
    return (_jsx(_Fragment, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Add Numbers'), _jsx(TabsFormsNumberCreateMailing, { step: step, onStepChange: handleStep }), step === TYPE.MANUALINPUT && _jsx(ManualForm, {}), step === TYPE.DATABASEROOMS && _jsx(DatabaseNumberForms, {})] })) }));
};
