var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Text, TextVariant } from '../../../../../common';
import { useAudioTranslateMutation } from '../../../../../store/api/ai';
import { handleMutation } from '../../../../../utils/handleMutation';
import { LoaderContainer } from '../generate-post/styles';
import { HeaderAudioTranslate } from './header';
import * as Styles from './styles';
export const AudioTranslate = () => {
    var _a, _b, _c, _d, _e, _f;
    const alert = useAlert();
    const { t } = useTranslation();
    const audioInputRef = useRef(null);
    const [changeFile, mutationFile] = useAudioTranslateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const onSuccess = useCallback(() => {
        setIsLoading(false);
        alert.success(t('Success'));
    }, [alert]);
    const onError = useCallback((error) => {
        var _a;
        try {
            setIsLoading(false);
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationFile), { onSuccess,
        onError }));
    useEffect(() => {
        console.log(mutationFile);
    }, [mutationFile]);
    const uploadAudio = () => {
        if (audioInputRef.current) {
            audioInputRef.current.click();
        }
    };
    const changeFileHandler = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield changeFile({ file: file });
    });
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(HeaderAudioTranslate, {}), _jsxs(Styles.AudioAction, { children: [_jsxs(Styles.AudioButtonContainer, { children: [_jsx(Styles.AudioButton, { text: t('Upload audio'), disabled: isLoading, onClick: () => uploadAudio() }), _jsx(Text, Object.assign({ "$variant": TextVariant.ultraSmallMedium, "$colorVariant": 'tetriery' }, { children: "\u0422\u043E\u043B\u044C\u043A\u043E \u0444\u0430\u0439\u043B\u044B .wav" }))] }), _jsx(Styles.AudioInput, { type: "file", accept: "audio/*", ref: audioInputRef, onChange: (event) => {
                                var _a;
                                if ((_a = event.target.files) === null || _a === void 0 ? void 0 : _a.item(0)) {
                                    changeFileHandler(event.target.files.item(0));
                                }
                            } }), ((_c = (_b = (_a = audioInputRef.current) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.item(0)) === null || _c === void 0 ? void 0 : _c.name) && (_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: (_f = (_e = (_d = audioInputRef.current) === null || _d === void 0 ? void 0 : _d.files) === null || _e === void 0 ? void 0 : _e.item(0)) === null || _f === void 0 ? void 0 : _f.name })))] }), isLoading && (_jsx(LoaderContainer, { children: _jsx(Loader, { text: t('Audio file processing...') }) })), mutationFile.data && (_jsx(_Fragment, { children: _jsxs(Styles.GeneratedText, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: mutationFile.data })), _jsx(Styles.ButtonWrapper, { children: _jsx(Button, { text: t('Copy to clipboard'), onClick: () => {
                                        if (mutationFile.data) {
                                            return navigator.clipboard.writeText(mutationFile.data);
                                        }
                                    } }) })] }) }))] }) }));
};
