import styled from 'styled-components';
import { Button } from '../../../../../common';
/* stylelint-disable */
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1vicdei" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-16ee4y4" }) `
  display: flex;
  flex-direction: column;
  margin: 40px;
  gap: 24px;
`;
export const GeneratedText = styled.div.withConfig({ displayName: "GeneratedText", componentId: "sc-rtiy4a" }) `
  white-space: pre-wrap;
  background: ${({ theme }) => theme.config.input.default.background};
  border-radius: 16px;
  padding: 15px;
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "sc-1txispe" }) `
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
export const FileInput = styled.input.withConfig({ displayName: "FileInput", componentId: "sc-eco3xw" }) `
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;
  padding: 0;
`;
export const InputAction = styled.div.withConfig({ displayName: "InputAction", componentId: "sc-gz1fpr" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const InputButtonContainer = styled.div.withConfig({ displayName: "InputButtonContainer", componentId: "sc-14wvzlu" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const InputButton = styled(Button).withConfig({ displayName: "InputButton", componentId: "sc-8ylbvr" }) `
  min-width: fit-content;
`;
