import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../../../../common';
import { Checkbox } from '../../../../../../common/checkbox';
import { Tag } from '../../../../../../components';
import { useBackdropClick } from '../../../../../../hooks/useBackdropClick';
import { ChevronBottom } from '../../../marketplaces/table/styles';
import * as Styles from './styles';
function findInTree(tree, predicate, includeSelf = false) {
    if (includeSelf && predicate(tree)) {
        return tree;
    }
    for (let child of tree.children) {
        if (findInTree(child, predicate, true)) {
            return child;
        }
    }
}
function visitAll(item, visitor) {
    const visitResult = visitor(item);
    return Object.assign(Object.assign({}, visitResult), { children: visitResult.children.map((c) => visitAll(c, visitor)) });
}
function applyUpdate(update) {
    return function (child) {
        var _a, _b;
        const { filter } = update;
        return Object.assign(Object.assign({}, child), { checked: (_a = update.checked) !== null && _a !== void 0 ? _a : child.checked, open: filter
                ? !!findInTree(child, (item) => item.name.includes(filter))
                : (_b = update.open) !== null && _b !== void 0 ? _b : child.open, highlight: filter !== null && filter !== void 0 ? filter : child.highlight });
    };
}
const HighlightPart = styled.span.withConfig({ displayName: "HighlightPart", componentId: "sc-vggqrm" }) `
  background-color: rgba(46, 255, 0, 0.85);
`;
const Highlight = ({ word, term }) => {
    if (!term) {
        return _jsx("span", { children: word });
    }
    return (_jsx(_Fragment, { children: word
            .replaceAll(term, `|<${term}|`)
            .split('|')
            .filter(Boolean)
            .map((part, index) => part[0] === '<' ? (_jsx(HighlightPart, { children: part.replace('<', '') }, index)) : (_jsx("span", { children: part.replace('<', '') }, index))) }));
};
function updateTree(item, update) {
    return update.onlySelf
        ? applyUpdate(update)(item)
        : visitAll(item, applyUpdate(update));
}
function updateTreeFromRoot(root, item, update) {
    return visitAll(root, (child) => child === item ? updateTree(item, update) : Object.assign({}, child));
}
function getToggledTreeItems(item, parent) {
    const childToggledTreeItems = item.children.flatMap((c) => getToggledTreeItems(c, item));
    if ((!item.checked && parent.checked) || (item.checked && !parent.checked)) {
        return [
            {
                item,
                checked: !!item.checked,
                unchecked: !!parent.checked,
            },
            ...childToggledTreeItems,
        ];
    }
    return childToggledTreeItems;
}
const initialRootRegion = {
    id: '',
    name: '',
    checked: false,
    children: [
        {
            id: '1',
            name: 'Russia',
            children: [
                {
                    id: '2',
                    name: 'Moskov',
                    children: [
                        {
                            id: '3',
                            name: 'Moskov 1',
                            children: [
                                {
                                    id: '4',
                                    name: 'Moskov 11',
                                    children: [],
                                },
                                {
                                    id: '5',
                                    name: 'Moskov 12',
                                    children: [],
                                },
                                {
                                    id: '6',
                                    name: 'Moskov 13',
                                    children: [],
                                },
                            ],
                        },
                        {
                            id: '7',
                            name: 'Moskov 2',
                            children: [],
                        },
                        {
                            id: '8',
                            name: 'Moskov 3',
                            children: [],
                        },
                    ],
                },
                {
                    id: '12',
                    name: 'SPB',
                    children: [],
                },
                {
                    id: '13',
                    name: 'Novosib',
                    children: [],
                },
            ],
        },
    ],
};
const DropdownItem = ({ region, updateItem }) => {
    return (_jsxs(Styles.DropdownItem, { children: [_jsxs(Styles.DropdownItemContent, { children: [_jsx(Styles.ChevronContainer, Object.assign({ onClick: () => updateItem(region, {
                            open: !region.open,
                            onlySelf: true,
                        }) }, { children: !!region.children.length && _jsx(ChevronBottom, {}) })), _jsx(Checkbox, { checked: region.checked, onChange: () => updateItem(region, {
                            checked: !region.checked,
                        }) }), _jsx(Highlight, { word: region.name, term: region.highlight })] }), !!region.children.length &&
                region.open &&
                region.children.map((childRegion) => (_jsx(DropdownItem, { region: childRegion, updateItem: updateItem }, childRegion.id)))] }));
};
export const RegionsDropdown = () => {
    const { setFieldValue } = useFormikContext();
    const [isDropdown, setIsDropdown] = useState(false);
    const [rootRegion, setRootRegion] = useState(initialRootRegion);
    const [search, setSearch] = useState('');
    const dropdownRef = useRef(null);
    const closeDropdown = () => {
        setIsDropdown(false);
    };
    useBackdropClick(dropdownRef, closeDropdown, isDropdown);
    useEffect(() => {
        setRootRegion((region) => updateTreeFromRoot(region, region, {
            filter: search,
        }));
    }, [search]);
    const chipName = (unchecked, name) => {
        if (unchecked) {
            return '- ' + name;
        }
        else {
            return name;
        }
    };
    useEffect(() => {
        setFieldValue('regions', rootRegion.children
            .flatMap((r) => getToggledTreeItems(r, initialRootRegion))
            .map((toggled) => {
            return {
                text: chipName(toggled.unchecked, toggled.item.name),
                value: chipName(toggled.unchecked, toggled.item.name),
            };
        }));
    }, [rootRegion.children]);
    return (_jsxs(Styles.DropdownContainer, Object.assign({ ref: dropdownRef }, { children: [_jsx(Styles.ChipsContainer, { children: rootRegion.children
                    .flatMap((r) => getToggledTreeItems(r, initialRootRegion))
                    .map((toggled) => (_jsx(Tag, { onClose: () => setRootRegion(updateTreeFromRoot(rootRegion, toggled.item, {
                        checked: !toggled.checked,
                    })), item: {
                        text: chipName(toggled.unchecked, toggled.item.name),
                        value: chipName(toggled.unchecked, toggled.item.name),
                    } }, toggled.item.id))) }), _jsx("div", Object.assign({ onFocus: () => setIsDropdown(true) }, { children: _jsx(Input, { colorType: 'LIGHT', placeholder: "\u041F\u043E\u0438\u0441\u043A...", value: search, onChange: (e) => setSearch(e.target.value) }) })), isDropdown && (_jsx(Styles.DropdownWrapper, { children: rootRegion.children.map((rootRegionChild) => (_jsx(DropdownItem, { region: rootRegionChild, updateItem: (region, checked) => setRootRegion(updateTreeFromRoot(rootRegion, region, checked)) }, rootRegionChild.id))) }))] })));
};
