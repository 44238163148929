import styled, { css } from 'styled-components';
import { Button, Input, InputField } from '../../../../../common';
import { AddFill, Trash } from '../../../../../common/icon/icons';
import { smallSemiBold, ultraSmallMedium, } from '../../../../../styles/mixins/fonts';
import { EWeeklyBudgetType } from './config';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1s1a87h" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 15px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};
  padding: 20px;
  position: relative;

  overflow-y: ${({ $isScrollDisabled }) => $isScrollDisabled ? 'hidden' : 'default'};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const PageTitle = styled.div.withConfig({ displayName: "PageTitle", componentId: "sc-1ha9jc3" }) ``;
export const EmptyPageContainer = styled.div.withConfig({ displayName: "EmptyPageContainer", componentId: "sc-7uygon" }) `
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 20px;
`;
export const EmptyPageContent = styled.div.withConfig({ displayName: "EmptyPageContent", componentId: "sc-tbicnt" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const EmptyPageActions = styled.div.withConfig({ displayName: "EmptyPageActions", componentId: "sc-nz22g6" }) `
  display: flex;
  flex-direction: row;

  background-color: ${({ theme }) => theme.config.input.default.background};
  padding: 3px;
  border-radius: 22px;
`;
export const UrlInput = styled(Input).withConfig({ displayName: "UrlInput", componentId: "sc-m6ki9o" }) `
  white-space: break-spaces;
  width: 100%;
`;
export const UrlButton = styled(Button).withConfig({ displayName: "UrlButton", componentId: "sc-1e7hc8d" }) `
  width: fit-content;
  white-space: nowrap;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1ayltcc" }) `
  padding: 10px;
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-ej9n2w" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const AdvContent = styled.div.withConfig({ displayName: "AdvContent", componentId: "sc-1x7rfgs" }) `
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const AdvWrapper = styled.div.withConfig({ displayName: "AdvWrapper", componentId: "sc-13hkb8h" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const BlockContainer = styled.div.withConfig({ displayName: "BlockContainer", componentId: "sc-1r8cx6t" }) `
  display: flex;
  flex-direction: column;
  margin: 3px 0px;
  background-color: ${({ theme }) => theme.config.input.default.background};
  border-radius: 20px;
  gap: 20px;
  flex-grow: 1;
  max-width: 600px;
  min-width: 500px;
  width: 100%;

  & > * {
    padding: 10px 20px;
  }
`;
export const Block = styled.div.withConfig({ displayName: "Block", componentId: "sc-1y0d335" }) `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 15px;
`;
export const BlockHeader = styled.div.withConfig({ displayName: "BlockHeader", componentId: "sc-yq3feh" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  max-width: 600px;
  min-width: 500px;
  width: 100%;
`;
export const BlockContent = styled.div.withConfig({ displayName: "BlockContent", componentId: "sc-1fcqay1" }) `
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 500px;
  width: 100%;
  gap: 10px;
`;
export const LinkActions = styled.div.withConfig({ displayName: "LinkActions", componentId: "sc-7nha4l" }) `
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  gap: 20px;
`;
export const LinksButton = styled(Button).withConfig({ displayName: "LinksButton", componentId: "sc-vbwo8h" }) `
  display: flex;
  flex-grow: 1;
`;
export const ChangeUrlWrapper = styled.div.withConfig({ displayName: "ChangeUrlWrapper", componentId: "sc-cippl8" }) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -100vh;
  z-index: 999;
  backdrop-filter: blur(5px);
`;
export const ChangeUrlContainer = styled.div.withConfig({ displayName: "ChangeUrlContainer", componentId: "sc-6i67jv" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  top: 180px;
  left: 30px;
  right: 30px;
  background-color: ${({ theme }) => theme.config.input.green.background};
  padding: 3px;
  border-radius: 22px;
`;
export const HeadersWrapper = styled.div.withConfig({ displayName: "HeadersWrapper", componentId: "sc-11gdect" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const TextsWrapper = styled.div.withConfig({ displayName: "TextsWrapper", componentId: "sc-imh0o8" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const HiddenInput = styled.input.withConfig({ displayName: "HiddenInput", componentId: "sc-1q1osw7" }) `
  display: none;
`;
export const MediasWrapper = styled.div.withConfig({ displayName: "MediasWrapper", componentId: "sc-1slhsxb" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const MediaPreviewWrapper = styled.div.withConfig({ displayName: "MediaPreviewWrapper", componentId: "sc-1aby6hx" }) `
  width: 100px;
  height: 100px;
  position: relative;
`;
export const RemoveMedia = styled(Trash).withConfig({ displayName: "RemoveMedia", componentId: "sc-1tfsrsy" }) `
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 3px;
  background: white;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;
export const ImagePreview = styled.img.withConfig({ displayName: "ImagePreview", componentId: "sc-qkk7gm" }) `
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.background.secondary};
`;
export const VideoPreview = styled.video.withConfig({ displayName: "VideoPreview", componentId: "sc-139hxzj" }) `
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.background.secondary};
`;
export const AddMedia = styled.div.withConfig({ displayName: "AddMedia", componentId: "sc-1l04ara" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.background.secondary};
  cursor: pointer;
`;
export const AddMediaIcon = styled(AddFill).withConfig({ displayName: "AddMediaIcon", componentId: "sc-53gawh" }) ``;
export const AddFieldButton = styled(Button).withConfig({ displayName: "AddFieldButton", componentId: "sc-1yf7443" }) `
  width: 300px;
`;
export const DropdownWrapper = styled.div.withConfig({ displayName: "DropdownWrapper", componentId: "sc-bvi29q" }) `
  display: flex;
  flex-grow: 1;
  background: ${({ theme }) => theme.config.input.light};
  border-radius: 20px;
  position: relative;
`;
export const DropdownButton = styled.div.withConfig({ displayName: "DropdownButton", componentId: "sc-8a4dwc" }) `
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  background-color: ${({ theme }) => theme.config.input.light};
`;
export const GenderAndAgeContainer = styled.div.withConfig({ displayName: "GenderAndAgeContainer", componentId: "sc-10svfo7" }) `
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const GenderAndAgeUnit = styled.div.withConfig({ displayName: "GenderAndAgeUnit", componentId: "sc-16aelrq" }) `
  display: flex;
  width: 390px;
  gap: 10px;
`;
export const TransitionPriceContainer = styled.div.withConfig({ displayName: "TransitionPriceContainer", componentId: "sc-1nxdr3z" }) `
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const WeekBudgetTabs = styled.header.withConfig({ displayName: "WeekBudgetTabs", componentId: "sc-e7ljd2" }) `
  display: flex;
  position: relative;

  border-radius: 12px;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.config.tab.background.secondary};
`;
export const WeekBudgetTabLink = styled.div.withConfig({ displayName: "WeekBudgetTabLink", componentId: "sc-zi0c9z" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 50%;
  padding: 8px;
  margin: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: -0.02em;

  transition: transform 0.3s ease-out;

  &:hover {
    text-decoration: none;
  }

  ${({ $isActive }) => $isActive &&
    css `
      position: relative;
      z-index: 1;
    `};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      padding: 6px;
      ${ultraSmallMedium}
    `}
`;
export const WeekBudgetTabLabel = styled.div.withConfig({ displayName: "WeekBudgetTabLabel", componentId: "sc-16flih5" }) `
  ${smallSemiBold};

  z-index: 10;
  color: ${({ theme }) => theme.config.text.primary};

  ${({ $isActive }) => $isActive &&
    css `
      color: ${({ theme }) => theme.config.text.primary};
      transition: color 0.25s ease-out;
    `};
`;
export const WeekBudgetTabBackground = styled.div.withConfig({ displayName: "WeekBudgetTabBackground", componentId: "sc-9otx1r" }) `
  position: absolute;

  width: 50%;

  height: 40px;
  border-radius: ${({ theme }) => (theme.responsive.isMobile ? '10px' : '8px')};
  background: ${({ theme }) => theme.config.tab.background.primary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07),
    inset 0px 1px 2px rgba(255, 255, 255, 0.02);

  transition: transform 0.3s ease-out;

  ${({ step, theme }) => {
    switch (step) {
        case EWeeklyBudgetType.CHOOSE:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(2%, ${theme.responsive.isMobile ? '5%' : '10%'});
          transition: transform 0.3s ease-out;
        `;
        case EWeeklyBudgetType.MANUAL:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            98%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
        default:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            99%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
    }
}}
`;
export const ChooseBudgetContainer = styled.div.withConfig({ displayName: "ChooseBudgetContainer", componentId: "sc-1jmnp46" }) `
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.config.tab.background.secondary};
`;
export const BudgetItem = styled.div.withConfig({ displayName: "BudgetItem", componentId: "sc-16k614r" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.config.tab.background.primary};
`;
export const BudgetContent = styled.div.withConfig({ displayName: "BudgetContent", componentId: "sc-6r5tz1" }) `
  display: flex;
  flex-direction: column;
`;
export const ChosenBudgetCheckmarkContainer = styled.div.withConfig({ displayName: "ChosenBudgetCheckmarkContainer", componentId: "sc-1jxzb4m" }) `
  width: 30px;
  margin-right: 10px;
`;
export const ChosenBudgetCheckmark = styled.div.withConfig({ displayName: "ChosenBudgetCheckmark", componentId: "sc-1j9xgx" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green.primary};
  box-shadow: 0 0 2px 1px ${({ theme }) => theme.colors.green.primary};
`;
export const BudgetInput = styled(InputField).withConfig({ displayName: "BudgetInput", componentId: "sc-1qsymjk" }) `
  width: 200px;
`;
export const RecommendationsCheckboxWrapper = styled.div.withConfig({ displayName: "RecommendationsCheckboxWrapper", componentId: "sc-1hse4ho" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const ProgressBarWrapper = styled.div.withConfig({ displayName: "ProgressBarWrapper", componentId: "sc-1r8wiiv" }) `
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  position: sticky;
  bottom: -20px;
  background: ${({ theme }) => theme.config.background.tetriery};
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.config.background.secondary};
  border-bottom: none;
`;
