import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Text, TextVariant } from 'common';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SearchInput } from '../../../../../../common/fields/search-input';
import { Filter } from '../../../mailing/filter-mailing';
import { audioClipsFilterModel } from '../config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1k0xfkt" }) ``;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-vg1bfa" }) `
  display: flex;
  flex-direction: column;

  gap: 24px;
`;
const WrapperTop = styled.div.withConfig({ displayName: "WrapperTop", componentId: "sc-cgm8nr" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperBottom = styled.div.withConfig({ displayName: "WrapperBottom", componentId: "sc-1ygnwl5" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperSearch = styled.div.withConfig({ displayName: "WrapperSearch", componentId: "sc-1il2poh" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const WrapperButtons = styled.div.withConfig({ displayName: "WrapperButtons", componentId: "sc-b6zhv3" }) `
  display: flex;
  align-items: center;

  gap: 24px;
`;
// const ButtonDelete = styled(Button)`
//   display: flex;
//   padding: 24px;
//   align-items: center;
//   justify-content: center;
//   color: ${({ theme }) => theme.config.text.primary};
//
//   &:hover {
//     color: ${({ theme }) => theme.config.text.primary};
//   }
// `;
export const HeaderAudioClips = ({ search, onClick, searchFilter, onApplyFilters, }) => {
    const { t } = useTranslation();
    return (_jsx(Container, { children: _jsxs(Wrapper, { children: [_jsxs(WrapperTop, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Audio clips') })), _jsxs(WrapperButtons, { children: [_jsx(Button, { padding: "24px", text: t('Create'), onClick: onClick }), _jsx(Filter, { onApplyFilters: (filterValues) => {
                                        onApplyFilters({
                                            status: filterValues.status,
                                        });
                                    }, filterFieldsModel: audioClipsFilterModel })] })] }), _jsx(WrapperBottom, { children: _jsx(WrapperSearch, { children: _jsx(SearchInput, { visibleByDefault: true, search: search, searchFilter: searchFilter }) }) })] }) }));
};
