var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { ChangePassword, DeleteProfile, EmployeeInvitation, Info, } from 'components/modal';
import { UserRole } from 'constants/enums';
import { Formik } from 'formik';
import { useStep } from 'hooks';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordMutation, useResetPasswordMutation, } from 'store/api/auth';
import { useMeQuery, useUpdateAvatarMutation, useUpdateProfileMutation, } from 'store/api/profile';
import { handleMutation } from 'utils/handleMutation';
import { BASE_URL } from '../../../../../../../api/constants';
import DefaultAvatar from '../../../../../../../assets/images/default-avatar-profile-icon.png';
import IconWrapper from '../../../../../../../common/icon-wrapper/icon-wrapper';
import { initialValues, model } from './config';
import * as Styles from './styles';
export const PersonalDataForm = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const changeImageInputRef = useRef(null);
    const { data } = useMeQuery();
    const [currentUserData, setCurrentUserData] = useState();
    const [updateAvatar] = useUpdateAvatarMutation();
    const [forgotPassword] = useForgotPasswordMutation();
    const [changeMe, mutationMe] = useUpdateProfileMutation();
    const [resetPassword, mutationReset] = useResetPasswordMutation();
    const reader = new FileReader();
    const isResetPasswordLoading = mutationReset.isLoading;
    const [isVisibleChangeStatus] = useState(false);
    const [isInfoVisible, setInfoVisible] = useState(false);
    const [isDeleteProfileVisible, setDeleteProfileVisible] = useState(false);
    const [isChangeProfileVisible, setIsChangeProfileVisible] = useState(false);
    const [isEmployeeInvitationVisible, setIsEmployeeInvitationVisible] = useState(false);
    const [avatarUser, setAvatarLink] = useState((data === null || data === void 0 ? void 0 : data.avatar_link) && (data === null || data === void 0 ? void 0 : data.avatar_link) !== 'none'
        ? BASE_URL + '/v1/' + (data === null || data === void 0 ? void 0 : data.avatar_link)
        : DefaultAvatar);
    useEffect(() => {
        setCurrentUserData(data);
    }, [data]);
    const onSuccess = useCallback(() => {
        alert.success(t('Success'));
    }, [alert]);
    const onSuccessReset = useCallback(() => {
        alert.success(t('Password changed successfully'));
        setIsChangeProfileVisible(false);
    }, [alert]);
    const onError = useCallback((error) => {
        var _a, _b;
        try {
            alert.error(((_b = (_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail[0]) === null || _b === void 0 ? void 0 : _b.msg) || t('Error'));
        }
        catch (_c) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationMe), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, mutationReset), { onSuccess: onSuccessReset, onError }));
    const clearImage = ({ setFieldValue, }) => {
        setFieldValue('avatar', null);
        setFieldValue('avatarFile', null);
        setAvatarLink('');
    };
    const onImageChange = ({ event, setFieldValue, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const imageFile = (_b = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (imageFile) {
            setFieldValue('avatarFile', imageFile);
            reader.onload = (e) => {
                var _a, _b;
                const result = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result) !== null && _b !== void 0 ? _b : '';
                setFieldValue('avatar', result);
            };
            reader.readAsDataURL(imageFile);
        }
    });
    const changeUserPhoto = (setFieldValue) => {
        if (changeImageInputRef.current) {
            const ref = changeImageInputRef.current;
            ref.click();
            ref.onchange = () => {
                if (ref.files && ref.files.length && ref.files.item(0)) {
                    const imageFile = ref.files.item(0);
                    setFieldValue('avatarFile', imageFile);
                    reader.onload = (e) => {
                        var _a, _b;
                        const result = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result) !== null && _b !== void 0 ? _b : '';
                        setFieldValue('avatar', result);
                    };
                    reader.readAsDataURL(imageFile);
                }
            };
        }
    };
    const handleInfo = (email, isChange) => __awaiter(void 0, void 0, void 0, function* () {
        setInfoVisible(false);
        setIsChangeProfileVisible(true);
        try {
            yield forgotPassword({
                email,
                isChange,
            });
        }
        catch (error) {
            console.error('Error occurred:', error);
        }
    });
    const handleDeleteProfile = () => {
        setDeleteProfileVisible(!isDeleteProfileVisible);
    };
    const handleChangePassword = () => {
        setIsChangeProfileVisible(!isChangeProfileVisible);
    };
    const sendNewPassword = (code, password, confirmPassword) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield resetPassword({
                code,
                password,
                confirmPassword,
            });
        }
        catch (error) {
            console.error('Error occurred:', error);
            setInfoVisible(false);
        }
    });
    const handleEmployeeInvitation = () => {
        setIsEmployeeInvitationVisible(!isEmployeeInvitationVisible);
    };
    const { handleNextStep, getCurrentStepFunction } = useStep(handleInfo, handleChangePassword);
    const handleStepAction = (values) => {
        const currentStepFunction = getCurrentStepFunction();
        if (currentStepFunction === handleChangePassword) {
            currentStepFunction();
        }
        else if (currentStepFunction === handleInfo) {
            currentStepFunction(values.email, true);
        }
        handleNextStep();
    };
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { email, phone, first_name, last_name, avatarFile } = values;
        if ((data === null || data === void 0 ? void 0 : data.email) !== email ||
            (data === null || data === void 0 ? void 0 : data.phone) !== phone ||
            (data === null || data === void 0 ? void 0 : data.first_name) !== first_name ||
            (data === null || data === void 0 ? void 0 : data.last_name) !== last_name) {
            yield changeMe({
                first_name,
                email,
                phone,
                last_name,
            });
        }
        if (avatarFile) {
            yield updateAvatar({ avatar: avatarFile });
        }
    });
    return (_jsx(Styles.Container, { children: _jsx(Formik, Object.assign({ onSubmit: onSubmit, initialValues: initialValues(data) }, { children: ({ dirty, isSubmitting, values, setFieldValue, submitForm }) => {
                return (_jsxs(_Fragment, { children: [_jsxs(Styles.Wrapper, { children: [_jsxs(Styles.WrapperLeft, { children: [_jsx(Styles.WrapperImage, { children: (values === null || values === void 0 ? void 0 : values.avatar) || !!avatarUser ? (_jsx(Styles.Image, { width: 236, alt: "image", height: 236, borderRadius: 50, objectFit: "cover", src: (values === null || values === void 0 ? void 0 : values.avatar) || avatarUser })) : (_jsxs(_Fragment, { children: [_jsxs(Styles.WrapperUploadImage, { children: [_jsx(IconWrapper, { children: _jsx(Icon.Download, {}) }), _jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: t('Upload a photo') }))] }), _jsx(InputField, Object.assign({}, model.avatar, { onChange: (event) => onImageChange({
                                                            event,
                                                            setFieldValue,
                                                        }) }))] })) }), _jsxs(Styles.WrapperButtonImg, { children: [_jsx(Styles.HiddenInput, { ref: changeImageInputRef, type: "file", accept: "image/*" }), _jsx(Styles.ButtonRefresh, { text: "", icon: _jsx(Icon.Reload, {}), onClick: () => changeUserPhoto(setFieldValue) }), _jsx(Styles.ButtonDelete, { text: "", icon: _jsx(Icon.Delete, {}), onClick: () => {
                                                        clearImage({ setFieldValue });
                                                    } })] })] }), _jsxs(Styles.WrapperForm, { children: [_jsxs(Styles.FieldsRow, { children: [(data === null || data === void 0 ? void 0 : data.role) === UserRole.Legal && (_jsx(InputField, Object.assign({ icon: _jsx(Icon.Building, {}) }, model.companyName))), _jsx(InputField, Object.assign({ icon: _jsx(Icon.User, {}) }, model.first_name)), _jsx(InputField, Object.assign({ icon: _jsx(Icon.User, {}) }, model.last_name))] }), _jsxs(Styles.FieldsRow, { children: [(data === null || data === void 0 ? void 0 : data.role) === UserRole.Legal && (_jsx(InputField, Object.assign({ icon: _jsx(Icon.Building, {}) }, model.inn))), _jsx(InputField, Object.assign({ icon: _jsx(Icon.Phone, {}) }, model.phone)), _jsx(InputField, Object.assign({ icon: _jsx(Icon.Mail, {}) }, model.email))] }), _jsx(Styles.Section, { children: _jsx(Styles.FieldsRow, { children: _jsx(Styles.Password, { onClick: () => {
                                                        // handleStepAction(values);
                                                        setInfoVisible(true);
                                                    }, text: t('Change Password'), variant: ButtonVariant.TETRIERY }) }) }), isVisibleChangeStatus && (_jsxs(Styles.FieldsRow, { children: [_jsx(Styles.WrapperText, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.smallReqular }, { children: t('Status') })) }), _jsx(Button, { text: t('Change Status'), variant: ButtonVariant.TETRIERY, onClick: handleEmployeeInvitation })] }))] }), _jsxs(Styles.FooterButton, { children: [_jsx(Styles.ButtonPrimary, { text: t('Save'), disabled: !dirty || isSubmitting, variant: ButtonVariant.PRIMARY, onClick: submitForm }), _jsx(Button, { text: t('Cancel'), disabled: !dirty, variant: ButtonVariant.TETRIERY, onClick: () => {
                                                setFieldValue('email', currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.email);
                                                setFieldValue('phone', currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.phone);
                                                setFieldValue('first_name', currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.first_name);
                                                setFieldValue('last_name', currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.last_name);
                                            } })] })] }), _jsx(DeleteProfile, { isVisible: isDeleteProfileVisible, onClick: handleDeleteProfile }), _jsx(Info, { isVisible: isInfoVisible, closeModal: () => setInfoVisible(false), openForm: () => handleStepAction(values) }), _jsx(ChangePassword, { sendPassword: sendNewPassword, isLoading: isResetPasswordLoading, isVisible: isChangeProfileVisible, onClick: handleChangePassword }), _jsx(EmployeeInvitation, { width: 1000, isVisible: isEmployeeInvitationVisible, onClick: handleEmployeeInvitation })] }));
            } })) }));
};
