var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, InputField, Text, TextVariant } from 'common';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../../../components/form/Form';
import { model, validationSchema, vkPostsInitialValues, } from './config';
import * as Styles from './styles';
import { SwitchField } from '../../../../../../../common/fields/switch-field';
const VkParserPostsForm = () => {
    const { t } = useTranslation();
    const { isSubmitting, dirty, submitForm, values, isValid } = useFormikContext();
    return (_jsxs(Styles.WrapperGroups, { children: [_jsxs(Styles.InfoContainer, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: t('Posts') })) }), _jsx(Styles.ContentContainer, { children: _jsxs(Styles.Content, { children: [_jsx(InputField, Object.assign({}, model.groupScreenName)), _jsx(InputField, Object.assign({}, model.countPosts)), _jsxs(Styles.ContentToggle, { children: [_jsx(SwitchField, Object.assign({}, model.hasCommentsLimit)), values.hasCommentsLimit && (_jsx(InputField, Object.assign({}, model.countComments)))] })] }) })] }), _jsx(Styles.Footer, { children: _jsx(Button, { padding: "24px 24px", onClick: submitForm, text: t('Prepare the database'), disabled: !dirty ||
                        isSubmitting ||
                        !isValid ||
                        (values.hasCommentsLimit && values.countComments.length === 0) }) })] }));
};
export const FormsVkParserPosts = ({ parse }) => {
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { countPosts, groupScreenName, countComments } = values;
        yield parse({
            key: 'posts',
            count: countPosts,
            groupScreenName,
            comments: countComments,
        });
    });
    return (_jsx(Styles.Content, { children: _jsx(Form, Object.assign({ onSubmit: onSubmit, initialValues: vkPostsInitialValues, validationSchema: validationSchema }, { children: _jsx(VkParserPostsForm, {}) })) }));
};
