import * as Yup from 'yup';
export const model = {
    groupScreenName: {
        label: 'Link to the group',
        placeholder: 'Enter a link to the group',
        name: 'groupScreenName',
    },
    countPosts: {
        label: 'Posts limit',
        placeholder: 'Enter posts limit',
        name: 'countPosts',
    },
    countComments: {
        placeholder: 'Введите лимит комментариев',
        name: 'countComments',
    },
    hasCommentsLimit: {
        label: 'Лимит на парсинг комментариев',
        type: 'checkbox',
        withBorder: false,
        name: 'hasCommentsLimit',
    },
};
export const validationSchema = Yup.object().shape({
    groupScreenName: Yup.string()
        .required('Link to the group is required')
        .matches(/^(https:\/\/vk\.com\/|vk\.com\/)/, 'Ссылка должна начинаться с https://vk.com/ или vk.com/'),
    countPosts: Yup.number()
        .required('Posts is required')
        .integer('Must be an integer')
        .positive('Must be positive number'),
    countComments: Yup.number()
        .integer('Must be an integer')
        .positive('Must be positive number'),
});
export const vkPostsInitialValues = {
    groupScreenName: '',
    countPosts: '',
    countComments: '',
    hasCommentsLimit: false,
};
