import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryNavigate } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as Styles from './styles';
export const HeaderTabsInfo = ({ itemsTab }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useQueryNavigate();
    return (_jsx(Styles.Container, { children: _jsx(Styles.Wrapper, { children: _jsx(Styles.HeaderTabsWrapper, { children: _jsx(Styles.HeaderTabs, { children: itemsTab === null || itemsTab === void 0 ? void 0 : itemsTab.map((item) => (_jsx(React.Fragment, { children: _jsxs(Styles.Tab, Object.assign({ id: item.id, "$isActive": pathname === item.value, onClick: () => navigate(item.value, { replace: true }) }, { children: [_jsx(Styles.TabLabel, Object.assign({ "$isActive": pathname === item.value }, { children: t(item.label) })), _jsx(Styles.ActiveLine, { "$isActive": pathname === item.value })] })) }, item.id))) }) }) }) }));
};
