import styled, { css } from 'styled-components';
import { ultraSmallMedium } from 'styles/mixins/fonts';
export const ErrorContainer = styled.div.withConfig({ displayName: "ErrorContainer", componentId: "sc-led94x" }) `
  position: relative;
`;
export const Error = styled.div.withConfig({ displayName: "Error", componentId: "sc-14fbmul" }) `
  position: absolute;
  top: 4px;
  ${ultraSmallMedium}
  font-style: italic;
  padding: 0px 0px 8px;
  ${({ theme }) => css `
    color: ${theme.config.input.light.error};
  `}
`;
