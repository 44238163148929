import { InputSize } from 'common/input/types';
import { InputMasks } from 'common/fields/input-field';
import { t } from 'i18next';
import * as Yup from 'yup';
export const model = {
    code: {
        label: '',
        type: 'text',
        name: 'code',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.inn,
        placeholder: 'Code',
    },
    password: {
        label: '',
        type: 'password',
        name: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Password',
    },
    newPassword: {
        label: '',
        type: 'password',
        name: 'newPassword',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'New password',
    },
    confirmPassword: {
        label: '',
        type: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        name: 'confirmPassword',
        placeholder: 'Confirm Password',
    },
};
export const initialValues = {
    [model.code.name]: '',
    [model.newPassword.name]: '',
    [model.confirmPassword.name]: '',
};
export const validationSchema = () => {
    return Yup.object().shape({
        [model.code.name]: Yup.string().required(t('Code is required')),
        [model.newPassword.name]: Yup.string()
            .required()
            .min(8)
            .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
            .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
            .matches(/^(?=.*\d)/, 'Password must contain at least one digit'),
        [model.confirmPassword.name]: Yup.string()
            .required(t('Password is required'))
            .test('passwords-match', t('Passwords do not match'), function (value) {
            return value === this.resolve(Yup.ref(model.newPassword.name));
        }),
    });
};
