import styled, { css } from 'styled-components';
import { Icon } from 'common/icon';
import { bodyRegular, bodySemibold, smallReqular } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-18prjnc" }) `
  ${({ theme, inline, changeByButton, title }) => css `
    .react-datepicker {
      background-color: ${inline ? 'unset' : theme.config.background.secondary};
      box-shadow: ${!inline && '0px 8px 16px rgba(0, 0, 0, 0.05)'};
      border: ${theme.config.calendar.container.border.default};
      padding: 16px;
      border-radius: 30px;

      &-popper {
        z-index: 100;
      }

      ${inline &&
    css `
        width: 100%;
      `}

      ${changeByButton &&
    css `
        display: flex;
        padding-bottom: 80px;
      `}

      ${title &&
    css `
        padding-top: 64px;
      `}

      .react-datepicker__month-container {
        width: ${inline ? '100%' : '312px'};
      }

      &__day--range-start,
      &__day--selecting-range-start {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      &__day--range-end {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }

      .react-datepicker__day--today {
        color: ${theme.config.calendar.day.selectedDay.borderColor} !important;

        &:hover {
          color: ${theme.config.calendar.day.default.hoverTextColor} !important;
        }
      }

      &__day--selected,
      &__day--range-start,
      &__day--range-end,
      &__month-text--selected,
      &__month-text--in-selecting-range,
      &__month-text--in-range,
      &__day--keyboard-selected,
      &__month-text--keyboard-selected {
        border: 1px solid ${theme.config.calendar.day.selectedDay.borderColor};
        color: ${theme.config.calendar.day.selectedDay.textColor} !important;
        background: ${theme.config.calendar.day.selectedDay
    .backgroundOpacity} !important;
      }

      &__day--selected,
      &__day--range-start,
      &__day--range-end,
      &__month-text--selected,
      &__month-text--in-selecting-range,
      &__month-text--in-range,
      &__day--keyboard-selected,
      &__month-text--keyboard-selected:hover {
        border: 2px solid ${theme.config.calendar.day.selectedDay.borderColor};
        background: ${theme.config.calendar.day.selectedDay
    .backgroundOpacity} !important;
        color: ${theme.config.calendar.day.default.hoverTextColor} !important;
      }

      &__day--in-range,
      &__day--in-selecting-range {
        border-radius: 0px;
        color: ${theme.config.calendar.day.dateBetween.textColor};
        background-color: ${theme.config.calendar.day.selectedDay
    .backgroundOpacity};
      }

      &__day--disabled {
        opacity: 0.3;
      }

      &__day--outside-month {
        color: ${theme.colors.grey.secondary} !important;
        background: none !important;
      }
    }

    .react-datepicker__header {
      background-color: transparent;
      border: none;
      padding: 0px 0px 8px;
    }

    .react-datepicker__day-names {
      padding: 16px 0px 4px 0px;
      ${smallReqular};
      border-bottom: ${theme.config.calendar.day.border.default};

      .react-datepicker__day-name {
        margin: 0;
        padding: 4px;
        width: calc(100% / 8);
        height: 32px;
        gap: 10px;
        color: ${theme.config.select.text.disabled};
        background: transparent;
        opacity: 0.5;

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__week {
      display: flex;
    }

    .react-datepicker__day {
      margin: 3px 0px;
      display: flex;
      align-items: center;
      aspect-ratio: 1 / 1;
      width: calc(100% / 7);
      justify-content: center;
      color: ${theme.config.text.primary};
      z-index: 100;
      ${bodyRegular};

      &:not(.react-datepicker__day--in-range):not(
          .react-datepicker__day--in-selecting-range
        ) {
        border-radius: 16px;
      }

      &:not(.react-datepicker__day--disabled):hover {
        color: ${theme.config.calendar.day.default.hoverTextColor} !important;
        border: 2px solid ${theme.config.calendar.day.selectedDay.borderColor};
        background: ${theme.config.calendar.day.selectedDay
    .backgroundOpacity} !important;
      }
    }
  `}
`;
export const Arrow = styled(Icon.ArrowDown).withConfig({ displayName: "Arrow", componentId: "sc-1dyiwrm" }) `
  ${({ theme }) => css `
    path,
    fill {
      fill: ${theme.config.select.label.default} !important;
    }
  `}
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-120kekr" }) `
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    ${bodySemibold};
    ${({ theme }) => css `
      color: ${theme.config.text.primary};
    `}
  }
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-1cgaruj" }) `
  ${({ monthsShown }) => css `
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 16px 24px;
    button {
      width: 112px;
      &:not(:first-of-type) {
        margin-left: 24px;
      }
    }
    ${monthsShown === 1 &&
    css `
      width: 100%;
      display: flex;
      button {
        flex: 1;
      }
    `}
  `}
`;
export const Sidebar = styled.div.withConfig({ displayName: "Sidebar", componentId: "sc-1kgu05u" }) `
  width: 140px;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 0 24px;
  list-style: none;
  li {
    padding: 8px 0;
    button {
      padding: 4px 12px;
    }
  }
`;
export const Title = styled.h3.withConfig({ displayName: "Title", componentId: "sc-7641xx" }) `
  position: absolute;
  left: 24px;
  top: 24px;
  right: 24px;
  ${({ theme }) => css `
    color: ${theme.config.text.primary};
  `}
`;
