var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, Text, TextVariant } from 'common';
import { DatePickerField } from 'common/fields/date-picker/date-picker';
import { SelectField } from 'common/fields/select-field/select';
import { AppRoutes } from 'config';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAvitoMutation, useParserFiltersQuery } from 'store/api/parser';
import { handleMutation } from 'utils/handleMutation';
import { useNavigate } from '../../../../../hooks';
import { initialValues, model, validationSchema } from './config';
import * as Styles from './styles';
export const AvitoPage = () => {
    const showAdditionalFilters = false;
    const { Home, Parsing } = AppRoutes.Dashboard;
    const navigate = useNavigate();
    const alert = useAlert();
    const { t } = useTranslation();
    const [parse, mutation] = useAvitoMutation();
    const { data: filters, isFetching: isFetchingFilters } = useParserFiltersQuery({ type: 'Avito' });
    const isLoading = mutation.isLoading;
    const onSuccess = useCallback((data) => {
        navigate(`/dashboard/my-database/${data._id}`);
    }, []);
    const onError = useCallback((error) => {
        alert.error((error === null || error === void 0 ? void 0 : error.data) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutation), { onSuccess,
        onError }));
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { category, limit, region } = values;
        yield parse({
            limit: limit.value,
            region: region.value,
            category: category.value,
        });
    });
    if (isLoading || isFetchingFilters) {
        return (_jsx(Styles.Container, { children: _jsxs(Styles.LoadingWrapper, { children: [_jsxs(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: [t('Loading'), "..."] })), _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: t('Please wait. This may take a few minutes.') }))] }) }));
    }
    return (_jsx(Styles.Container, { children: _jsx(Styles.ScrollContent, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Styles.TextsContainer, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: t('Avito Base') })) }), _jsx(Formik, Object.assign({ onSubmit: onSubmit, initialValues: initialValues, validationSchema: validationSchema }, { children: ({ dirty, isSubmitting, submitForm }) => {
                            var _a;
                            return (_jsxs(Styles.Content, { children: [_jsxs(Styles.InfoContainer, { children: [_jsxs(Styles.Content, { children: [_jsxs(Styles.FormRow, { children: [_jsx(SelectField, Object.assign({}, model.region, { options: filters === null || filters === void 0 ? void 0 : filters.region, isSearchable: true, noOptionsMessage: (e) => e.inputValue ? t('No options') : null, components: {
                                                                    ClearIndicator: () => _jsx(_Fragment, {}),
                                                                    MultiValue: () => _jsx(_Fragment, {}),
                                                                } })), showAdditionalFilters && _jsx(SelectField, Object.assign({}, model.city))] }), _jsxs(Styles.FormRow, { children: [_jsx(SelectField, Object.assign({}, model.category, { options: filters === null || filters === void 0 ? void 0 : filters.category })), _jsx(SelectField, Object.assign({}, model.limit, { options: (_a = filters === null || filters === void 0 ? void 0 : filters.limits) === null || _a === void 0 ? void 0 : _a.map((item) => ({
                                                                    label: item.toString(),
                                                                    value: item.toString(),
                                                                })).sort((a, b) => parseInt(a.value) - parseInt(b.value) ||
                                                                    a.label.localeCompare(b.label)) }))] })] }), showAdditionalFilters && (_jsxs(Styles.Content, { children: [_jsx(Styles.FormRow, { children: _jsx(Styles.FormInputField, Object.assign({}, model.adName)) }), _jsxs(Styles.FormColumn, { children: [_jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.smallSemiBold }, { children: t('Date of publication of the announcement') })), _jsxs(Styles.FormRow, { children: [_jsx(DatePickerField, Object.assign({}, model.date_from)), _jsx(DatePickerField, Object.assign({}, model.date_to))] })] })] }))] }), _jsxs(Styles.ButtonContainer, { children: [_jsx(Styles.ButtonCancel, { text: t('Cancel'), to: Home + Parsing.Home, variant: ButtonVariant.SECONDARY }), _jsx(Button, { onClick: submitForm, text: t('Prepare the database'), disabled: !dirty || isSubmitting })] })] }));
                        } }))] }) }) }));
};
