import { Link as ComponentLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { smallMedium } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1sa13ta" }) `
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => css `
    background: ${theme.config.dashboard.background};
  `} @media screen(max-width: 500 px) {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 250px;
    height: 100vh;

    background-color: ${({ theme }) => theme.config.dashboard.background};
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.15);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    z-index: 100;

    ${({ open }) => open &&
    css `
        transform: translateX(0);
      `}
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1njycok" }) `
  margin: 48px 20px 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > *:not(:first-child, :last-child) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 500px) {
    margin-right: 2rem;
  }
`;
export const Link = styled(ComponentLink).withConfig({ displayName: "Link", componentId: "sc-189ms9q" }) `
  ${smallMedium}

  flex: none;
  order: 1;
  flex-grow: 1;
  letter-spacing: -0.01em;

  ${({ theme }) => css `
    color: ${theme.config.sideLink.text.default};

    &:is(:hover, :focus) {
      text-decoration: none;
      color: ${theme.config.sideLink.text.hover};
    }
  `} @media screen and(max-width: 767 px) {
    display: none;
  }

  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
export const BackMain = styled.div.withConfig({ displayName: "BackMain", componentId: "sc-rwvnpf" }) `
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  padding: 12px 20px 12px 13px;

  cursor: pointer;
  margin-top: 10px;
`;
export const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-l8frl4" }) `
  margin-top: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.config.line.secondary};
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-10pqfk9" }) `
  margin-top: 16px;
  padding: 12px 0px;
  opacity: 0.75;
`;
