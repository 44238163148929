import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1tjj04b" }) `
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 40px 40px 32px;

  @media screen and (max-width: 560px) {
    margin: 20px 20px 16px;
  }

  height: 100%;
`;
export const BottomContent = styled.div.withConfig({ displayName: "BottomContent", componentId: "sc-1wwc1pe" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 56px;

  @media screen and (max-width: 1600px) {
    margin-right: 0;
    margin-bottom: 16px;
    flex-direction: column;
  }

  @media screen and (max-width: 560px) {
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1etpz3p" }) `
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 24px 24px 24px 0px;

  @media screen and (max-width: 500px) {
    margin-left: 24px;
  }

  @media screen and (max-width: 375px) {
    margin-left: 16px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-egpbe0" }) `
  width: 100%;
  max-height: 100vh;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.config.background.secondary};
  overflow: auto;
`;
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-f0plt0" }) `
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: calc(100vh - 160px);
  ${scrollMedium};
`;
