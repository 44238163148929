export const isSingleSelectValue = (value) => typeof value === 'object' &&
    value !== null &&
    'value' in value &&
    'label' in value;
export const isMultiSelectValue = (value) => {
    if (Array.isArray(value)) {
        if (value.length > 0) {
            return 'label' in value[0] && 'value' in value[0];
        }
        return true;
    }
    return false;
};
export const isSelectValue = (value) => isMultiSelectValue(value) || isSingleSelectValue(value);
export const getSelectValue = ({ value, options, onlyValue, isCreatable, }) => {
    var _a;
    if (!onlyValue && isSelectValue(value))
        return value;
    const getOption = (search) => {
        let option = options === null || options === void 0 ? void 0 : options.find((item) => !Array.isArray(item) && item.value === search);
        if (!option && isCreatable && search) {
            option = { label: search, value: search };
        }
        if (isSingleSelectValue(option))
            return option;
    };
    if (Array.isArray(value)) {
        return value === null || value === void 0 ? void 0 : value.map((item) => getOption(item));
    }
    return (_a = getOption(value)) !== null && _a !== void 0 ? _a : null;
};
export const downloadBlob = ({ data, filename, fileType = 'xlsx', }) => {
    var _a;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename + '.' + fileType);
    document.body.appendChild(link);
    link.click();
    (_a = link === null || link === void 0 ? void 0 : link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
};
