import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';
import { Text, TextVariant } from '../../../../../common';
import { SearchInput } from '../../../../../common/fields/search-input';
import { Icon } from '../../../../../common/icon';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import { ItemStatus, MailingModal, Table, } from '../../../../../components';
import { FilterMailing } from '../../../../../components/dropdown/filter-mailing';
import { DropdownMenu } from '../../../../../components/dropdown/menu';
import { ButtonTable, MailingType } from '../../../../../constants/enums';
import { useCallDeleteMutation, useCallListQuery, } from '../../../../../store/api/audio-clips';
import { useMeQuery } from '../../../../../store/api/profile';
import { formatId } from '../../../../../utils/formatId';
import { handleMutation } from '../../../../../utils/handleMutation';
import { audioClipMenuElements, AudioClipVariantMenuItems, } from '../audio-clips/config';
import { fakeData } from './config';
import * as Styles from './styles';
import { DeleteModal } from '../../../../../components/modal/delete-modal';
export const Mailing = ({ typeMailing }) => {
    const { data: me } = useMeQuery();
    const alert = useAlert();
    const { t } = useTranslation();
    const [filter, setFilter] = useState('');
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);
    const { data: callList, refetch } = useCallListQuery();
    const [requestDelete, responseDelete] = useCallDeleteMutation();
    const [data, setData] = useState([]);
    const [isDeleteMailingVisible, setIsDeleteMailingVisible] = useState(false);
    const handleDeleteMailingVisible = () => {
        setIsDeleteMailingVisible(!isDeleteMailingVisible);
        setSelectedElementIndex(null);
    };
    const [selectedItemMenuDelete, setSelectedItemMenuDelete] = useState();
    const getCallList = () => {
        if (me && me._id) {
            refetch();
        }
    };
    useEffect(() => {
        if (callList) {
            const response = callList;
            setData(response.map((call) => {
                const mockExtension = fakeData.find((i) => i.id == call.call_id % 10);
                return {
                    name: call.name,
                    audioclip_id: call.audioclip_id,
                    call_id: call.call_id,
                    phones: call.phones,
                    user_id: call.user_id,
                    price: call.price,
                    created: mockExtension.created,
                    started: mockExtension.started,
                    status: mockExtension.status,
                };
            }));
        }
    }, [callList]);
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseDelete), { onSuccess: getCallList, onError }));
    const [checkedRows, setCheckedRows] = useState([]);
    const debounceValue = useDebounce(filter, 500);
    const filteredData = useMemo(() => {
        return data.filter((row) => {
            return row.name.toLowerCase().includes(debounceValue.toLowerCase());
        });
    }, [debounceValue, data]);
    const handleRowChecked = (index) => {
        if (checkedRows.includes(index)) {
            setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
        }
        else {
            setCheckedRows([...checkedRows, index]);
        }
    };
    const handleAllRowsChecked = () => {
        const isAllChecked = checkedRows.length === filteredData.length;
        if (isAllChecked) {
            setCheckedRows([]);
        }
        else {
            setCheckedRows(filteredData.map((_, index) => index));
        }
    };
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };
    // const handleInDevelopment = () => {
    //   alert.success(t('In development'));
    // };
    const handleCreateMailing = () => {
        setCreateModalOpen(true);
    };
    const handleButtonPress = (index, element, action) => {
        switch (action) {
            case ButtonTable.Delete:
                const newData = filteredData.filter((_, i) => i !== index);
                setData(newData);
                break;
            case ButtonTable.Menu:
                setSelectedElementIndex((prevIndex) => prevIndex === index ? null : index);
                break;
        }
    };
    const handleRemoveCall = () => {
        if (selectedItemMenuDelete) {
            requestDelete({
                call_id: selectedItemMenuDelete.call_id.toString(),
                user_id: me._id.toString(),
            });
        }
    };
    const handleDropdownMenuItem = (action, id) => {
        const item = filteredData[+id];
        switch (action) {
            case AudioClipVariantMenuItems.Remove:
                setSelectedItemMenuDelete(item);
                handleDeleteMailingVisible();
                break;
            default:
                break;
        }
    };
    const handleDropdownMenu = (selectedId, row, tableRef) => {
        return (_jsx(DropdownMenu, { tableRef: tableRef, isVisible: true, dataItem: row, items: audioClipMenuElements, selectedId: selectedElementIndex === null || selectedElementIndex === void 0 ? void 0 : selectedElementIndex.toString(), setVisible: () => setSelectedElementIndex(null), onItemClick: handleDropdownMenuItem }));
    };
    return (_jsxs(Styles.Container, { children: [_jsxs(Styles.Header, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Mailing') })), _jsxs(Styles.SectionContainer, { children: [_jsx(Styles.ButtonCreateMailing, { padding: "24px", onClick: handleCreateMailing, text: t('Create') }), _jsx(FilterMailing, {})] })] }), _jsxs(Styles.Actions, { children: [_jsx(Styles.SectionContainer, {}), _jsx(Styles.SectionContainer, { children: _jsx(SearchInput, { search: filter, searchFilter: handleFilterChange }) })] }), _jsx(Styles.TableContainer, { children: _jsx(Table
                // isTertiary
                // hasItemCheckbox
                // hasHeaderCheckbox
                , { 
                    // isTertiary
                    // hasItemCheckbox
                    // hasHeaderCheckbox
                    icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.More, {}) })), checkedRows: checkedRows, onRowChecked: handleRowChecked, buttonAction: ButtonTable.Menu, handleButton: handleButtonPress, selectedElement: selectedElementIndex, onAllRowsChecked: handleAllRowsChecked, handleDropdownMenu: handleDropdownMenu, dataHeader: [
                        '№',
                        t('Name'),
                        t('Start Time'),
                        t('Number of phone numbers'),
                        t('Status'),
                        t('Cost'),
                    ], data: filteredData.map((row, index) => [
                        formatId(index + 1),
                        row.name,
                        row.started,
                        row.phones === '' ? '0' : row.phones.split(',').length.toString(),
                        _jsx(ItemStatus, { text: t(row.status.text), variant: row.status.variant }),
                        Number(row.price).toFixed(2) + ' ₽',
                    ]) }) }), _jsx(MailingModal, { trigger: getCallList, isModalOpen: isCreateModalOpen, setModalOpen: setCreateModalOpen, isAudio: typeMailing === MailingType.VOICE_ROBOT }), _jsx(DeleteModal, { isVisible: isDeleteMailingVisible, onClick: handleDeleteMailingVisible, message: 'Вы точно хотите удалить выбранную рассылку?', modalTitle: 'Удалить рассылку', buttonText: 'Да, удалить', deleteFunc: handleRemoveCall })] }));
};
