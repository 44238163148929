import { Button as ButtomComponent } from 'common';
import styled, { css } from 'styled-components';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
export const ContainerTable = styled.div.withConfig({ displayName: "ContainerTable", componentId: "sc-mg2y8o" }) `
  margin-top: 32px;
`;
export const ContainerItem = styled.div.withConfig({ displayName: "ContainerItem", componentId: "sc-4qo304" }) `
  display: flex;
  align-items: center;

  cursor: pointer;
`;
export const WrapperName = styled.div.withConfig({ displayName: "WrapperName", componentId: "sc-hh82fn" }) `
  margin-left: 19px;
`;
export const LeftContent = styled.div.withConfig({ displayName: "LeftContent", componentId: "sc-wapsuw" }) `
  gap: 16px;
  display: flex;
  align-items: center;
`;
export const NameCompany = styled.div.withConfig({ displayName: "NameCompany", componentId: "sc-mfi6qo" }) `
  display: flex;
  flex: 1;
  white-space: nowrap;
  align-items: center;

  gap: 16px;
`;
export const WrapperIcon = styled(IconWrapper).withConfig({ displayName: "WrapperIcon", componentId: "sc-mitulr" }) `
  ${({ theme }) => css `
    path,
    fill {
      fill: ${theme.config.slider.icon.secondary};
    }
  `}
`;
export const Button = styled(ButtomComponent).withConfig({ displayName: "Button", componentId: "sc-1rgz0wh" }) `
  flex: 0;
  margin: 5px 0px;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-61ev19" }) `
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;

  &:before {
    content: '';
    position: absolute;
    left: 5px;
    width: 0.5px;
    transform: translateY(60%);
    height: 25px;
    background-color: ${({ theme }) => theme.config.dropdown.border.default};
  }
`;
