import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../../common/icon-wrapper/icon-wrapper';
import * as Styles from './styles';
export const Dropdown = ({ top, left, show, items, button, footer, zIndex, isArrow, onChange, paddingRight, fullWidthItems, }) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(show !== null && show !== void 0 ? show : false);
    const toggleDropdown = () => {
        if (onChange) {
            onChange();
        }
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current &&
                !dropdownRef.current.contains(event.target)) {
                if (onChange) {
                    onChange();
                }
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleItemClick = (item) => {
        if (item.onClick) {
            item.onClick();
            setIsOpen(false);
        }
    };
    return (_jsxs(Styles.DropdownContainer, Object.assign({ "$zindex": zIndex, ref: dropdownRef, "$dropdownOpen": isOpen }, { children: [_jsxs(Styles.DropdownButton, Object.assign({ onClick: toggleDropdown }, { children: [button, isArrow && (_jsx(IconWrapper, Object.assign({ "$isCursor": true }, { children: _jsx(Styles.Arrow, { "$isOpen": isOpen }) })))] })), _jsxs(Styles.DropdownMenu, Object.assign({ "$open": isOpen, "$top": top, "$left": left, "$fullWidth": fullWidthItems }, { children: [_jsx(Styles.Wrapper, { children: items.map((item) => (_jsxs(Styles.DropdownMenuItem, Object.assign({ onClick: () => handleItemClick(item), "$paddingRight": paddingRight, "$rightIcon": !!item.rightIcon }, { children: [t(item.label), item.rightIcon] }), item.label))) }), footer] }))] })));
};
