import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1hv7sy6" }) `
  display: flex;
  padding: 40px;
  width: 80%;
  overflow-y: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1rp0tel" }) `
  padding: 10px 10px 10px 15px;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.background.tetriery};
`;
export const MailingTextContainer = styled.div.withConfig({ displayName: "MailingTextContainer", componentId: "sc-yeqa5a" }) `
  margin-top: 20px;
`;
export const Close = styled.div.withConfig({ displayName: "Close", componentId: "sc-fxgxiz" }) `
  position: absolute;
  top: 10%;
  right: 5%;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.modal.bg.default};
  cursor: pointer;
  transition: background 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.config.modal.bg.hover};
  }
`;
export const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "sc-1hu0eo3" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const ModalContainer = styled.div.withConfig({ displayName: "ModalContainer", componentId: "sc-124es12" }) `
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  overflow-y: auto;
  ${scrollMedium};

  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in, backdrop-filter 0.1s ease-in,
    visibility 0.4s ease-in;
  backdrop-filter: blur(10px);
`;
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-fpdnzn" }) `
  padding: 20px;
`;
