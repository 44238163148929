var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import * as Styles from './styles';
import { ButtonVariant } from './types';
export const Button = (_a) => {
    var { to, text, icon, onClick, padding, disabled, variant = ButtonVariant.PRIMARY } = _a, props = __rest(_a, ["to", "text", "icon", "onClick", "padding", "disabled", "variant"]);
    const navigate = useNavigate();
    const handleClick = (e) => {
        if (to) {
            navigate(to);
        }
        if (onClick) {
            onClick(e);
        }
    };
    return (_jsx("div", { children: _jsxs(Styles.StyledButton, Object.assign({ "$padding": padding, "$variant": variant, disabled: disabled, onClick: (e) => handleClick(e) }, props, { children: [icon && (_jsx(Styles.IconButton, Object.assign({ "$withText": !!(text === null || text === void 0 ? void 0 : text.length) }, { children: _jsx(IconWrapper, { children: icon }) }))), text && _jsx("span", { children: text })] })) }));
};
