import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TextVariant } from '../../../../../common';
import { Search } from '../../../../../common/icon/icons';
import { ConnectionModal, EMarketplaces } from '../connection-modal';
import { ETableType, MarketplaceTable } from '../table';
import * as Styles from './styles';
const mockTables = [
    {
        searchQuery: ETableType.SELLERS,
        columnName: 'Sellers',
        tableItems: [
            {
                id: 1,
                title: 'First Choice',
                revenue: 50286109,
            },
            {
                id: 2,
                title: 'АО "МЭЛОН ФЭШН ГРУП"',
                revenue: 43802386109,
            },
            {
                id: 3,
                title: 'ООО МПТрэйд',
                revenue: 565802386109,
            },
            {
                id: 4,
                title: 'ООО "КАРИ"',
                revenue: 112802386109,
            },
            {
                id: 5,
                title: 'ООО "ВБ РИТЕЙЛ"',
                revenue: 655680238610,
            },
            {
                id: 6,
                title: 'Aga da',
                revenue: 3432802386109,
            },
        ],
    },
    {
        searchQuery: ETableType.BRANDS,
        columnName: 'Brands',
        tableItems: [
            {
                id: 1,
                title: 'First Choice',
                revenue: 5802386109,
            },
            {
                id: 2,
                title: 'АО "МЭЛОН ФЭШН ГРУП"',
                revenue: 432386109,
            },
            {
                id: 3,
                title: 'ООО МПТрэйд',
                revenue: 565802386109,
            },
            {
                id: 4,
                title: 'ООО "КАРИ"',
                revenue: 112802386109,
            },
            {
                id: 5,
                title: 'ООО "ВБ РИТЕЙЛ"',
                revenue: 6556802386109,
            },
            {
                id: 6,
                title: 'Aga da',
                revenue: 3432802386109,
            },
        ],
    },
    {
        searchQuery: ETableType.CATEGORIES,
        columnName: 'Categories',
        tableItems: [
            {
                id: 1,
                categories: ['First Choice', 'First Choice', 'First Choice'],
                revenue: 5802386109,
            },
            {
                id: 2,
                categories: [
                    'АО "МЭЛОН ФЭШН ГРУП"',
                    'АО "МЭЛОН ФЭШН ГРУП"',
                    'АО "МЭЛОН ФЭШН ГРУП"',
                ],
                revenue: 43802386109,
            },
            {
                id: 3,
                categories: ['ООО МПТрэйд', 'ООО МПТрэйд', 'ООО МПТрэйд'],
                revenue: 565802386109,
            },
            {
                id: 4,
                categories: ['ООО "КАРИ"', 'ООО "КАРИ"', 'ООО "КАРИ"'],
                revenue: 112802386109,
            },
            {
                id: 5,
                categories: ['ООО "ВБ РИТЕЙЛ"', 'ООО "ВБ РИТЕЙЛ"', 'ООО "ВБ РИТЕЙЛ"'],
                revenue: 6556802386109,
            },
            {
                id: 6,
                categories: ['Aga da', 'Aga da', 'Aga da'],
                revenue: 3432802386109,
            },
        ],
    },
    {
        searchQuery: ETableType.PRODUCTS,
        columnName: 'Products',
        tableItems: [
            {
                id: 1,
                title: 'First Choice',
                revenue: 5802386109,
                image: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
            },
            {
                id: 2,
                title: 'АО "МЭЛОН ФЭШН ГРУП"',
                revenue: 43802386109,
                image: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
            },
            {
                id: 3,
                title: 'ООО МПТрэйд',
                revenue: 565802386109,
                image: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
            },
            {
                id: 4,
                title: 'ООО "КАРИ"',
                revenue: 11280386109,
                image: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
            },
            {
                id: 5,
                title: 'ООО "ВБ РИТЕЙЛ"',
                revenue: 6556802386109,
                image: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
            },
            {
                id: 6,
                title: 'Aga da',
                revenue: 3432802386109,
                image: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D',
            },
        ],
    },
];
const tabs = [
    {
        value: ETableType.BRANDS,
        label: 'Brands',
    },
    {
        value: ETableType.PRODUCTS,
        label: 'Products',
    },
    {
        value: ETableType.SELLERS,
        label: 'Sellers',
    },
    {
        value: ETableType.CATEGORIES,
        label: 'Categories',
    },
];
export const Wildberries = () => {
    var _a;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(ETableType.BRANDS);
    const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
    return (_jsxs(Styles.Container, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: "Wildberries" })) }), _jsx(Styles.Tabs, { children: tabs.map((tab) => (_jsx(Styles.TabItem, Object.assign({ onClick: () => setActiveTab(tab.value), "$isActive": tab.value === activeTab }, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: t(tab.label) })) }), tab.value))) }), _jsxs(Styles.Content, { children: [_jsx(Styles.SearchBarWrapper, { children: _jsx(Styles.SearchBar, { placeholder: t(`Search by ${(_a = tabs.find((tab) => tab.value === activeTab)) === null || _a === void 0 ? void 0 : _a.label}`), rightIcon: _jsx(Search, {}), onRightIconClick: () => void 0 }) }), _jsx(MarketplaceTable, { searchQuery: activeTab, columnName: tabs.find((tab) => tab.value === activeTab).label, tableItems: mockTables.find((table) => table.searchQuery == activeTab)
                            .tableItems }), _jsx(Styles.ConnectButton, { text: t('Connect shop'), onClick: () => setIsConnectionModalOpen(true) })] }), isConnectionModalOpen && (_jsx(ConnectionModal, { setIsOpen: setIsConnectionModalOpen, marketplace: EMarketplaces.WILDBERRIES }))] }));
};
