import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { Dropdown, NotificationDropdown } from 'components';
import { Balance } from 'components/balance';
import { AppRoutes } from 'config';
import { zIndex } from 'constants/enums';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { useMeQuery } from 'store/api/profile';
import { clearToken } from 'utils/storage';
import { BASE_URL } from '../../../../api/constants';
import DefaultAvatar from '../../../../assets/images/default-avatar-profile-icon.png';
import { ButtonVariant } from '../../../../common';
import IconWrapper from '../../../../common/icon-wrapper/icon-wrapper';
import { logout } from '../../../../store/thunks/auth/auth';
import { HelpModal } from './help-modal/HelpModal';
import * as Styles from './styles';
const BUTTONS = {
    Balance: 'Balance',
    Notification: '',
};
export const RightContent = () => {
    var _a, _b;
    const { Home, Profile } = AppRoutes.Dashboard;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data } = useMeQuery();
    const [isBalanceVisible, setBalanceVisible] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const onLogout = () => {
        clearToken();
        dispatch(logout());
    };
    const handleNotification = () => {
        setIsNotificationVisible(!isNotificationVisible);
    };
    const handleBalance = () => {
        setBalanceVisible(!isBalanceVisible);
    };
    return (_jsxs(Styles.Container, { children: [_jsx(HelpModal, { isModalOpen: isHelpModalOpen, setIsModalOpen: setIsHelpModalOpen }), _jsx(Styles.WrapperWallet, { children: _jsx(Balance, { left: -80, isVisible: isBalanceVisible, onClick: handleBalance, button: _jsxs(Styles.ContentWallet, Object.assign({ onClick: handleBalance }, { children: [_jsx(IconWrapper, Object.assign({ "$isCursor": true, "$isActive": isBalanceVisible }, { children: _jsx(Icon.Wallet, {}) })), _jsx(Styles.TextWallet, { children: t(BUTTONS.Balance) })] })), userBalance: (_a = data.balance) !== null && _a !== void 0 ? _a : 0, items: [
                        {
                            id: 0,
                            label: JSON.parse(data.tariff.toString()).name,
                            //TODO add coast here
                            coast: '12$ / месяц',
                        },
                    ] }) }), _jsxs(Styles.WrapperNotification, Object.assign({ "$isVisible": isNotificationVisible, onClick: () => setIsNotificationVisible(true) }, { children: [_jsx(NotificationDropdown, { isVisible: isNotificationVisible, onClick: handleNotification, button: _jsx(IconWrapper, Object.assign({ "$isCursor": true, "$isActive": isNotificationVisible }, { children: _jsx(Icon.Notification, {}) })) }), _jsx(IconWrapper, Object.assign({ "$isCursor": true }, { children: _jsx(Styles.Arrow, { "$isVisible": isNotificationVisible }) }))] })), _jsx(Dropdown, { hide: true, left: -100, zIndex: zIndex.Extra, button: !((_b = data === null || data === void 0 ? void 0 : data.avatar_link) === null || _b === void 0 ? void 0 : _b.includes('None')) ? (_jsx(Styles.Image, { width: 40, alt: "user", height: 40, objectFit: "cover", borderRadius: 80, 
                    // src={null}
                    src: (data === null || data === void 0 ? void 0 : data.avatar_link) && data.avatar_link !== 'none'
                        ? BASE_URL + '/v1/' + (data === null || data === void 0 ? void 0 : data.avatar_link)
                        : DefaultAvatar })) : (_jsx(Styles.EmptyImage, {})), items: [
                    {
                        label: t('Profile'),
                        onClick: () => {
                            navigate(Home + Profile.Home + Profile.PersonalData);
                        },
                    },
                    {
                        label: t('Help'),
                        onClick: () => {
                            setIsHelpModalOpen(true);
                        },
                    },
                ], footer: _jsx(Styles.WrapperButton, { children: _jsx(Styles.Button, { text: t('Logout'), onClick: onLogout, variant: ButtonVariant.LIGHT }) }) })] }));
};
