import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
const loaderSlice = createSlice({
    name: 'loader',
    initialState: { isLoading: false },
    reducers: {
        setLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
    },
});
const { reducer: loaderReducer, actions: { setLoading }, } = loaderSlice;
const loaderStore = (store) => store === null || store === void 0 ? void 0 : store.loader;
const selectIsLoading = createSelector([loaderStore], (store) => { var _a; return (_a = store === null || store === void 0 ? void 0 : store.isLoading) !== null && _a !== void 0 ? _a : false; });
export { loaderReducer, setLoading, selectIsLoading };
