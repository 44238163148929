import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { forwardRef, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAutoFocus } from '../../components/form/Form';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import * as Styles from './styles';
import { IconSize, InputColorType, InputSize, LabelSize, } from './types';
const InputContainer = styled.div.withConfig({ displayName: "InputContainer", componentId: "sc-k538v7" }) `
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${({ fullWidth }) => fullWidth &&
    css `
      flex-grow: 1;
    `}
`;
const InputComponent = ({ icon, type, value, error, label, onBlur, name, style, onChange, rightIcon, placeholder, onRightIconClick, disabled = false, withBorder = false, inputType = 'input', size = InputSize.DEFAULT, sizeIcon = IconSize.MEDIUM, sizeLabel = LabelSize.MEDIUM, colorType = InputColorType.DEFAULT, className, fullWidth, inputRef, onEnterPress, }) => {
    const [isPassword, setIsPassword] = useState(false);
    // @ts-ignore
    inputRef !== null && inputRef !== void 0 ? inputRef : (inputRef = useRef());
    useAutoFocus(name, inputRef === null || inputRef === void 0 ? void 0 : inputRef.current);
    const togglePassword = () => {
        setIsPassword(!isPassword);
    };
    const currentType = useMemo(() => {
        if (type === 'password') {
            return isPassword ? 'text' : 'password';
        }
        return type;
    }, [type, isPassword]);
    return (_jsxs(InputContainer, Object.assign({ className: className, fullWidth: fullWidth }, { children: [label && _jsx(Styles.Label, Object.assign({ "$sizeLabel": sizeLabel }, { children: label })), _jsxs(Styles.InputWrapper, { children: [inputType === 'textarea' ? (_jsx(Styles.TextArea, { ref: inputRef, name: name, style: style, value: value, disabled: disabled, "$colorType": colorType, placeholder: placeholder, onChange: (e) => onChange && onChange(e) })) : (_jsx(Styles.StyledInput, { ref: inputRef, name: name, "$isIcon": !!icon, value: value, style: style, onBlur: onBlur, "$sizeType": size, type: currentType, disabled: disabled, autoComplete: "off", "$colorType": colorType, "$withBorder": withBorder, placeholder: placeholder, "$rightIcon": !!rightIcon || type === 'password', onChange: (e) => onChange && onChange(e), onKeyDown: (e) => {
                            if (e.key === 'Enter' && onEnterPress) {
                                onEnterPress();
                            }
                        } })), icon && (_jsx(Styles.LeftIcon, Object.assign({ "$sizeIcon": sizeIcon }, { children: _jsx(IconWrapper, { children: icon }) }))), rightIcon && (_jsx(Styles.RightIcon, Object.assign({ "$sizeIcon": sizeIcon, "$isClickable": !!onRightIconClick, onClick: () => {
                            if (onRightIconClick) {
                                onRightIconClick();
                            }
                        } }, { children: _jsx(IconWrapper, { children: rightIcon }) }))), type === 'password' && (_jsx(Styles.IconEyes, Object.assign({ onClick: togglePassword }, { children: isPassword ? (_jsx(IconWrapper, { children: _jsx(Icon.EyesOn, {}) })) : (_jsx(IconWrapper, { children: _jsx(Icon.EyesOff, {}) })) })))] }), _jsx(Styles.ErrorContainer, { children: _jsxs(Styles.Error, { children: [" ", error] }) })] })));
};
export const Input = forwardRef((props, ref) => (_jsx(InputComponent, Object.assign({}, props, { inputRef: ref }))));
