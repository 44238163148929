import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProtectedRoute } from 'common';
import { AppLayout } from 'components/app-layout';
import { Layout } from 'components/layout';
import { AppRoutes } from 'config/routes';
import i18n from 'i18n';
import { Auth } from 'pages/auth';
import { Dashboard } from 'pages/dashboard';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { selectIsLoggedIn } from 'store/slices/auth';
import { setLocale } from 'yup';
import * as Locales from 'yup-locales';
import * as Styles from './styles';
import { AuthGateway } from '../components/auth-gateway/AuthGateway';
const i18nLocaleName = i18n.language;
const locale = Locales[i18nLocaleName || 'ru'];
setLocale(locale);
function App() {
    const isLoggedIn = useSelector(selectIsLoggedIn());
    return (_jsx(Styles.Wrap, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "*", element: _jsx(ProtectedRoute, Object.assign({ allowed: isLoggedIn, 
                        // allowed={true}
                        redirect: AppRoutes.Auth.Home + AppRoutes.Auth.Login }, { children: _jsx(AppLayout, { children: _jsx(AuthGateway, { children: _jsx(Dashboard, {}) }) }) })) }), _jsx(Route, { path: AppRoutes.Auth.Home + '/*', element: _jsx(ProtectedRoute, Object.assign({ allowed: !isLoggedIn, redirect: AppRoutes.Dashboard.Home }, { children: _jsx(Layout, { children: _jsx(Auth, {}) }) })) }), _jsx(Route, { path: AppRoutes.Dashboard + '/*', element: _jsx(ProtectedRoute, Object.assign({ allowed: isLoggedIn, redirect: AppRoutes.Auth.Home + AppRoutes.Auth.Login }, { children: _jsx(AppLayout, { children: _jsx(Dashboard, {}) }) })) })] }) }));
}
export default App;
