import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { SearchInput } from '../../../../../../common/fields/search-input';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { Table } from '../../../../../../components';
import { formatId } from '../../../../../../utils/formatId';
import { Actions, Header, SectionContainer, TableContainer, } from '../../mailingHistory/styles';
import * as Styles from './styles';
export const MailingInfoModal = ({ isModalOpen, setModalOpen, initialValues, historyData, }) => {
    const [data, setData] = useState([]);
    const closeModal = () => {
        setModalOpen(false);
    };
    const [checkedRows, setCheckedRows] = useState([]);
    const handleRowChecked = (index) => {
        if (checkedRows.includes(index)) {
            setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
        }
        else {
            setCheckedRows([...checkedRows, index]);
        }
    };
    const [filter, setFilter] = useState('');
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };
    const debounceValue = useDebounce(filter, 500);
    const dataAfterSearch = useMemo(() => {
        return data.filter((chip) => {
            return chip.value.toLowerCase().includes(debounceValue.toLowerCase());
        });
    }, [debounceValue, data]);
    // const [filteredData, setFilteredData] = useState(dataAfterSearch);
    useEffect(() => {
        const result = [];
        historyData === null || historyData === void 0 ? void 0 : historyData.historyChips.forEach((chip) => {
            result.push(chip);
        });
        setData(result);
    }, [historyData]);
    const handleAllRowsChecked = () => {
        const isAllChecked = checkedRows.length === dataAfterSearch.length;
        if (isAllChecked) {
            setCheckedRows([]);
        }
        else {
            setCheckedRows(dataAfterSearch.map((_, index) => index));
        }
    };
    return (_jsx(Formik, Object.assign({ initialValues: {
            filters: {
                status: [],
            },
        }, onSubmit: () => void 0 }, { children: () => {
            // useEffect(() => {
            //   setFilteredData(
            //     dataAfterSearch.filter((recipient: IHistoryChips) => {
            //       const mailingStatus = recipient.status.text.toLowerCase();
            //       const lowercaseStatusValues = values.filters.status?.map(
            //         (filterValue: { value: string; label: string }) =>
            //           filterValue.value.toLowerCase()
            //       );
            //       if (
            //         lowercaseStatusValues?.length > 0 &&
            //         !lowercaseStatusValues.includes(mailingStatus)
            //       ) {
            //         return false;
            //       }
            //       return true;
            //     })
            //   );
            // }, [values.filters.status, dataAfterSearch]);
            return (_jsx("div", { children: _jsxs(Styles.ModalContainer, Object.assign({ "$isVisible": isModalOpen }, { children: [_jsxs(Styles.Container, { children: [_jsx(Header, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: initialValues.modalName })) }), _jsxs(Styles.MailingTextContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: "\u0422\u0435\u043A\u0441\u0442 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438" })), _jsx(Styles.TextContainer, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: historyData === null || historyData === void 0 ? void 0 : historyData.mailingText })) })] }), _jsxs(Actions, { children: [_jsx(SectionContainer, {}), _jsx(Styles.SectionContainer, { children: _jsx(SearchInput, { search: filter, searchFilter: handleFilterChange }) })] }), _jsx(TableContainer, { children: _jsx(Table, { isTertiary: true, hasItemCheckbox: true, hasHeaderCheckbox: true, icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.More, {}) })), checkedRows: checkedRows, onRowChecked: handleRowChecked, onAllRowsChecked: handleAllRowsChecked, 
                                        // dataHeader={['№', t(initialValues.columnName), t('Status')]}
                                        data: dataAfterSearch.map((row, index) => [
                                            formatId(index + 1),
                                            row.value,
                                            // <ItemStatus
                                            //   text={t(row.status.text)}
                                            //   variant={row.status.variant}
                                            // />,
                                        ]) }) })] }), _jsx(Styles.Close, Object.assign({ onClick: closeModal }, { children: _jsx(IconWrapper, { children: _jsx(Icon.Close, {}) }) }))] })) }));
        } })));
};
