import styled, { css } from 'styled-components';
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1l4itzx" }) `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ProgressBar = styled.div.withConfig({ displayName: "ProgressBar", componentId: "sc-1ypk5qd" }) `
  background: #6c6c6c;
  height: 5px;
  width: 100%;
  border-radius: 2px;
`;
export const Progress = styled.div.withConfig({ displayName: "Progress", componentId: "sc-fhp3nf" }) `
  height: 5px;
  border-radius: 2px;
  width: ${(props) => (props.$progressValue && props.$progressValue) || 5}%;
  ${({ $progressValue }) => {
    if ($progressValue > 60) {
        return css `
        background: #3dcc4a;
      `;
    }
    if ($progressValue >= 25) {
        return css `
        background: orange;
      `;
    }
    if ($progressValue < 25) {
        return css `
        background: red;
      `;
    }
}}
`;
