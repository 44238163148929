import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AvitoPage } from './avito';
import { HomePage } from './home';
import { VkontaktePage } from './vkontakte';
import { YandexParserPage } from './yandex';
export const ParsingContent = () => {
    const { Avito, Vkontakte, Yandex } = AppRoutes.Dashboard.Parsing;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: '/*', element: _jsx(HomePage, {}) }), _jsx(Route, { path: Avito, element: _jsx(AvitoPage, {}) }), _jsx(Route, { path: Yandex, element: _jsx(YandexParserPage, {}) }), _jsx(Route, { path: Vkontakte.Home + '/*', element: _jsx(VkontaktePage, {}) })] }));
};
