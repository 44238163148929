import * as Yup from 'yup';
import { InputMasks } from 'common/fields/input-field';
import { InputSize, IconSize, LabelSize } from 'common/input/types';
export const model = {
    numberCard: {
        type: 'text',
        name: 'numberCard',
        label: 'Number card',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.card,
        placeholder: '1234 5678 9012 3456',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    holderCard: {
        type: 'text',
        name: 'holderCard',
        label: 'Holder card',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'IVAN IVANOV',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    expirationDate: {
        type: 'text',
        name: 'date',
        label: 'Month/year',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.date,
        placeholder: 'MM/YY',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    cvv: {
        type: 'text',
        name: 'cvv',
        label: 'CVC/CCV',
        withBorder: false,
        size: InputSize.SMALL,
        mask: InputMasks.cvv,
        placeholder: 'CVV',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
};
export const validationSchema = (t) => Yup.object().shape({
    [model.numberCard.name]: Yup.string()
        .required(t('\nThe card number is missing'))
        .matches(/^([0-9]{4}\s){3}[0-9]{4}$/, t('Invalid card number')),
    [model.expirationDate.name]: Yup.string()
        .required(t('\nThere is no expiration date of the card'))
        .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, t('Invalid date characters')),
    [model.holderCard.name]: Yup.string()
        .required(t('\nHolder card is missing'))
        .matches(/^[A-Za-z\s]+$/, t('Invalid holder name')),
    [model.cvv.name]: Yup.string()
        .required(t('\nCVV is missing'))
        .matches(/^\d{3,4}$/, t('CVV invalid')),
});
export const initialValues = () => {
    return {
        [model.cvv.name]: '',
        [model.expirationDate.name]: '',
        [model.numberCard.name]: '',
        [model.holderCard.name]: '',
    };
};
