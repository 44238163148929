var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { useFormikContext } from 'formik';
import { useTheme } from 'styled-components';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { model, } from '../../../../../../pages/dashboard/content/voice-robot/mailing/config';
import { isValidPhone } from '../database-number';
import * as Styles from './styles';
export const ManualForm = () => {
    const theme = useTheme();
    const { setFieldValue, values } = useFormikContext();
    return (_jsx(Styles.Scroll, { children: _jsx(Styles.TextAreaContainer, { children: _jsx(Styles.TextAreaSection, { children: _jsx(_Fragment, { children: _jsxs(Styles.PhoneNumbers, { children: [_jsxs(Styles.WrapperField, { children: [_jsx("div", Object.assign({ onKeyDown: (event) => __awaiter(void 0, void 0, void 0, function* () {
                                            if (event.key === 'Enter' || event.code === 'NumpadEnter') {
                                                if (values.manualInput.length) {
                                                    const temp = new Set();
                                                    const a = values.manualInput
                                                        .replaceAll(' ', ',')
                                                        .replaceAll('/', ',')
                                                        .replaceAll('.', ',')
                                                        .replaceAll('\\', ',')
                                                        .replaceAll('\n', '')
                                                        .split(',')
                                                        .filter((str) => {
                                                        if (str.length &&
                                                            str &&
                                                            !values.manualPhones.find((value) => value === str)) {
                                                            if (str.length &&
                                                                Number.isInteger(Number(str)) &&
                                                                isValidPhone(str)) {
                                                                temp.add(str);
                                                                return 0;
                                                            }
                                                            return 1;
                                                        }
                                                        return 0;
                                                    });
                                                    setFieldValue('manualPhones', [
                                                        ...values.manualPhones,
                                                        ...temp,
                                                    ]);
                                                    setFieldValue('manualInput', a.join(', '));
                                                }
                                            }
                                        }) }, { children: _jsx(Styles.PhoneInput, Object.assign({ rightIcon: _jsx(IconWrapper, { children: _jsx(Icon.Delete, {}) }), onRightIconClick: () => setFieldValue('manualInput', '') }, model.phoneNumbers, { name: `manualInput` })) })), _jsx(Styles.RemovePhoneNumberButton, { text: "", icon: _jsx(IconWrapper, Object.assign({ "$strokeColor": theme.config.text.tetriery }, { children: _jsx(Icon.Add, {}) })), variant: ButtonVariant.CANCEL, onClick: () => {
                                            if (values.manualInput.length) {
                                                const temp = new Set();
                                                const a = values.manualInput
                                                    .replaceAll(' ', ',')
                                                    .replaceAll('/', ',')
                                                    .replaceAll('.', ',')
                                                    .replaceAll('\\', ',')
                                                    .replaceAll('\n', '')
                                                    .split(',')
                                                    .filter((str) => {
                                                    if (str.length &&
                                                        str &&
                                                        !values.manualPhones.find((value) => value === str)) {
                                                        if (str.length &&
                                                            Number.isInteger(Number(str)) &&
                                                            isValidPhone(str)) {
                                                            temp.add(str);
                                                            return 0;
                                                        }
                                                        return 1;
                                                    }
                                                    return 0;
                                                });
                                                setFieldValue('manualPhones', [
                                                    ...values.manualPhones,
                                                    ...temp,
                                                ]);
                                                setFieldValue('manualInput', a.join(', '));
                                            }
                                        } })] }), !!values.manualPhones.length && (_jsx(Styles.PhonesChips, { children: values.manualPhones.map((number) => (_jsxs(Styles.PhoneChip, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: number })), _jsx(Styles.PhoneChipButton, { text: "", onClick: () => setFieldValue('manualPhones', values.manualPhones.filter((n) => n !== number)), icon: _jsx(Icon.Close, {}), variant: ButtonVariant.TRANSPARENT })] }, number))) }))] }) }) }) }) }));
};
