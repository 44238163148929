import styled from 'styled-components';
export const ShowScheduleContainer = styled.div.withConfig({ displayName: "ShowScheduleContainer", componentId: "sc-j3z4rp" }) `
  display: flex;
  flex-direction: column;
`;
export const TimezonesContainer = styled.div.withConfig({ displayName: "TimezonesContainer", componentId: "sc-1vw757l" }) `
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
`;
export const DisplayIntervalsWrapper = styled.div.withConfig({ displayName: "DisplayIntervalsWrapper", componentId: "sc-1a9peej" }) `
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
export const HolidaysContainer = styled.div.withConfig({ displayName: "HolidaysContainer", componentId: "sc-1o2kxra" }) `
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
export const ManualHolidayTime = styled.div.withConfig({ displayName: "ManualHolidayTime", componentId: "sc-dbwjtx" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`;
export const DisplayIntervalsContainer = styled.div.withConfig({ displayName: "DisplayIntervalsContainer", componentId: "sc-1gnspkl" }) `
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grey.primary};
`;
export const AddIntervalButton = styled.div.withConfig({ displayName: "AddIntervalButton", componentId: "sc-1349mh" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
`;
export const IntervalItem = styled.div.withConfig({ displayName: "IntervalItem", componentId: "sc-hwof5x" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.primary};
  gap: 15px;
`;
export const IntervalTimeContainer = styled.div.withConfig({ displayName: "IntervalTimeContainer", componentId: "sc-1ijzmox" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  gap: 10px;
`;
