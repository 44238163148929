import { validationSchema as individualValidationSchema } from './forms/individual/config';
import { validationSchema as legalValidationSchema } from './forms/legal/config';
import { IndividualForm } from './forms/individual';
import { LegalForm } from './forms/legal';
export const RegisterTypes = {
    Individual: 'individual',
    Legal: 'legal',
};
export const TabItems = [
    {
        id: RegisterTypes.Individual,
        label: 'Individual',
        Content: IndividualForm,
    },
    {
        id: RegisterTypes.Legal,
        label: 'Legal Entity',
        Content: LegalForm,
    },
];
export const validationSchema = ({ t, tab }) => {
    if (tab === RegisterTypes.Individual) {
        return individualValidationSchema(t);
    }
    else if (tab === RegisterTypes.Legal) {
        return legalValidationSchema(t);
    }
    return null;
};
