import styled, { css } from 'styled-components';
import { smallSemiBold, ultraSmallMedium } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-19ltxvj" }) `
  position: relative;
`;
export const IconClose = styled.div.withConfig({ displayName: "IconClose", componentId: "sc-1b2nhwa" }) `
  width: 48px;
  height: 48px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.config.button.secondary.background.default};

  position: absolute;
  right: -58px;

  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.config.button.secondary.background.hover};
  }

  ${({ theme }) => css `
      box-shadow: ${theme.isDarkTheme
    ? '0px 8px 16px rgba(0, 0, 0, 0.05)'
    : ''};
      border: ${theme.isDarkTheme ? '1px solid #e8ecef' : ''};
      padding: ${theme.isDarkTheme ? '11px' : '12px'};
    `}
`;
export const TabHeader = styled.header.withConfig({ displayName: "TabHeader", componentId: "sc-1s6g5zt" }) `
  display: flex;
  margin: 0 10px;
  position: relative;

  border-radius: 12px;

  background-color: ${({ theme }) => theme.config.tab.background.primary};
`;
export const TabLink = styled.div.withConfig({ displayName: "TabLink", componentId: "sc-177kpq3" }) `
  flex: 1;
  padding: 8px;
  margin: 4px;
  text-align: center;
  text-decoration: none;

  text-align: center;
  letter-spacing: -0.02em;

  transition: transform 0.3s ease-out;

  &:hover {
    text-decoration: none;
  }

  ${({ $isActive }) => $isActive &&
    css `
      position: relative;
      z-index: 1;
    `};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      padding: 6px;
      ${ultraSmallMedium}
    `}
`;
export const TabLinkLabel = styled.div.withConfig({ displayName: "TabLinkLabel", componentId: "sc-91q02n" }) `
  ${smallSemiBold}

  z-index: 10;
  cursor: pointer;
  color: ${({ theme }) => theme.config.text.primary};

  ${({ $isActive }) => $isActive &&
    css `
      color: ${({ theme }) => theme.config.text.primary};
      transition: color 0.25s ease-out;
    `};
`;
export const TabBackground = styled.div.withConfig({ displayName: "TabBackground", componentId: "sc-14w5t3m" }) `
  position: absolute;

  width: 50%;
  height: 40px;
  border-radius: ${({ theme }) => (theme.responsive.isMobile ? '10px' : '8px')};
  transform: translate(
    98%,
    ${({ theme }) => (theme.responsive.isMobile ? '5%' : '10%')}
  );
  background: ${({ theme }) => theme.config.tab.background.secondary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07),
    inset 0px 1px 2px rgba(255, 255, 255, 0.02);

  transition: transform 0.3s ease-out;

  ${({ $isActive, theme }) => $isActive &&
    css `
      border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
      transform: translate(2%, ${theme.responsive.isMobile ? '5%' : '10%'});
      transition: transform 0.3s ease-out;
    `}
`;
