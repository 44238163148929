export const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
export const BASE_AI_URL = process.env.REACT_APP_SERVER_AI_URL;
export const BASE_VOICE_ROBOT_URL = process.env.REACT_APP_SERVER_VOICE_ROBOT_URL;
export const BASE_PARSER_URL = process.env.REACT_APP_SERVER_PARSER_URL;
export const BASE_MAILING_URL = process.env.REACT_APP_SERVER_PARSER_URL;
export const BASE_MAILING_HISTORY_URL = process.env.REACT_APP_SERVER_PARSER_URL;
export const SECRET_KEY = process.env.REACT_APP_CRYPTO_KEY || 'secret';
export const BASE_AUDIO_CLIPS_URL = process.env.REACT_APP_SERVER_BASE_URL;
export const BASE_COMPANIES_URL = process.env.REACT_APP_SERVER_BASE_URL;
export const BASE_NOTIFICATION_URL = process.env.REACT_APP_SERVER_BASE_URL;
export const BASE_BALANCE_URL = process.env.REACT_APP_SERVER_BALANCE_URL;
export const URLS = {
    ME: 'v1/users/me/',
    LOGIN: 'v1/users/login/',
    UPDATE_AVATAR: 'v1/media/me/avatar/',
    RESET_PASSWORD: 'v1/users/reset_password/',
    FORGOT_PASSWORD: 'v1/users/forgot_password/',
    REGISTER_PERSONAL: 'v1/users/private_person/register/',
    REGISTER_LEGAL: 'v1/users/legal_person/register/',
    USER_SELECT_COMPANY: '/v1/users/select_company/',
    USER_UPDATE_TARIFF: '/v1/users/update_tariff/',
    NEW_TICKET: '/v1/users/new_ticket/',
    ACTIVATE_EMAIL: '/v1/users/activate/',
    CHECK_INN: '/v1/users/check_inn/',
    CHECK_INN_LOCAL: '/v1/users/check_inn_local/',
    DATABASE_LIST: 'v1/parsers/retrieve_user_parsers',
    DATABASE_BY_ID: 'v1/parsers/retrieve_user_parsers/',
    DATABASE_DOWNLOAD: 'v1/parsers/excel/create_excel',
    DATABASE_DELETE_ID: 'v1/parsers/delete_user_parser_by_id',
    DATABASE_DELETE_BULK_PARSER: 'v1/parsers/delete_user_parsers_by_ids/',
    PARSER_AVITO: 'v1/parsers/avito/get_data',
    PARSER_FILTERS: 'v1/parsers/get_filters/',
    PARSER_YANDEX: 'v1/parsers/yandex/get_organizations',
    PARSER_VK_GROUPS: 'v1/parsers/vk/get_groups',
    PARSER_VK_POSTS: 'v1/parsers/vk/get_posts',
    MAILING_TELEGRAM: 'v1/parsers/newsletter/send_telegram',
    MAILING_EMAIL: 'v1/parsers/newsletter/send_email',
    MAILING_VIBER: 'v1/parsers/newsletter/send_viber',
    MAILING_WHATSAPP: 'v1/parsers/newsletter/send_whatsapp',
    GET_MAILING_HISTORY: 'v1/parsers/newsletter/get_history',
    DELETE_MAILING: 'v1/parsers/newsletter/delete_newsletter',
    AI_IMAGE_GENERATE: 'image_generate',
    AI_DESCRIBE_IMAGE: 'describe_image',
    AI_GENERATE_POST: 'generate_post',
    AI_GENERATE_MUSIC: 'generate_music',
    AI_AUDIO_TRANSLATE: 'audio_translate',
    AI_MAKE_REQUEST: 'make_request',
    AI_GET_REQUESTS_HISTORY: 'get_requests_history',
    AI_CLEAR_HISTORY: 'clear_history',
    PROJECT_CREATE: 'api/v1/projects/create',
    VOICE_ROBOT_AUDIO_CREATE: '/audio_create',
    AUDIO_CLIPS_CLIP_LIST: '/v1/audioclips/clip_list/',
    AUDIO_CLIPS_CLIP_CREATE: '/v1/audioclips/clip_create',
    AUDIO_CLIPS_CLIP_DELETE: '/v1/audioclips/clip_delete/',
    AUDIO_CLIPS_CALL_LIST: '/v1/audioclips/call_list/',
    AUDIO_CLIPS_CALL_CREATE: '/v1/audioclips/call_create/',
    AUDIO_CLIPS_CALL_DELETE: '/v1/audioclips/call_delete/',
    AUDIO_CLIPS_CALL_GET: '/v1/audioclips/clip_get',
    COMPANIES_LIST: '/v1/companies/companies_list/',
    COMPANIES_MAINPAGE_LIST: '/v1/companies/companies_main/',
    COMPANIES_CREATE_COMPANY: '/v1/companies/companies_create/',
    COMPANIES_UPDATE_COMPANY: '/v1/companies/companies_update/',
    COMPANIES_DELETE_COMPANY: '/v1/companies/companies_delete/',
    COMPANIES_GET_COMPANY: '/v1/companies/companies_get/',
    NOTIFICATION_LIST: '/v1/notifications/notification_list/',
    NOTIFICATION_ADD: '/v1/notifications/notification_add/',
    NOTIFICATION_DELETE: '/v1/notifications/notification_delete/',
    NOTIFICATION_CLEAR: '/v1/notifications/notifications_clear/',
    REFILL_BALANCE: '/api/v1/payments/create_payment/',
};
