import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BackImage from 'assets/images/background-login.jpg';
import { AppRoutes } from 'config';
import { HeaderTabs } from 'pages/auth/header-tabs';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from './login/login-form';
import { RecoverPasswordForms } from './recovery-password';
import { RegisterPage } from './register/register-form';
import * as Styles from './styles';
export const Auth = () => {
    const { Home, Login, SignUp, ForgotPassword } = AppRoutes.Auth;
    return (_jsx(Styles.Container, Object.assign({ img: BackImage }, { children: _jsx(Styles.ColorContainer, { children: _jsxs(Routes, { children: [_jsx(Route, { path: Login, element: _jsx(Styles.FormContainerWrapper, { children: _jsxs(Styles.ContentForm, { children: [_jsx(Styles.ContainerHeader, { children: _jsx(HeaderTabs, {}) }), _jsx(LoginPage, {})] }) }) }), _jsx(Route, { path: SignUp, element: _jsx(Styles.FormContainerWrapper, { children: _jsxs(Styles.ContentForm, { children: [_jsx(Styles.ContainerHeader, { children: _jsx(HeaderTabs, {}) }), _jsx(RegisterPage, {})] }) }) }), _jsx(Route, { path: ForgotPassword, element: _jsx(Styles.FormContainerWrapper, { children: _jsx(RecoverPasswordForms, {}) }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: Home + Login }) })] }) }) })));
};
