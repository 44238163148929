import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-15zjmg9" }) `
  height: calc(100vh - 150px);
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  padding: 0px 40px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-1bnfxcg" }) `
  width: 100%;
  padding: 0px 28px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  max-height: 100vh;
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1uzyhgx" }) `
  flex: 1;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  height: 100%;
`;
export const ContainerForm = styled.div.withConfig({ displayName: "ContainerForm", componentId: "sc-1l7ovrw" }) `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
