import * as Yup from 'yup';
import { InputSize, IconSize, LabelSize } from 'common/input/types';
export const model = {
    numberQiwi: {
        type: 'text',
        name: 'numberQiwi',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Number QIWI',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
    email: {
        type: 'email',
        name: 'email',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'E-mail',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
};
export const validationSchema = (t) => Yup.object().shape({
    [model.email.name]: Yup.string()
        .required()
        .email(t('Invalid E-Mail format')),
    [model.numberQiwi.name]: Yup.string()
        .required(t('Number QIWI is required'))
        .matches(/^\d{10}$/, t('Number QIWI must be 10 digits')),
});
export const initialValues = () => {
    return {
        [model.email.name]: '',
        [model.numberQiwi.name]: '',
    };
};
