import { createSelector, createSlice } from '@reduxjs/toolkit';
import { profileApi } from 'store/api/profile';
import { logout } from '../thunks/auth/auth';
const initialState = {
    data: null,
};
export const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(logout, (state) => {
            state.data = null;
        });
        builder.addMatcher(profileApi.endpoints.me.matchFulfilled, (state, action) => {
            return Object.assign(Object.assign({}, state), { data: action.payload });
        });
    },
});
export const selectProfileData = (state) => state === null || state === void 0 ? void 0 : state.profileSlice.data;
export const selectMeData = createSelector([selectProfileData], (profileData) => profileData === null || profileData === void 0 ? void 0 : profileData.me);
