import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { Button, ButtonVariant, Text, TextVariant, } from '../../../../../common';
import { SearchInput } from '../../../../../common/fields/search-input';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import { Table, } from '../../../../../components';
import { DropdownMenu } from '../../../../../components/dropdown/menu';
import { ButtonTable } from '../../../../../constants/enums';
import { VariantMenuItems } from '../../../../../constants/templates';
import { useDeleteMailingMutation, useGetHistoryMutation, } from '../../../../../store/api/mailing-history';
import { useMeQuery } from '../../../../../store/api/profile';
import { formatId } from '../../../../../utils/formatId';
import { Filter } from '../filter-mailing';
import { MailingInfoModal } from '../modal/mailingMoreInfo';
import { mailingHistoryFilterModel, mailingHistoryFiltersInitialValues, menuElements, } from './config';
import * as Styles from './styles';
import { handleMutation } from '../../../../../utils/handleMutation';
import { DeleteModal } from '../../../../../components/modal/delete-modal';
const EMPTY_HISTORY = [];
export const MailingHistory = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const { t } = useTranslation();
    const { data: user } = useMeQuery();
    const [changeHistory, mutationHistory] = useGetHistoryMutation();
    const [deleteMailing, mutationDelete] = useDeleteMailingMutation();
    const [filters, setFilters] = useState(mailingHistoryFiltersInitialValues);
    const [searchFilter, setSearchFilter] = useState('');
    const [isMoreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);
    const [selectedMailing, setSelectedMailing] = useState();
    const [checkedRows, setCheckedRows] = useState([]);
    const [rowsForDelete, setRowsForDelete] = useState(0);
    const [isDeleteMailingVisible, setIsDeleteMailingVisible] = useState(false);
    const handleDeleteMailingVisible = () => {
        setIsDeleteMailingVisible(!isDeleteMailingVisible);
    };
    const [selectedItemMenuDelete, setSelectedItemMenuDelete] = useState();
    const [isDeleteMailingMenuVisible, setIsDeleteMailingMenuVisible] = useState(false);
    const handleDeleteMailingMenuVisible = () => {
        setIsDeleteMailingMenuVisible(!isDeleteMailingMenuVisible);
    };
    const debounceValue = useDebounce(searchFilter, 500);
    useEffect(() => {
        if (user && user._id) {
            changeHistory({
                user_id: user === null || user === void 0 ? void 0 : user._id,
            });
        }
    }, [user]);
    const handleDeleteMailing = () => {
        if (user && user._id && selectedItemMenuDelete) {
            deleteMailing({
                user_id: user._id.toString(),
                newsletter_ids: selectedItemMenuDelete.toString(),
            });
        }
    };
    const onSuccess = () => {
        if (rowsForDelete > 1) {
            alert.success(t('Successfully deleted mailings: ') + rowsForDelete);
        }
        else {
            alert.success(t('Mailing deleted successfully'));
        }
        setRowsForDelete(0);
        if (user && user._id) {
            changeHistory({
                user_id: user._id,
            });
        }
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
            setRowsForDelete(0);
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationDelete), { onSuccess,
        onError }));
    const uploadHistoryData = (response) => {
        const result = [];
        response.map((mail) => {
            const recipients = mail.users_to.split(';');
            result.unshift({
                type: mail.type,
                date: mail.date,
                mailingId: mail._id,
                totalRecipients: recipients.length,
                mailingData: {
                    historyChips: recipients.map((label) => ({
                        label: label.toString(),
                        value: label.toString(),
                    })),
                    mailingName: mail.name,
                    mailingText: mail.text,
                },
            });
        });
        return result;
    };
    const mailingHistoryData = useMemo(() => uploadHistoryData(mutationHistory.data || EMPTY_HISTORY), [mutationHistory.data]);
    const dataAfterSearch = useMemo(() => {
        return mailingHistoryData.filter((row) => row.mailingData.mailingName
            .toLowerCase()
            .includes(debounceValue.toLowerCase()));
    }, [debounceValue, mailingHistoryData]);
    const [filteredData, setFilteredData] = useState(dataAfterSearch);
    const handleRowChecked = (index) => {
        if (checkedRows.includes(index)) {
            setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
        }
        else {
            setCheckedRows([...checkedRows, index]);
        }
    };
    const handleCreateMailing = () => {
        setMoreInfoModalOpen(true);
    };
    const handleAllRowsChecked = () => {
        const isAllChecked = checkedRows.length === filteredData.length;
        if (isAllChecked) {
            setCheckedRows([]);
        }
        else {
            setCheckedRows(filteredData.map((_, index) => index));
        }
    };
    const handleFilterChange = (event) => {
        setSearchFilter(event.target.value);
    };
    const handleDeleteButton = () => {
        const newsletter_ids = [];
        checkedRows.forEach((value) => {
            newsletter_ids.push(filteredData[value].mailingId);
        });
        setRowsForDelete(newsletter_ids.length);
        if (user && user._id) {
            deleteMailing({
                user_id: user._id.toString(),
                newsletter_ids: newsletter_ids.join(','),
            });
        }
        setCheckedRows([]);
    };
    const handleButtonPress = (index, element, action) => {
        switch (action) {
            case ButtonTable.Menu:
                setSelectedElementIndex((prevIndex) => prevIndex === index ? null : index);
                break;
        }
    };
    const handleRepeatButton = (type, row) => {
        const options = { state: { data: row } };
        switch (type) {
            case 'Email':
                navigate('../mail', options);
                break;
            case 'Telegram':
                navigate('../telegram', options);
                break;
            // case 'Viber':
            // navigate('../viber', options);
            // break;
            // case 'Whatsapp':
            //   navigate('../whatsApp', options);
            //   break;
            default:
                navigate('../');
                break;
        }
    };
    const handleDropdownMenuItem = (action, id) => {
        const item = filteredData[+id];
        const type = item.type;
        switch (action) {
            case VariantMenuItems.Repeat:
                handleRepeatButton(type, item.mailingData);
                break;
            case VariantMenuItems.Remove:
                setSelectedItemMenuDelete(item.mailingId);
                handleDeleteMailingMenuVisible();
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        setFilteredData(dataAfterSearch);
    }, [dataAfterSearch]);
    const handleDropdownMenu = (selectedId, row, tableRef) => {
        return (_jsx(DropdownMenu, { tableRef: tableRef, isVisible: true, dataItem: row, items: menuElements, selectedId: selectedElementIndex === null || selectedElementIndex === void 0 ? void 0 : selectedElementIndex.toString(), setVisible: () => setSelectedElementIndex(null), onItemClick: handleDropdownMenuItem }));
    };
    useEffect(() => {
        setFilteredData(dataAfterSearch.filter((mailing) => {
            // const mailingStatus = mailing.status.text.toLowerCase();
            // const lowercaseStatusValues = filters.status?.map((filterValue) =>
            //   filterValue.value.toLowerCase()
            // );
            // if (
            //   lowercaseStatusValues?.length > 0 &&
            //   !lowercaseStatusValues.includes(mailingStatus)
            // ) {
            //   return false;
            // }
            var _a;
            const mailingType = mailing.type.toLowerCase();
            const lowercaseTypesValues = (_a = filters.mailingType) === null || _a === void 0 ? void 0 : _a.map((filterValue) => filterValue.value.toLowerCase());
            if ((lowercaseTypesValues === null || lowercaseTypesValues === void 0 ? void 0 : lowercaseTypesValues.length) > 0 &&
                !lowercaseTypesValues.includes(mailingType)) {
                return false;
            }
            return true;
        }));
    }, [filters.status, filters.mailingType]);
    return (_jsxs(_Fragment, { children: [_jsxs(Styles.Container, { children: [_jsxs(Styles.Header, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Mailing history') })), _jsx(Styles.SectionContainer, { children: _jsx(Filter, { onApplyFilters: (filterValues) => {
                                        setFilters({
                                            status: filterValues.status,
                                            mailingType: filterValues.mailingType,
                                        });
                                    }, filterFieldsModel: mailingHistoryFilterModel }) })] }), _jsxs(Styles.Actions, { children: [_jsx(Styles.SectionContainer, { children: _jsx(Styles.ButtonDelete, { text: t('Delete'), icon: _jsx(Icon.Delete, {}), 
                                    // onClick={handleDeleteButton}
                                    onClick: handleDeleteMailingVisible, variant: ButtonVariant.TETRIERY }) }), _jsx(Styles.SectionContainer, { children: _jsx(SearchInput, { search: searchFilter, searchFilter: handleFilterChange }) })] }), _jsx(Styles.TableContainer, { children: _jsx(Table, { isTertiary: true, hasItemCheckbox: true, hasHeaderCheckbox: true, icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.More, {}) })), checkedRows: checkedRows, onRowChecked: handleRowChecked, buttonAction: ButtonTable.Menu, handleButton: handleButtonPress, selectedElement: selectedElementIndex, onAllRowsChecked: handleAllRowsChecked, handleDropdownMenu: handleDropdownMenu, dataHeader: [
                                '№',
                                t('Name'),
                                t('Type'),
                                t('Date'),
                                t('Recipients'),
                                // t('Status'),
                                t('Action'),
                            ], data: filteredData.map((row, index) => [
                                formatId(index + 1),
                                row.mailingData.mailingName,
                                t(row.type).toString(),
                                dayjs(row.date).locale('ru').format('HH:mm, D MMM, YYYY'),
                                row.totalRecipients.toString(),
                                // <ItemStatus
                                //   text={t(row.status.text)}
                                //   variant={row.status.variant}
                                // />,
                                _jsx(Button, { text: t('More info'), variant: ButtonVariant.SECONDARY, onClick: () => {
                                        handleCreateMailing();
                                        setSelectedMailing(filteredData[index]);
                                    } }),
                            ]) }) }), _jsx(MailingInfoModal, { isModalOpen: isMoreInfoModalOpen, setModalOpen: setMoreInfoModalOpen, initialValues: {
                            columnName: selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.type,
                            modalName: selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.mailingData.mailingName,
                            filters: {
                                status: [],
                            },
                        }, historyData: selectedMailing === null || selectedMailing === void 0 ? void 0 : selectedMailing.mailingData })] }), _jsx(DeleteModal, { isVisible: isDeleteMailingVisible, onClick: handleDeleteMailingVisible, message: 'Вы точно хотите удалить выбранные рассылки(-у)?', modalTitle: 'Удалить рассылки', buttonText: 'Да, удалить', deleteFunc: handleDeleteButton }), _jsx(DeleteModal, { isVisible: isDeleteMailingMenuVisible, onClick: handleDeleteMailingMenuVisible, message: 'Вы точно хотите удалить выбранную рассылку?', modalTitle: 'Удалить рассылку', buttonText: 'Да, удалить', deleteFunc: handleDeleteMailing })] }));
};
