export var InputColorType;
(function (InputColorType) {
    InputColorType["DEFAULT"] = "DEFAULT";
    InputColorType["LIGHT"] = "LIGHT";
    InputColorType["GREEN"] = "GREEN";
    InputColorType["GREY"] = "GREY";
    InputColorType["DARK"] = "DARK";
})(InputColorType || (InputColorType = {}));
export var InputForm;
(function (InputForm) {
    InputForm["DEFAULT"] = "DEFAULT";
    InputForm["SQUARE"] = "SQUARE";
})(InputForm || (InputForm = {}));
export var InputSize;
(function (InputSize) {
    InputSize["ULTRA_SMALL"] = "ULTRA_SMALL";
    InputSize["DEFAULT"] = "DEFAULT";
    InputSize["SMALL"] = "SMALL";
    InputSize["MEDIUM"] = "MEDIUM";
    InputSize["BIG"] = "BIG";
    InputSize["BIGGER"] = "BIGGER";
})(InputSize || (InputSize = {}));
export var IconSize;
(function (IconSize) {
    IconSize["SMALL"] = "SMALL";
    IconSize["MEDIUM"] = "MEDIUM";
})(IconSize || (IconSize = {}));
export var LabelSize;
(function (LabelSize) {
    LabelSize["SMALL"] = "SMALL";
    LabelSize["MEDIUM"] = "MEDIUM";
})(LabelSize || (LabelSize = {}));
