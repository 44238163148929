var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Formik, useFormikContext } from 'formik';
import React, { createContext, useContext, useRef, } from 'react';
const RefsContext = createContext({});
export const useAutoFocus = (name, element) => {
    const context = useContext(RefsContext);
    if (name && element) {
        context[name] = element;
    }
};
const EnterSubmitSwitch = ({ children }) => {
    const { validateForm, submitForm, setFieldTouched } = useFormikContext();
    const refsValue = useRef({});
    return (_jsx(RefsContext.Provider, Object.assign({ value: refsValue.current }, { children: _jsx("div", Object.assign({ style: { width: '100%' }, onKeyDown: (event) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                if (event.key === 'Enter' || event.code === 'NumpadEnter') {
                    const errors = yield validateForm();
                    const fields = Object.keys(errors);
                    for (let field of fields) {
                        setFieldTouched(field, true);
                    }
                    if (!fields.length &&
                        !(event.target instanceof HTMLTextAreaElement)) {
                        submitForm();
                    }
                    else {
                        (_b = (_a = refsValue.current[fields[0]]) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
                    }
                }
            }) }, { children: children })) })));
};
export function Form({ children, initialValues, onSubmit, validationSchema, }) {
    return (_jsx(Formik, Object.assign({ validationSchema: validationSchema, initialValues: initialValues, onSubmit: onSubmit }, { children: _jsx(EnterSubmitSwitch, { children: children }) })));
}
