import { AppRoutes } from 'config';
import { Icon } from 'common/icon';
const { Home, Profile } = AppRoutes.Dashboard;
export const ChoosePaymentTypes = {
    Card: 'card',
    Qiwi: 'qiwi',
    Alfa: 'alfa',
    Yandex: 'yandex',
};
export const ChoosePaymentLabel = {
    Card: 'Bank card',
    Qiwi: 'QIWI Wallet',
    Alfa: 'Alpha Click',
    Yandex: 'Yandex. Money',
};
export const TabItems = [
    {
        icon: Icon.Mir,
        id: ChoosePaymentTypes.Card,
        label: ChoosePaymentLabel.Card,
        value: Home +
            Profile.Home +
            Profile.Tariff +
            Profile.Payment.Home +
            Profile.Payment.Card,
    },
    {
        icon: Icon.Qiwi,
        id: ChoosePaymentTypes.Qiwi,
        label: ChoosePaymentLabel.Qiwi,
        value: Home +
            Profile.Home +
            Profile.Tariff +
            Profile.Payment.Home +
            Profile.Payment.Qiwi,
    },
    {
        icon: Icon.Alfa,
        id: ChoosePaymentTypes.Alfa,
        label: ChoosePaymentLabel.Alfa,
        value: Home +
            Profile.Home +
            Profile.Tariff +
            Profile.Payment.Home +
            Profile.Payment.Alfa,
    },
    {
        icon: Icon.YandexPay,
        id: ChoosePaymentTypes.Yandex,
        label: ChoosePaymentLabel.Yandex,
        value: Home +
            Profile.Home +
            Profile.Tariff +
            Profile.Payment.Home +
            Profile.Payment.Yandex,
    },
];
