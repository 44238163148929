import { jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import * as Styles from './styles';
import { Button } from 'common/button';
const Sidebar = ({ setCurrentDate, handleSidebarRange }) => {
    const rangeSelectors = [
        {
            label: 'Last 14 days',
            range: [dayjs().subtract(14, 'day').toDate(), dayjs().toDate()],
        },
        {
            label: 'This week',
            range: [dayjs().startOf('week').toDate(), dayjs().endOf('week').toDate()],
        },
        {
            label: 'Last week',
            range: [
                dayjs().subtract(1, 'week').startOf('week').toDate(),
                dayjs().subtract(1, 'week').endOf('week').toDate(),
            ],
        },
        {
            label: 'This month',
            range: [
                dayjs().startOf('month').toDate(),
                dayjs().endOf('month').toDate(),
            ],
        },
        {
            label: 'Last month',
            range: [
                dayjs().subtract(1, 'month').startOf('month').toDate(),
                dayjs().subtract(1, 'month').endOf('month').toDate(),
            ],
        },
    ];
    return (_jsx(Styles.Sidebar, { children: rangeSelectors.map((selector) => {
            return (_jsx("li", { children: _jsx(Button, { text: selector.label, onClick: () => {
                        handleSidebarRange(selector.range[0]);
                        setCurrentDate(selector.range);
                    } }) }, selector.label));
        }) }));
};
export default Sidebar;
