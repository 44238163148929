import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Text, TextVariant } from 'common';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClipsListQuery } from '../../../../../store/api/audio-clips';
import { useMeQuery } from '../../../../../store/api/profile';
import { controlError } from '../../../../../utils/controlError';
import * as Styles from './styles';
export const FormsAudioCreateMailing = ({ setIsAudioClipModalOpen, }) => {
    const { setFieldValue, values } = useFormikContext();
    const { t } = useTranslation();
    const { data: me } = useMeQuery();
    const { data: clipsList, refetch } = useClipsListQuery();
    const [, meta] = useField('audioClipId');
    const errorAudioClip = t(controlError(meta, 'audioClipId', 'AudioClip'));
    const getAudioList = () => {
        if (me) {
            refetch();
        }
    };
    useEffect(() => {
        getAudioList();
    }, [me]);
    useEffect(() => {
        console.log(clipsList);
    }, [clipsList]);
    return (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Add an audio clip for a call') })), errorAudioClip && (_jsx(Styles.ErrorContainer, { children: errorAudioClip })), _jsx(Styles.MailingModalAudioTableContainer, { children: _jsxs(Styles.MailingModalAudioTable, { children: [!(clipsList === null || clipsList === void 0 ? void 0 : clipsList.length) && (_jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: t("You haven't created any audio clips yet") }))), clipsList === null || clipsList === void 0 ? void 0 : clipsList.map((audio, index) => {
                            return (_jsxs(Styles.MailingModalAudioTableItem, Object.assign({ "$isSelected": values.audioClipId == audio.audioclip_id, onClick: () => {
                                    if (values.audioClipId == audio.audioclip_id) {
                                        setFieldValue('audioClipId', null);
                                    }
                                    else {
                                        setFieldValue('audioClipId', audio.audioclip_id);
                                    }
                                } }, { children: [_jsx(Styles.MailingModalAudioTableItemIndex, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.smallSemiBold }, { children: [index + 1, "."] })) }), _jsx(Styles.MailingModalAudioTableItemName, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: audio.clip_name })) }), _jsx(Styles.MailingModalAudioTableItemDesc, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'secondary' }, { children: audio.clip_description })) })] }), audio.audioclip_id));
                        }), _jsx(Button, { text: t('Create'), variant: 'TETRIERY', onClick: () => setIsAudioClipModalOpen(true) })] }) })] }));
};
