import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Table } from 'components';
import { Button, ButtonVariant } from 'common';
import { staffFakeData } from '../notification/config';
import { Icon } from 'common/icon';
import * as Styles from './styles';
export const StaffForm = () => {
    const { t } = useTranslation();
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Styles.Title, { children: t('Invited employees') }), _jsx(Table, { isScrollable: true, icon: _jsx(Icon.Delete, {}), dataHeader: [
                        t('Position'),
                        t('Name'),
                        t('Email'),
                        t('Access to services'),
                    ], data: staffFakeData === null || staffFakeData === void 0 ? void 0 : staffFakeData.map((row) => [
                        row.position,
                        row.name,
                        row.email,
                        row.accessToServices,
                    ]) }), _jsx(Styles.ButtonContainer, { children: _jsx(Button, { text: t('Invite an employee'), variant: ButtonVariant.PRIMARY }) })] }) }));
};
