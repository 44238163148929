import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import { useMeQuery } from '../../store/api/profile';
import { logout } from '../../store/thunks/auth/auth';
export const AuthGateway = ({ children, }) => {
    const { data: me, refetch, error } = useMeQuery();
    const dispatch = useAppDispatch();
    const [trigger, setTrigger] = useState(0);
    const [access, setAccess] = useState(false);
    useEffect(() => {
        if (me) {
            setAccess(true);
        }
    }, [me]);
    useEffect(() => {
        if (error && error.message === 'Network Error') {
            if (trigger < 4) {
                setTrigger(trigger + 1);
                refetch();
            }
            else {
                dispatch(logout());
            }
        }
    }, [error]);
    if (access) {
        localStorage.setItem('userId', me._id.toString());
        return children;
    }
};
