import { useEffect, useRef } from 'react';
export function useStableRef(value) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
}
export function useBackdropClick(ref, callback, active) {
    const stableCallback = useStableRef(callback);
    const stableActive = useStableRef(active);
    useEffect(() => {
        const handler = (event) => {
            if (!stableActive.current) {
                return;
            }
            if (ref.current && !event.composedPath().includes(ref.current)) {
                stableCallback.current();
                event.stopPropagation();
            }
        };
        document.addEventListener('click', handler, { capture: true });
        return () => document.removeEventListener('click', handler);
    }, []);
}
