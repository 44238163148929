import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from '../locales/en/translation.json';
import translationRu from '../locales/ru/translation.json';
const resources = {
    en: {
        translation: translationEn,
    },
    ru: {
        translation: translationRu,
    },
};
const userLanguage = localStorage.getItem('i18nextLng') || navigator.language.split('-')[0];
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources,
    lng: userLanguage,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});
export const changeLanguage = (lng) => {
    if (lng) {
        i18n.changeLanguage(lng);
    }
    i18n.changeLanguage(userLanguage).catch(() => {
        i18n.changeLanguage('en');
    });
};
export default i18n;
