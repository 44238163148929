var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'common/input';
import { Text } from 'common/text';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const SwitchContainer = styled.div.withConfig({ displayName: "SwitchContainer", componentId: "sc-1of7jsw" }) `
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;
const SwitchSlider = styled.span.withConfig({ displayName: "SwitchSlider", componentId: "sc-gjk18g" }) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme, $isChecked }) => $isChecked
    ? theme.config.switch.bg.active
    : theme.config.switch.bg.default};
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
`;
const SwitchHandle = styled.span.withConfig({ displayName: "SwitchHandle", componentId: "sc-1my23n9" }) `
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${({ theme, $isChecked }) => $isChecked
    ? theme.config.switch.thumb.active
    : theme.config.switch.thumb.default};
  border-radius: 50%;
  top: 2px;
  transform: ${({ $isChecked }) => $isChecked ? 'translateX(127%)' : 'translateX(10%)'};
  transition: 0.3s all ease-in-out;
`;
const FieldRow = styled.div.withConfig({ displayName: "FieldRow", componentId: "sc-1fy54pu" }) `
  display: flex;
  flex-direction: row;

  gap: 16px;
`;
const Label = styled(Text).withConfig({ displayName: "Label", componentId: "sc-h5ofed" }) ``;
export const Switch = (_a) => {
    var { label, onChange, checked = false, colorLabel = { $colorVariant: 'primary' } } = _a, otherProps = __rest(_a, ["label", "onChange", "checked", "colorLabel"]);
    const { t } = useTranslation();
    return (_jsxs(FieldRow, { children: [_jsxs(SwitchContainer, { children: [_jsx(SwitchSlider, { "$isChecked": checked }), _jsx(SwitchHandle, { "$isChecked": checked }), _jsx(Input, Object.assign({ style: {
                            opacity: 0,
                            cursor: 'pointer',
                        }, onChange: (e) => { var _a; return onChange && onChange((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.checked); } }, otherProps))] }), label && (_jsx(Label, Object.assign({ "$colorVariant": colorLabel === null || colorLabel === void 0 ? void 0 : colorLabel.$colorVariant }, { children: t(label) })))] }));
};
