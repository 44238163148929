import { t } from 'i18next';
import * as Yup from 'yup';
import { EInputType } from './EInputType';
export const model = {
    message: {
        type: 'text',
        name: 'message',
        withBorder: true,
        inputType: 'textarea',
        style: { minHeight: 240 },
        placeholder: 'Insert the text for the audio clip',
    },
    audio: {
        name: 'audio',
    },
    audioName: {
        label: 'Enter the name of the audio clip',
        type: 'text',
        name: 'audioName',
        withBorder: false,
        placeholder: 'Audio name',
    },
    audioLanguage: {
        name: 'audioLanguage',
        label: 'Audio language',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a language',
        options: [
            {
                label: 'Русский',
                value: 'ru',
            },
            {
                label: 'English',
                value: 'en',
            },
        ],
    },
    audioDescription: {
        name: 'audioDescription',
        label: 'Audio description',
        type: 'text',
        // containerStyle: { flex: 1 },
        placeholder: 'Audio description',
    },
};
export const validationSchema = (step) => {
    if (step === EInputType.TEXT) {
        return Yup.object().shape({
            audioName: Yup.string().required(t('Name is required')),
            generatedFile: Yup.mixed().required('File is required'),
        });
    }
    if (step === EInputType.MICROPHONE) {
        return Yup.object().shape({
            audioName: Yup.string().required(t('Name is required')),
            voiceRecord: Yup.mixed().required('File is required'),
        });
    }
    if (step === EInputType.AUDIOFILE) {
        return Yup.object().shape({
            audioName: Yup.string().required(t('Name is required')),
            userFile: Yup.mixed().required('File is required'),
        });
    }
};
export const audioFormInitialValue = {
    audioName: '',
    language: {
        label: 'Русский',
        value: 'ru',
    },
    message: '',
    voiceRecord: null,
    audioDescription: '',
    generatedFile: null,
    userFile: null,
};
