import styled, { css } from 'styled-components';
export const Wrap = styled.div.withConfig({ displayName: "Wrap", componentId: "sc-12l5mmw" }) `
  position: relative;
  width: 100%;
  height: 100%;
  height: 100vh;

  ${({ theme }) => css `
    background-color: ${theme.colors.white.primary};
  `}
`;
