import styled, { css } from 'styled-components';
import { smallSemiBold } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-syw1xo" }) `
  display: flex;
  align-items: center;
  width: 100%;

  max-width: calc(1280px + 6rem);

  @media screen and (main-width: 1024px) {
    padding: 0px 16px;
  }

  @media screen and (main-width: 768px) {
    padding: 0px 8px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-qpx8j2" }) `
  width: 100%;
`;
export const HeaderTabsWrapper = styled.div.withConfig({ displayName: "HeaderTabsWrapper", componentId: "sc-yge3tf" }) `
  width: 100%;
`;
export const HeaderTabs = styled.div.withConfig({ displayName: "HeaderTabs", componentId: "sc-w7auwi" }) `
  display: flex;
  flex: 1;
  gap: ${({ theme }) => (theme.responsive.isMobile ? '10px' : '24px')};
  border-bottom: ${({ theme }) => theme.config.toggleTabs.border.default};

  width: 100%;
`;
export const Tab = styled.div.withConfig({ displayName: "Tab", componentId: "sc-p01hvy" }) `
  margin-bottom: -1px;
`;
export const TabLabel = styled.div.withConfig({ displayName: "TabLabel", componentId: "sc-qd3fvx" }) `
  ${smallSemiBold};

  display: inline-block;
  padding: 0px 24px 12px;
  white-space: nowrap;
  text-decoration: none;
  transition: padding 0.3s ease-in;
  cursor: pointer;
  opacity: 0.5;
  color: ${({ theme }) => theme.config.toggleTabs.text.default};

  ${({ theme, $isActive }) => $isActive &&
    css `
      opacity: 1;
      color: ${theme.config.toggleTabs.text.active};
    `};

  @media screen and (max-width: 826px) {
    padding: 0px 16px 8px;
    transition: padding 0.3s ease-in;
  }

  @media screen and (max-width: 460px) {
    padding: 0px 8px 6px;
    transition: padding 0.3s ease-in;
  }
`;
export const ActiveLine = styled.div.withConfig({ displayName: "ActiveLine", componentId: "sc-dwh990" }) `
  height: 1px;
  width: 100%;

  ${({ theme, $isActive }) => $isActive &&
    css `
      background: ${theme.config.toggleTabs.bg.active};
    `}
`;
