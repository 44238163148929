import { Button as ButtonComponent } from 'common';
import { Icon } from 'common/icon';
import styled, { css } from 'styled-components';
import { heading3Medium } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-s5exxg" }) `
  margin: 0 auto;
  position: relative;

  ${({ theme }) => css `
    border-radius: 16px;
    padding-bottom: 20px;
    background: ${theme.config.form.background};
    box-shadow: ${theme.config.form.boxShadowForm};
    width: ${theme.responsive.isMobile ? '350px' : '450px'};
  `}

  @media (max-width: 375px) {
    width: 300px;
  }
`;
export const WrapperForm = styled.div.withConfig({ displayName: "WrapperForm", componentId: "sc-ur1fkz" }) `
  margin: 0 2rem 1rem;
`;
export const Header = styled.header.withConfig({ displayName: "Header", componentId: "sc-1pf9p30" }) `
  ${heading3Medium}

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 3rem;
  color: ${({ theme }) => theme.config.text.primary};
`;
export const ContainerButton = styled.div.withConfig({ displayName: "ContainerButton", componentId: "sc-11o1xkt" }) `
  margin: 0 2rem;

  display: flex;
  flex-direction: column;

  gap: 10px;
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-1uvk6z" }) `
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  height: 46px;
`;
export const FieldsColumn = styled.div.withConfig({ displayName: "FieldsColumn", componentId: "sc-ou30py" }) `
  display: flex;
  flex-direction: column;

  gap: 20px;
`;
export const IconClose = styled.div.withConfig({ displayName: "IconClose", componentId: "sc-1hujmgz" }) `
  width: 48px;
  height: 48px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.config.button.secondary.background.default};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);

  position: absolute;

  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.config.button.secondary.background.hover};
  }

  ${({ theme }) => css `
    width: 48px;
    height: 48px;
    top: ${theme.responsive.isMobile ? '5px' : ''};
    right: ${theme.responsive.isMobile ? '5px' : '-58px'};
    padding: ${theme.isDarkTheme ? '11px' : '12px'};
    border: ${theme.isDarkTheme ? '1px solid #e8ecef' : ''};
  `}
`;
export const Close = styled(Icon.Close).withConfig({ displayName: "Close", componentId: "sc-step5c" }) `
  ${({ theme }) => css `
    fill: ${theme.config.text.primary};
  `}
`;
