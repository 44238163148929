import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import AdvMockImage from '../../../../../../assets/images/AdvMockImage.png';
import { Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { More } from '../../../../../../common/icon/icons';
import { PreviewContext } from './AdvPreview';
import * as Styles from './styles';
export const SecondVariant = () => {
    const contextValues = useContext(PreviewContext);
    return (_jsxs(Styles.SecondVariantContainer, { children: [_jsxs(Styles.SecondVariantMeta, { children: [_jsx(Styles.SecondVariantFavicon, { src: contextValues === null || contextValues === void 0 ? void 0 : contextValues.faviconSrc }), _jsx(Styles.SecondVariantUrlWrapper, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular }, { children: (contextValues === null || contextValues === void 0 ? void 0 : contextValues.url) || 'Реклама' })) }), _jsx(Styles.SecondVariantMoreInfo, { children: _jsx(IconWrapper, { children: _jsx(More, {}) }) })] }), _jsx(Styles.SecondVariantImage, { src: AdvMockImage }), _jsx(Styles.SecondVariantHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: contextValues === null || contextValues === void 0 ? void 0 : contextValues.header })) })] }));
};
