import styled, { css } from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { Button as ButtonComponent } from 'common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1jy3lav" }) `
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-40z3sn" }) `
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;

  height: calc(100vh - 150px);
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-vnj9tn" }) `
  margin: 40px;
`;
export const SubText = styled.div.withConfig({ displayName: "SubText", componentId: "sc-1jiay7l" }) `
  padding: 0;
  margin: 12px 0px 40px 0px;
`;
export const ChooseWrapper = styled.div.withConfig({ displayName: "ChooseWrapper", componentId: "sc-zhqh8j" }) `
  margin-top: 12px;
  border-radius: 20px;
  background: ${({ theme }) => theme.config.background.tetriery};
  border: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const Contents = styled.div.withConfig({ displayName: "Contents", componentId: "sc-1gk738g" }) `
  margin: 32px;
`;
export const ContainerItem = styled.div.withConfig({ displayName: "ContainerItem", componentId: "sc-d81a5p" }) `
  display: flex;
  align-items: center;

  cursor: pointer;
`;
export const WrapperItem = styled.div.withConfig({ displayName: "WrapperItem", componentId: "sc-1la8t5q" }) `
  padding: 8px 16px;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  background: ${({ theme }) => theme.config.checkbox.field.bg.default};

  &:hover {
    background: ${({ theme }) => theme.config.checkbox.field.bg.hover};
  }

  &:active {
    background: ${({ theme }) => theme.config.checkbox.field.bg.active};
  }
`;
export const WrapperName = styled.div.withConfig({ displayName: "WrapperName", componentId: "sc-18zj1dd" }) `
  margin-left: 19px;
`;
export const LeftContent = styled.div.withConfig({ displayName: "LeftContent", componentId: "sc-148d0i" }) `
  gap: 16px;
  display: flex;
  align-items: center;
`;
export const RightContent = styled.div.withConfig({ displayName: "RightContent", componentId: "sc-102ends" }) ``;
export const FooterChoose = styled.div.withConfig({ displayName: "FooterChoose", componentId: "sc-1qe3r0x" }) `
  margin: 12px 0px 12px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-7ig48e" }) `
  display: flex;
  gap: 24px;

  justify-content: flex-end;
`;
export const NoDataText = styled.div.withConfig({ displayName: "NoDataText", componentId: "sc-gsonqp" }) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WrapperTable = styled.div.withConfig({ displayName: "WrapperTable", componentId: "sc-55s95j" }) `
  margin-top: 12px;
`;
export const WrapperHeader = styled.div.withConfig({ displayName: "WrapperHeader", componentId: "sc-24i84" }) `
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-4gkd9l" }) `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-14bhg9f" }) `
  margin-bottom: -10px;
`;
export const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "sc-34j7yh" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-nkpnal" }) `
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-npjhyx" }) `
  margin-top: 48px;
  margin-left: 24px;
`;
export const ButtonBack = styled(ButtonComponent).withConfig({ displayName: "ButtonBack", componentId: "sc-13hg1o6" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  border-radius: 50%;

  ${({ theme }) => css `
    border: ${theme.config.uploadImage.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}
  &:hover {
    background: none;
  }
`;
export const ButtonFilters = styled(ButtonComponent).withConfig({ displayName: "ButtonFilters", componentId: "sc-krau8e" }) `
  box-shadow: ${({ theme }) => theme.config.button.primary.shadow.secondary};
`;
export const ButtonDelete = styled(ButtonComponent).withConfig({ displayName: "ButtonDelete", componentId: "sc-13a5u27" }) `
  color: ${({ theme }) => theme.config.text.primary};
`;
