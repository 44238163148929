var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { AppRoutes } from 'config';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../components/form/Form';
import { useNavigate } from '../../../../../hooks';
import { useCompanyCreateMutation } from '../../../../../store/api/companies';
import { useCheckInnMutation } from '../../../../../store/api/profile';
import { handleMutation } from '../../../../../utils/handleMutation';
import { CompanyForm } from '../form';
import { LegalInfoModal } from '../legal-info-modal';
import { createCompanyInitialValues, createCompanyValidationSchema, EActivityType, } from './config';
import * as Styles from './styles';
const CreateCompanyForm = () => {
    const alert = useAlert();
    const { submitForm, isSubmitting, dirty, values, setValues, isValid } = useFormikContext();
    const { Home, Main } = AppRoutes.Dashboard;
    const [isLegalInfo, setIsLegalInfo] = useState(false);
    const [isInnChecked, setIsInnChecked] = useState(false);
    const { t } = useTranslation();
    const [requestCheck, responseCheck] = useCheckInnMutation();
    const [innInput, setInnInput] = useState('');
    const handleLegalInfo = () => {
        setIsLegalInfo(!isLegalInfo);
    };
    const checkInn = () => {
        if (innInput.length == 10) {
            requestCheck({ inn: innInput });
        }
    };
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        // @ts-ignore
        const d = data === null || data === void 0 ? void 0 : data[0]['ЮЛ'];
        yield setValues(Object.assign(Object.assign({}, values), { ogrn: d['ОГРН'], kpp: d['КПП'], inn: d['ИНН'], director: {
                position: d['Руководитель']['Должн'],
                name: d['Руководитель']['ФИОПолн'],
            }, address: d['Адрес']['АдресПолн'], fullName: d['НаимПолнЮЛ'], shortName: d['НаимСокрЮЛ'] }));
        setIsInnChecked(true);
    });
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseCheck), { onSuccess,
        onError }));
    if (!isInnChecked) {
        return (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Creating a company') })), _jsx("div", { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallLight }, { children: t(`Enter your company's tax identification number and we will make it easier for you to fill in the required fields`) })) }), _jsxs(Styles.ActionWrapper, { children: [_jsx(Styles.CompanyNameInput, { icon: _jsx(Icon.Building, {}), onEnterPress: () => checkInn(), onChange: (e) => setInnInput(e.target.value), placeholder: 'Введите ИНН', value: innInput, error: innInput.length !== 10 && innInput.length !== 0
                                ? 'Введите корректный ИНН'
                                : '' }), _jsx(Styles.WrapperButton, { children: _jsx(Button, { text: t('Confirm INN'), variant: ButtonVariant.TETRIERY, disabled: innInput.length !== 10, onClick: checkInn }) })] })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Styles.Header, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Creating a company') })), _jsxs(Styles.HeaderInnContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "\u0418\u041D\u041D: " })), _jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: values.inn }))] })] }), _jsx(CompanyForm, { handleLegalInfo: handleLegalInfo }), _jsxs(Styles.FooterWrapper, { children: [_jsx(Button, { padding: "24px", text: t('Create a company'), onClick: submitForm, disabled: isSubmitting || !dirty || !isValid }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, to: Home + Main })] }), _jsx(LegalInfoModal, { isOpen: isLegalInfo, handleOpen: handleLegalInfo })] }));
};
export const CreateCompanyContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const [createCompanyParse, createCompanyMutation] = useCompanyCreateMutation();
    const onSuccess = () => {
        alert.success(t('The company has been successfully created'));
        navigate(`/dashboard/my-company/`);
    };
    const onError = useCallback((error) => {
        try {
            alert.error((error === null || error === void 0 ? void 0 : error.data.detail) || t('Error'));
            navigate(`/dashboard/my-company/`);
        }
        catch (_a) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, createCompanyMutation), { onSuccess,
        onError }));
    const checkIsIncluded = (activitySpheres, checkedSphere) => {
        return activitySpheres.filter((sphere) => sphere.value === checkedSphere)
            .length === 0
            ? 'False'
            : 'True';
    };
    const checkIsConnectedSN = (socialNetworks, checkedSN) => {
        return socialNetworks
            .filter((searchSN) => searchSN.network === checkedSN)
            .map((SN) => (SN.connected ? SN.website : 'False'))[0];
    };
    const onSubmit = ({ companyWebsites, fullName, activityTypes, activitySpheres, location, socialNetworks, director, ogrn, inn, kpp, address, shortName, }) => {
        const correctFullName = fullName.replaceAll(`\"`, `\\"`);
        createCompanyParse({
            company_urls: companyWebsites
                .filter((w) => w.connected)
                .map((w) => w.website)
                .join(','),
            company_city: location.country.value,
            company_name: shortName,
            company_region: location.region.value,
            education: checkIsIncluded(activitySpheres, 'Education'),
            goods: activityTypes.includes(EActivityType.PRODUCTS) ? 'True' : 'False',
            health: checkIsIncluded(activitySpheres, 'Healthcare'),
            official_address: address,
            official_requisites: JSON.stringify({
                ogrn,
                inn,
                kpp,
                director,
                fullName: correctFullName,
            }),
            production: checkIsIncluded(activitySpheres, 'Manufacturing'),
            facebook: checkIsConnectedSN(socialNetworks, 'FACEBOOK'),
            vk: checkIsConnectedSN(socialNetworks, 'VKONTAKTE'),
            tiktok: checkIsConnectedSN(socialNetworks, 'TIKTOK'),
            telegram: checkIsConnectedSN(socialNetworks, 'TELEGRAM'),
            instagram: checkIsConnectedSN(socialNetworks, 'INSTAGRAM'),
            is_services: activityTypes.includes(EActivityType.SERVICES)
                ? 'True'
                : 'False',
            technologies: checkIsIncluded(activitySpheres, 'Technology'),
            transportation: checkIsIncluded(activitySpheres, 'Transportation'),
            inn,
        });
    };
    return (_jsx(Styles.Container, { children: _jsx(Styles.ScrollContent, { children: _jsx(Styles.Wrapper, { children: _jsx(Form, Object.assign({ onSubmit: onSubmit, initialValues: createCompanyInitialValues, 
                    // validationSchema={null}
                    validationSchema: createCompanyValidationSchema }, { children: _jsx(CreateCompanyForm, {}) })) }) }) }));
};
