import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export const ItemStatusVariants = {
    Active: 'active',
    Paused: 'paused',
    Failed: 'failed',
    Pending: 'pending',
    Inactive: 'inactive',
    Completed: 'completed',
    Cancelled: 'cancelled',
    Updating: 'updating',
};
const ItemStatusWrapper = styled.div.withConfig({ displayName: "ItemStatusWrapper", componentId: "sc-o5d01y" }) `
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
const Square = styled.div.withConfig({ displayName: "Square", componentId: "sc-u6yl72" }) `
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background-color: ${({ theme, $variant }) => theme.config.itemStatus[$variant]};
`;
const Text = styled.p.withConfig({ displayName: "Text", componentId: "sc-bucpmc" }) `
  margin: 0;
`;
export const ItemStatus = ({ text, variant }) => {
    return (_jsxs(ItemStatusWrapper, { children: [_jsx(Square, { color: '#000', "$variant": variant }), _jsx(Text, { children: text })] }));
};
