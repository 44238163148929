import styled, { css } from 'styled-components';
import { SidenavVariant } from '../../constants/enums';
const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "sc-1f9nn14" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width || '24px'};
  height: ${({ $height }) => $height || '24px'};

  & > svg {
    width: ${({ $width }) => $width || '24px'};
    height: ${({ $height }) => $height || '24px'};

    & > * {
      ${({ $reverseStyles, $sidenav, $isActive, $strokeColorActive, $strokeColor, $fillColor, $fillColorHover, $isSecondaryIcon, theme, }) => $reverseStyles
    ? css `
              stroke: ${() => $isActive
        ? $fillColorHover || $fillColor || theme.config.text.primary
        : $isSecondaryIcon
            ? theme.config.icon.tetriary
            : $fillColor || theme.config.icon.primary};

              fill: ${() => {
        switch ($sidenav) {
            case SidenavVariant.sidemenu:
                if ($isActive) {
                    return ($strokeColorActive ||
                        $strokeColor ||
                        theme.config.icon.green.active);
                }
                else {
                    return ($strokeColor ||
                        theme.config.icon.green.default ||
                        'none');
                }
            default:
                return $strokeColor || 'none';
        }
    }};
            `
    : css `
              fill: ${() => $isActive
        ? $fillColorHover || $fillColor || theme.config.text.primary
        : $isSecondaryIcon
            ? theme.config.icon.tetriary
            : $fillColor || theme.config.icon.primary};

              stroke: ${() => {
        switch ($sidenav) {
            case SidenavVariant.sidemenu:
                if ($isActive) {
                    return ($strokeColorActive ||
                        $strokeColor ||
                        theme.config.icon.green.active);
                }
                else {
                    return ($strokeColor ||
                        theme.config.icon.green.default ||
                        'none');
                }
            default:
                return $strokeColor || 'none';
        }
    }};
            `}
    }
  }

  &:hover > svg > * {
    ${({ $reverseStyles, $sidenav, $fillColorHover, $fillColor, $strokeColorHover, $strokeColor, theme, }) => $reverseStyles
    ? css ` stroke: ${() => {
        if ($sidenav === SidenavVariant.sidemenu) {
            return $fillColorHover || $fillColor || theme.config.text.primary;
        }
        else {
            return $fillColorHover || $fillColor || theme.config.text.primary;
        }
    }};
                            fill: ${() => {
        if ($sidenav === SidenavVariant.sidemenu) {
            return ($strokeColorHover ||
                $strokeColor ||
                theme.config.icon.green.active);
        }
        else {
            return ($strokeColorHover || $strokeColor || 'none');
        }
    }};
                        }`
    : css `
            fill: ${() => {
        if ($sidenav === SidenavVariant.sidemenu) {
            return ($fillColorHover || $fillColor || theme.config.text.primary);
        }
        else {
            return ($fillColorHover || $fillColor || theme.config.text.primary);
        }
    }};
            stroke: ${() => {
        if ($sidenav === SidenavVariant.sidemenu) {
            return ($strokeColorHover ||
                $strokeColor ||
                theme.config.icon.green.active);
        }
        else {
            return $strokeColorHover || $strokeColor || 'none';
        }
    }};
          `}
  }

  &:active > svg > * {
    ${({ $reverseStyles, $fillColorActive, $fillColor, $isActive, $strokeColorActive, $strokeColor, theme, }) => $reverseStyles
    ? css `
            stroke: ${() => $fillColorActive || $fillColor || theme.config.text.primary};
            fill: ${() => {
        if ($isActive) {
            return $strokeColorActive || $strokeColor;
        }
        else {
            return $strokeColor;
        }
    }};
          `
    : css `
            fill: ${() => $fillColorActive || $fillColor || theme.config.text.primary};
            stroke: ${() => {
        if ($isActive) {
            return $strokeColorActive || $strokeColor;
        }
        else {
            return $strokeColor;
        }
    }};
          `}
  }

  ${({ $isCursor: isCursor }) => isCursor && `cursor: pointer;`}
`;
export default IconWrapper;
