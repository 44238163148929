var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { addParamsToUrl } from 'utils/query';
import { useNavigate } from './useNavigation';
export const useQueryNavigate = () => {
    const navigate = useNavigate();
    const navigateParams = useCallback((url, options) => {
        if (typeof url === 'number') {
            navigate(url);
        }
        else {
            const _a = options !== null && options !== void 0 ? options : {}, { query } = _a, opts = __rest(_a, ["query"]);
            const oldSearch = window.location.search;
            const params = new URLSearchParams(oldSearch).entries();
            const newUrl = addParamsToUrl(url, Object.assign(Object.assign({}, Object.fromEntries(params)), query));
            navigate(newUrl, opts);
        }
    }, [navigate]);
    return navigateParams;
};
