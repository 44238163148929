import { css } from 'styled-components';
const ultraSmall = css `
  ${({ theme }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
    font-size: ${(_c = (_b = (_a = theme.fonts) === null || _a === void 0 ? void 0 : _a.ultraSmall) === null || _b === void 0 ? void 0 : _b.fontSize) !== null && _c !== void 0 ? _c : 11}px;
    line-height: ${(_f = (_e = (_d = theme.fonts) === null || _d === void 0 ? void 0 : _d.ultraSmall) === null || _e === void 0 ? void 0 : _e.lineHeight) !== null && _f !== void 0 ? _f : 16}px;
  `;
}};
`;
const small = css `
  ${({ theme }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
    font-size: ${(_c = (_b = (_a = theme.fonts) === null || _a === void 0 ? void 0 : _a.small) === null || _b === void 0 ? void 0 : _b.fontSize) !== null && _c !== void 0 ? _c : 14}px;
    line-height: ${(_f = (_e = (_d = theme.fonts) === null || _d === void 0 ? void 0 : _d.small) === null || _e === void 0 ? void 0 : _e.lineHeight) !== null && _f !== void 0 ? _f : 24}px;
  `;
}};
`;
const body = css `
  ${({ theme }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
    font-size: ${(_c = (_b = (_a = theme.fonts) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.fontSize) !== null && _c !== void 0 ? _c : 16}px;
    line-height: ${(_f = (_e = (_d = theme.fonts) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.lineHeight) !== null && _f !== void 0 ? _f : 24}px;
  `;
}};
`;
const heading1 = css `
  ${({ theme }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
    font-size: ${(_c = (_b = (_a = theme.fonts) === null || _a === void 0 ? void 0 : _a.heading1) === null || _b === void 0 ? void 0 : _b.fontSize) !== null && _c !== void 0 ? _c : 28}px;
    line-height: ${(_f = (_e = (_d = theme.fonts) === null || _d === void 0 ? void 0 : _d.heading1) === null || _e === void 0 ? void 0 : _e.lineHeight) !== null && _f !== void 0 ? _f : 40}px;
  `;
}};
`;
const heading2 = css `
  ${({ theme }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
    font-size: ${(_c = (_b = (_a = theme.fonts) === null || _a === void 0 ? void 0 : _a.heading2) === null || _b === void 0 ? void 0 : _b.fontSize) !== null && _c !== void 0 ? _c : 24}px;
    line-height: ${(_f = (_e = (_d = theme.fonts) === null || _d === void 0 ? void 0 : _d.heading2) === null || _e === void 0 ? void 0 : _e.lineHeight) !== null && _f !== void 0 ? _f : 40}px;
  `;
}};
`;
const heading3 = css `
  ${({ theme }) => {
    var _a, _b, _c, _d, _e, _f;
    return css `
    font-size: ${(_c = (_b = (_a = theme.fonts) === null || _a === void 0 ? void 0 : _a.heading3) === null || _b === void 0 ? void 0 : _b.fontSize) !== null && _c !== void 0 ? _c : 18}px;
    line-height: ${(_f = (_e = (_d = theme.fonts) === null || _d === void 0 ? void 0 : _d.heading3) === null || _e === void 0 ? void 0 : _e.lineHeight) !== null && _f !== void 0 ? _f : 32}px;
  `;
}};
`;
export const ultraSmallMedium = css `
  font-weight: 500;
  ${ultraSmall};
`;
export const smallLight = css `
  font-weight: 300;
  line-height: 18px;
  ${small};
`;
export const smallReqular = css `
  font-weight: 400;
  ${small};
`;
export const smallMedium = css `
  font-weight: 500;
  ${small};
`;
export const smallSemiBold = css `
  font-weight: 600;
  ${small};
`;
export const bodyRegular = css `
  font-weight: 400;
  ${body};
`;
export const bodySemibold = css `
  font-weight: 600;
  ${body};
`;
export const heading1Medium = css `
  font-weight: 500;
  ${heading1}
`;
export const heading2Medium = css `
  font-weight: 500;
  ${heading2}
`;
export const heading3Medium = css `
  font-weight: 500;
  ${heading3}
`;
