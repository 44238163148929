import styled, { css } from 'styled-components';
export const AlertContainer = styled.div.withConfig({ displayName: "AlertContainer", componentId: "sc-ksp126" }) `
  padding: 20px;
  margin: 16px;
  border-radius: 16px;
  pointer-events: all;

  display: flex;
  flex-direction: column;

  ${({ theme, variant }) => css `
    border: 1px solid ${theme.config.alert.border};
    width: ${theme.responsive.isMobile ? 'auto' : '399px'};

    ${variant &&
    css `
      background-color: ${theme.config.alert[variant]};
    `}
  `}
`;
export const ContentContainer = styled.div.withConfig({ displayName: "ContentContainer", componentId: "sc-fpmk9g" }) `
  height: 100%;

  display: flex;
  margin-bottom: 12px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
export const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-18fgv0" }) `
  > div {
    white-space: break-spaces;
  }
`;
export const IconContainer = styled.div.withConfig({ displayName: "IconContainer", componentId: "sc-sgmv42" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;
export const CloseButton = styled.button.withConfig({ displayName: "CloseButton", componentId: "sc-jix1ux" }) `
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledAlertButtonsWrapper = styled.div.withConfig({ displayName: "StyledAlertButtonsWrapper", componentId: "sc-1u2mwv3" }) `
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin: 0 20px 20px 0;
  }
`;
