import { css } from 'styled-components';
export const scrollLight = css `
  scrollbar-color: ${({ theme }) => theme.config.scroll.background.default};

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.config.scroll.background.default};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.config.scroll.thumb.default};
    border-radius: 10px;
  }
`;
export const scrollMedium = css `
  scrollbar-color: ${({ theme }) => theme.config.scroll.background.default};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.config.scroll.background.default};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.config.scroll.thumb.default};
    border-radius: 10px;
  }
`;
