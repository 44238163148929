import styled, { css } from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { InputField } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-18tg1xi" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const ShowMoreBtn = styled.div.withConfig({ displayName: "ShowMoreBtn", componentId: "sc-6a161r" }) `
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  height: 32px;
  justify-content: center;

  transition: 0.3s ease-out;

  ${({ theme, selected }) => css `
    border-color: ${selected
    ? theme.colors.blue.primary
    : theme.colors.black.primary};
    background: ${theme.config.tags.background.default};
    color: ${theme.config.tags.text};
  `}
  &:hover {
    transition: 0.3s ease-out;
    transform: scale(105%, 105%);
  }
`;
export const ScrollContainer = styled.div.withConfig({ displayName: "ScrollContainer", componentId: "sc-3lqd4h" }) `
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 6px;
  ${scrollMedium};
`;
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-1sdlu19" }) `
  padding: 20px;
`;
export const WrapperTags = styled.div.withConfig({ displayName: "WrapperTags", componentId: "sc-qt0pnn" }) `
  padding: 20px 0px 40px 0px;
`;
export const CreateButtonContainer = styled.div.withConfig({ displayName: "CreateButtonContainer", componentId: "sc-b645mv" }) `
  margin-bottom: 4px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const InputsContainer = styled.div.withConfig({ displayName: "InputsContainer", componentId: "sc-1ronr4" }) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 26px 0 0 0;
`;
export const ManualInputWrapper = styled.div.withConfig({ displayName: "ManualInputWrapper", componentId: "sc-1aon63u" }) `
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
`;
export const NameInputContainer = styled.div.withConfig({ displayName: "NameInputContainer", componentId: "sc-1e6of3n" }) `
  display: flex;
  flex-direction: column;
  width: 30%;
`;
export const ManualInputContainer = styled.div.withConfig({ displayName: "ManualInputContainer", componentId: "sc-pb2hi3" }) `
  display: flex;
  flex-direction: column;
  width: 45%;
`;
export const DropdownInputWrapper = styled.div.withConfig({ displayName: "DropdownInputWrapper", componentId: "sc-1ebk5qw" }) `
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const DropdownInputContainer = styled.div.withConfig({ displayName: "DropdownInputContainer", componentId: "sc-40ae4d" }) `
  display: flex;
  flex-direction: column;
  width: 45%;
`;
export const HiddenInput = styled.input.withConfig({ displayName: "HiddenInput", componentId: "sc-1rofh0j" }) `
  display: none;
`;
export const WrapperRecipientsChips = styled.div.withConfig({ displayName: "WrapperRecipientsChips", componentId: "sc-j6mrkt" }) `
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-left: 16px;
  flex-wrap: wrap;

  ${({ $isColumn }) => $isColumn &&
    css `
      width: fit-content;
      margin-left: 0px;
    `}
`;
export const ManualInput = styled(InputField).withConfig({ displayName: "ManualInput", componentId: "sc-17982so" }) `
  border-radius: 16px;
  height: 22px;
  width: 100%;
`;
