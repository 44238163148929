import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { AppRoutes } from 'config';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import { ChoosePayment } from './choose-payment';
import { Cheque } from './choose-payment/forms/cheque';
import * as Styles from './styles';
export const PaymentPage = () => {
    const { t } = useTranslation();
    const { Home, Profile } = AppRoutes.Dashboard;
    const containerRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }, []);
    const onSubmit = () => { };
    return (_jsxs(Styles.Grid, Object.assign({ ref: containerRef }, { children: [_jsxs(Styles.Header, { children: [_jsx(Styles.ButtonBack, { text: "", icon: _jsx(IconWrapper, Object.assign({ "$fillColor": "none", "$strokeColor": "currentColor" }, { children: _jsx(Icon.Back, {}) })), variant: ButtonVariant.TETRIERY, to: Home + Profile.Home + Profile.Tariff }), _jsxs(Styles.Title, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Payment') })), _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular, "$colorVariant": "tetriery" }, { children: t('Change tariff') }))] })] }), _jsxs(Styles.DataPayment, { children: [_jsx(Styles.TitleData, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Payment details'), ":"] })) }), _jsx(Cheque, {})] }), _jsxs(Styles.Methods, { children: [_jsx(Styles.TitleData, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Choose a payment method'), ":"] })) }), _jsx(ChoosePayment, {})] }), _jsxs(Styles.Footer, { children: [_jsx(Button, { text: t('Pay'), onClick: onSubmit, padding: "24px" }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, onClick: () => { } })] })] })));
};
