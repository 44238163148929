import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TextVariant } from '../../../../../../common';
import { ButtonGenerate } from '../../image-generate/styles';
import * as Styles from './styles';
export const HeaderGeneratePost = ({ generateDescription, isButtonDisabled, }) => {
    const { t } = useTranslation();
    return (_jsxs(Styles.Wrapper, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Generate post') })), _jsx(ButtonGenerate, { text: t('Generate'), disabled: isButtonDisabled, onClick: () => generateDescription() })] }));
};
