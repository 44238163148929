import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { components } from 'react-select';
import { bodyRegular } from 'styles/mixins/fonts';
const Text = styled.div.withConfig({ displayName: "Text", componentId: "sc-1dhwom9" }) `
  ${bodyRegular};
  color: ${(p) => p.theme.config.select.multiValue.text};
`;
export const MultiValue = (props) => {
    return (_jsx(components.MultiValue, Object.assign({}, props, { children: _jsx(Text, { children: props.children }) })));
};
export const multiValueStyles = (theme) => {
    return {
        height: '28px',
        padding: '0 2px 0 8px',
        borderRadius: theme.borderRadius,
        backgroundColor: theme.config.select.multiValue.text,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        "div[role='button']": {
            paddingLeft: '0px',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
            '&:active': { backgroundColor: 'transparent' },
            svg: {
                width: '14px',
                height: '14px',
                path: { fill: theme.config.select.multiValue.text },
            },
        },
    };
};
