import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiCompaniesQuery } from '../query';
const tagTypes = ['Companies'];
export const CompaniesApi = createApi({
    tagTypes,
    reducerPath: 'CompaniesApi',
    baseQuery: apiCompaniesQuery,
    endpoints: (build) => ({
        companiesList: build.query({
            query: () => {
                return {
                    method: 'GET',
                    url: `${URLS.COMPANIES_LIST}`,
                };
            },
            providesTags: () => ['Companies'],
        }),
        companiesListForMain: build.query({
            query: () => {
                return {
                    method: 'GET',
                    url: `${URLS.COMPANIES_MAINPAGE_LIST}`,
                };
            },
            providesTags: () => ['CompaniesMain'],
        }),
        companyCreate: build.mutation({
            query: (values) => {
                return {
                    url: URLS.COMPANIES_CREATE_COMPANY,
                    method: 'POST',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: () => ['Companies', 'CompaniesMain'],
        }),
        companyUpdate: build.mutation({
            query: (values) => {
                return {
                    url: URLS.COMPANIES_UPDATE_COMPANY,
                    method: 'POST',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: () => ['Companies', 'CompaniesMain'],
        }),
        companyGet: build.mutation({
            query: (values) => {
                return {
                    url: URLS.COMPANIES_GET_COMPANY,
                    method: 'GET',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: () => ['Companies', 'CompaniesMain'],
        }),
        companyDelete: build.mutation({
            query: (values) => {
                return {
                    url: URLS.COMPANIES_DELETE_COMPANY,
                    method: 'POST',
                    params: Object.assign({}, values),
                };
            },
            invalidatesTags: () => ['Companies', 'CompaniesMain'],
        }),
    }),
});
export const { useCompaniesListQuery, useCompaniesListForMainQuery, useCompanyDeleteMutation, useCompanyGetMutation, useCompanyCreateMutation, useCompanyUpdateMutation, } = CompaniesApi;
