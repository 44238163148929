var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_AI_URL } from '../../../../../api/constants';
import { Loader, Text, TextVariant } from '../../../../../common';
import { Download, Sound } from '../../../../../common/icon/icons';
import { Form } from '../../../../../components/form/Form';
import { AudioListeningModal } from '../../../../../components/modal/audio-listening';
import { LoaderContainer } from '../generate-post/styles';
import { generateImageInitialValues, generateImageValidationSchema, keys, scale_intervals, scales, } from './config';
import * as Styles from './styles';
const ImageGenerateForm = ({ isLoading }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { values, setFieldValue, submitForm, errors } = useFormikContext();
    const fileInputRef = useRef(null);
    const uploadPhoto = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (_jsxs(Styles.ImageGenerateFormWrapper, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Music generate') })), !values.image && (_jsx(Styles.UploadImageButton, { text: t('Upload a photo'), disabled: isLoading, onClick: () => uploadPhoto(), variant: 'DARK' })), _jsx(Styles.FileInput, { type: "file", accept: "image/*", ref: fileInputRef, onChange: (event) => {
                    var _a, _b;
                    if ((_a = event.target.files) === null || _a === void 0 ? void 0 : _a.item(0)) {
                        setFieldValue('image', (_b = event.target.files) === null || _b === void 0 ? void 0 : _b.item(0));
                    }
                } }), values.image && (_jsxs(Styles.FullForm, { children: [_jsxs(Styles.ImageContainer, { children: [_jsx(Styles.ImagePreview, { src: URL.createObjectURL(values.image), alt: 'qwe' }), _jsx(Styles.ChangeImageButton, Object.assign({ onClick: () => uploadPhoto() }, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0444\u043E\u0442\u043E" })) }))] }), _jsxs(Styles.ParamsContainer, { children: [_jsxs(Styles.ParamRow, { children: [_jsx(Styles.ParamText, { children: "\u041E\u043A\u0442\u0430\u0432\u0430" }), _jsx(Styles.DropdownWrapper, { items: scale_intervals.map((i) => {
                                            return {
                                                label: i.toString(),
                                                onClick: () => setFieldValue('octave', i.toString()),
                                                rightIcon: null,
                                            };
                                        }), button: _jsx(Styles.DropdownButton, { children: values.octave.length ? values.octave : 'Выберите октаву' }) }), ((_a = errors.octave) === null || _a === void 0 ? void 0 : _a.length) && (_jsxs(Styles.ErrorText, { children: ["* ", errors.octave] }))] }), _jsxs(Styles.ParamRow, { children: [_jsx(Styles.ParamText, { children: "\u041B\u0430\u0434\u044B" }), _jsx(Styles.DropdownWrapper, { items: scales.map((i) => {
                                            return {
                                                label: i,
                                                onClick: () => setFieldValue('scale', i),
                                                rightIcon: null,
                                            };
                                        }), button: _jsx(Styles.DropdownButton, { children: values.scale.length ? values.scale : 'Выберите лад' }) }), ((_b = errors.scale) === null || _b === void 0 ? void 0 : _b.length) && (_jsxs(Styles.ErrorText, { children: ["* ", errors.scale] }))] }), _jsxs(Styles.ParamRow, { children: [_jsx(Styles.ParamText, { children: "\u041A\u043B\u044E\u0447" }), _jsx(Styles.DropdownWrapper, { items: keys.map((i) => {
                                            return {
                                                label: i.toString(),
                                                onClick: () => setFieldValue('key', i.toString()),
                                                rightIcon: null,
                                            };
                                        }), button: _jsx(Styles.DropdownButton, { children: values.key.length ? values.key : 'Выберите ключ' }) }), ((_c = errors.key) === null || _c === void 0 ? void 0 : _c.length) && (_jsxs(Styles.ErrorText, { children: ["* ", errors.key] }))] }), _jsx(Styles.GenerateButton, { text: 'Сгенерировать', onClick: () => submitForm() })] })] }))] }));
};
export const MusicGenerate = () => {
    const { t } = useTranslation();
    const [lastUrl, setLastUrl] = useState('');
    const [generatedMusic, setGeneratedMusic] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isAudioModalOpen, setIsAudioModalOpen] = useState(false);
    const generateMusic = ({ key, image, octave, scale }) => __awaiter(void 0, void 0, void 0, function* () {
        const url = `${BASE_AI_URL}/generate_music?octave=${octave}&key=${key}&scale=${scale}`;
        if (lastUrl !== url) {
            setLastUrl(url);
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', image);
            try {
                const response = (yield fetch(url, {
                    method: 'post',
                    body: formData,
                }));
                const file = yield response.blob();
                setGeneratedMusic(URL.createObjectURL(file));
            }
            catch (_) {
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const downloadFile = (url) => {
        const aElement = document.createElement('a');
        const time = new Date();
        aElement.setAttribute('download', `${time.getTime()}.wav`);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    };
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Form, Object.assign({ initialValues: generateImageInitialValues, validationSchema: generateImageValidationSchema, onSubmit: generateMusic }, { children: _jsx(ImageGenerateForm, { isLoading: isLoading }) })), isLoading && (_jsx(LoaderContainer, { children: _jsx(Loader, { text: '' }) })), generatedMusic && (_jsxs(Styles.Generated, { children: [_jsxs(Styles.DownloadButtonWrapper, { children: [_jsx(Styles.DownloadButton, { text: t('Listen'), variant: 'DARK', icon: _jsx(Sound, {}), onClick: () => setIsAudioModalOpen(true) }), _jsx(Styles.DownloadButton, { text: t('Download'), variant: 'DARK', icon: _jsx(Download, {}), onClick: () => downloadFile(generatedMusic) })] }), _jsx(AudioListeningModal, { isOpen: isAudioModalOpen, closeModal: () => setIsAudioModalOpen(false), fileUrl: generatedMusic })] }))] }) }));
};
