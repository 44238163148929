import styled from 'styled-components';
import { Button as ComponentButton } from 'common';
import { heading1Medium, heading2Medium, smallLight, } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1ov1dx0" }) `
  width: 100%;
  border-radius: 16px;
  height: 280px;
  margin-right: 16px;
  background: ${({ theme }) => theme.config.background.tetriery};
  border: ${({ theme }) => theme.config.dropdown.border.all};

  @media screen and (max-width: 1600px) {
    margin-right: 0;
    margin-bottom: 16px;
    height: 100%;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-2yhpoy" }) `
  padding: 24px 24px 22px;

  @media screen and (max-width: 560px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-z2ugp5" }) `
  ${heading1Medium}

  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.config.text.primary};

  @media screen and (max-width: 560px) {
    ${heading2Medium}
  }
`;
export const SubTitle = styled.div.withConfig({ displayName: "SubTitle", componentId: "sc-1qwvydk" }) `
  ${smallLight}

  margin: 12px 0px 24px 0px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.config.text.secondary};

  @media screen and (max-width: 560px) {
    text-align: start;
  }
`;
export const Button = styled(ComponentButton).withConfig({ displayName: "Button", componentId: "sc-143z6oi" }) `
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
