import styled, { css } from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { Button } from 'common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1dd5m9z" }) `
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-17uk6ke" }) `
  width: 100%;
  padding: 40px;
  max-height: calc(100vh - 150px);
  ${scrollMedium};
`;
export const Grid = styled.div.withConfig({ displayName: "Grid", componentId: "sc-1r827ap" }) `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 48px 1fr 72px;
  grid-template-areas:
    'header header'
    'data methods'
    'footer footer';
  height: 100%;

  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'header'
      'data'
      'methods'
      'footer';
  }
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-19vzbnv" }) `
  grid-area: header;
  display: flex;

  gap: 24px;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-4um3oj" }) `
  display: flex;
  align-items: flex-end;

  gap: 16px;
`;
export const TitleData = styled.div.withConfig({ displayName: "TitleData", componentId: "sc-1s1eryt" }) `
  margin: 24px 0px 16px;
`;
export const DataPayment = styled.div.withConfig({ displayName: "DataPayment", componentId: "sc-1jju3gi" }) `
  grid-area: data;
  max-width: 340px;
`;
export const Methods = styled.div.withConfig({ displayName: "Methods", componentId: "sc-19qhewk" }) `
  grid-area: methods;
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-eb1kuc" }) `
  grid-area: footer;
  display: flex;
  align-items: center;
  margin-top: 124px;
  padding: 48px 0px;
  border-top: 1px solid ${({ theme }) => theme.config.line.secondary};

  gap: 24px;

  @media screen and (max-width: 720px) {
    margin-top: 24px;
  }
`;
export const ButtonBack = styled(Button).withConfig({ displayName: "ButtonBack", componentId: "sc-1s4ekjk" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  border-radius: 50%;

  ${({ theme }) => css `
    border: ${theme.config.uploadImage.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}

  &:hover {
    background: none;
  }
`;
export const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-14p1blo" }) `
  margin: 16px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.config.line.secondary};
`;
