export const categories = [
    {
        name: 'ai',
        title: 'Artificial intelligence',
        desc: 'ai desc',
    },
    {
        name: 'advertising',
        title: 'Advertisement',
        desc: 'advertising desc',
    },
    {
        name: 'marketplaces',
        title: 'Marketplaces',
        desc: 'marketplaces desc',
    },
    {
        name: 'parsers',
        title: 'Parsing',
        desc: 'parsers desc',
    },
    {
        name: 'reports',
        title: 'Reports',
        desc: 'reports desc',
    },
    {
        name: 'text_mailing',
        title: 'Mailing',
        desc: 'text_mailing desc',
    },
    {
        name: 'voice_mailing',
        title: 'Voice Robot',
        desc: 'voice_mailing desc',
    },
];
export const tariffsModel = {
    tariff1: {
        name: 'Минимальный',
        ai: true,
        parsers: false,
        reports: false,
        advertising: true,
        marketplaces: true,
        text_mailing: false,
        voice_mailing: false,
    },
    tariff2: {
        name: 'Оптимальный',
        ai: true,
        parsers: true,
        reports: true,
        advertising: true,
        marketplaces: true,
        text_mailing: false,
        voice_mailing: false,
    },
    tariff3: {
        name: 'Максимальный',
        ai: true,
        parsers: true,
        reports: true,
        advertising: true,
        marketplaces: true,
        text_mailing: true,
        voice_mailing: true,
    },
};
