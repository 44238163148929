import * as XLSX from 'xlsx';
export const handleCSVFile = (data, validationFunc) => {
    const content = new TextDecoder('utf-8').decode(data);
    const parsedData = [];
    const rows = content.split('\n');
    for (const row of rows) {
        const email = row.trim();
        if (validationFunc(email)) {
            parsedData.push({ label: email, value: email });
        }
    }
    return parsedData;
};
export const handleXLSXFile = (data, validationFunc) => {
    const workbook = XLSX.read(data, { type: 'array' });
    const firstSheet = workbook.SheetNames[0];
    const elements = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    const result = [];
    elements.forEach(({ Value }) => {
        if (validationFunc(Value)) {
            result.push({
                label: Value,
                value: Value,
            });
        }
    });
    return result;
};
