var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { useTranslation } from 'react-i18next';
import { CheckboxLabel, CheckboxMark, CheckboxText, StyledCheckbox, StyledIcon, } from './styles';
const Checkbox = (_a) => {
    var { id, icon, label, disabled, className, block = false, checked = false, positionRightIcon, transparent = false, checkedIcon = _jsx(Icon.Check, {}) } = _a, props = __rest(_a, ["id", "icon", "label", "disabled", "className", "block", "checked", "positionRightIcon", "transparent", "checkedIcon"]);
    const { t } = useTranslation();
    const checkboxId = id || label;
    return (_jsxs(CheckboxLabel, Object.assign({ "$block": block, checked: checked, disabled: disabled, htmlFor: checkboxId, className: className }, { children: [_jsx(StyledCheckbox, Object.assign({ type: "checkbox", checked: checked, disabled: disabled, "$transparent": transparent, id: checkboxId }, props)), _jsx(CheckboxMark, Object.assign({ checked: checked, label: label }, { children: checkedIcon })), icon && (_jsx(StyledIcon, Object.assign({ positionRightIcon: positionRightIcon }, { children: icon }))), label && _jsx(CheckboxText, { children: t(label) })] })));
};
Checkbox.defaultProps = {
    icon: undefined,
    label: undefined,
    positionRightIcon: false,
};
export { Checkbox };
