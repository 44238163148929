import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { Button as ButtomComponent } from 'common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-v6shkv" }) `
  gap: 32px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 32px 24px 24px 0px;
  background-color: ${({ theme }) => theme.config.background.secondary};

  overflow-y: auto;

  @media screen and (max-width: 500px) {
    margin-left: 24px;
  }
`;
export const ScrollWrapper = styled.div.withConfig({ displayName: "ScrollWrapper", componentId: "sc-d35eoq" }) `
  width: 100%;
  padding: 0px 48px;

  overflow-y: auto;
  max-height: calc(100vh - 150px);
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1j65zw6" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 48px 110px;
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.background.tetriery};
  gap: 100px;

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 50px;
  }

  @media screen and (max-width: 400px) {
    padding: 24px 10px;
  }
`;
export const TextWrapper = styled.div.withConfig({ displayName: "TextWrapper", componentId: "sc-13pik7k" }) `
  margin: 40px 0px 32px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1piwtox" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const MailingWrapper = styled.div.withConfig({ displayName: "MailingWrapper", componentId: "sc-pgqo3r" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-rquv6y" }) `
  display: flex;
  gap: 24px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 16px;
  }
`;
export const Button = styled(ButtomComponent).withConfig({ displayName: "Button", componentId: "sc-761tnb" }) `
  border-radius: 8px;
  padding-inline: 90px;
  white-space: nowrap;
  width: 250px;
`;
