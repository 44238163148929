import styled from 'styled-components';
import { Button } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1qeurvz" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-e962ii" }) `
  display: flex;
  flex-direction: column;
  margin: 40px;
  gap: 24px;
`;
export const AudioAction = styled.div.withConfig({ displayName: "AudioAction", componentId: "sc-g1224l" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const AudioButtonContainer = styled.div.withConfig({ displayName: "AudioButtonContainer", componentId: "sc-1uknfsf" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const AudioButton = styled(Button).withConfig({ displayName: "AudioButton", componentId: "sc-n48w82" }) `
  width: fit-content;
`;
// export const AudioButtonText = styled.text
export const AudioInput = styled.input.withConfig({ displayName: "AudioInput", componentId: "sc-kxx9yc" }) `
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;
  padding: 0;
`;
export const GeneratedText = styled.div.withConfig({ displayName: "GeneratedText", componentId: "sc-9brtcj" }) `
  white-space: pre-wrap;
  background: #383838;
  border-radius: 16px;
  padding: 15px;
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "sc-t8ug2y" }) `
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
