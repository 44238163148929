import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TextVariant } from '../../../../../common';
import * as Styles from './styles';
var ETableFilters;
(function (ETableFilters) {
    ETableFilters["TITLE_MIN"] = "TITLE_MIN";
    ETableFilters["TITLE_MAX"] = "TITLE_MAX";
    ETableFilters["REVENUE_MIN"] = "REVENUE_MIN";
    ETableFilters["REVENUE_MAX"] = "REVENUE_MAX";
})(ETableFilters || (ETableFilters = {}));
export var ETableType;
(function (ETableType) {
    ETableType["CATEGORIES"] = "CATEGORIES";
    ETableType["PRODUCTS"] = "PRODUCTS";
    ETableType["BRANDS"] = "BRANDS";
    ETableType["SELLERS"] = "SELLERS";
})(ETableType || (ETableType = {}));
const sortByLists = (a, b) => {
    return a.join('').localeCompare(b.join(''));
};
const sortBy = (filter) => (a, b) => {
    var _a, _b;
    switch (filter) {
        case ETableFilters.REVENUE_MAX:
            return b.revenue - a.revenue;
        case ETableFilters.REVENUE_MIN:
            return a.revenue - b.revenue;
        case ETableFilters.TITLE_MAX:
            if (a.title && b.title) {
                return (_a = a.title) === null || _a === void 0 ? void 0 : _a.localeCompare(b.title);
            }
            else if (a.categories && b.categories) {
                return sortByLists(a.categories, b.categories);
            }
            else {
                return 0;
            }
        case ETableFilters.TITLE_MIN:
            if (a.title && b.title) {
                return (_b = b.title) === null || _b === void 0 ? void 0 : _b.localeCompare(a.title);
            }
            else if (a.categories && b.categories) {
                return sortByLists(b.categories, a.categories);
            }
            else {
                return 0;
            }
        default:
            throw new Error(`unknown filter type ${filter}`);
    }
};
const TableItems = ({ type, items, filter }) => {
    const [sortedItems, setSortedItems] = useState([...items].sort(sortBy(filter)));
    const RUB = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        maximumFractionDigits: 0,
    });
    useEffect(() => {
        setSortedItems([...items].sort(sortBy(filter)));
    }, [filter, items]);
    const CategoriesToTitle = ({ categories }) => {
        return (_jsx(Styles.CategoriesWrapper, { children: categories.map((cat, i, arr) => (_jsxs(Styles.CategoryWrapper, { children: [_jsx(Styles.Category, { children: _jsx(Styles.TitleText, { children: cat }) }), i < arr.length - 1 && (_jsx(Styles.CategorySeparator, { children: "/" }))] }, i))) }));
    };
    return (_jsx(_Fragment, { children: sortedItems.map((i) => {
            return (_jsxs(Styles.TableRow, { children: [type === ETableType.PRODUCTS && (_jsx(Styles.ProductImageWrapper, { children: _jsx(Styles.ProductImage, { src: i.image }) })), _jsxs(Styles.TableItemTitle, { children: [type === ETableType.CATEGORIES && i.categories && (_jsx(CategoriesToTitle, { categories: i.categories })), type === ETableType.PRODUCTS && (_jsx(Styles.TitleText, { children: i.title })), [ETableType.BRANDS, ETableType.SELLERS].includes(type) && (_jsx(Styles.TitleText, { children: i.title }))] }), _jsx(Styles.TableItemRevenue, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: RUB.format(i.revenue) })) })] }, i.id));
        }) }));
};
export const MarketplaceTable = ({ tableItems, columnName, searchQuery, }) => {
    const { t } = useTranslation();
    const [filterState, setFilterState] = useState(ETableFilters.REVENUE_MAX);
    const TitleChevron = () => {
        if (filterState === ETableFilters.TITLE_MAX) {
            return _jsx(Styles.ChevronBottom, {});
        }
        else if (filterState === ETableFilters.TITLE_MIN) {
            return _jsx(Styles.ChevronTop, {});
        }
        else {
            return _jsx(_Fragment, {});
        }
    };
    const RevenueChevron = () => {
        if (filterState === ETableFilters.REVENUE_MAX) {
            return _jsx(Styles.ChevronBottom, {});
        }
        else if (filterState === ETableFilters.REVENUE_MIN) {
            return _jsx(Styles.ChevronTop, {});
        }
        else {
            return _jsx(_Fragment, {});
        }
    };
    return (_jsxs(Styles.Table, { children: [_jsxs(Styles.TableHeader, { children: [_jsxs(Styles.TableHeaderTitle, Object.assign({ onClick: () => {
                            setFilterState(filterState === ETableFilters.TITLE_MAX
                                ? ETableFilters.TITLE_MIN
                                : ETableFilters.TITLE_MAX);
                        } }, { children: [_jsx(Text, Object.assign({ "$colorVariant": 'secondary', "$variant": TextVariant.h3Medium }, { children: t(columnName) })), _jsx(TitleChevron, {})] })), _jsxs(Styles.TableHeaderRevenue, Object.assign({ onClick: () => {
                            setFilterState(filterState === ETableFilters.REVENUE_MAX
                                ? ETableFilters.REVENUE_MIN
                                : ETableFilters.REVENUE_MAX);
                        } }, { children: [_jsx(RevenueChevron, {}), _jsx(Text, Object.assign({ "$colorVariant": 'secondary', "$variant": TextVariant.h3Medium }, { children: t('Revenue') }))] }))] }), _jsx(TableItems, { items: tableItems, type: searchQuery, filter: filterState })] }));
};
