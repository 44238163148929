import styled, { css } from 'styled-components';
import { Icon } from 'common/icon';
export const Clock = styled(Icon.Tooltip).withConfig({ displayName: "Clock", componentId: "sc-1p5rnqa" }) `
  ${({ theme, disabled }) => css `
    margin-right: 4px;
    transform: none !important;
    path {
      fill: ${disabled
    ? theme.config.select.label.disabled
    : theme.config.select.text.default} !important;
    }
  `}
`;
