import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AIApi } from './api/ai';
import { AudioClipsApi } from './api/audio-clips';
import { authApi } from './api/auth';
import { CompaniesApi } from './api/companies';
import { databaseApi } from './api/databases';
import { exampleApi } from './api/example';
import { MailingHistoryApi } from './api/mailing-history';
import { MailingApi } from './api/mailings';
import { NotificationApi } from './api/notifications';
import { parserApi } from './api/parser';
import { profileApi } from './api/profile';
import { ProjectsApi } from './api/projects';
import { VoiceRobotApi } from './api/voice-robot';
import { authSlice } from './slices/auth';
import { loaderReducer } from './slices/loader';
import { profileSlice } from './slices/profile';
import { BalanceApi } from './api/balance';
const appReducer = combineReducers({
    loader: loaderReducer,
    [authSlice.name]: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileSlice.name]: profileSlice.reducer,
    [parserApi.reducerPath]: parserApi.reducer,
    [exampleApi.reducerPath]: exampleApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [databaseApi.reducerPath]: databaseApi.reducer,
    [AIApi.reducerPath]: AIApi.reducer,
    [VoiceRobotApi.reducerPath]: VoiceRobotApi.reducer,
    [ProjectsApi.reducerPath]: ProjectsApi.reducer,
    [MailingApi.reducerPath]: MailingApi.reducer,
    [MailingHistoryApi.reducerPath]: MailingHistoryApi.reducer,
    [BalanceApi.reducerPath]: BalanceApi.reducer,
    [CompaniesApi.reducerPath]: CompaniesApi.reducer,
    [AudioClipsApi.reducerPath]: AudioClipsApi.reducer,
    [NotificationApi.reducerPath]: NotificationApi.reducer,
});
const rootReducer = (state, action) => {
    return appReducer(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
    // @ts-ignore
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        authApi.middleware,
        parserApi.middleware,
        exampleApi.middleware,
        profileApi.middleware,
        databaseApi.middleware,
        AIApi.middleware,
        VoiceRobotApi.middleware,
        ProjectsApi.middleware,
        MailingApi.middleware,
        MailingHistoryApi.middleware,
        BalanceApi.middleware,
        CompaniesApi.middleware,
        AudioClipsApi.middleware,
        NotificationApi.middleware,
    ],
});
