import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Button, Text, TextVariant } from '../../common';
import { Warning } from '../../common/icon/icons';
import { useNavigate } from '../../hooks';
import * as Styles from './styles';
export const DevStub = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    return (_jsxs(Styles.DevStubContainer, { children: [_jsxs(Styles.DevStubTitle, { children: [_jsx(Styles.DevStubWarningIconWrapper, Object.assign({ "$strokeColor": theme.colors.green.tetriery, "$fillColor": 'transparent' }, { children: _jsx(Warning, {}) })), _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('In development') })), _jsx(Styles.DevStubWarningIconWrapper, Object.assign({ "$strokeColor": theme.colors.green.tetriery, "$fillColor": 'transparent' }, { children: _jsx(Warning, {}) }))] }), _jsxs(Styles.DevStubDesc, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium, "$colorVariant": 'secondary' }, { children: "\u0414\u0430\u043D\u043D\u0430\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043F\u043E\u043A\u0430 \u043D\u0430\u0445\u043E\u0434\u0438\u0442\u044C\u0441\u044F \u0432 \u0440\u0430\u0437\u0440\u0430\u0431\u043E\u0442\u043A\u0435" })), _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium, "$colorVariant": 'secondary' }, { children: "\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u0435\u0451 \u043F\u043E\u0437\u0436\u0435" }))] }), _jsx("div", Object.assign({ style: {
                    fontSize: '5px',
                    lineHeight: '5px',
                    height: '5px',
                    userSelect: 'none',
                    cursor: 'default',
                    color: theme.config.background.tetriery,
                } }, { children: "\u0421\u043A\u043E\u0440\u043E \u0437\u0434\u0435\u0441\u044C \u0431\u0443\u0434\u0435\u0442 \u0432\u043A\u0443\u0441\u043D\u043E, \u0430 \u043F\u043E\u043A\u0430 \u0440\u0435\u043C\u043E\u043D\u0442" })), _jsx(Button, { text: 'Вернуться на главную', variant: 'TETRIERY', onClick: () => navigate('/dashboard/home') })] }));
};
