var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { controlError } from '../../../../../../utils/controlError';
import { SelectRecipients } from '../index';
export const SelectRecipientsField = (_a) => {
    var _b;
    var { name, options, isColumn, onChange, onlyValue, isCreatable, isAutocomplete } = _a, props = __rest(_a, ["name", "options", "isColumn", "onChange", "onlyValue", "isCreatable", "isAutocomplete"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = t(controlError(meta, name, t((_b = props.placeholder) !== null && _b !== void 0 ? _b : '')));
    return (_jsx(_Fragment, { children: _jsx(SelectRecipients, Object.assign({}, field, { error: error, isColumn: isColumn, items: field.value, onClose: (item) => {
                var _a;
                return helpers.setValue((_a = field.value) === null || _a === void 0 ? void 0 : _a.filter((i) => i.value !== item.value));
            }, onChange: (values) => {
                helpers.setValue(values);
            }, options: options }, props)) }));
};
