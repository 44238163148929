import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiAudioClipsQuery } from '../query';
const tagTypes = ['CallList', 'ClipsList'];
export const AudioClipsApi = createApi({
    tagTypes,
    reducerPath: 'AudioClipsApi',
    baseQuery: apiAudioClipsQuery,
    endpoints: (build) => ({
        clipsList: build.query({
            query: () => {
                return {
                    method: 'GET',
                    url: URLS.AUDIO_CLIPS_CLIP_LIST,
                };
            },
            providesTags: () => ['ClipsList'],
        }),
        clipCreate: build.mutation({
            query: ({ user_id, clip_description, clip_name, audio }) => {
                const formData = new FormData();
                formData.append('file', audio);
                return {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                    url: URLS.AUDIO_CLIPS_CLIP_CREATE +
                        `?user_id=${user_id}&clip_name=${clip_name}&clip_description=${clip_description}`,
                    data: formData,
                };
            },
            invalidatesTags: () => ['ClipsList'],
        }),
        clipDelete: build.mutation({
            query: ({ user_id, clip_id }) => {
                return {
                    method: 'POST',
                    url: URLS.AUDIO_CLIPS_CLIP_DELETE +
                        `?user_id=${user_id}&clip_id=${clip_id}`,
                };
            },
            invalidatesTags: () => ['ClipsList'],
        }),
        callList: build.query({
            query: () => {
                return {
                    method: 'GET',
                    url: URLS.AUDIO_CLIPS_CALL_LIST,
                };
            },
            providesTags: () => ['CallList'],
        }),
        callCreate: build.mutation({
            query: ({ user_id, call_name, audioclip_id, phones }) => {
                return {
                    method: 'POST',
                    url: URLS.AUDIO_CLIPS_CALL_CREATE +
                        `?user_id=${user_id}&call_name=${call_name}&audioclip_id=${audioclip_id}`,
                    data: phones,
                };
            },
            invalidatesTags: () => ['CallList'],
        }),
        callDelete: build.mutation({
            query: ({ user_id, call_id }) => {
                return {
                    method: 'POST',
                    url: URLS.AUDIO_CLIPS_CALL_DELETE +
                        `?user_id=${user_id}&call_id=${call_id}`,
                };
            },
            invalidatesTags: () => ['CallList'],
        }),
    }),
});
export const { useClipsListQuery, useClipCreateMutation, useClipDeleteMutation, useCallListQuery, useCallCreateMutation, useCallDeleteMutation, } = AudioClipsApi;
