import styled from 'styled-components';
import { Button } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-6hnzc1" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-4wqze8" }) `
  display: flex;
  flex-direction: column;
  margin: 40px;
  gap: 24px;
`;
export const Generated = styled.div.withConfig({ displayName: "Generated", componentId: "sc-w7f07x" }) `
  width: fit-content;
  position: relative;
`;
export const GeneratedImage = styled.img.withConfig({ displayName: "GeneratedImage", componentId: "sc-rd1ija" }) `
  max-width: 60vw;
  border-radius: 16px;
`;
export const DownloadButtonWrapper = styled.a.withConfig({ displayName: "DownloadButtonWrapper", componentId: "sc-17wl01j" }) ``;
export const DownloadButton = styled(Button).withConfig({ displayName: "DownloadButton", componentId: "sc-ug48da" }) `
  position: absolute;
  top: 10px;
  right: 5px;
  width: fit-content;
`;
export const ButtonGenerate = styled(Button).withConfig({ displayName: "ButtonGenerate", componentId: "sc-dhk6wb" }) `
  width: fit-content;
`;
export const ImageGenerateFormWrapper = styled.div.withConfig({ displayName: "ImageGenerateFormWrapper", componentId: "sc-10fgwd2" }) `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
