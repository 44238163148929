var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useFormikContext } from 'formik';
import React, { createContext, useEffect, useState } from 'react';
import globusFavicon from '../../../../../../assets/images/globusFavicon.png';
import { AdvWebToggle } from './AdvWebToggle';
import { FirstVariant } from './FirstVariant';
import { SearchPreview } from './SearchPreview';
import { SecondVariant } from './SecondVariant';
import * as Styles from './styles';
import { ThirdVariant } from './ThirdVariant';
export var EPreviewType;
(function (EPreviewType) {
    EPreviewType["ADV_WEB"] = "ADV_WEB";
    EPreviewType["SEARCH"] = "SEARCH";
})(EPreviewType || (EPreviewType = {}));
const previewTabItems = [
    {
        label: 'Рекламная сеть',
        value: EPreviewType.ADV_WEB,
    },
    {
        label: 'Поиск',
        value: EPreviewType.SEARCH,
    },
];
export const PreviewContext = createContext(null);
export const AdvPreview = () => {
    const { values } = useFormikContext();
    const [previewType, setPreviewType] = useState(EPreviewType.ADV_WEB);
    const [webPreviewVariant, setWebPreviewVariant] = useState(1);
    const [faviconSrc, setFaviconSrc] = useState('');
    const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentHeader, setCurrentHeader] = useState('');
    const [currentText, setCurrentText] = useState('');
    const [filteredHeaders, setFilteredHeaders] = useState([]);
    const [filteredTexts, setFilteredTexts] = useState([]);
    const siteFavicon = () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield axios.get(`https://favicongrabber.com/api/grab/${values.mainUrl}`);
        setFaviconSrc(response.data.icons[0].src);
    });
    useEffect(() => {
        siteFavicon();
    }, [values.mainUrl]);
    useEffect(() => {
        setFilteredHeaders(values.headers.filter((header) => header.header.length));
    }, [values.headers]);
    useEffect(() => {
        setFilteredTexts(values.texts.filter((text) => text.text.length));
    }, [values.texts]);
    const headerInterval = () => {
        if (!filteredHeaders.length) {
            setCurrentHeader('Заголовок объявления');
        }
        else {
            setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % filteredHeaders.length);
        }
    };
    const textInterval = () => {
        if (!filteredTexts.length) {
            setCurrentText('Текст объявления');
        }
        else {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % filteredTexts.length);
        }
    };
    useEffect(() => {
        var _a;
        setCurrentHeader((_a = filteredHeaders === null || filteredHeaders === void 0 ? void 0 : filteredHeaders[currentHeaderIndex]) === null || _a === void 0 ? void 0 : _a.header);
    }, [currentHeaderIndex, filteredHeaders]);
    useEffect(() => {
        var _a;
        setCurrentText((_a = filteredTexts === null || filteredTexts === void 0 ? void 0 : filteredTexts[currentTextIndex]) === null || _a === void 0 ? void 0 : _a.text);
    }, [currentTextIndex, filteredTexts]);
    useEffect(() => {
        const changeHeader = setInterval(() => headerInterval(), 3000);
        const changeText = setInterval(() => textInterval(), 3000);
        return () => {
            clearInterval(changeHeader);
            clearInterval(changeText);
        };
    }, [filteredHeaders, filteredTexts]);
    return (_jsxs(Styles.PreviewWrapper, { children: [_jsxs(Styles.PreviewHandlers, { children: [_jsxs(Styles.PreviewTabs, { children: [previewTabItems === null || previewTabItems === void 0 ? void 0 : previewTabItems.map((item) => (_jsx(Styles.PreviewTabLink, Object.assign({ id: item.value.toString(), "$isActive": item.value === previewType, onClick: () => {
                                    setPreviewType(item.value);
                                } }, { children: _jsx(Styles.PreviewTabLabel, Object.assign({ "$isActive": item.value === previewType }, { children: item.label })) }), item.value.toString()))), _jsx(Styles.PreviewTabBackground, { "$step": previewType })] }), previewType === EPreviewType.ADV_WEB && (_jsx(AdvWebToggle, { variant: webPreviewVariant, setVariant: setWebPreviewVariant }))] }), _jsxs(PreviewContext.Provider, Object.assign({ value: {
                    faviconSrc: faviconSrc || globusFavicon,
                    header: currentHeader || 'Заголовок объявления',
                    text: currentText || 'Текст объявления',
                    url: values.mainUrl || '',
                } }, { children: [previewType === EPreviewType.ADV_WEB && (_jsxs("div", { children: [webPreviewVariant === 1 && _jsx(FirstVariant, {}), webPreviewVariant === 2 && _jsx(SecondVariant, {}), webPreviewVariant === 3 && _jsx(ThirdVariant, {})] })), previewType === EPreviewType.SEARCH && _jsx(SearchPreview, {})] }))] }));
};
