import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { ButtonVariant, Dialog, InputField, Text, TextVariant, } from '../../../common';
import * as Styles from './styles';
import { useRefillBalanceMutation } from '../../../store/api/balance';
import { handleMutation } from '../../../utils/handleMutation';
import { useMeQuery } from '../../../store/api/profile';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { Form } from '../../form/Form';
import { refillBalanceInitialValues, refillBalanceValidationSchema, } from './config';
import { useFormikContext } from 'formik';
import { Ruble } from '../../../common/icon/icons';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
import { useTheme } from 'styled-components';
export const RefillBalanceForm = ({ onSubmitForm }) => {
    const { values } = useFormikContext();
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(Styles.InputsWrapper, { children: _jsx(InputField, { label: "\u0421\u0443\u043C\u043C\u0430 \u0434\u043B\u044F \u043F\u043E\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F", name: "refillAmount", placeholder: 'Сумма пополнения', inputType: "text", rightIcon: _jsx(IconWrapper, Object.assign({ "$strokeColor": theme.config.icon.primary }, { children: _jsx(Ruble, {}) })) }) }), _jsx(Styles.ButtonWrapper, { children: _jsx(Styles.Button, { variant: ButtonVariant.LIGHT, text: 'Перейти к оплате', onClick: () => onSubmitForm(values) }) })] }));
};
export const RefillBalanceModal = ({ isOpen, closeModal, }) => {
    const alert = useAlert();
    const { t } = useTranslation();
    const { data: user } = useMeQuery();
    const [refillBalance, mutationRefill] = useRefillBalanceMutation();
    const onRefillBalance = (values) => {
        var _a;
        if (user && user._id) {
            refillBalance({
                user_id: (_a = user._id) === null || _a === void 0 ? void 0 : _a.toString(),
                amount: values.refillAmount,
            });
        }
    };
    const onSuccess = () => {
        document.location.replace(mutationRefill.data);
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationRefill), { onSuccess,
        onError }));
    return (_jsx(_Fragment, { children: _jsxs(Dialog, Object.assign({ isOpen: isOpen, width: '444px', topMargin: '200px', onClose: closeModal, zIndex: 999 }, { children: [_jsx(Styles.DialogHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0431\u0430\u043B\u0430\u043D\u0441" })) }), _jsx(Styles.DialogContent, { children: _jsx(Form, Object.assign({ initialValues: refillBalanceInitialValues, validationSchema: refillBalanceValidationSchema, onSubmit: onRefillBalance }, { children: _jsx(RefillBalanceForm, { onSubmitForm: onRefillBalance }) })) })] })) }));
};
