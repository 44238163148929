import { Icon } from 'common/icon';
import styled from 'styled-components';
import { smallMedium } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-jxhrt0" }) `
  display: flex;
  align-items: center;
  margin: 16px 0px 16px 40px;

  @media screen and (max-width: 420px) {
    margin-left: 35px;
  }

  @media screen and (max-width: 376px) {
    margin-left: 25px;
  }
`;
export const WrapperTooltip = styled.div.withConfig({ displayName: "WrapperTooltip", componentId: "sc-3ixhr0" }) `
  display: flex;
  align-items: center;
  border-radius: 50px;

  margin-right: 24px;

  @media screen and (max-width: 376px) {
    margin-right: 16px;
  }
`;
export const TextTooltip = styled.div.withConfig({ displayName: "TextTooltip", componentId: "sc-q7s1gq" }) `
  margin: 8px 2px 8px 10px;

  // ${smallMedium}
  letter-spacing: -0.01em;
  flex: none;
  order: 0;
  flex-grow: 0;

  color: ${({ theme }) => theme.config.dashboard.text};

  cursor: pointer;

  @media screen and (max-width: 1023px) {
    display: none;
    transition: all 0.3s ease-in-out;
  }
`;
export const WrapperCompany = styled.div.withConfig({ displayName: "WrapperCompany", componentId: "sc-xmfdq2" }) `
  display: flex;
  align-items: center;

  opacity: 0.5;
  border-radius: 30px;
  padding-right: 16px;
  background: ${({ theme }) => theme.isDarkTheme
    ? theme.config.toggle.bg.active
    : theme.config.dropdown.background.default};
  transition: padding 0.3s ease-in-out;

  @media screen and (max-width: 1023px) {
    padding: 4px;
    border-radius: 10px;
    transition: padding 0.3s ease-in-out;
  }
`;
export const TextCompany = styled.div.withConfig({ displayName: "TextCompany", componentId: "sc-pngehy" }) ``;
// export const ArrowHint = styled(Icon.ArrowDown)<{ isVisible: boolean }>`
//   transition: transform 0.3s ease-in-out;
//   transform: ${({ isVisible }) => (isVisible ? 'rotate(180deg)' : 'rotate(0)')};
// `;
export const ArrowCompany = styled(Icon.ArrowDown).withConfig({ displayName: "ArrowCompany", componentId: "sc-12tsxge" }) ``;
export const ContainerTooltip = styled.div.withConfig({ displayName: "ContainerTooltip", componentId: "sc-fe1ih2" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;
