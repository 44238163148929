import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as Styles from './styles';
import React, { useState } from 'react';
import { ChevronBottom, ChevronTop } from '../../../marketplaces/table/styles';
import { RolesObject } from './config';
export const Role = ({ roleItem, isSelected, handleChangeSelected, handleIsVisible, }) => {
    const [gradientPosition, setGradientPosition] = useState({
        x: '50%',
        y: '50%',
        opacity: 0,
    });
    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = Math.min(Math.max(e.clientX - rect.left, 0), rect.width);
        const y = Math.min(Math.max(e.clientY - rect.top, 0), rect.height);
        setGradientPosition({
            x: `${x}px`,
            y: `${y}px`,
            opacity: 1,
        });
    };
    const handleMouseLeave = () => {
        setGradientPosition((prev) => (Object.assign(Object.assign({}, prev), { opacity: 0 })));
    };
    return (_jsx(Styles.RoleWrapper, Object.assign({ "$isSelected": isSelected, onClick: () => {
            handleChangeSelected();
            handleIsVisible();
        }, style: {
            '--x': gradientPosition.x,
            '--y': gradientPosition.y,
            '--opacity': gradientPosition.opacity,
        }, onMouseMove: handleMouseMove, onMouseLeave: handleMouseLeave }, { children: _jsxs(Styles.RoleBody, { children: [_jsx(Styles.RoleIcon, { src: roleItem.roleIconUrl }), _jsx(Styles.RoleName, { children: roleItem.roleName }), _jsx(Styles.RoleDescriptionWrapper, { children: roleItem.roleDescription })] }) })));
};
export const ChatRole = ({ selectedRole, setSelectedRole }) => {
    const [rolesVisible, setRolesVisible] = useState(false);
    const handleRolesVisible = () => {
        setRolesVisible(!rolesVisible);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Styles.Container, Object.assign({ onClick: handleRolesVisible }, { children: [_jsx(Styles.IconWrapper, { src: selectedRole.roleIconUrl }), _jsx(Styles.RoleNameWrapper, { children: selectedRole.roleName }), _jsx(Styles.ChevronWrapper, { children: rolesVisible ? _jsx(ChevronTop, {}) : _jsx(ChevronBottom, {}) })] })), _jsx(Styles.AllRolesWrapper, Object.assign({ "$isVisible": rolesVisible }, { children: RolesObject.map((role) => {
                    return (_jsx(Role, { handleIsVisible: handleRolesVisible, roleItem: role, isSelected: role.id === selectedRole.id, handleChangeSelected: () => setSelectedRole(role) }, role.id));
                }) }))] }));
};
