import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TabsPayment } from './tabs';
import { Routes, Route } from 'react-router-dom';
import { AppRoutes } from 'config';
import { Card } from './forms/card';
import { Alfa } from './forms/alfa';
import { Qiwi } from './forms/qiwi';
import { Yandex } from './forms/yandex';
import styled from 'styled-components';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-m11lmo" }) ``;
export const ChoosePayment = () => {
    const { Payment } = AppRoutes.Dashboard.Profile;
    return (_jsxs(Container, { children: [_jsx(TabsPayment, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "/*", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: Payment.Card, element: _jsx(Card, {}) }), _jsx(Route, { path: Payment.Qiwi, element: _jsx(Qiwi, {}) }), _jsx(Route, { path: Payment.Alfa, element: _jsx(Alfa, {}) }), _jsx(Route, { path: Payment.Yandex, element: _jsx(Yandex, {}) })] })] }));
};
