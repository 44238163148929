var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { components } from 'react-select';
import { Search } from './styles';
export const Control = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsxs(components.Control, Object.assign({}, props, { children: [props.selectProps.isSearchable && _jsx(Search, {}), children] })));
};
