import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { InputField, Text, TextVariant } from 'common';
import { Checkbox } from 'common/checkbox';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { initialValues, model, validationSchema } from './config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1vovc" }) `
  margin-top: 40px;
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-7fjpqo" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1biwo6a" }) ``;
const WrapperFieldEmail = styled.div.withConfig({ displayName: "WrapperFieldEmail", componentId: "sc-crra5z" }) `
  display: flex;
  flex-direction: row;
  margin-left: 110px;

  gap: 16px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;
const WrapperField = styled.div.withConfig({ displayName: "WrapperField", componentId: "sc-d2rwvg" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  gap: 16px;

  @media screen and (max-width: 720px) {
    align-items: flex-start;
  }
`;
const TextEmail = styled.div.withConfig({ displayName: "TextEmail", componentId: "sc-1gdqg8n" }) `
  @media screen and (max-width: 720px) {
    margin-top: 6px;
  }
`;
const WrapperFieldRow = styled.div.withConfig({ displayName: "WrapperFieldRow", componentId: "sc-1lxzgoh" }) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-885jfe" }) ``;
export const Qiwi = () => {
    const { t } = useTranslation();
    const onSubmit = () => { };
    return (_jsx(Formik, Object.assign({ initialValues: initialValues, validationSchema: validationSchema(t), onSubmit: onSubmit }, { children: ({}) => (_jsx(Container, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Payers details'), ":"] })) }), _jsxs(WrapperFieldRow, { children: [_jsxs(WrapperField, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: t('Number QIWI Wallet') })), _jsx(InputField, Object.assign({}, model.numberQiwi))] }), _jsxs(WrapperField, { children: [_jsx(TextEmail, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: t('Email') })) }), _jsxs(WrapperFieldEmail, { children: [_jsx(InputField, Object.assign({}, model.email)), _jsx(Checkbox, { readOnly: true, label: "Remember" })] })] })] }), _jsx(Description, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallLight }, { children: t('To continue the payment, you will be redirected to the payment system website') })) })] }) })) })));
};
