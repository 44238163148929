import { createApi } from '@reduxjs/toolkit/query/react';
import { URLS } from 'api/constants';
import { apiParserQuery } from 'store/query';
const tagTypes = ['MyDatabase'];
export const databaseApi = createApi({
    tagTypes: tagTypes,
    reducerPath: 'databaseApi',
    baseQuery: apiParserQuery,
    endpoints: (build) => ({
        list: build.query({
            query: () => {
                return {
                    method: 'GET',
                    url: `${URLS.DATABASE_LIST}`,
                };
            },
            providesTags: ['MyDatabase'],
        }),
        getById: build.query({
            query: ({ id }) => {
                return {
                    method: 'GET',
                    url: `${URLS.DATABASE_BY_ID}${id}`,
                };
            },
        }),
        download: build.query({
            query: ({ id }) => {
                const params = new URLSearchParams({
                    base_id: id,
                });
                return {
                    method: 'POST',
                    responseType: 'blob',
                    url: `${URLS.DATABASE_DOWNLOAD}?${params.toString()}`,
                };
            },
        }),
        deleteById: build.mutation({
            query: ({ id }) => {
                const params = new URLSearchParams({
                    parser_id: id,
                });
                return {
                    method: 'DELETE',
                    url: `${URLS.DATABASE_DELETE_ID}/${params.toString()}`,
                };
            },
            invalidatesTags: () => [{ type: 'MyDatabase' }],
        }),
        deleteArrayParser: build.mutation({
            query: ({ parserIds }) => {
                return {
                    method: 'DELETE',
                    url: `${URLS.DATABASE_DELETE_BULK_PARSER}`,
                    data: {
                        parser_ids: parserIds,
                    },
                };
            },
            invalidatesTags: () => [{ type: 'MyDatabase' }],
        }),
    }),
});
export const { useListQuery, useGetByIdQuery, useLazyDownloadQuery, useDeleteByIdMutation, useDeleteArrayParserMutation, } = databaseApi;
