import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DevStub } from '../../../../../components/Dev-stub/DevStub';
import { YandexDirect } from '../yandex-direct';
import * as Styles from './styles';
export const AdvertisementAside = () => {
    const { Advertisement } = AppRoutes.Dashboard;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: Advertisement.GoogleAdwords, element: _jsx(YandexDirect, { platform: "Adwords" }) }), _jsx(Route, { path: Advertisement.YandexDirect, element: _jsx(YandexDirect, { platform: "Direct" }) }), _jsx(Route, { path: Advertisement.Instagram, element: _jsx(Styles.Container, { children: _jsx(DevStub, {}) }) }), _jsx(Route, { path: Advertisement.Telegram, element: _jsx(Styles.Container, { children: _jsx(DevStub, {}) }) }), _jsx(Route, { path: Advertisement.Vkontakte, element: _jsx(Styles.Container, { children: _jsx(DevStub, {}) }) })] }));
};
export default AdvertisementAside;
