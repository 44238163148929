import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-pditv3" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-16fw2cs" }) `
  width: 100%;
  padding: 40px;
  padding-right: 16px;
  padding-bottom: 10px;

  overflow-y: auto;
  max-height: 100vh;

  ${scrollMedium};

  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-n64c28" }) `
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  grid-template-areas:
    'cities category'
    'footer footer';

  @media (max-width: 1124px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'category'
      'cities'
      'footer';
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1obnlmh" }) ``;
export const MainContent = styled.div.withConfig({ displayName: "MainContent", componentId: "sc-wla70i" }) ``;
export const WrapperLoading = styled.div.withConfig({ displayName: "WrapperLoading", componentId: "sc-b0u0jr" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const Left = styled.div.withConfig({ displayName: "Left", componentId: "sc-1x5zgje" }) `
  grid-area: cities;
  display: flex;
  flex-direction: column;
`;
export const WrapperSelect = styled.div.withConfig({ displayName: "WrapperSelect", componentId: "sc-ah1gck" }) `
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 12px;
  margin: 24px 0px 48px 0px;
`;
export const WrapperSettings = styled.div.withConfig({ displayName: "WrapperSettings", componentId: "sc-kfaoxp" }) `
  display: flex;
  flex-direction: row;

  gap: 24px;
`;
export const FieldsColumn = styled.div.withConfig({ displayName: "FieldsColumn", componentId: "sc-r4oacm" }) `
  display: flex;
  flex-direction: column;

  gap: 12px;
  flex-basis: 324px;
  white-space: nowrap;
`;
export const Right = styled.div.withConfig({ displayName: "Right", componentId: "sc-9cqdi9" }) `
  grid-area: category;
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-114ba9p" }) `
  grid-area: footer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  padding: 24px 0px 24px;
  border-top: ${({ theme }) => theme.config.switch.border.default};
`;
