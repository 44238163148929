import styled from 'styled-components';
import { Input } from '../../../../../../common';
export const RadiusContainer = styled.div.withConfig({ displayName: "RadiusContainer", componentId: "sc-1l93cto" }) `
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;
export const RadiusManualInputContainer = styled.div.withConfig({ displayName: "RadiusManualInputContainer", componentId: "sc-19dddhj" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
export const RadiusManualInput = styled(Input).withConfig({ displayName: "RadiusManualInput", componentId: "sc-2pv0ca" }) `
  width: 100px;
`;
