var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Button, InputField, Loader } from '../../../../../common';
import { Form } from '../../../../../components/form/Form';
import { useGeneratePostMutation } from '../../../../../store/api/ai';
import { handleMutation } from '../../../../../utils/handleMutation';
import { generatePostInitialValues, generatePostValidationSchema, } from './config';
import { HeaderGeneratePost } from './header';
import * as Styles from './styles';
import { GeneratedText } from './styles';
const GeneratePostForm = ({ generatePost, mutationPost, isLoading, }) => {
    var _a;
    const { t } = useTranslation();
    const { values } = useFormikContext();
    return (_jsxs(_Fragment, { children: [_jsx(HeaderGeneratePost, { generateDescription: () => generatePost(values), isButtonDisabled: isLoading }), _jsxs(Styles.PageWrapper, { children: [_jsxs(Styles.InputsWrapper, { children: [_jsx(InputField, { name: "socialNetwork", placeholder: t('Targeted social network'), inputType: "text", disabled: isLoading }), _jsx(Styles.DescriptionInput, { children: _jsx(InputField, { name: "description", placeholder: t('Post description'), inputType: "textarea", disabled: isLoading }) })] }), isLoading && (_jsx(Styles.LoaderContainer, { children: _jsx(Loader, { text: t('Generating a post...') }) })), ((_a = mutationPost.data) === null || _a === void 0 ? void 0 : _a.post) && !isLoading && (_jsx(Styles.Generated, { children: _jsxs(GeneratedText, { children: [mutationPost.data.post, _jsx(Styles.ButtonWrapper, { children: _jsx(Button, { text: t('Copy to clipboard'), onClick: () => navigator.clipboard.writeText(mutationPost.data.post) }) })] }) }))] })] }));
};
export const GeneratePostPage = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const [changePost, mutationPost] = useGeneratePostMutation();
    const [isLoading, setIsLoading] = useState(false);
    const onError = (error) => {
        var _a;
        setIsLoading(false);
        alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
    };
    const onSuccess = useCallback(() => {
        setIsLoading(false);
        alert.success(t('Success'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationPost), { onSuccess,
        onError }));
    const generatePost = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield changePost({
            postDescription: values.description,
            media: values.socialNetwork,
        });
    });
    return (_jsx(Styles.Container, { children: _jsx(Styles.Wrapper, { children: _jsx(Form, Object.assign({ initialValues: generatePostInitialValues, validationSchema: generatePostValidationSchema, onSubmit: generatePost }, { children: _jsx(GeneratePostForm, { generatePost: generatePost, mutationPost: mutationPost, isLoading: isLoading }) })) }) }));
};
