import styled from 'styled-components';
import { Button as ButtonContainer } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1q3357e" }) `
  display: flex;
  padding: 40px;
  overflow-y: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-16exgp0" }) `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "sc-b1lxs5" }) `
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-ge0j6y" }) `
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  justify-content: space-between;
`;
export const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-1dt828p" }) `
  margin-top: 24px;
`;
export const ButtonCreateMailing = styled(ButtonContainer).withConfig({ displayName: "ButtonCreateMailing", componentId: "sc-cv67ke" }) `
  white-space: nowrap;
`;
export const ButtonDelete = styled(ButtonContainer).withConfig({ displayName: "ButtonDelete", componentId: "sc-a1r9n" }) `
  color: ${({ theme }) => theme.config.text.primary};
`;
