import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React from 'react';
import * as Styles from './styles';
export const ChangeUrlModal = ({ campaignUrl, setCampaignUrl, changeInputRef, setIsChangeUrl, }) => {
    const { setFieldValue } = useFormikContext();
    return (_jsx(Styles.ChangeUrlWrapper, { children: _jsxs(Styles.ChangeUrlContainer, Object.assign({ ref: changeInputRef }, { children: [_jsx(Styles.UrlInput, { placeholder: 'https://', colorType: 'GREY', value: campaignUrl, onChange: (e) => setCampaignUrl(e.target.value) }), _jsx(Styles.UrlButton, { onClick: () => {
                        setIsChangeUrl(false);
                        setFieldValue('mainUrl', campaignUrl);
                    }, text: campaignUrl ? 'Далее' : 'У меня нет сайта', variant: campaignUrl ? 'LIGHT' : 'SECONDARY' })] })) }));
};
