import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-o2xuap" }) `
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-ce5odb" }) ``;
export const InputsContainer = styled.div.withConfig({ displayName: "InputsContainer", componentId: "sc-1kop9bf" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
`;
export const InputBlockContainer = styled.div.withConfig({ displayName: "InputBlockContainer", componentId: "sc-zbf73" }) `
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
