import { Icon } from 'common/icon';
import { ItemStatusVariants } from 'components/item-status';
import { t } from 'i18next';
export const AudioClipVariantMenuItems = {
    Remove: 'Remove',
    Listen: 'Listen',
};
export const audioClipMenuElements = [
    {
        icon: Icon.Delete,
        label: 'Remove',
        labelAction: 'Remove',
    },
    {
        icon: Icon.Sound,
        label: 'Listen',
        labelAction: 'Listen',
    },
];
const statusOptions = Object.keys(ItemStatusVariants).map((optionKey) => ({
    label: t(optionKey),
    value: optionKey,
}));
export const audioClipsFilterModel = [
    {
        name: 'status',
        label: t('Status'),
        placeholder: t('Select status'),
        options: statusOptions,
    },
];
export const filtersInitialValues = {
    status: [],
};
