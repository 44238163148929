import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Text, TextVariant } from '../../../../../../common';
import * as Styles from './styles';
export const ProgressBar = ({ label, maxValues, currentValuesCount, tollTipLabel, }) => {
    const progress = (100 / maxValues) * currentValuesCount;
    return (_jsxs(_Fragment, { children: [tollTipLabel && (
            // <Tooltip position="top" text={tollTipLabel!}>
            _jsxs(Styles.Wrapper, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: label })), _jsx(Styles.ProgressBar, { children: _jsx(Styles.Progress, { "$progressValue": progress }) })] })
            // </Tooltip>
            ), !tollTipLabel && (_jsxs(Styles.Wrapper, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: label })), _jsx(Styles.ProgressBar, { children: _jsx(Styles.Progress, { "$progressValue": progress }) })] }))] }));
};
