import { zIndex } from 'constants/enums';
import styled, { keyframes } from 'styled-components';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const DropdownContainer = styled.div.withConfig({ displayName: "DropdownContainer", componentId: "sc-fk350a" }) `
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: ${zIndex.None};
`;
export const DropdownButton = styled.div.withConfig({ displayName: "DropdownButton", componentId: "sc-4rl2ni" }) `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 16px;
`;
export const DropdownMenuYandex = styled.ul.withConfig({ displayName: "DropdownMenuYandex", componentId: "sc-dlp0g9" }) `
  position: absolute;
  top: ${({ $open, $top }) => ($open && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};

  padding: 24px;
  margin-top: 0.5rem;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
  background: ${({ theme }) => theme.config.toggle.bg.active};
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  animation: ${({ $open }) => ($open ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;

  max-height: ${({ $open }) => ($open ? '100vh' : '10px')};
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-x6nwq7" }) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 24px;
`;
export const WrapperCheckboxAll = styled.div.withConfig({ displayName: "WrapperCheckboxAll", componentId: "sc-681jt4" }) `
  margin-bottom: 24px;
`;
export const WrapperMain = styled.div.withConfig({ displayName: "WrapperMain", componentId: "sc-19b0fsg" }) `
  display: flex;
  width: 100%;

  gap: 64px;
  padding: 24px 0px 16px 0px;
  border-top: ${({ theme }) => theme.config.dropdown.border.all};
  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-1lwqg4m" }) `
  display: flex;
  white-space: nowrap;
  flex-direction: column;

  gap: 10px;
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-t13zxd" }) `
  display: flex;
  justify-content: flex-end;

  margin-top: 40px;
`;
