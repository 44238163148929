import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { createContext, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import { Text } from '../text';
import { TextVariant } from '../text/types';
import * as Styles from './styles';
const stub = () => void 0;
const DialogActionsContext = createContext({
    close: stub,
});
export const Dialog = ({ children, onClose, isOpen, zIndex, title, topMargin, width, }) => {
    const backdropRef = useRef(null);
    const { t } = useTranslation();
    const handleBackdropClick = (event) => {
        const target = event.target;
        if (target === backdropRef.current) {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
        event.stopPropagation();
    };
    return createPortal(_jsxs(Styles.Container, Object.assign({ ref: backdropRef, onClick: handleBackdropClick, "$isVisible": isOpen, "$zIndex": zIndex, "$topMargin": topMargin }, { children: [_jsx(Styles.Wrapper, Object.assign({ "$isVisible": isOpen, width: width }, { children: _jsxs(Styles.Content, { children: [title && (_jsx(Styles.Header, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t(title) })) })), _jsx(Styles.ScrollContainer, { children: _jsx(DialogActionsContext.Provider, Object.assign({ value: { close: onClose || stub } }, { children: children })) })] }) })), _jsx(Styles.Close, Object.assign({ onClick: () => onClose === null || onClose === void 0 ? void 0 : onClose() }, { children: _jsx(IconWrapper, { children: _jsx(Icon.Close, {}) }) }))] })), document.body);
};
