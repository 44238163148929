var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'common/input';
import { InputColorType, InputSize } from 'common/input/types';
import { Text } from 'common/text';
import { TextVariant } from 'common/text/types';
import { isMobile } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import ReactDatePickerRaw from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from './footer';
import { Header } from './header';
import Sidebar from './sidebar';
import * as Styles from './styles';
const ReactDatePicker = ReactDatePickerRaw;
export const DatePicker = (_a) => {
    var { name, label, title, inline, endDate, selected, onChange, disabled, className, startDate, pickerRef, monthsShown, customInput, selectsRange, changeByButton, dateFormat = 'MM/dd/yyyy' } = _a, props = __rest(_a, ["name", "label", "title", "inline", "endDate", "selected", "onChange", "disabled", "className", "startDate", "pickerRef", "monthsShown", "customInput", "selectsRange", "changeByButton", "dateFormat"]);
    const mobile = isMobile();
    const [currentStartDate, setCurrentStartDate] = useState(selectsRange ? startDate : selected);
    const [currentEndDate, setCurrentEndDate] = useState(endDate);
    const calendarRef = useRef(null);
    useEffect(() => {
        if (pickerRef) {
            pickerRef.current = calendarRef.current;
        }
    }, [pickerRef, calendarRef.current]);
    const setCurrentDate = (date) => {
        if (selectsRange) {
            const [start, end] = date;
            setCurrentStartDate(start);
            setCurrentEndDate(end);
        }
        else {
            setCurrentStartDate(date);
        }
    };
    useEffect(() => {
        if (selectsRange) {
            setCurrentDate([startDate, endDate]);
        }
        else {
            setCurrentDate(selected);
        }
    }, [startDate, endDate, selected, selectsRange]);
    const handleSidebarRange = (date) => {
        var _a;
        // @ts-ignore
        (_a = calendarRef === null || calendarRef === void 0 ? void 0 : calendarRef.current) === null || _a === void 0 ? void 0 : _a.setPreSelection(date);
    };
    const closePicker = () => {
        var _a;
        (_a = calendarRef === null || calendarRef === void 0 ? void 0 : calendarRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(false);
    };
    const onSave = () => {
        onChange(selectsRange ? [currentStartDate, currentEndDate] : currentStartDate);
        closePicker();
    };
    const onCancel = () => {
        const dates = selectsRange ? [startDate, endDate] : selected;
        setCurrentDate(dates);
        onChange(dates);
        closePicker();
    };
    return (_jsx(Styles.Container, Object.assign({ title: title, inline: inline, className: className, changeByButton: changeByButton }, { children: _jsxs(ReactDatePicker, Object.assign({ onCalendarOpen: () => {
                setTimeout(() => {
                    const el = document.getElementsByClassName('react-datepicker-popper')[0];
                    el.scrollIntoView({
                        behavior: 'auto',
                        block: 'nearest',
                        inline: 'nearest',
                    });
                }, 1);
            }, inline: inline, ref: calendarRef, id: name !== null && name !== void 0 ? name : label, disabled: disabled, showPopperArrow: false, dateFormat: dateFormat, monthsShown: monthsShown, disabledKeyboardNavigation: true, selectsRange: selectsRange, renderCustomHeader: Header, withPortal: mobile && !inline, shouldCloseOnSelect: !changeByButton, endDate: changeByButton ? currentEndDate : endDate, onChange: changeByButton ? setCurrentDate : onChange, onClickOutside: changeByButton ? onCancel : undefined, startDate: changeByButton ? currentStartDate : startDate, selected: changeByButton
                ? currentStartDate
                : selectsRange
                    ? startDate
                    : selected, popperModifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        rootBoundary: 'viewport',
                    },
                },
            ], customInput: customInput || (_jsx(Input, { label: label, size: InputSize.DEFAULT, colorType: InputColorType.LIGHT, rightIcon: _jsx(Styles.Arrow, {}), onRightIconClick: () => {
                    const calendar = calendarRef.current;
                    if (!calendar)
                        return;
                    calendar.setOpen(!calendar.isCalendarOpen());
                } })) }, props, { children: [title && (_jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: _jsx(Styles.Title, { children: title }) }))), changeByButton && selectsRange && (_jsx(Sidebar, { setCurrentDate: setCurrentDate, handleSidebarRange: handleSidebarRange })), changeByButton && (_jsx(Footer, { onSave: onSave, onCancel: onCancel, monthsShown: monthsShown }))] })) })));
};
