import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { useEffect, useState } from 'react';
import * as Styles from './styles';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import { formatId } from '../../../../../utils/formatId';
import { TableBodyParsingResult } from '../../../../table';
import { Link } from 'react-router-dom';
export const MembersDialog = ({ isModalOpen, setModalOpen, membersData, postName, }) => {
    const [data, setData] = useState([]);
    const closeModal = () => {
        setModalOpen(false);
    };
    useEffect(() => {
        const temp = membersData === null || membersData === void 0 ? void 0 : membersData.map((member) => {
            return {
                vk_id: member,
            };
        });
        setData(temp);
    }, [membersData]);
    return (_jsxs(Styles.ModalContainer, Object.assign({ "$isVisible": isModalOpen }, { children: [_jsxs(Styles.Container, { children: [_jsx(Styles.Header, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: postName })) }), _jsx(Styles.MailingTextContainer, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: "\u0423\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438" })) }), _jsx(Styles.TableContainer, { children: data && (_jsx(TableBodyParsingResult, { isShortParser: true, icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.More, {}) })), 
                            // dataHeader={['№', t('Author'), t('Comment Text')]}
                            data: data === null || data === void 0 ? void 0 : data.map((row, index) => [
                                formatId(index + 1),
                                `id${row.vk_id}`,
                                _jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ "$styles": { marginRight: '10px' }, "$variant": TextVariant.bodyRegular }, { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0430:" })), _jsx(Link, Object.assign({ to: `https://vk.com/id${row.vk_id}`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.User, {}) })) }))] }),
                            ]) })) })] }), _jsx(Styles.Close, Object.assign({ onClick: closeModal }, { children: _jsx(IconWrapper, { children: _jsx(Icon.Close, {}) }) }))] })));
};
