var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag } from 'components';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Select } from '../select';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-pyjsq0" }) `
  display: flex;

  ${({ $isColumn }) => $isColumn &&
    css `
      width: 100%;
      gap: 8px;
      flex-direction: column;
    `};
`;
const WrapperTags = styled.div.withConfig({ displayName: "WrapperTags", componentId: "sc-117g1ox" }) `
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-left: 16px;
  flex-wrap: wrap;

  ${({ $isColumn }) => $isColumn &&
    css `
      width: fit-content;
      margin-left: 0px;
    `}
`;
export const SelectTags = (_a) => {
    var { items, label, onClose, onChange, components, isColumn = false } = _a, props = __rest(_a, ["items", "label", "onClose", "onChange", "components", "isColumn"]);
    const { t } = useTranslation();
    return (_jsxs(Container, Object.assign({ "$isColumn": isColumn }, { children: [_jsx(Select, Object.assign({ isMulti: true, isSearchable: true, value: items, defaultControl: true, noOptionsMessage: (e) => (e.inputValue ? t('No options') : null), components: {
                    ClearIndicator: () => _jsx(_Fragment, {}),
                    MultiValue: () => _jsx(_Fragment, {}),
                }, label: (label === null || label === void 0 ? void 0 : label.length) && (items === null || items === void 0 ? void 0 : items.length) ? `${label} (${items === null || items === void 0 ? void 0 : items.length})` : label, onChange: (option, action) => {
                    if (onChange) {
                        onChange(option, action);
                    }
                } }, props)), _jsx(WrapperTags, Object.assign({ "$isColumn": isColumn }, { children: items === null || items === void 0 ? void 0 : items.map((item) => (_jsx(Tag, { onClose: () => {
                        if (onClose) {
                            onClose(item);
                        }
                    }, item: {
                        text: item.label,
                        value: item.value,
                    } }, item.value))) }))] })));
};
