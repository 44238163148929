import styled, { css } from 'styled-components';
export const FormSection = styled.div.withConfig({ displayName: "FormSection", componentId: "sc-1db23q0" }) `
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  gap: 20px;
`;
export const FieldsRow = styled.div.withConfig({ displayName: "FieldsRow", componentId: "sc-11xv4mb" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ theme }) => css `
    gap: ${theme.responsive.isMobile ? '8px' : ''};
  `}
`;
export const ContainerFields = styled.div.withConfig({ displayName: "ContainerFields", componentId: "sc-zn7zy4" }) ``;
