import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiProjectsQuery } from '../query';
var ESocialNetwork;
(function (ESocialNetwork) {
    ESocialNetwork["TIKTOK"] = "TIKTOK";
    ESocialNetwork["FACEBOOK"] = "FACEBOOK";
    ESocialNetwork["INSTAGRAM"] = "INSTAGRAM";
    ESocialNetwork["TELEGRAM"] = "TELEGRAM";
    ESocialNetwork["VKONTAKTE"] = "VKONTAKTE";
})(ESocialNetwork || (ESocialNetwork = {}));
var EActivityType;
(function (EActivityType) {
    EActivityType["PRODUCTS"] = "PRODUCTS";
    EActivityType["SERVICES"] = "SERVICES";
})(EActivityType || (EActivityType = {}));
export const ProjectsApi = createApi({
    reducerPath: 'ProjectsApi',
    baseQuery: apiProjectsQuery,
    endpoints: (build) => ({
        projectCreate: build.mutation({
            query: (values) => {
                return {
                    url: URLS.PROJECT_CREATE,
                    method: 'POST',
                    data: values,
                };
            },
        }),
    }),
});
export const { useProjectCreateMutation } = ProjectsApi;
