import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant } from 'common';
import { Icon } from 'common/icon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import WaveSurfer from 'wavesurfer.js';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
const VariantButtonText = {
    Listen: 'Listen',
    Stop: 'Stop',
};
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-l6neg6" }) `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 16px;
`;
const Wave = styled.div.withConfig({ displayName: "Wave", componentId: "sc-d5wo0o" }) `
  width: 50%;
`;
export const Visualization = ({ audioUrl }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [isAudioAvailable, setIsAudioAvailable] = useState(!!audioUrl);
    const [buttonText, setButtonText] = useState('');
    const waveSurferRef = useRef(null);
    const waveFormRef = useRef(null);
    useEffect(() => {
        if (!audioUrl) {
            setIsAudioAvailable(false);
            setButtonText(VariantButtonText.Listen);
            return;
        }
        waveSurferRef.current = WaveSurfer.create({
            container: waveFormRef.current,
            barWidth: 4,
            barGap: 2,
            cursorWidth: 1,
            height: 48,
            progressColor: '#2D5BFF',
            waveColor: theme.config.voiceRecorder.visualVoice,
            cursorColor: 'transparent',
        });
        if (audioUrl) {
            waveSurferRef.current.load(audioUrl);
            waveSurferRef.current.on('ready', () => {
                setIsAudioAvailable(true);
                setButtonText(VariantButtonText.Listen);
            });
            waveSurferRef.current.on('finish', () => {
                setButtonText(VariantButtonText.Listen);
            });
        }
        return () => {
            var _a;
            (_a = waveSurferRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
        };
    }, [audioUrl]);
    const handleButtonClick = () => {
        if (waveSurferRef.current) {
            if (!waveSurferRef.current.isPlaying()) {
                waveSurferRef.current.play();
                setButtonText(VariantButtonText.Stop);
            }
            else {
                waveSurferRef.current.pause();
                setButtonText(VariantButtonText.Listen);
            }
        }
    };
    return (_jsxs(Container, { children: [_jsx(Wave, { ref: waveFormRef }), _jsx(Button, { text: t(buttonText), variant: ButtonVariant.TETRIERY, icon: _jsx(IconWrapper, { children: _jsx(Icon.Play, {}) }), onClick: handleButtonClick, disabled: !isAudioAvailable })] }));
};
