var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { FieldArray, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariant, InputField, Loader, Text, TextVariant, } from '../../../../../common';
import { Tag } from '../../../../../components';
import { ModalMain } from '../../../../../components/modal/main';
import { useSendEmailMutation, useSendTelegramMutation, useSendViberMutation, useSendWhatsAppMutation, } from '../../../../../store/api/mailings';
import { handleMutation } from '../../../../../utils/handleMutation';
import { dispatchFormModel, } from '../config';
import { SelectRecipientsField } from '../dropdown/dropdownContainer';
import { handleCSVFile, handleXLSXFile } from '../fileReader';
import * as Styles from './styles';
import { UploadInfoModal } from '../../../../../components/modal/upload-info-modal';
export const FullDispatchForm = ({ initialDispatchFormValues, dispatchFormSchema, manualInputSchema, manualInputFormValues, manualInputFormPlaceholder, validationFunc, mailingMessenger, dataFromHistory, }) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const navigate = useNavigate();
    const [sendTelegram, mutationTelegram] = useSendTelegramMutation();
    const [sendEmail, mutationEmail] = useSendEmailMutation();
    const [sendViber, mutationViber] = useSendViberMutation();
    const [sendWhatsApp, mutationWhatsApp] = useSendWhatsAppMutation();
    const [dropdownItems, setDropdownItems] = useState([]);
    const [showMore, setShowMore] = useState(5);
    const fileInputRef = useRef(null);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const handleInfoModalVisible = () => {
        setInfoModalVisible(!infoModalVisible);
    };
    const onSuccess = useCallback(() => {
        navigate('../');
        setIsLoading(false);
        setConfirmModalOpen(false);
        alert.success(t('The mailing list was successfully created'));
    }, [alert]);
    const onError = useCallback(() => {
        setIsLoading(false);
        setConfirmModalOpen(false);
        alert.error(t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationTelegram), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, mutationEmail), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, mutationViber), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, mutationWhatsApp), { onSuccess,
        onError }));
    const handleClick = () => {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const deleteDataFromFile = () => {
        setDropdownItems([]);
    };
    const handleFileUpload = useCallback((e) => {
        var _a;
        const file = e.target.files && e.target.files[0];
        if (file) {
            const fileType = (_a = file.name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            const reader = new FileReader();
            if (fileType === 'csv') {
                reader.onload = (event) => {
                    var _a;
                    if ((_a = event.target) === null || _a === void 0 ? void 0 : _a.result) {
                        const content = new Uint8Array(event.target.result);
                        const parsedData = handleCSVFile(content, validationFunc);
                        setDropdownItems(parsedData);
                    }
                };
                reader.readAsArrayBuffer(file);
            }
            else if (fileType === 'xlsx') {
                reader.onload = (event) => {
                    var _a;
                    if ((_a = event.target) === null || _a === void 0 ? void 0 : _a.result) {
                        const data = new Uint8Array(event.target.result);
                        const parsedItems = handleXLSXFile(data, validationFunc);
                        setDropdownItems(parsedItems);
                    }
                };
                reader.readAsArrayBuffer(file);
            }
            else if (fileType === 'xml') {
                alert.error('XML parse not ready :(');
            }
        }
    }, []);
    const onSendMailing = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const recipientsAsString = data.to
            .map((recipient) => recipient.value)
            .join(';');
        const requestParams = {
            name: data.name,
            text: data.text,
            to: recipientsAsString,
        };
        switch (mailingMessenger) {
            case 'Telegram':
                yield sendTelegram(requestParams);
                break;
            case 'Email':
                yield sendEmail(requestParams);
                break;
            case 'Viber':
                yield sendViber(requestParams);
                break;
            case 'WhatsApp':
                yield sendWhatsApp(requestParams);
                break;
        }
    });
    return (_jsxs(Styles.Container, { children: [_jsx(Formik, Object.assign({ onSubmit: () => void 0, initialValues: initialDispatchFormValues, validationSchema: dispatchFormSchema }, { children: ({ values, setFieldValue }) => {
                    var _a, _b, _c, _d, _e;
                    useEffect(() => {
                        if (dataFromHistory) {
                            setFieldValue('historyChips', dataFromHistory.historyChips);
                            setFieldValue('mailingText', dataFromHistory.mailingText);
                            setFieldValue('mailingName', dataFromHistory.mailingName);
                        }
                    }, [dataFromHistory]);
                    useEffect(() => {
                        setFieldValue('chips.baseChips', dropdownItems.filter((item) => {
                            var _a;
                            return !((_a = values.chips.inputChips) === null || _a === void 0 ? void 0 : _a.some((chip) => chip.value === item.value));
                        }));
                    }, [dropdownItems]);
                    useEffect(() => {
                        setShowMore((prevState) => prevState + values.chips.inputChips.length);
                    }, [values.chips.inputChips]);
                    return (_jsxs(Styles.ScrollContainer, { children: [_jsxs(Styles.WrapperContent, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t(initialDispatchFormValues.name) })), _jsx(Styles.InputsContainer, { children: _jsxs(Styles.NameInputContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Mailing name') })), _jsx(InputField, Object.assign({ placeholder: t('Mailing name') }, dispatchFormModel.mailingName))] }) }), _jsxs(Styles.InputsContainer, { children: [_jsxs(Styles.ManualInputContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Add manually') })), _jsx(Styles.ManualInputWrapper, { children: _jsx(FieldArray, Object.assign({ name: 'chips.inputChips' }, { children: ({ push }) => {
                                                                return (_jsx(Formik, Object.assign({ initialValues: manualInputFormValues, onSubmit: () => void 0, validationSchema: manualInputSchema }, { children: ({ dirty, isValid, values: addEmailValues, 
                                                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                                                    setFieldValue, }) => (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ onKeyDown: (event) => __awaiter(void 0, void 0, void 0, function* () {
                                                                                    var _a, _b;
                                                                                    if ((event.key === 'Enter' ||
                                                                                        event.code === 'NumpadEnter') &&
                                                                                        dirty &&
                                                                                        isValid) {
                                                                                        const value = {
                                                                                            label: addEmailValues.manualInput,
                                                                                            value: addEmailValues.manualInput,
                                                                                        };
                                                                                        if (!((_a = values.chips.inputChips) === null || _a === void 0 ? void 0 : _a.some((chip) => chip.value === value.value)) &&
                                                                                            !((_b = values.chips.baseChips) === null || _b === void 0 ? void 0 : _b.some((chip) => chip.value === value.value))) {
                                                                                            push(value);
                                                                                            setFieldValue('manualInput', '');
                                                                                        }
                                                                                    }
                                                                                }) }, { children: _jsx(Styles.ManualInput, Object.assign({}, dispatchFormModel.manualInput, { placeholder: manualInputFormPlaceholder })) })), _jsx(Button, { disabled: !dirty || !isValid, text: '', icon: _jsx(Icon.AddFill, {}), variant: ButtonVariant.TETRIERY, onClick: () => {
                                                                                    var _a, _b;
                                                                                    const value = {
                                                                                        label: addEmailValues.manualInput,
                                                                                        value: addEmailValues.manualInput,
                                                                                    };
                                                                                    if (!((_a = values.chips.inputChips) === null || _a === void 0 ? void 0 : _a.some((chip) => chip.value === value.value)) &&
                                                                                        !((_b = values.chips.baseChips) === null || _b === void 0 ? void 0 : _b.some((chip) => chip.value === value.value))) {
                                                                                        push(value);
                                                                                        setFieldValue('manualInput', '');
                                                                                    }
                                                                                } })] })) })));
                                                            } })) })] }), _jsxs(Styles.DropdownInputContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Add from base') })), _jsxs(Styles.DropdownInputWrapper, { children: [_jsx(SelectRecipientsField, Object.assign({ isPlaceholderPermanent: true }, dispatchFormModel.dropdown, { options: dropdownItems.filter((item) => {
                                                                    var _a;
                                                                    return !((_a = values.chips.inputChips) === null || _a === void 0 ? void 0 : _a.some((chip) => chip.value === item.value));
                                                                }), name: "chips.baseChips", isColumn: true })), _jsx(Button, { text: '', variant: ButtonVariant.TETRIERY, icon: _jsx(Icon.Download, {}), 
                                                                // onClick={() => handleClick()}
                                                                onClick: handleInfoModalVisible }), _jsx(Styles.HiddenInput, { ref: fileInputRef, type: "file", accept: ".csv,.xlsx,.xml", onChange: handleFileUpload })] })] })] }), _jsx(Styles.WrapperTags, { children: _jsxs(Styles.WrapperRecipientsChips, Object.assign({ "$isColumn": false }, { children: [(_a = values.chips.inputChips) === null || _a === void 0 ? void 0 : _a.map((item) => (_jsx(Tag, { onClose: () => {
                                                        setFieldValue('chips.inputChips', values.chips.inputChips
                                                            .filter((chips) => chips !== item)
                                                            .filter((a) => a));
                                                    }, item: {
                                                        text: item.label,
                                                        value: item.value,
                                                    } }, item.value))), (_b = values.chips.baseChips) === null || _b === void 0 ? void 0 : _b.slice(0, showMore - values.chips.inputChips.length).map((item) => (_jsx(Tag, { onClose: () => {
                                                        setFieldValue('chips.baseChips', values.chips.baseChips
                                                            .filter((chips) => chips !== item)
                                                            .filter((a) => a));
                                                    }, item: {
                                                        text: item.label,
                                                        value: item.value,
                                                    } }, item.value))), (_c = values.historyChips) === null || _c === void 0 ? void 0 : _c.slice(0, showMore -
                                                    values.chips.inputChips.length -
                                                    values.chips.baseChips.length).map((item) => (_jsx(Tag, { onClose: () => {
                                                        setFieldValue('historyChips', values.historyChips
                                                            .filter((chips) => chips !== item)
                                                            .filter((a) => a));
                                                    }, item: {
                                                        text: item.label,
                                                        value: item.value,
                                                    } }, item.value))), (((_d = values.chips.inputChips) === null || _d === void 0 ? void 0 : _d.length) || 0) +
                                                    (((_e = values.chips.baseChips) === null || _e === void 0 ? void 0 : _e.length) || 0) +
                                                    (values.historyChips.length || 0) >=
                                                    showMore && (_jsx(Styles.ShowMoreBtn, Object.assign({ onClick: () => {
                                                        setShowMore((prevState) => {
                                                            return (prevState += 5);
                                                        });
                                                    } }, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: t('Show more') })) })))] })) }), _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Add text for mailing message'), _jsx(InputField, Object.assign({}, dispatchFormModel.mailingText))] })), _jsxs(Styles.CreateButtonContainer, { children: [_jsx(Button, { disabled: !dropdownItems.length, text: t('Delete data from base'), onClick: deleteDataFromFile, variant: ButtonVariant.TETRIERY }), _jsx(Button, { text: t('Create a newsletter'), padding: "24px", disabled: !(values.chips.inputChips.length +
                                                    values.chips.baseChips.length +
                                                    values.historyChips.length) ||
                                                    !values.mailingText.length ||
                                                    !values.mailingName.length, onClick: () => {
                                                    setConfirmModalOpen(true);
                                                } })] })] }), _jsxs(ModalMain, Object.assign({ title: t('Create a newsletter'), isVisible: isConfirmModalOpen, onClick: () => {
                                    setConfirmModalOpen(false);
                                }, footer: _jsxs(_Fragment, { children: [_jsx(Button, { text: t('Cancel'), disabled: isLoading, variant: ButtonVariant.TETRIERY, onClick: () => {
                                                setConfirmModalOpen(false);
                                            } }), _jsx(Button, { padding: "24px", disabled: isLoading, text: t('Create a newsletter'), variant: ButtonVariant.PRIMARY, onClick: () => {
                                                onSendMailing({
                                                    to: values.chips.inputChips
                                                        .concat(values.chips.baseChips)
                                                        .concat(values.historyChips),
                                                    text: values.mailingText,
                                                    name: values.mailingName,
                                                });
                                            } })] }) }, { children: [!isLoading && (_jsx(Text, { children: t('Are you sure you want to create a newsletter?') })), isLoading && _jsx(Loader, {})] }))] }));
                } })), _jsx(UploadInfoModal, { isVisible: infoModalVisible, onClick: handleInfoModalVisible, uploadFunc: handleClick, modalTitle: 'Формат загружаемых файлов', message: 'Текст с обучалкой', buttonText: 'Скачать файл-шаблон' })] }));
};
