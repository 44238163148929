import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { Table } from 'components';
import { ButtonTable } from 'constants/enums';
import dayjs from 'dayjs';
import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../../../../../../../common/icon-wrapper/icon-wrapper';
import { useNotificationClearMutation, useNotificationDeleteMutation, useNotificationListQuery, } from '../../../../../../../store/api/notifications';
import { initialValues } from './config';
import { Settings } from './settings';
import * as Styles from './styles';
export const NotificationFormContent = () => {
    const { t } = useTranslation();
    const { data: notificationList } = useNotificationListQuery();
    const [requestDelete] = useNotificationDeleteMutation();
    const [requestClear] = useNotificationClearMutation();
    const handleClearHistory = () => {
        requestClear();
    };
    const handleDeleteNotification = (notificationId) => {
        requestDelete({ notificationId });
    };
    const { setFieldValue } = useFormikContext();
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsxs(Styles.WrapperMessage, { children: [_jsx(Styles.Header, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary" }, { children: t('All notifications') })) }), _jsx(Styles.ContentMessage, { children: notificationList && notificationList.length ? (_jsx(Table, { isScrollable: true, icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.Delete, {}) })), hasItemCheckbox: false, hasHeaderCheckbox: false, buttonAction: ButtonTable.Delete, handleButton: (index) => handleDeleteNotification(notificationList[index].notification_id.toString()), dataHeader: ['№', t('Date'), t('Notification'), t('Source')], data: notificationList === null || notificationList === void 0 ? void 0 : notificationList.map((row, index) => [
                                    (index + 1).toString(),
                                    dayjs(row.data).format('D.MM.YYYY'),
                                    row.text,
                                    row.source,
                                ]) })) : (_jsx(Styles.WrapperEmptyMessage, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t("You haven't received any notifications yet") })) })) })] }), _jsxs(Styles.WrapperSettings, { children: [_jsx(Styles.Header, { children: _jsxs(Text, { children: [t('Notification settings'), ":"] }) }), _jsx(Styles.WrapperSwitch, { children: _jsx(Settings, {}) }), _jsx(Styles.WrapperButton, { children: _jsxs(Styles.ContentButton, { children: [_jsx(Button, { text: t('Default Settings'), variant: ButtonVariant.TETRIERY, onClick: () => {
                                            setFieldValue('changePassword', initialValues.changePassword);
                                            setFieldValue('changeTariff', initialValues.changeTariff);
                                            setFieldValue('changePayment', initialValues.changePayment);
                                            setFieldValue('changeMailing', initialValues.changeMailing);
                                            setFieldValue('changeReport', initialValues.changeReport);
                                            setFieldValue('changeAvailable', initialValues.changeAvailable);
                                        } }), _jsx(Button, { padding: "24px", text: t('Clear History'), onClick: handleClearHistory, variant: ButtonVariant.PRIMARY })] }) })] })] }) }));
};
export const NotificationForm = () => (_jsx(Formik, Object.assign({ onSubmit: () => { }, initialValues: initialValues }, { children: _jsx(NotificationFormContent, {}) })));
