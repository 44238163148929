import styled from 'styled-components';
import { Button, Input } from '../../../../../common';
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1xuzsue" }) ``;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-vzhxio" }) `
  width: 100%;
  min-height: 300px;
`;
export const DropdownButton = styled.div.withConfig({ displayName: "DropdownButton", componentId: "sc-9n7l0o" }) `
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  background-color: ${({ theme }) => theme.config.input.default.background};
  padding: 10px;
  border-radius: 10px;

  margin-top: 10px;
`;
export const HelpModalInputContainer = styled.div.withConfig({ displayName: "HelpModalInputContainer", componentId: "sc-w1tdkm" }) `
  display: flex;
  flex-direction: column;

  margin-top: 20px;
`;
export const HelpModalInput = styled(Input).withConfig({ displayName: "HelpModalInput", componentId: "sc-pc7ja" }) `
  width: 100%;
  margin-top: 10px;
`;
export const SubmitButtonContainer = styled.div.withConfig({ displayName: "SubmitButtonContainer", componentId: "sc-1smhtei" }) `
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;
export const SubmitButton = styled(Button).withConfig({ displayName: "SubmitButton", componentId: "sc-qqnwek" }) `
  width: 300px;
`;
