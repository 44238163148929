import { useState } from 'react';
export const useStep = (handleInfo, handleChangePassword) => {
    const [currentStep, setCurrentStep] = useState(0);
    const steps = [
        { title: 'Information', function: handleInfo },
        { title: 'Change Password', function: handleChangePassword },
    ];
    const handleNextStep = () => {
        const nextStep = currentStep + 1;
        if (nextStep < steps.length) {
            setCurrentStep(nextStep);
        }
        else {
            setCurrentStep(0);
        }
    };
    const getCurrentStepFunction = () => {
        var _a;
        return ((_a = steps[currentStep]) === null || _a === void 0 ? void 0 : _a.function) || (() => { });
    };
    return {
        handleNextStep,
        getCurrentStepFunction,
    };
};
