import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-lz7gva" }) `
  height: calc(100vh - 150px);
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  padding: 0px 40px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-eqe6f3" }) `
  width: 100%;
  padding: 0px 48px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  max-height: 100vh;
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1yrfkgk" }) `
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-duborz" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Info = styled.div.withConfig({ displayName: "Info", componentId: "sc-1o6ddet" }) `
  display: flex;
  align-items: center;
`;
export const WrapperNumber = styled.div.withConfig({ displayName: "WrapperNumber", componentId: "sc-33mz8s" }) `
  display: flex;
  align-items: center;

  gap: 32px;
  margin-left: 57px;
`;
export const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-bm0diz" }) ``;
