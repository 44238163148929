var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { apiAIInstance, apiAudioClipsInstance, apiBalanceInstance, apiCompaniesInstance, apiInstance, apiLoaderInstance, apiMailingHistoryInstance, apiMailingInstance, apiNotificationInstance, apiParserInstance, apiProjectsInstance, apiVoiceRobotInstance, } from 'api/instance';
const parseAxiosError = (error) => {
    var _a, _b, _c, _d, _e, _f;
    const axiosError = ((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.msg) || ((_f = (_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.debug) === null || _f === void 0 ? void 0 : _f.message);
    if (axiosError) {
        return axiosError;
    }
    return error.message;
};
const axiosBaseQuery = ({ instance, }) => (_a) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    var { url, method, data, headers } = _a, props = __rest(_a, ["url", "method", "data", "headers"]);
    try {
        const result = yield instance(Object.assign({ url,
            method,
            data,
            headers }, props));
        return { data: result.data };
    }
    catch (axiosError) {
        let err = axiosError;
        return {
            error: {
                status: (_b = err.response) === null || _b === void 0 ? void 0 : _b.status,
                data: (_c = err.response) === null || _c === void 0 ? void 0 : _c.data,
                message: parseAxiosError(err),
            },
        };
    }
});
export const apiQuery = axiosBaseQuery({
    instance: apiInstance,
});
export const apiLoaderQuery = axiosBaseQuery({
    instance: apiLoaderInstance,
});
export const apiParserQuery = axiosBaseQuery({
    instance: apiParserInstance,
});
export const apiAIQuery = axiosBaseQuery({
    instance: apiAIInstance,
});
export const apiVoiceRobotQuery = axiosBaseQuery({
    instance: apiVoiceRobotInstance,
});
export const apiProjectsQuery = axiosBaseQuery({
    instance: apiProjectsInstance,
});
export const apiMailingQuery = axiosBaseQuery({
    instance: apiMailingInstance,
});
export const apiMailingHistoryQuery = axiosBaseQuery({
    instance: apiMailingHistoryInstance,
});
export const apiCompaniesQuery = axiosBaseQuery({
    instance: apiCompaniesInstance,
});
export const apiAudioClipsQuery = axiosBaseQuery({
    instance: apiAudioClipsInstance,
});
export const apiNotificationQuery = axiosBaseQuery({
    instance: apiNotificationInstance,
});
export const apiBalanceQuery = axiosBaseQuery({
    instance: apiBalanceInstance,
});
