import styled, { css } from 'styled-components';
export const MailingModalAudioTableContainer = styled.div.withConfig({ displayName: "MailingModalAudioTableContainer", componentId: "sc-134y0u7" }) `
  background: ${({ theme }) => theme.config.background.tetriery};
  padding: 20px 0 20px 15px;
  border-radius: 30px;
  margin-top: 10px;
`;
export const MailingModalAudioTable = styled.div.withConfig({ displayName: "MailingModalAudioTable", componentId: "sc-7hlcqc" }) `
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 260px;
  overflow-y: auto;
  background: ${({ theme }) => theme.config.background.tetriery};
  padding-right: 15px;
  border-radius: 15px;
`;
export const MailingModalAudioTableItem = styled.div.withConfig({ displayName: "MailingModalAudioTableItem", componentId: "sc-12kjs03" }) `
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background: ${({ theme }) => theme.config.background.secondary};
  padding: 7px 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    filter: invert(0.05);
    border-color: ${({ theme }) => theme.colors.green.tetriery};
  }

  ${({ $isSelected }) => $isSelected &&
    css `
      filter: invert(0.08);
      border-color: ${({ theme }) => theme.colors.green.tetriery};
    `}
`;
export const MailingModalAudioTableItemIndex = styled.div.withConfig({ displayName: "MailingModalAudioTableItemIndex", componentId: "sc-6z8u91" }) `
  display: flex;
  align-items: center;
  min-width: 30px;
  padding-left: 5px;
  height: 20px;
`;
export const MailingModalAudioTableItemName = styled.div.withConfig({ displayName: "MailingModalAudioTableItemName", componentId: "sc-5xuo44" }) `
  display: flex;
  align-items: center;
  width: 40%;
`;
export const MailingModalAudioTableItemDesc = styled.div.withConfig({ displayName: "MailingModalAudioTableItemDesc", componentId: "sc-m5fozi" }) `
  display: flex;
  align-items: center;
  width: 40%;
`;
export const ErrorContainer = styled.div.withConfig({ displayName: "ErrorContainer", componentId: "sc-u3dtob" }) `
  margin-bottom: -10px;
`;
