import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant } from 'common';
import { Icon } from 'common/icon';
import { AppRoutes } from 'config';
import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
import { useNavigate } from '../../../hooks';
import { useCompaniesListForMainQuery } from '../../../store/api/companies';
import { useMeQuery, useSelectCompanyMutation, } from '../../../store/api/profile';
import { handleMutation } from '../../../utils/handleMutation';
import * as Styles from './styles';
export const DropCompany = ({ top, left, items, button, onClick, isVisible, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const alert = useAlert();
    const { data: me } = useMeQuery();
    const [selectCompany, responseSelectCompany] = useSelectCompanyMutation();
    const { refetch } = useCompaniesListForMainQuery();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const dropdownRef = useRef(null);
    const { Home, CreateCompany } = AppRoutes.Dashboard;
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    const onSuccess = () => {
        alert.success(t('Company selected successfully'));
        refetch();
    };
    handleMutation(Object.assign(Object.assign({}, responseSelectCompany), { onSuccess,
        onError }));
    const handleClickOutside = (event) => {
        if (isOpen &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
            if (!isOpen && onClick) {
                onClick(isOpen);
            }
        }
    };
    useEffect(() => {
        if (items.length > 0) {
            items.map((company) => {
                if (me && me.selected_company !== '-' && company.id) {
                    if (me.selected_company === company.id.toString()) {
                        setSelectedCompany(company);
                    }
                }
                else {
                    setSelectedCompany(null);
                }
            });
        }
    }, [me, items]);
    const handleCompanySelect = useMemo(() => (company) => {
        setSelectedCompany(company);
        setIsOpen(false);
        if (me && me._id && company.id) {
            selectCompany({
                user_id: me._id.toString(),
                company_id: company.id.toString(),
            });
        }
        company.onClick();
    }, [me]);
    const isActive = useMemo(() => {
        return (item) => {
            if (selectedCompany) {
                return item.id === selectedCompany.id;
            }
            return false;
        };
    }, [selectedCompany]);
    useEffect(() => {
        if (isVisible) {
            setIsOpen(true);
        }
    }, [isVisible]);
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    return (_jsxs(Styles.DropContainer, Object.assign({ ref: dropdownRef }, { children: [_jsxs(Styles.WrapperButton, Object.assign({ onClick: () => setIsOpen(!isOpen), "$isActive": !!selectedCompany }, { children: [_jsx(Styles.SelectButton, { children: selectedCompany ? selectedCompany.label : button }), _jsx(IconWrapper, Object.assign({ "$isCursor": true }, { children: _jsx(Styles.Arrow, { "$isOpen": isOpen }) }))] })), isOpen && (_jsx(Styles.WrapperDrop, Object.assign({ "$isOpen": isOpen }, { children: _jsxs(Styles.DropMenu, Object.assign({ "$isOpen": isOpen, "$top": top, "$left": left }, { children: [items.map((item) => (_jsxs(Styles.WrapperItem, Object.assign({ onClick: () => handleCompanySelect(item) }, { children: [_jsx(Styles.WrapperIcon, Object.assign({ "$isActive": isActive(item) }, { children: _jsx(Icon.CheckmarkDrop, {}) })), _jsx(Styles.DropMenuItem, Object.assign({ "$isActive": isActive(item) }, { children: item.label }))] }), item.id))), items.length === 0 && (_jsx(Styles.WrapperItem, { children: _jsx(Styles.DropMenuMock, { children: t('No companies available') }) })), _jsx(Styles.Line, {}), _jsxs(Styles.ButtonsContainer, { children: [_jsx(Button, { text: t('My Companies'), variant: 'TETRIERY', onClick: () => {
                                        setIsOpen(false);
                                        navigate(Home + AppRoutes.Dashboard.MyCompany.Home);
                                    } }), _jsx(Styles.CreateButton, { onClick: () => setIsOpen(false), variant: ButtonVariant.LIGHT, text: t('Create a company'), to: Home + CreateCompany })] })] })) })))] })));
};
