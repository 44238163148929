import { InputSize, IconSize, LabelSize } from 'common/input/types';
export const model = {
    email: {
        type: 'email',
        name: 'email',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'E-mail',
        sizeIcon: IconSize.MEDIUM,
        sizeLabel: LabelSize.SMALL,
    },
};
export const validationSchema = () => { };
export const initialValues = () => {
    return {
        [model.email.name]: '',
    };
};
