import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-zjdxnp" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const ScrollWrapper = styled.div.withConfig({ displayName: "ScrollWrapper", componentId: "sc-mmuveh" }) `
  width: 100%;
  padding: 40px;
  padding-right: 16px;
  padding-bottom: 10px;

  overflow-y: auto;
  max-height: 100vh;

  ${scrollMedium}
`;
export const WrapperProfile = styled.div.withConfig({ displayName: "WrapperProfile", componentId: "sc-p4iqau" }) `
  display: flex;
  flex-direction: column;

  gap: 40px;
`;
export const WrapperField = styled.div.withConfig({ displayName: "WrapperField", componentId: "sc-17qb5gj" }) ``;
export const Loading = styled.div.withConfig({ displayName: "Loading", componentId: "sc-1lzbkg3" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-nicdhq" }) `
  display: flex;
  flex-direction: column;

  gap: 12px;

  max-width: 400px;
  height: 100%;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1lxkobp" }) `
  display: flex;
  gap: 24px;

  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    gap: 8px;
    flex-direction: column;
  }
`;
export const Status = styled.div.withConfig({ displayName: "Status", componentId: "sc-sfd1eh" }) `
  display: flex;
  align-items: flex-end;
  opacity: 0.2;
`;
export const WrapperHeader = styled.div.withConfig({ displayName: "WrapperHeader", componentId: "sc-s1vpbf" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;
