import styled from 'styled-components';
import { Button } from '../../button';
export const SearchInputContainer = styled.div.withConfig({ displayName: "SearchInputContainer", componentId: "sc-sk26t3" }) `
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;

  max-width: ${({ $isVisible }) => ($isVisible ? '500px' : '0')};
`;
export const ButtonSearch = styled(Button).withConfig({ displayName: "ButtonSearch", componentId: "sc-vv0bcu" }) `
  background: ${({ theme }) => theme.config.button.tertiary.background.primary};
  border: ${({ theme }) => theme.config.button.tertiary.border};
`;
