import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TextVariant } from 'common';
import { RowPayment } from 'components';
import styled from 'styled-components';
const tableData = [
    {
        id: 0,
        columnOne: 'Current tariff',
        columnTwo: 'Name tariff',
        variant: {
            columnOne: TextVariant.bodyRegular,
            columnTwo: TextVariant.bodyRegular,
        },
    },
    {
        id: 1,
        columnOne: 'New tariff',
        columnTwo: 'Name tariff',
        variant: {
            columnOne: TextVariant.bodyRegular,
            columnTwo: TextVariant.bodyRegular,
        },
    },
    {
        id: 2,
        columnOne: 'Current balance',
        columnTwo: '8,400 RUB',
        variant: {
            columnOne: TextVariant.bodyRegular,
            columnTwo: TextVariant.smallSemiBold,
        },
    },
    {
        id: 3,
        columnOne: 'The cost of the tariff',
        columnTwo: '12,000 RUB',
        variant: {
            columnOne: TextVariant.bodyRegular,
            columnTwo: TextVariant.smallSemiBold,
        },
    },
    {
        id: 4,
        columnOne: 'Taking into account the current balance',
        columnTwo: '-8,400 RUB',
        variant: {
            columnOne: TextVariant.bodyRegular,
            columnTwo: TextVariant.smallSemiBold,
        },
    },
    {
        id: 5,
        columnOne: 'Total payment',
        columnTwo: '3,600 RUB',
        variant: {
            columnOne: TextVariant.smallSemiBold,
            columnTwo: TextVariant.smallSemiBold,
        },
    },
];
export const TitleData = styled.div.withConfig({ displayName: "TitleData", componentId: "sc-1cpdg9p" }) `
  margin: 24px 0px 16px;
`;
export const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-1pqszm4" }) `
  margin: 16px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.config.line.secondary};
`;
export const Cheque = () => {
    return (_jsxs(_Fragment, { children: [tableData === null || tableData === void 0 ? void 0 : tableData.slice(0, 3).map((item) => (_jsx(RowPayment, { columnOne: item.columnOne, columnTwo: item.columnTwo, variant: item.variant }, item.id.toString()))), _jsx(Line, {}), tableData === null || tableData === void 0 ? void 0 : tableData.slice(3, 5).map((item) => (_jsx(RowPayment, { columnOne: item.columnOne, columnTwo: item.columnTwo, variant: item.variant }, item.id.toString()))), _jsx(Line, {}), tableData === null || tableData === void 0 ? void 0 : tableData.slice(5).map((item) => (_jsx(RowPayment, { columnOne: item.columnOne, columnTwo: item.columnTwo, variant: item.variant }, item.id.toString())))] }));
};
