import styled, { css } from 'styled-components';
import { Button } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { Right } from '../../../../../../common/icon/icons';
import { smallSemiBold, ultraSmallMedium, } from '../../../../../../styles/mixins/fonts';
import { EPreviewType } from './AdvPreview';
export const PreviewWrapper = styled.div.withConfig({ displayName: "PreviewWrapper", componentId: "sc-83fjre" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;

  position: sticky;
  top: -20px;
  width: 360px;

  height: fit-content;

  //padding: 7px;
  border-radius: 15px;
  // background: ${({ theme }) => theme.config.background.tetriery};
`;
export const PreviewHandlers = styled.div.withConfig({ displayName: "PreviewHandlers", componentId: "sc-1lik1bo" }) `
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.background.tetriery};
  padding: 5px;
`;
export const PreviewTabs = styled.header.withConfig({ displayName: "PreviewTabs", componentId: "sc-10ri4cc" }) `
  display: flex;
  position: relative;

  border-radius: 12px;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.config.tab.background.primary};
`;
export const PreviewTabLink = styled.div.withConfig({ displayName: "PreviewTabLink", componentId: "sc-1c85i68" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 50%;
  padding: 8px;
  margin: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: -0.02em;

  transition: transform 0.3s ease-out;

  &:hover {
    text-decoration: none;
  }

  ${({ $isActive }) => $isActive &&
    css `
      position: relative;
      z-index: 1;
    `};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      padding: 6px;
      ${ultraSmallMedium}
    `}
`;
export const PreviewTabLabel = styled.div.withConfig({ displayName: "PreviewTabLabel", componentId: "sc-ktfz2h" }) `
  ${smallSemiBold};

  z-index: 10;
  color: ${({ theme }) => theme.config.text.primary};

  ${({ $isActive }) => $isActive &&
    css `
      color: ${({ theme }) => theme.config.text.primary};
      transition: color 0.25s ease-out;
    `};
`;
export const PreviewTabBackground = styled.div.withConfig({ displayName: "PreviewTabBackground", componentId: "sc-3optni" }) `
  position: absolute;

  width: 50%;

  height: 40px;
  border-radius: ${({ theme }) => (theme.responsive.isMobile ? '10px' : '8px')};
  background: ${({ theme }) => theme.config.tab.background.secondary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07),
    inset 0px 1px 2px rgba(255, 255, 255, 0.02);

  transition: transform 0.3s ease-out;

  ${({ $step, theme }) => {
    switch ($step) {
        case EPreviewType.ADV_WEB:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(2%, ${theme.responsive.isMobile ? '5%' : '10%'});
          transition: transform 0.3s ease-out;
        `;
        case EPreviewType.SEARCH:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            98%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
        default:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            99%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
    }
}}
`;
export const WebToggle = styled.div.withConfig({ displayName: "WebToggle", componentId: "sc-13wmehe" }) `
  display: flex;
  flex-direction: row;
  padding: 10px;
`;
export const WebToggleContent = styled.div.withConfig({ displayName: "WebToggleContent", componentId: "sc-1l42koi" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
export const FirstVariantContainer = styled.div.withConfig({ displayName: "FirstVariantContainer", componentId: "sc-10vtcsi" }) `
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.config.background.tetriery};
  padding-bottom: 5px;
`;
export const FirstVariantImageContainer = styled.div.withConfig({ displayName: "FirstVariantImageContainer", componentId: "sc-137evdo" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const FirstVariantImage = styled.img.withConfig({ displayName: "FirstVariantImage", componentId: "sc-1lz3rx4" }) `
  width: 100%;
  height: 300px;
`;
export const FirstVariantMeta = styled.div.withConfig({ displayName: "FirstVariantMeta", componentId: "sc-iesoqi" }) `
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 3px;
`;
export const FirstVariantAdChip = styled.div.withConfig({ displayName: "FirstVariantAdChip", componentId: "sc-mthgtp" }) `
  padding: 0 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.config.background.primary};
  opacity: 0.5;
  height: fit-content;
`;
export const FirstVariantMoreInfoChip = styled.div.withConfig({ displayName: "FirstVariantMoreInfoChip", componentId: "sc-724bdq" }) `
  padding: 4px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.config.background.primary};
  opacity: 0.5;
`;
export const FirstVariantContent = styled.div.withConfig({ displayName: "FirstVariantContent", componentId: "sc-1scyl7j" }) `
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;
export const FirstVariantLinkContainer = styled.div.withConfig({ displayName: "FirstVariantLinkContainer", componentId: "sc-gg6av3" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
export const FirstVariantLinkFavicon = styled.img.withConfig({ displayName: "FirstVariantLinkFavicon", componentId: "sc-gs8vws" }) `
  width: 20px;
  height: 20px;
  background: transparent;
`;
export const FirstVariantTitle = styled.div.withConfig({ displayName: "FirstVariantTitle", componentId: "sc-1xjiap4" }) `
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const FirstVariantDescription = styled.div.withConfig({ displayName: "FirstVariantDescription", componentId: "sc-hpdgbz" }) `
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const FirstVariantSublinksContainer = styled.div.withConfig({ displayName: "FirstVariantSublinksContainer", componentId: "sc-imivp7" }) `
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
`;
export const FirstVariantSublink = styled.div.withConfig({ displayName: "FirstVariantSublink", componentId: "sc-vb6g23" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;
export const FirstVariantSublinkSeparator = styled.div.withConfig({ displayName: "FirstVariantSublinkSeparator", componentId: "sc-1y2bzx1" }) `
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.config.background.secondary};
`;
export const FirstVariantShowMoreButton = styled(Button).withConfig({ displayName: "FirstVariantShowMoreButton", componentId: "sc-urmujj" }) ``;
export const SecondVariantContainer = styled.div.withConfig({ displayName: "SecondVariantContainer", componentId: "sc-1g0pwf2" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 20px;

  background: ${({ theme }) => theme.config.background.tetriery};
`;
export const SecondVariantMeta = styled.div.withConfig({ displayName: "SecondVariantMeta", componentId: "sc-1u6hawo" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 7px 0;
`;
export const SecondVariantFavicon = styled.img.withConfig({ displayName: "SecondVariantFavicon", componentId: "sc-85rvme" }) `
  width: 20px;
  height: 20px;
`;
export const SecondVariantUrlWrapper = styled.div.withConfig({ displayName: "SecondVariantUrlWrapper", componentId: "sc-wue5hc" }) `
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;
export const SecondVariantMoreInfo = styled.div.withConfig({ displayName: "SecondVariantMoreInfo", componentId: "sc-16v4v38" }) ``;
export const SecondVariantImage = styled.img.withConfig({ displayName: "SecondVariantImage", componentId: "sc-1xrkz3b" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;
export const SecondVariantHeader = styled.div.withConfig({ displayName: "SecondVariantHeader", componentId: "sc-115t9qy" }) `
  padding: 5px 0;
  width: 330px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ThirdVariantContainer = styled.div.withConfig({ displayName: "ThirdVariantContainer", componentId: "sc-130r9nw" }) `
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.config.background.tetriery};
`;
export const ThirdVariantImageContainer = styled.div.withConfig({ displayName: "ThirdVariantImageContainer", componentId: "sc-1lk9szq" }) `
  position: relative;
`;
export const ThirdVariantImage = styled.img.withConfig({ displayName: "ThirdVariantImage", componentId: "sc-1ebrkhr" }) `
  height: 300px;
`;
export const ThirdVariantMeta = styled.div.withConfig({ displayName: "ThirdVariantMeta", componentId: "sc-12gr4xo" }) `
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 3px;
`;
export const ThirdVariantAdvChip = styled.div.withConfig({ displayName: "ThirdVariantAdvChip", componentId: "sc-16o4wni" }) `
  padding: 0 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.config.background.primary};
  opacity: 0.5;
  height: fit-content;
`;
export const ThirdVariantMoreInfo = styled.div.withConfig({ displayName: "ThirdVariantMoreInfo", componentId: "sc-oq66bk" }) `
  padding: 4px;
  border-radius: 50%;

  background-color: ${({ theme }) => theme.config.background.primary};
  opacity: 0.5;
`;
export const ThirdVariantSiteChip = styled.div.withConfig({ displayName: "ThirdVariantSiteChip", componentId: "sc-lmqpgv" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom: 7px;
  right: 2px;
  height: 22px;

  padding: 2px 8px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.config.background.primary};
  opacity: 0.9;
`;
export const ThirdVariantFavicon = styled.img.withConfig({ displayName: "ThirdVariantFavicon", componentId: "sc-18uklbg" }) `
  width: 15px;
  height: 15px;
`;
export const ThirdVariantInfoContainer = styled.div.withConfig({ displayName: "ThirdVariantInfoContainer", componentId: "sc-gg439w" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
`;
export const ThirdVariantHeader = styled.div.withConfig({ displayName: "ThirdVariantHeader", componentId: "sc-724xoq" }) `
  width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const ThirdVariantInfoSeparator = styled.div.withConfig({ displayName: "ThirdVariantInfoSeparator", componentId: "sc-77qngw" }) `
  width: 1px;
  height: 30px;
  background: ${({ theme }) => theme.colors.grey.primary};
`;
export const ThirdVariantArrowContainer = styled.div.withConfig({ displayName: "ThirdVariantArrowContainer", componentId: "sc-1bxpaz4" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;
export const ThirdVariantArrow = styled(Right).withConfig({ displayName: "ThirdVariantArrow", componentId: "sc-1f1vlwi" }) `
  width: 30px;
  height: 30px;
`;
export const SearchVariantContainer = styled.div.withConfig({ displayName: "SearchVariantContainer", componentId: "sc-8myhvd" }) `
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.config.background.tetriery};
  padding: 10px;
  border-radius: 20px;
`;
export const SearchVariantContent = styled.div.withConfig({ displayName: "SearchVariantContent", componentId: "sc-1yxtr8y" }) `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const SearchVariantTitleContainer = styled.div.withConfig({ displayName: "SearchVariantTitleContainer", componentId: "sc-3ukhuu" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SearchVariantTitle = styled.div.withConfig({ displayName: "SearchVariantTitle", componentId: "sc-1k4tfun" }) `
  width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const SearchVariantLinkContainer = styled.div.withConfig({ displayName: "SearchVariantLinkContainer", componentId: "sc-17kdbn3" }) `
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;

  height: 20px;
`;
export const SearchVariantFavicon = styled.img.withConfig({ displayName: "SearchVariantFavicon", componentId: "sc-iyv0k1" }) `
  width: 15px;
  height: 15px;
`;
export const SearchVariantLinkDomain = styled.div.withConfig({ displayName: "SearchVariantLinkDomain", componentId: "sc-19vy9pc" }) `
  width: fit-content;
`;
export const SearchVariantLinkSeparatorContainer = styled(IconWrapper).withConfig({ displayName: "SearchVariantLinkSeparatorContainer", componentId: "sc-1m1n5tq" }) `
  width: fit-content;
  height: fit-content;
`;
export const SearchVariantLinkSeparator = styled(Right).withConfig({ displayName: "SearchVariantLinkSeparator", componentId: "sc-y0hmtx" }) `
  width: 10px;
  height: 10px;
`;
export const SearchVariantLinkSubDomain = styled.div.withConfig({ displayName: "SearchVariantLinkSubDomain", componentId: "sc-1mhtnh6" }) `
  max-width: 200px;
  width: fit-content;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const SearchVariantLinkAdv = styled.div.withConfig({ displayName: "SearchVariantLinkAdv", componentId: "sc-1seox2k" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
`;
export const SearchVariantDescription = styled.div.withConfig({ displayName: "SearchVariantDescription", componentId: "sc-1vi7g3z" }) `
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const SearchVariantMoreInfo = styled.div.withConfig({ displayName: "SearchVariantMoreInfo", componentId: "sc-13adkhh" }) `
  border-radius: 50%;
  height: fit-content;

  opacity: 0.5;
`;
// export const SearchVariant = styled.div``;
// export const SearchVariant = styled.div``;
