import styled, { css } from 'styled-components';
import { smallSemiBold, ultraSmallMedium } from 'styles/mixins/fonts';
import { EInputType } from '../EInputType';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-dbxime" }) `
  position: relative;
`;
export const TabsHeader = styled.header.withConfig({ displayName: "TabsHeader", componentId: "sc-104ex6b" }) `
  display: flex;
  position: relative;

  border-radius: 12px;

  background-color: ${({ theme }) => theme.config.tab.background.primary};
`;
export const TabLink = styled.div.withConfig({ displayName: "TabLink", componentId: "sc-1gus4c6" }) `
  flex: 1;
  padding: 8px;
  margin: 4px;
  text-align: center;
  text-decoration: none;

  letter-spacing: -0.02em;

  transition: transform 0.3s ease-out;

  &:hover {
    text-decoration: none;
  }

  ${({ $isActive }) => $isActive &&
    css `
      position: relative;
      z-index: 1;
    `};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      padding: 6px;
      ${ultraSmallMedium}
    `}
`;
export const Label = styled.div.withConfig({ displayName: "Label", componentId: "sc-age2tp" }) `
  ${smallSemiBold};

  z-index: 10;
  cursor: pointer;
  color: ${({ theme }) => theme.config.text.primary};

  ${({ $isActive }) => $isActive &&
    css `
      color: ${({ theme }) => theme.config.text.primary};
      transition: color 0.25s ease-out;
    `};
`;
export const TabBackground = styled.div.withConfig({ displayName: "TabBackground", componentId: "sc-tfkmm3" }) `
  position: absolute;

  width: 33.3%;
  height: 40px;
  border-radius: ${({ theme }) => (theme.responsive.isMobile ? '10px' : '8px')};
  background: ${({ theme }) => theme.config.tab.background.secondary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07),
    inset 0px 1px 2px rgba(255, 255, 255, 0.02);

  transition: transform 0.3s ease-out;

  ${({ $step, theme }) => {
    switch ($step) {
        case EInputType.TEXT:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(2%, ${theme.responsive.isMobile ? '5%' : '10%'});
          transition: transform 0.3s ease-out;
        `;
        case EInputType.MICROPHONE:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            100%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
        case EInputType.AUDIOFILE:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            198%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
        default:
            return css `
          border-radius: ${theme.responsive.isMobile ? '10px' : '8px'};
          transform: translate(
            99%,
            ${theme.responsive.isMobile ? '5%' : '10%'}
          );
          transition: transform 0.3s ease-out;
        `;
    }
}}
`;
