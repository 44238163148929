import styled from 'styled-components';
import { Button } from '../../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-6i037o" }) `
  display: flex;
  flex-direction: column;
`;
export const WrapperForm = styled.div.withConfig({ displayName: "WrapperForm", componentId: "sc-553kmn" }) `
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1xx70vu" }) `
  margin: 16px 0px 12px 0px;
`;
export const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-1shl26d" }) `
  margin-top: 8px;
`;
export const HiddenInput = styled.input.withConfig({ displayName: "HiddenInput", componentId: "sc-lrsdej" }) `
  display: none;
`;
export const DownloadButton = styled(Button).withConfig({ displayName: "DownloadButton", componentId: "sc-1fgtlg7" }) `
  margin-bottom: 10px;
`;
