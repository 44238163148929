import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
import { useBackdropClick } from '../../../hooks/useBackdropClick';
import * as Styles from './styles';
export const DropdownMenu = ({ top, left, items, button, isVisible, setVisible, selectedId, onItemClick, tableRef, }) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);
    const [menuTop, setMenuTop] = useState(undefined);
    const [menuLeft, setMenuLeft] = useState(undefined);
    useEffect(() => {
        if (dropdownMenuRef.current && tableRef.current) {
            const rect = dropdownMenuRef.current.getBoundingClientRect();
            const tableRect = tableRef.current.getBoundingClientRect();
            const windowRect = document.documentElement.getBoundingClientRect();
            // const bottomSpace = window.innerHeight - rect.bottom;
            const bottomSpace = () => {
                const menuToWindowBottom = windowRect.height - rect.bottom;
                const tableBottomToWindowBottom = windowRect.height - tableRect.bottom;
                if (tableBottomToWindowBottom > 0) {
                    if (tableRect.bottom - rect.bottom - rect.height - 25 > 0) {
                        return 0;
                    }
                    else {
                        return -rect.height + 50;
                    }
                }
                else {
                    return menuToWindowBottom - 25;
                }
            };
            // const rightSpace = window.innerWidth - rect.right;
            //
            // const bufferPercentage = 0.45;
            if (bottomSpace() < 0) {
                setMenuTop(bottomSpace());
            }
            setMenuLeft(-rect.width);
            // if (rightSpace > rect.width * bufferPercentage) {
            //   setMenuLeft(-rect.width);
            // } else {
            //   setMenuLeft(0);
            // }
        }
    }, [dropdownMenuRef]);
    const handleClick = (labelElement) => {
        const selectedItem = items === null || items === void 0 ? void 0 : items.map((item) => item.labelAction === labelElement);
        if (selectedItem && selectedId && onItemClick) {
            onItemClick(labelElement, selectedId === null || selectedId === void 0 ? void 0 : selectedId.toString());
        }
    };
    if (setVisible) {
        useBackdropClick(dropdownRef, setVisible, isVisible);
    }
    return (_jsxs(Styles.DropdownContainer, Object.assign({ ref: dropdownRef }, { children: [_jsx(Styles.DropdownButton, { children: button }), isVisible && (_jsx(Styles.DropdownMenu, Object.assign({ ref: dropdownMenuRef, "$top": menuTop !== null && menuTop !== void 0 ? menuTop : top, "$left": menuLeft !== null && menuLeft !== void 0 ? menuLeft : left, "$isVisible": isVisible !== null && isVisible !== void 0 ? isVisible : false }, { children: _jsx(Styles.Wrapper, { children: items === null || items === void 0 ? void 0 : items.map((item) => (_jsx(Styles.DropdownMenuItem, Object.assign({ onClick: () => handleClick(item.labelAction) }, { children: _jsxs(Styles.WrapperItem, { children: [_jsx(IconWrapper, { children: _jsx(item.icon, {}) }), t(item === null || item === void 0 ? void 0 : item.label)] }) }), item === null || item === void 0 ? void 0 : item.label))) }) })))] })));
};
