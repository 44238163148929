var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Text, TextVariant } from '../../../../../common';
import { useDescribeImageMutation } from '../../../../../store/api/ai';
import { handleMutation } from '../../../../../utils/handleMutation';
import { LoaderContainer } from '../generate-post/styles';
import { HeaderDescribeImage } from './header';
import * as Styles from './styles';
export const DescribeImage = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const alert = useAlert();
    const { t } = useTranslation();
    const [changeUrl, mutationUrl] = useDescribeImageMutation();
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const onError = useCallback((error) => {
        var _a;
        try {
            setIsLoading(false);
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    const onSuccess = useCallback(() => {
        setIsLoading(false);
        alert.success(t('Success'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationUrl), { onSuccess,
        onError }));
    const uploadAudio = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const describeImage = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield changeUrl({
            file,
        });
    });
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(HeaderDescribeImage, {}), _jsxs(Styles.InputAction, { children: [_jsxs(Styles.InputButtonContainer, { children: [_jsx(Styles.InputButton, { text: t('Upload a photo'), disabled: isLoading, onClick: () => uploadAudio() }), _jsx(Text, Object.assign({ "$variant": TextVariant.ultraSmallMedium, "$colorVariant": 'tetriery' }, { children: "\u0424\u043E\u0440\u043C\u0430\u0442\u044B: jpeg, png, webp \u0438 \u0442.\u0434." }))] }), _jsx(Styles.FileInput, { type: "file", accept: "image/*", ref: fileInputRef, onChange: (event) => {
                                var _a;
                                if ((_a = event.target.files) === null || _a === void 0 ? void 0 : _a.item(0)) {
                                    describeImage(event.target.files.item(0));
                                }
                            } }), ((_c = (_b = (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.item(0)) === null || _c === void 0 ? void 0 : _c.name) && (_jsx(_Fragment, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: (_f = (_e = (_d = fileInputRef.current) === null || _d === void 0 ? void 0 : _d.files) === null || _e === void 0 ? void 0 : _e.item(0)) === null || _f === void 0 ? void 0 : _f.name })) }))] }), isLoading && (_jsx(LoaderContainer, { children: _jsx(Loader, { text: t('Image processing...') }) })), ((_g = mutationUrl.data) === null || _g === void 0 ? void 0 : _g.describe) && (_jsx(_Fragment, { children: _jsxs(Styles.GeneratedText, { children: [(_h = mutationUrl.data) === null || _h === void 0 ? void 0 : _h.describe, _jsx(Styles.ButtonWrapper, { children: _jsx(Button, { text: t('Copy to clipboard'), onClick: () => navigator.clipboard.writeText(mutationUrl.data.describe) }) })] }) }))] }) }));
};
