import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Dialog, InputField, Text, TextVariant, } from 'common';
import { VoiceRecordingField } from 'common/fields/audioplayer-field';
import { SelectField } from 'common/fields/select-field/select';
import { Formik, useFormikContext } from 'formik';
import { useCallback, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useClipCreateMutation } from '../../../../../../store/api/audio-clips';
import { useMeQuery } from '../../../../../../store/api/profile';
import { handleMutation } from '../../../../../../utils/handleMutation';
import { audioFormInitialValue, model, validationSchema, } from './config';
import { EInputType } from './EInputType';
import { DownloadForm } from './forms/download';
import { AudioPreview } from './forms/text';
import { HeaderTabs } from './header-tabs';
import * as Styles from './styles';
export const AudioClipModalComponent = ({ setModalOpen, trigger, audioRef, setStep, step, responseCreate, }) => {
    const { values, setFieldValue, resetForm, dirty, isValid, isSubmitting, submitForm, } = useFormikContext();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const alert = useAlert();
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    const onSuccess = useCallback(() => {
        resetForm();
        setFieldValue('userFile', null);
        alert.success(t('The audioclip was successfully created'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseCreate), { onSuccess: () => {
            onSuccess();
            trigger();
        }, onError }));
    const handleStep = (inputType) => {
        setStep(inputType);
    };
    return (_jsxs(Styles.Container, { children: [_jsxs(Styles.WrapperForm, { children: [_jsxs(Styles.WrapperFields, { children: [_jsxs(Styles.MainFields, { children: [_jsx(InputField, Object.assign({}, model.audioName)), _jsx(SelectField, Object.assign({}, model.audioLanguage, { name: "language", value: values.language, onChange: (o) => setFieldValue('language', o) }))] }), _jsx(InputField, Object.assign({}, model.audioDescription))] }), _jsxs(Styles.Header, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Select a source') })), _jsx(HeaderTabs, { step: step, onStepChange: handleStep })] }), step === EInputType.TEXT && (_jsx(AudioPreview, { audioRef: audioRef, loading: isLoading, setLoading: setIsLoading, text: values.message, language: values.language.value })), step === EInputType.MICROPHONE && (_jsx(VoiceRecordingField, Object.assign({}, model.audio))), step === EInputType.AUDIOFILE && _jsx(DownloadForm, {})] }), _jsxs(Styles.CreateButtonContainer, { children: [_jsx(Button, { text: t('Cancel'), onClick: () => {
                            audioRef.current.pause();
                            audioRef.current.currentTime = 0;
                            setModalOpen(false);
                        }, padding: "24px", variant: ButtonVariant.TETRIERY, disabled: !dirty || isSubmitting || isLoading }), _jsx(Button, { disabled: isLoading || !isValid, padding: "24px", text: t('Create an audio clip'), onClick: submitForm })] })] })
    /*  <Styles.Close*/
    /*    onClick={() => {*/
    /*      if (audioRef.current) {*/
    /*        audioRef.current!.pause();*/
    /*        audioRef.current!.currentTime = 0;*/
    /*      }*/
    /*      setModalOpen(false);*/
    /*    }}*/
    );
};
export const AudioClipModal = ({ isDialogOpen, setIsDialogOpen, trigger, zIndex, }) => {
    const { data: me } = useMeQuery();
    const audioRef = useRef(null);
    const [step, setStep] = useState(EInputType.TEXT);
    const [requestCreate, responseCreate] = useClipCreateMutation();
    const submitForm = (values) => {
        {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            if (me && me._id) {
                if (step === EInputType.MICROPHONE && values.voiceRecord) {
                    requestCreate({
                        clip_description: values.audioDescription,
                        clip_name: values.audioName,
                        user_id: me._id.toString(),
                        audio: values.voiceRecord,
                    });
                    setIsDialogOpen(false);
                }
                else if (step === EInputType.AUDIOFILE && values.userFile) {
                    if (values.userFile) {
                        requestCreate({
                            clip_description: values.audioDescription,
                            clip_name: values.audioName,
                            user_id: me._id.toString(),
                            audio: values.userFile,
                        });
                        setIsDialogOpen(false);
                    }
                }
                else if (step === EInputType.TEXT && values.generatedFile) {
                    if (values.generatedFile) {
                        requestCreate({
                            clip_description: values.audioDescription,
                            clip_name: values.audioName,
                            user_id: me._id.toString(),
                            audio: values.generatedFile,
                        });
                        setIsDialogOpen(false);
                    }
                }
            }
        }
    };
    return (_jsx(Formik, Object.assign({ onSubmit: submitForm, validateOnChange: true, initialValues: audioFormInitialValue, validationSchema: validationSchema(step), isInitialValid: false }, { children: _jsx(Dialog, Object.assign({ isOpen: isDialogOpen, onClose: () => setIsDialogOpen(false), zIndex: zIndex, title: 'Creating an audio clip' }, { children: _jsx(AudioClipModalComponent, { isVisible: isDialogOpen, setModalOpen: setIsDialogOpen, trigger: trigger, audioRef: audioRef, setStep: setStep, step: step, responseCreate: responseCreate }) })) })));
};
