import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant } from 'common';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-pgjgsg" }) `
  margin-top: 42px;
  padding-bottom: 32px;
  border-top: ${({ theme }) => theme.config.dropdown.border.all};
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-k76ds" }) `
  display: flex;
  align-items: center;

  gap: 24px;
  margin-top: 24px;
`;
export const Footer = () => {
    const { dirty, submitForm, isValid, initialValues, values, resetForm } = useFormikContext();
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        setIsDisabled(!dirty || !isValid || initialValues == values);
    }, [values]);
    return (_jsx(Container, { children: _jsxs(Wrapper, { children: [_jsx(Button, { text: t('Save'), onClick: submitForm, disabled: isDisabled }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, onClick: () => resetForm() })] }) }));
};
