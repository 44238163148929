import { Button as ButtonComponent, Image as ImageComponent } from 'common';
import styled, { css } from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-19qayxc" }) `
  flex: 1;
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-11wmszb" }) `
  display: grid;
  grid-template-columns: 236px 1fr;
  grid-template-rows: 1fr;
  gap: 40px;
  grid-template-areas:
    'image form'
    'footer footer';

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'form'
      'footer';
  }
`;
export const WrapperLeft = styled.div.withConfig({ displayName: "WrapperLeft", componentId: "sc-tt1qvm" }) `
  grid-area: image;
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 16px;
`;
export const WrapperImage = styled.div.withConfig({ displayName: "WrapperImage", componentId: "sc-16k1ib9" }) `
  position: relative;
  cursor: default;
`;
export const WrapperForm = styled.div.withConfig({ displayName: "WrapperForm", componentId: "sc-1m1ackq" }) `
  grid-area: form;
  display: flex;
  flex: 1;
  flex-direction: column;

  gap: 16px;
  width: 100%;
`;
export const FieldsRow = styled.div.withConfig({ displayName: "FieldsRow", componentId: "sc-v8p2fq" }) `
  display: flex;
  flex: 1;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const Section = styled.div.withConfig({ displayName: "Section", componentId: "sc-woacel" }) `
  margin-top: 24px;
  padding: 24px 0px 40px;
  border-top: ${({ theme }) => theme.config.toggleTabs.border.default};
  border-bottom: ${({ theme }) => theme.config.toggleTabs.border.default};
`;
export const WrapperUploadImage = styled.div.withConfig({ displayName: "WrapperUploadImage", componentId: "sc-1bhpw1l" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  gap: 10px;
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;

  ${({ theme }) => css `
    width: ${theme.responsive.isMobile ? '100px' : '100%'};
    height: ${theme.responsive.isMobile ? '100px' : '100%'};
    background: ${theme.config.uploadImage.bg.default};
    border: ${theme.config.uploadImage.border.default};
  `}
`;
export const WrapperButtonImg = styled.div.withConfig({ displayName: "WrapperButtonImg", componentId: "sc-1kv5c12" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
`;
export const ButtonImage = styled(ButtonComponent).withConfig({ displayName: "ButtonImage", componentId: "sc-1oqfbru" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  border-radius: 50%;

  ${({ theme }) => css `
    border: ${theme.config.uploadImage.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}
  &:hover {
    background: none;
  }
`;
export const ButtonDelete = styled(ButtonImage).withConfig({ displayName: "ButtonDelete", componentId: "sc-1m9fayg" }) ``;
export const ButtonRefresh = styled(ButtonImage).withConfig({ displayName: "ButtonRefresh", componentId: "sc-y6vmp4" }) ``;
export const Image = styled(ImageComponent).withConfig({ displayName: "Image", componentId: "sc-1rm4pc9" }) `
  cursor: default;
  object-fit: contain;
  border-radius: 50%;
`;
export const FooterButton = styled.div.withConfig({ displayName: "FooterButton", componentId: "sc-53nsht" }) `
  grid-area: footer;
  display: flex;
  padding: 62px 0px 16px;

  gap: 24px;
  //border-top: ${({ theme }) => theme.config.toggleTabs.border.default};
`;
export const Password = styled(ButtonComponent).withConfig({ displayName: "Password", componentId: "sc-1nwj3f5" }) `
  margin-bottom: -28px;
`;
export const ButtonPrimary = styled(ButtonComponent).withConfig({ displayName: "ButtonPrimary", componentId: "sc-muywlk" }) `
  height: 48px;
`;
export const WrapperText = styled.div.withConfig({ displayName: "WrapperText", componentId: "sc-16sa84" }) `
  flex-basis: 300px;

  @media screen and (max-width: 1024px) {
    flex-basis: 0;
  }
`;
export const HiddenInput = styled.input.withConfig({ displayName: "HiddenInput", componentId: "sc-17sdrij" }) `
  display: none;
`;
