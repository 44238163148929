import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { Text, TextVariant } from '../../../../../common';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import { Checkmark, Ruble } from '../../../../../common/icon/icons';
import { EBudgetItemType, EWeeklyBudgetType, weeklyBudgetTypeItems, } from './config';
import * as Styles from './styles';
export const chooseBudgetItems = [
    {
        budgetType: EBudgetItemType.MIN,
        budget: 1750,
        description: 'Минимальный бюджет, чтобы получить первые результаты от рекламы',
    },
    {
        budgetType: EBudgetItemType.MID,
        budget: 3850,
        description: 'Оптимальный бюджет, который выбирают бошльшинство конкурентов',
    },
    {
        budgetType: EBudgetItemType.MAX,
        budget: 7000,
        description: 'Эффективный бюджет, чтобы привлекать ещё больше клиентов',
    },
];
export const WeekBudgetBlock = () => {
    const theme = useTheme();
    const { values, setFieldValue } = useFormikContext();
    const [budgetType, setBudgetType] = useState(EWeeklyBudgetType.CHOOSE);
    const RUB = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        maximumFractionDigits: 0,
    });
    const isActive = useMemo(() => {
        return ((weeklyBudgetTypeItems === null || weeklyBudgetTypeItems === void 0 ? void 0 : weeklyBudgetTypeItems.filter((item) => item.value === budgetType).length) > 0);
    }, [budgetType]);
    return (_jsx(Styles.BlockContainer, { children: _jsxs(Styles.Block, { children: [_jsx(Styles.BlockHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: "\u041D\u0435\u0434\u0435\u043B\u044C\u043D\u044B\u0439 \u0431\u044E\u0434\u0436\u0435\u0442" })) }), _jsxs(Styles.BlockContent, { children: [_jsxs(Styles.WeekBudgetTabs, { children: [weeklyBudgetTypeItems === null || weeklyBudgetTypeItems === void 0 ? void 0 : weeklyBudgetTypeItems.map((item) => (_jsx(Styles.WeekBudgetTabLink, Object.assign({ id: item.value.toString(), "$isActive": isActive, onClick: () => {
                                        setBudgetType(item.value);
                                    } }, { children: _jsx(Styles.WeekBudgetTabLabel, Object.assign({ "$isActive": isActive }, { children: item.label })) }), item.value.toString()))), _jsx(Styles.WeekBudgetTabBackground, { step: budgetType })] }), budgetType === EWeeklyBudgetType.CHOOSE && (_jsx(Styles.ChooseBudgetContainer, { children: chooseBudgetItems.map((item) => (_jsxs(Styles.BudgetItem, Object.assign({ onClick: () => setFieldValue('chosenBudgetType', item.budgetType) }, { children: [_jsxs(Styles.BudgetContent, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'primary' }, { children: RUB.format(item.budget) })), _jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium, "$colorVariant": 'tetriery' }, { children: item.description }))] }), _jsx(Styles.ChosenBudgetCheckmarkContainer, { children: item.budgetType === values.chosenBudgetType && (_jsx(Styles.ChosenBudgetCheckmark, { children: _jsx(Checkmark, {}) })) })] }), item.budget))) })), budgetType === EWeeklyBudgetType.MANUAL && (_jsxs("div", { children: ["\u041D\u0435\u0434\u0435\u043B\u044C\u043D\u044B\u0439 \u0431\u044E\u0434\u0436\u0435\u0442", _jsx(Styles.BudgetInput, { name: 'manualBudget', colorType: 'LIGHT', type: 'number', rightIcon: _jsx(IconWrapper, Object.assign({ "$strokeColor": theme.config.icon.primary }, { children: _jsx(Ruble, {}) })) })] }))] })] }) }));
};
