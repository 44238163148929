import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, Input, Text, TextVariant } from 'common';
import { useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Audio, Close } from '../../../../../../../../common/icon/icons';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-p394uf" }) `
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;

  ${({ theme, $isDragged }) => css `
    border: ${$isDragged
    ? theme.config.input.default.border.greenDashed
    : theme.config.input.default.border.default};
    background: ${theme.config.input.default.background};
  `}
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1f3xtj0" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 56px 46px 62px;
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1g9hggx" }) ``;
const SubHeader = styled.div.withConfig({ displayName: "SubHeader", componentId: "sc-1uqnk0t" }) ``;
const ContainerButton = styled.div.withConfig({ displayName: "ContainerButton", componentId: "sc-x3svdu" }) `
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;
const FieldColumn = styled.div.withConfig({ displayName: "FieldColumn", componentId: "sc-1u2yavt" }) `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const DownloadButton = styled(Button).withConfig({ displayName: "DownloadButton", componentId: "sc-nw2ubn" }) `
  border-radius: 8px;
  width: 200px;
`;
const SubText = styled.div.withConfig({ displayName: "SubText", componentId: "sc-1l9l4o" }) `
  margin-top: 8px;
`;
const InputForm = styled.div.withConfig({ displayName: "InputForm", componentId: "sc-1b2xs75" }) `
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  gap: 10px;
  align-items: center;
`;
const UrlInput = styled(Input).withConfig({ displayName: "UrlInput", componentId: "sc-1l1dz7g" }) `
  flex-grow: 1;
  min-width: 50%;
`;
const HiddenFileInput = styled.input.withConfig({ displayName: "HiddenFileInput", componentId: "sc-afthx5" }) `
  display: none;
`;
const FileForm = styled.div.withConfig({ displayName: "FileForm", componentId: "sc-1qer8no" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const AudioIcon = styled(Audio).withConfig({ displayName: "AudioIcon", componentId: "sc-ewllr6" }) `
  width: 20px;
  height: 20px;
`;
const FileNameWrapper = styled.div.withConfig({ displayName: "FileNameWrapper", componentId: "sc-atbrax" }) `
  display: flex;
  flex-grow: 1;
  width: 80%;
`;
const FileName = styled(Text).withConfig({ displayName: "FileName", componentId: "sc-1ccjtgc" }) `
  width: 100%;
`;
const DragNDrop = styled.div.withConfig({ displayName: "DragNDrop", componentId: "sc-1w6zd3" }) `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px;
  border: 5px dot-dash #3dcc4a;
  backdrop-filter: blur(2px);
`;
const CloseIcon = styled(Close).withConfig({ displayName: "CloseIcon", componentId: "sc-1lbmj7i" }) `
  width: 20px;
  height: 20px;
  cursor: pointer;

  ${({ theme }) => css `
    stroke: ${theme.config.icon.green.active};
  `}
`;
export const DownloadForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const [isInputFormOpen, setIsInputFormOpen] = useState(false);
    const { t } = useTranslation();
    const fileInputEl = useRef(null);
    const [dragActive, setDragActive] = useState(false);
    const uploadFromDevice = () => {
        var _a;
        if (fileInputEl.current) {
            (_a = fileInputEl.current) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        }
        else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };
    const handleDrop = function (e) {
        var _a;
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (((_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files) && e.dataTransfer.files[0]) {
            setFieldValue('userFile', e.dataTransfer.files[0]);
        }
    };
    const trimmedFileName = (fileName) => {
        const splitted = fileName.split('.');
        const type = splitted.pop();
        return (_jsxs(FileNameWrapper, { children: [_jsx(FileName, Object.assign({ "$styles": {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    } }, { children: splitted.join('.') })), _jsxs(FileName, { children: [".", type] })] }));
    };
    if (isInputFormOpen) {
        return (_jsxs(InputForm, { children: [_jsx(UrlInput, { placeholder: 'Paste URL here...' }), _jsx(CloseIcon, { onClick: () => setIsInputFormOpen(false) })] }));
    }
    if (values.userFile) {
        return (_jsxs(FileForm, { children: [_jsx(AudioIcon, {}), trimmedFileName(values.userFile.name), _jsx(CloseIcon, { onClick: () => setFieldValue('userFile', null) })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Container, Object.assign({ onDragEnter: handleDrag, "$isDragged": dragActive }, { children: [dragActive && (_jsx(DragNDrop, { id: "drag-file-element", onDragEnter: handleDrag, onDragLeave: handleDrag, onDragOver: handleDrag, onDrop: handleDrop })), _jsxs(Wrapper, { children: [_jsx(Header, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: t('Drag and drop the audio file here') })) }), _jsx(SubHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular }, { children: t('Or choose a source') })) }), _jsxs(ContainerButton, { children: [_jsxs(FieldColumn, { children: [_jsx(HiddenFileInput, { onChange: (e) => {
                                                    var _a, _b;
                                                    if ((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0])
                                                        setFieldValue('userFile', e.target.files[0]);
                                                }, ref: fileInputEl, type: "file", 
                                                // accept='audio/mpeg'
                                                accept: "audio/*" }), _jsx(DownloadButton, { onClick: () => uploadFromDevice(), text: t('This device'), variant: ButtonVariant.SECONDARY }), _jsx(DownloadButton, { onClick: () => setIsInputFormOpen(true), text: t('Google Drive'), variant: ButtonVariant.SECONDARY })] }), _jsxs(FieldColumn, { children: [_jsx(DownloadButton, { onClick: () => setIsInputFormOpen(true), text: t('Dropbox'), variant: ButtonVariant.SECONDARY }), _jsx(DownloadButton, { onClick: () => setIsInputFormOpen(true), text: t('URL'), variant: ButtonVariant.SECONDARY })] })] })] })] })), _jsx(SubText, { children: _jsx(Text, Object.assign({ "$colorVariant": "tetriery", "$variant": TextVariant.ultraSmallMedium }, { children: t('The file must be in mp3, mpeg format') })) })] }));
};
