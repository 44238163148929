import { Button as ButtonComponent } from 'common';
import styled, { css } from 'styled-components';
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-h0o8hl" }) `
  ${({ theme }) => css `
    padding-left: ${theme.responsive.isMobile ? '16px' : '26px'};
    padding-right: ${theme.responsive.isMobile ? '16px' : '26px'};
  `}
`;
export const Container = styled.header.withConfig({ displayName: "Container", componentId: "sc-wcafvj" }) `
  width: 100%;
  height: 90px;
  background: ${({ theme }) => theme.config.header.background};
  //box-shadow: 0px 12px 45px rgba(0, 0, 0, 0.08);
`;
export const ContentInner = styled.div.withConfig({ displayName: "ContentInner", componentId: "sc-15jw8i4" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  margin: 0 80px;

  @media only screen and (max-width: 1024px) {
    margin: 0 40px;
  }

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      margin: 0 16px;
    `}
`;
export const LeftContent = styled.div.withConfig({ displayName: "LeftContent", componentId: "sc-151pz92" }) `
  display: flex;
  align-items: center;
`;
export const Logo = styled.div.withConfig({ displayName: "Logo", componentId: "sc-1jdl57h" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  margin-right: 10px;
  width: 45px;
  height: 45px;
`;
export const RightContent = styled.div.withConfig({ displayName: "RightContent", componentId: "sc-il02xp" }) ``;
export const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-13lshgh" }) `
  display: flex;
  margin-right: 40px;
  cursor: pointer;
  color: ${({ theme }) => theme.config.text.primary};

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      display: none;
    `};
`;
export const MenuIconContainer = styled.div.withConfig({ displayName: "MenuIconContainer", componentId: "sc-1ggtvjy" }) `
  width: 24px;
  height: 16px;

  cursor: pointer;
`;
export const LoginIconContainer = styled.svg.withConfig({ displayName: "LoginIconContainer", componentId: "sc-6fwt5b" }) `
  width: 24px;
  height: 24px;

  cursor: pointer;

  background: red;
`;
export const LogoTextBold = styled.span.withConfig({ displayName: "LogoTextBold", componentId: "sc-rbj4uh" }) `
  font-size: 32px;
  font-weight: bold;
  margin-right: 10px;
  text-transform: uppercase;
`;
export const LogoTextLight = styled.span.withConfig({ displayName: "LogoTextLight", componentId: "sc-1ur4pmr" }) `
  font-size: 32px;
  font-weight: 200;
`;
