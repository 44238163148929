import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalMain } from '../main';
import { Button, ButtonVariant, Text } from '../../../common';
import React from 'react';
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-e67iy6" }) ``;
export const DeleteModal = ({ isVisible, onClick, message, modalTitle, deleteFunc, buttonText, }) => {
    const { t } = useTranslation();
    return (_jsx(ModalMain, Object.assign({ title: modalTitle, isVisible: isVisible, onClick: onClick, footer: _jsxs(_Fragment, { children: [_jsx(Button, { padding: "24px", onClick: () => {
                        deleteFunc();
                        onClick();
                    }, text: t(buttonText), variant: ButtonVariant.PRIMARY }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, onClick: onClick })] }) }, { children: _jsx(Body, { children: _jsx(Text, { children: t(message) }) }) })));
};
