import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox } from 'common/checkbox';
import { useRef } from 'react';
import { Text, TextVariant } from '../../common';
import * as Styles from './styles';
export var TableRowVariant;
(function (TableRowVariant) {
    TableRowVariant["DEFAULT"] = "DEFAULT";
    TableRowVariant["SMALL"] = "SMALL";
})(TableRowVariant || (TableRowVariant = {}));
export const TableItem = ({ data, onChange, checked = false, rowVariant, isHiddenLast, hasItemCheckbox, }) => {
    return (_jsxs(Styles.TableRow, { children: [hasItemCheckbox && (_jsx(Styles.CheckboxContainer, { children: _jsx(Checkbox, { checked: checked, onChange: onChange }) })), _jsx(Styles.TableRowContent, Object.assign({ checked: checked, "$rowVariant": rowVariant !== null && rowVariant !== void 0 ? rowVariant : TableRowVariant.DEFAULT }, { children: data.map((item, index) => (_jsx(Styles.TableColumn, Object.assign({ "$isHiddenLast": isHiddenLast }, { children: item }), index))) }))] }));
};
export const TableItemParserResult = ({ data, onChange, checked = false, rowVariant, hasItemCheckbox, isShortParser = false, }) => {
    return (_jsxs(Styles.TableRowParserResult, { children: [hasItemCheckbox && (_jsx(Styles.CheckboxContainer, { children: _jsx(Checkbox, { checked: checked, onChange: onChange }) })), _jsx(Styles.TableRowContentParerResult, Object.assign({ checked: checked, "$rowVariant": rowVariant !== null && rowVariant !== void 0 ? rowVariant : TableRowVariant.DEFAULT }, { children: data.map((item, index) => (_jsx(Styles.TableColumnParserResult, Object.assign({ "$isShortParser": isShortParser }, { children: item }), index))) }))] }));
};
export const TableHeader = ({ data, isChecked, isTertiary, hasHeaderCheckbox, onAllRowsChecked, }) => {
    return (_jsxs(Styles.TableHeader, { children: [hasHeaderCheckbox && (_jsx(Styles.CheckboxContainer, { children: _jsx(Checkbox, { onChange: onAllRowsChecked, checked: isChecked }) })), data === null || data === void 0 ? void 0 : data.map((item, index) => (_jsx(Styles.TableColumn, Object.assign({ "$isTertiary": isTertiary }, { children: item }), index)))] }));
};
export const TableHeaderParserResult = ({ data, isShortParser = false, }) => {
    return (_jsx(Styles.TableHeaderParserResult, { children: data === null || data === void 0 ? void 0 : data.map((item, index) => (_jsx(Styles.TableColumnParserResult, Object.assign({ "$isShortParser": isShortParser }, { children: item }), index))) }));
};
export const TableBody = ({ data, icon, allId, rowVariant, checkedRows, onRowChecked, handleButton, buttonAction, isHiddenLast, selectedElement, hasItemCheckbox, tableRef, handleDropdownMenu, }) => {
    const selectedId = allId === null || allId === void 0 ? void 0 : allId.find((_, index) => index === selectedElement);
    const menuRef = useRef(null);
    return (_jsx(_Fragment, { children: data.map((row, index) => {
            return (_jsx(Styles.WrapperTableItem, { children: _jsxs(_Fragment, { children: [_jsx(TableItem, { data: row, icon: icon, rowVariant: rowVariant, isHiddenLast: isHiddenLast, hasItemCheckbox: hasItemCheckbox, checked: checkedRows === null || checkedRows === void 0 ? void 0 : checkedRows.includes(index), onChange: () => onRowChecked && onRowChecked(index) }, index.toString()), _jsxs(Styles.WrapperButton, { children: [_jsx(Styles.Button, { text: "", icon: icon, onClick: () => handleButton && handleButton(index, row, buttonAction) }), selectedElement !== null && selectedElement === index && (_jsx(Styles.WrapperTableMenu, Object.assign({ ref: menuRef }, { children: handleDropdownMenu &&
                                        handleDropdownMenu(selectedId, row, tableRef) })))] })] }) }, index));
        }) }));
};
export const TableBodyParsingResult = ({ data, icon, rowVariant, checkedRows, onRowChecked, isShortParser, }) => {
    return (_jsx(_Fragment, { children: _jsx(Styles.WrapperTableItemParsingResult, { children: data.map((row, index) => {
                return (_jsx(_Fragment, { children: _jsx(TableItemParserResult, { data: row, icon: icon, rowVariant: rowVariant, isShortParser: isShortParser, checked: checkedRows === null || checkedRows === void 0 ? void 0 : checkedRows.includes(index), onChange: () => onRowChecked && onRowChecked(index) }, index.toString()) }));
            }) }) }));
};
export const Table = ({ data, icon, allId, isTertiary, dataHeader, checkedRows, onRowChecked, isScrollable, buttonAction, handleButton, isHiddenLast, selectedElement, onAllRowsChecked, handleDropdownMenu, hasItemCheckbox = false, hasHeaderCheckbox = false, rowVariant = TableRowVariant.DEFAULT, }) => {
    const tableRef = useRef(null);
    return (_jsx(Styles.Table, Object.assign({ ref: tableRef }, { children: _jsxs(Styles.Container, Object.assign({ "$isScrollable": isScrollable }, { children: [_jsx(TableHeader, { data: dataHeader, isTertiary: isTertiary, hasHeaderCheckbox: hasHeaderCheckbox, onAllRowsChecked: onAllRowsChecked, isChecked: (checkedRows === null || checkedRows === void 0 ? void 0 : checkedRows.length) === data.length && data.length !== 0 }), _jsx(TableBody, { data: data, tableRef: tableRef, icon: icon, allId: allId, rowVariant: rowVariant, handleButton: handleButton, checkedRows: checkedRows, isHiddenLast: isHiddenLast, onRowChecked: onRowChecked, buttonAction: buttonAction, selectedElement: selectedElement, hasItemCheckbox: hasItemCheckbox, handleDropdownMenu: handleDropdownMenu }), !data.length && (_jsx(Styles.EmptyTableRow, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: "\u0412 \u0442\u0430\u0431\u043B\u0438\u0446\u0435 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445" })) }))] })) })));
};
