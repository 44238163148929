import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-ud2r9j" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  height: 250px;
  width: 300px;
  border-radius: 20px;
  border: ${({ theme }) => theme.config.radio.border.default};
  background: ${({ theme }) => theme.config.radio.background};
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 1900px) {
    width: 360px;
    height: 470px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.config.radio.checked.border.default};

    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
export const SectionWrapper = styled.div.withConfig({ displayName: "SectionWrapper", componentId: "sc-bh2rki" }) `
  display: flex;
  flex-direction: row-reverse;
  align-content: space-between;
  gap: 5px;
  margin-left: auto;
  width: 90%;
`;
export const Tariffs = styled.div.withConfig({ displayName: "Tariffs", componentId: "sc-o44r3e" }) `
  display: flex;
  flex-direction: row;
  gap: 30px;
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ieg0o3" }) `
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  background-color: ${({ theme }) => theme.config.background.tetriery};
  border: 1px solid transparent;
  border-color: ${({ $isSelected, theme }) => {
    return $isSelected && `${theme.colors.green.tetriery}`;
}};
  gap: 16px;
`;
export const FieldsRow = styled.div.withConfig({ displayName: "FieldsRow", componentId: "sc-4wy9a2" }) `
  display: flex;
  flex-direction: row;

  gap: 16px;
`;
export const Separator = styled.div.withConfig({ displayName: "Separator", componentId: "sc-1sqt0n2" }) `
  width: 100%;
  height: 1px;
  border-bottom: ${({ theme }) => theme.config.switch.border.default};
`;
export const Section = styled.div.withConfig({ displayName: "Section", componentId: "sc-1ff3yvn" }) `
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 24px 0px;
  border-top: ${({ theme }) => theme.config.switch.border.default};
  border-bottom: ${({ theme }) => theme.config.switch.border.default};
`;
export const Price = styled.div.withConfig({ displayName: "Price", componentId: "sc-pxew4n" }) `
  width: 20px;
  margin-left: 10px;
`;
export const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "sc-4ntr7n" }) `
  justify-self: flex-end;
`;
