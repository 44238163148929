import styled, { css } from 'styled-components';
import * as Styles from 'styles';
import { TextVariant } from './types';
const { smallLight, bodyRegular, smallMedium, bodySemibold, smallReqular, smallSemiBold, heading1Medium, heading2Medium, heading3Medium, ultraSmallMedium, } = Styles.Font;
export const StyledText = styled.div.withConfig({ displayName: "StyledText", componentId: "sc-1oom25u" }) `
  ${({ $variant }) => {
    switch ($variant) {
        case TextVariant.h1Medium:
            return css `
          ${heading1Medium}
        `;
        case TextVariant.h2Medium:
            return css `
          ${heading2Medium}
        `;
        case TextVariant.h3Medium:
            return css `
          ${heading3Medium}
        `;
        case TextVariant.bodyRegular:
            return css `
          ${bodyRegular}
        `;
        case TextVariant.bodySemiBold:
            return css `
          ${bodySemibold}
        `;
        case TextVariant.smallLight:
            return css `
          ${smallLight}
        `;
        case TextVariant.smallMedium:
            return css `
          ${smallMedium}
        `;
        case TextVariant.smallReqular:
            return css `
          ${smallReqular}
        `;
        case TextVariant.smallSemiBold:
            return css `
          ${smallSemiBold}
        `;
        case TextVariant.ultraSmallMedium:
            return css `
          ${ultraSmallMedium}
        `;
        default:
            return css `
          ${bodySemibold}
        `;
    }
}};

  ${({ $colorVariant, theme }) => {
    switch ($colorVariant) {
        case 'primary':
            return css `
          color: ${theme.config.text.primary};
        `;
        case 'secondary':
            return css `
          color: ${theme.config.text.secondary};
        `;
        case 'tetriery':
            return css `
          color: ${theme.config.text.tetriery};
        `;
        case 'stableBlack':
            return css `
          color: ${theme.config.text.stableBlack};
        `;
        default:
            return css `
          color: ${theme.config.text.primary};
        `;
    }
}};
`;
