import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useStableRef } from '../hooks/useBackdropClick';
export const handleMutation = ({ data, error, status, onError, onSuccess, }) => {
    const stableOnError = useStableRef(onError);
    const stableOnSuccess = useStableRef(onSuccess);
    useEffect(() => {
        var _a;
        if (error) {
            (_a = stableOnError.current) === null || _a === void 0 ? void 0 : _a.call(stableOnError, error);
        }
    }, [error]);
    useEffect(() => {
        var _a;
        if (status === QueryStatus.fulfilled) {
            (_a = stableOnSuccess.current) === null || _a === void 0 ? void 0 : _a.call(stableOnSuccess, data);
        }
    }, [status, data]);
};
