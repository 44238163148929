import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, Dialog } from 'common';
import { Formik, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { zIndex } from '../../../constants/enums';
import { AudioClipModal } from '../../../pages/dashboard/content/voice-robot/audio-clips/modal';
import { mailingModalInitialValues, mailingModalValidationSchema, model, } from '../../../pages/dashboard/content/voice-robot/mailing/config';
import { useCallCreateMutation, useClipsListQuery, } from '../../../store/api/audio-clips';
import { useMeQuery } from '../../../store/api/profile';
import { handleMutation } from '../../../utils/handleMutation';
import { FormsAudioCreateMailing } from './forms/audio-clip';
import { FormsNumberCreateMailing } from './forms/number';
import * as Styles from './styles';
const MailingModalContent = ({ trigger, responseCreateCall, }) => {
    const { t } = useTranslation();
    const { data: me } = useMeQuery();
    const alert = useAlert();
    const { dirty, isSubmitting, submitForm, isValid, resetForm } = useFormikContext();
    const [isAudioClipModalOpen, setIsAudioClipModalOpen] = useState(false);
    const { refetch } = useClipsListQuery();
    const getAudioList = () => {
        if (me) {
            refetch();
        }
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    const onSuccess = useCallback(() => {
        resetForm();
        alert.success('Рассылка успешно создана');
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseCreateCall), { onSuccess: () => {
            onSuccess();
            trigger();
        }, onError }));
    return (_jsxs(_Fragment, { children: [_jsx(Styles.MailingNameInput, Object.assign({}, model.call_name)), _jsxs(Styles.Grid, { children: [_jsx(Styles.WrapperNumber, { children: _jsx(FormsNumberCreateMailing, {}) }), _jsx(Styles.WrapperAudioClip, { children: _jsx(FormsAudioCreateMailing, { isAudioClipModalOpen: isAudioClipModalOpen, setIsAudioClipModalOpen: setIsAudioClipModalOpen }) }), _jsx(Styles.WrapperFooter, { children: _jsx(Styles.CreateButtonContainer, { children: _jsx(Button, { text: t('Create a newsletter'), onClick: submitForm, variant: ButtonVariant.PRIMARY, disabled: !dirty || !isValid || isSubmitting }) }) })] }), _jsx(AudioClipModal, { isDialogOpen: isAudioClipModalOpen, setIsDialogOpen: setIsAudioClipModalOpen, trigger: getAudioList, zIndex: zIndex.Extra })] }));
};
export const MailingModal = ({ isModalOpen, setModalOpen, trigger, }) => {
    const { data: me } = useMeQuery();
    const [requestCreateCall, responseCreateCall] = useCallCreateMutation();
    const closeModal = () => {
        setModalOpen(false);
    };
    const onSubmit = (values) => {
        const allPhones = new Set(values.manualPhones);
        if (values.phoneNumbers.length) {
            allPhones.add(values.dbPhones);
        }
        if (me && values.audioClipId && values.call_name.length) {
            requestCreateCall({
                phones: [...allPhones],
                user_id: me._id.toString(),
                audioclip_id: values.audioClipId.toString(),
                call_name: values.call_name,
            });
            closeModal();
        }
    };
    return (_jsx(Dialog, Object.assign({ onClose: () => closeModal(), title: 'Create Messaging', isOpen: isModalOpen }, { children: _jsx(Formik, Object.assign({ onSubmit: onSubmit, validationSchema: mailingModalValidationSchema, initialValues: mailingModalInitialValues }, { children: _jsx(MailingModalContent, { trigger: trigger, responseCreateCall: responseCreateCall }) })) })));
};
