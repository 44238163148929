import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ButtonVariant } from '../../button/types';
import IconWrapper from '../../icon-wrapper/icon-wrapper';
import { Input } from '../../input';
import * as Styles from './styles';
export const SearchInput = ({ search, searchFilter, visibleByDefault, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [searchInputVisible, setSearchInputVisible] = useState(visibleByDefault || false);
    const handleSearchButtonClick = () => {
        setSearchInputVisible(!searchInputVisible);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Styles.SearchInputContainer, Object.assign({ "$isVisible": searchInputVisible }, { children: _jsx(Input, { type: "text", value: search, onChange: searchFilter, placeholder: t('Search') }) })), _jsx(Styles.ButtonSearch, { text: "", padding: "8px", variant: ButtonVariant.SECONDARY, onClick: handleSearchButtonClick, icon: _jsx(IconWrapper, Object.assign({ "$fillColor": theme.config.button.secondary.text }, { children: _jsx(Icon.Search, {}) })) })] }));
};
