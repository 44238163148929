import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { Left, Right } from '../../../../../../common/icon/icons';
import * as Styles from './styles';
export const AdvWebToggle = ({ variant, setVariant, }) => {
    return (_jsxs(Styles.WebToggle, { children: [_jsx(Button, { onClick: () => {
                    if (variant === 1) {
                        setVariant(3);
                    }
                    else {
                        setVariant(variant - 1);
                    }
                }, text: "", variant: 'TETRIERY', padding: '10px', icon: _jsx(IconWrapper, Object.assign({ "$strokeColor": 'white', "$fillColor": 'white' }, { children: _jsx(Left, {}) })) }), _jsx(Styles.WebToggleContent, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: ["\u0412\u0430\u0440\u0438\u0430\u043D\u0442 ", variant, " \u0438\u0437 3"] })) }), _jsx(Button, { onClick: () => {
                    if (variant === 3) {
                        setVariant(1);
                    }
                    else {
                        setVariant(variant + 1);
                    }
                }, text: "", variant: 'TETRIERY', padding: '10px', icon: _jsx(IconWrapper, Object.assign({ "$strokeColor": 'white', "$fillColor": 'white' }, { children: _jsx(Right, {}) })) })] }));
};
