import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1cek7jr" }) ``;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1a6oigb" }) `
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 142px;
  grid-template-areas: 'message setting';

  @media (max-width: 1500px) {
    gap: 40px;
  }

  @media (max-width: 1124px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'message'
      'setting';
  }
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-e73lq4" }) `
  margin-bottom: 16px;
`;
export const WrapperMessage = styled.div.withConfig({ displayName: "WrapperMessage", componentId: "sc-1rfxr2o" }) `
  grid-area: message;
  height: 100%;
  flex: 1;
`;
export const WrapperSettings = styled.div.withConfig({ displayName: "WrapperSettings", componentId: "sc-1yri1gm" }) `
  grid-area: setting;
  height: 100%;
  flex: 1;
`;
export const ContentMessage = styled.div.withConfig({ displayName: "ContentMessage", componentId: "sc-1fm6ebv" }) ``;
export const WrapperSwitch = styled.div.withConfig({ displayName: "WrapperSwitch", componentId: "sc-j9hbcf" }) ``;
export const FieldsRow = styled.div.withConfig({ displayName: "FieldsRow", componentId: "sc-n5eyvi" }) `
  display: flex;
  flex-direction: row;

  gap: 16px;
`;
export const WrapperEmptyMessage = styled.div.withConfig({ displayName: "WrapperEmptyMessage", componentId: "sc-a1nbvy" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  border-radius: 15px;

  background: ${({ theme }) => theme.config.background.tetriery};
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-1ejlk6o" }) `
  display: flex;
  margin-top: 200px;
  padding-top: 24px;
  border-top: ${({ theme }) => theme.config.switch.border.default};

  @media screen and (max-width: 1124px) {
    justify-content: flex-end;
    margin-top: 50px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 50px;
  }
`;
export const ContentButton = styled.div.withConfig({ displayName: "ContentButton", componentId: "sc-gl8rai" }) `
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 251px;
`;
