import { SECRET_KEY } from 'api/constants';
import CryptoJS from 'crypto-js';
const themeKey = 'theme';
const tokenKey = 'token';
export const clearToken = () => localStorage.removeItem(tokenKey);
export const clearAll = () => {
    clearToken();
};
export const saveToken = (token) => {
    const encrypted = CryptoJS.AES.encrypt(token, SECRET_KEY);
    localStorage.setItem(tokenKey, encrypted.toString());
};
export const getToken = () => {
    const token = localStorage.getItem(tokenKey);
    if (!token)
        return undefined;
    try {
        return CryptoJS.AES.decrypt(token, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    }
    catch (err) {
        clearAll();
        return undefined;
    }
};
export const saveDarkTheme = (isDarkTheme) => {
    if (typeof isDarkTheme === 'boolean') {
        localStorage.setItem(themeKey, String(isDarkTheme));
    }
    else {
        localStorage.removeItem(themeKey);
    }
};
export const getIsDarkTheme = () => {
    const isDarkTheme = localStorage.getItem(themeKey);
    if (isDarkTheme === null)
        return undefined;
    return isDarkTheme === 'true';
};
