import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, SelectTagsField } from 'common';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackdropClick } from '../../../../../hooks/useBackdropClick';
import { initialValues } from './config';
import * as Styles from './styles';
export const Filter = ({ top, left, onApplyFilters, filterFieldsModel, }) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [filterLeft, setFilterLeft] = useState(undefined);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const onSubmit = (values) => {
        onApplyFilters(values);
        setIsOpen(false);
    };
    useBackdropClick(menuRef, toggleDropdown, isOpen);
    useEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const rightSpace = window.innerWidth - rect.right;
            if (rightSpace) {
                setFilterLeft(-200);
            }
            else {
                setFilterLeft(undefined);
            }
        }
    }, []);
    return (_jsxs(Styles.Container, Object.assign({ ref: dropdownRef }, { children: [_jsx(Styles.Button, Object.assign({ onClick: toggleDropdown }, { children: _jsx(Styles.ButtonFilters, { padding: "24px", text: t('Filters'), onClick: toggleDropdown, variant: ButtonVariant.LIGHT }) })), _jsx(Styles.Menu, Object.assign({ "$isOpen": isOpen, "$top": top, "$left": left !== null && left !== void 0 ? left : filterLeft, ref: menuRef }, { children: _jsx(Formik, Object.assign({ onSubmit: onSubmit, initialValues: initialValues }, { children: ({ submitForm, setValues }) => (_jsxs(_Fragment, { children: [_jsx(Styles.Wrapper, { children: filterFieldsModel === null || filterFieldsModel === void 0 ? void 0 : filterFieldsModel.map((field, index) => (_jsx(Styles.FieldColumn, { children: _jsx(SelectTagsField, Object.assign({}, field)) }, index))) }), _jsxs(Styles.ContainerButton, { children: [_jsx(Button, { text: t('Reset Filters'), onClick: () => {
                                            setValues([]);
                                            submitForm();
                                        }, variant: ButtonVariant.TETRIERY }), _jsx(Button, { padding: "24px", text: t('Apply'), onClick: submitForm })] })] })) })) }))] })));
};
