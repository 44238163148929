var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField } from 'common';
import { Icon } from 'common/icon';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconWrapper from '../../../../../../../../common/icon-wrapper/icon-wrapper';
import { model } from '../../config';
import { BASE_AI_URL } from '../../../../../../../../api/constants';
const WrapperVoice = styled.div.withConfig({ displayName: "WrapperVoice", componentId: "sc-1ek5o7a" }) `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
`;
//
// const Voice = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 16px;
// `;
//
// const Avatar = styled(Image)`
//   width: 40px;
//   height: 40px;
//   border-radius: 50px;
// `;
//
// const WrapperImage = styled.div<{ $isSelected: boolean }>`
//   border: none;
//   width: 40px;
//   height: 40px;
//   border-radius: 50px;
//
//   ${({ theme, $isSelected }) =>
//     $isSelected &&
//     css`
//       outline-offset: 3px;
//       outline: 2px solid ${theme.config.line.primary};
//     `}
//   cursor: pointer;
// `;
const HiddenAudio = styled.audio.withConfig({ displayName: "HiddenAudio", componentId: "sc-bwj77l" }) `
  display: none;
`;
export const AudioPreview = ({ text, language, loading, setLoading, audioRef, }) => {
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    // const [selectedImageId, setSelectedImageId] = useState<number | null>(null);
    // const handleImageClick = (id: number) => {
    //   if (id === selectedImageId) {
    //     setSelectedImageId(null);
    //   } else {
    //     setSelectedImageId(id);
    //   }
    // };
    const [lastUrl, setLastUrl] = useState('');
    const playAudio = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!text || !language) {
            return;
        }
        const url = `${BASE_AI_URL}/audio_create?text=${text}&language=${language}`;
        if (lastUrl !== url) {
            setLastUrl(url);
            setLoading(true);
            try {
                const response = (yield fetch(url));
                const file = yield response.blob();
                setFieldValue('generatedFile', file);
                audioRef.current.src = URL.createObjectURL(file);
            }
            catch (_) {
            }
            finally {
                setLoading(false);
            }
            audioRef.current.currentTime = 0.1;
            audioRef.current.play();
        }
        if ((_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.src) {
            audioRef.current.play();
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(InputField, Object.assign({ disabled: loading }, model.message, { name: "message" })), _jsxs(WrapperVoice, { children: [_jsx(HiddenAudio, { ref: audioRef }), _jsx(Button, { disabled: loading, text: t('Listen'), onClick: () => {
                            playAudio();
                        }, variant: ButtonVariant.TETRIERY, icon: _jsx(IconWrapper, { children: _jsx(Icon.Play, {}) }) })] })] }));
};
