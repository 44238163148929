import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loader, Text, TextVariant } from 'common';
import { HeaderTabsInfo } from 'components';
import { AppRoutes } from 'config';
import { UserRole } from 'constants/enums';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { useMeQuery } from 'store/api/profile';
import { TabIndividualItems, TabLegalItems } from './config';
import { NotificationForm } from './forms/notification';
import { HeaderProfile } from './forms/notification/header';
import { PersonalDataForm } from './forms/personal-data';
// import { PaymentPage } from '../payment';
import { StaffForm } from './forms/staff';
import { TariffForm } from './forms/tariff';
import * as Styles from './styles';
export const ProfilePage = () => {
    const { t } = useTranslation();
    const { data, isLoading, error } = useMeQuery();
    const { Profile } = AppRoutes.Dashboard;
    const headerValue = (data === null || data === void 0 ? void 0 : data.role) === UserRole.Legal ? TabLegalItems : TabIndividualItems;
    if (isLoading) {
        return (_jsx(Styles.Container, { children: _jsx(Loader, {}) }));
    }
    if (error) {
        return (_jsx(Styles.Container, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Error occurred while fetching profile data.') })) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderProfile, {}), _jsxs(Styles.WrapperProfile, { children: [_jsx(HeaderTabsInfo, { itemsTab: headerValue }), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: Profile.PersonalData, element: _jsx(PersonalDataForm, {}) }), _jsx(Route, { path: Profile.Tariff, element: _jsx(TariffForm, {}) }), _jsx(Route, { path: Profile.Staff, element: _jsx(StaffForm, {}) }), _jsx(Route, { path: Profile.Notifications, element: _jsx(NotificationForm, {}) })] })] })] }));
};
export default ProfilePage;
