var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useField } from 'formik';
import { Switch } from 'common/switch';
import { useTranslation } from 'react-i18next';
import { controlError } from 'utils/controlError';
export const SwitchField = (_a) => {
    var _b;
    var { name, label, placeholder } = _a, props = __rest(_a, ["name", "label", "placeholder"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = t(controlError(meta, name, t(placeholder !== null && placeholder !== void 0 ? placeholder : '')));
    return (_jsx(Switch, Object.assign({}, props, { error: error, checked: field.value, onBlur: () => helpers.setTouched(true), label: label ? (_b = t(label !== null && label !== void 0 ? label : '')) !== null && _b !== void 0 ? _b : '' : undefined, onChange: (isChecked) => helpers.setValue(isChecked) })));
};
