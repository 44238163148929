import * as Yup from 'yup';
import { InputColorType } from '../../../../../common/input/types';
export var EShowSchedule;
(function (EShowSchedule) {
    EShowSchedule["EVERY_DAY"] = "EVERY_DAY";
    EShowSchedule["ON_WEEKDAYS"] = "ON_WEEKDAYS";
    EShowSchedule["MANUAL"] = "MANUAL";
})(EShowSchedule || (EShowSchedule = {}));
export var ETimezoneCategory;
(function (ETimezoneCategory) {
    ETimezoneCategory["SNG"] = "SNG";
    ETimezoneCategory["RUSSIA"] = "RUSSIA";
    ETimezoneCategory["WORLD"] = "WORLD";
})(ETimezoneCategory || (ETimezoneCategory = {}));
export const SNGTimezones = [
    'Абхазия (MSK +00:00, GMT +03:00)',
    'Азербайджан (MSK +01:00, GMT +04:00)',
    'Армения (MSK +01:00, GMT +04:00)',
    'Беларусь (MSK +00:00, GMT +03:00)',
    'Казахстан, Актобе (MSK +02:00, GMT +05:00)',
    'Казахстан, Алматы (MSK +03:00, GMT +06:00)',
    'Киргизия (MSK +03:00, GMT +06:00)',
    'Молдова (MSK -01:00, GMT +02:00)',
    'Таджикистан (MSK +02:00, GMT +05:00)',
    'Туркменистан (MSK +02:00, GMT +05:00)',
    'Узбекистан (MSK +02:00, GMT +05:00)',
    'Украина (MSK -01:00, GMT +02:00)',
    'Южная Осетия (MSK +00:00, GMT +03:00)',
];
export const RussiaTimezones = [
    'Калининград (MSK -01:00)',
    'Москва',
    'Самара (MSK +01:00)',
    'Ивановка (MSK +01:00)',
    'Ульяновск (MSK +01:00)',
    'Екатеринбург (MSK +02:00)',
    'Омск (MSK +03:00)',
    'Красноярск (MSK +04:00)',
    'Новокузнецк (MSK +04:00)',
    'Томск (MSK +04:00)',
    'Барнаул (MSK +04:00)',
    'Иркутск (MSK +05:00)',
    'Якутск (MSK +06:00)',
    'Чита (MSK +06:00)',
    'Владивосток (MSK +07:00)',
    'Среднеколымск (MSK +08:00)',
    'Петропавловск (MSK +09:00)',
];
export const WorldTimezones = [
    'Австралия, Брисбен (GMT +10:00)',
    'Австралия, Остров Рождества (GMT +07:00)',
    'Австралия, Перт (GMT +08:00)',
    'Австралия, Сидней (GMT +11:00)',
    'Австрия (GMT +01:00)',
    'Албания (GMT +01:00)',
    'Алжир (GMT +01:00)',
    'Американские Виргинские острова (GMT -04:00)',
    'Ангилья (GMT -04:00)',
    'Ангола (GMT +01:00)',
];
export var EHolidaysSchedule;
(function (EHolidaysSchedule) {
    EHolidaysSchedule["DONT_SHOW"] = "DONT_SHOW";
    EHolidaysSchedule["RELEVANT_DAY"] = "RELEVANT_DAY";
    EHolidaysSchedule["MANUAL"] = "MANUAL";
})(EHolidaysSchedule || (EHolidaysSchedule = {}));
export var EAudience;
(function (EAudience) {
    EAudience["OPTIMAL"] = "OPTIMAL";
    EAudience["MANUAL"] = "MANUAL";
})(EAudience || (EAudience = {}));
var EUsersGender;
(function (EUsersGender) {
    EUsersGender["MALE"] = "MALE";
    EUsersGender["FEMALE"] = "FEMALE";
    EUsersGender["ANY"] = "ANY";
})(EUsersGender || (EUsersGender = {}));
export var EUserDevices;
(function (EUserDevices) {
    EUserDevices["MOBILE"] = "MOBILE";
    EUserDevices["TABLET"] = "TABLET";
    EUserDevices["DESKTOP"] = "DESKTOP";
})(EUserDevices || (EUserDevices = {}));
export var EAdvTarget;
(function (EAdvTarget) {
    EAdvTarget["ACTIONS"] = "ACTIONS";
    EAdvTarget["TRANSITIONS"] = "TRANSITIONS";
})(EAdvTarget || (EAdvTarget = {}));
var EActionPrice;
(function (EActionPrice) {
    EActionPrice["AVERAGE"] = "AVERAGE";
    EActionPrice["FIXED"] = "FIXED";
    EActionPrice["UNLIMITED"] = "UNLIMITED";
})(EActionPrice || (EActionPrice = {}));
export var ETransitionPriceType;
(function (ETransitionPriceType) {
    ETransitionPriceType["AVERAGE"] = "AVERAGE";
    ETransitionPriceType["UNLIMITED"] = "UNLIMITED";
})(ETransitionPriceType || (ETransitionPriceType = {}));
export var EWeeklyBudgetType;
(function (EWeeklyBudgetType) {
    EWeeklyBudgetType["CHOOSE"] = "CHOOSE";
    EWeeklyBudgetType["MANUAL"] = "MANUAL";
})(EWeeklyBudgetType || (EWeeklyBudgetType = {}));
export var EBudgetItemType;
(function (EBudgetItemType) {
    EBudgetItemType["MIN"] = "MIN";
    EBudgetItemType["MID"] = "MID";
    EBudgetItemType["MAX"] = "MAX";
})(EBudgetItemType || (EBudgetItemType = {}));
export const intervalDaysItems = [
    {
        long: 'Понедельник',
        short: 'Пн',
        order: 1,
    },
    {
        long: 'Вторник',
        short: 'Вт',
        order: 2,
    },
    {
        long: 'Среда',
        short: 'Ср',
        order: 3,
    },
    {
        long: 'Четверг',
        short: 'Чт',
        order: 4,
    },
    {
        long: 'Пятница',
        short: 'Пт',
        order: 5,
    },
    {
        long: 'Суббота',
        short: 'Сб',
        order: 6,
    },
    {
        long: 'Воскресенье',
        short: 'Вс',
        order: 7,
    },
];
export const showScheduleItems = [
    {
        value: EShowSchedule.ON_WEEKDAYS,
        label: 'По будням с 8:00 до 20:00',
    },
    {
        value: EShowSchedule.MANUAL,
        label: 'Настроить интервалы вручную',
    },
    {
        value: EShowSchedule.EVERY_DAY,
        label: 'Каждый день, круглосуточно',
    },
];
export const timezoneCategoryItems = [
    {
        value: ETimezoneCategory.SNG,
        label: 'СНГ',
    },
    {
        value: ETimezoneCategory.RUSSIA,
        label: 'Россия',
    },
    {
        value: ETimezoneCategory.WORLD,
        label: 'Страны мира',
    },
];
export const holidaysScheduleItems = [
    {
        value: EHolidaysSchedule.DONT_SHOW,
        label: 'Не показывать',
    },
    {
        value: EHolidaysSchedule.RELEVANT_DAY,
        label: 'По расписанию соответсвующего дня недели',
    },
    {
        value: EHolidaysSchedule.MANUAL,
        label: 'Настроить интервал вручную',
    },
];
export const audienceItems = [
    {
        value: EAudience.MANUAL,
        label: 'Настроить вручную',
        description: '',
    },
    {
        value: EAudience.OPTIMAL,
        label: 'Подобрать оптимальную',
        description: '',
    },
];
export const usersGenderItems = [
    {
        value: EUsersGender.ANY,
        label: 'Любой пол',
    },
    {
        value: EUsersGender.FEMALE,
        label: 'Женщины',
    },
    {
        value: EUsersGender.MALE,
        label: 'Мужчины',
    },
];
export const minUsersAgeItems = [0, 18, 25, 35, 45, 55];
export const maxUsersAgeItems = [18, 25, 35, 45, 55, 99];
export const userDevicesItems = [
    {
        value: EUserDevices.DESKTOP,
        label: 'Компьютеры и ноутбуки',
    },
    {
        value: EUserDevices.MOBILE,
        label: 'Смартфоны',
    },
    {
        value: EUserDevices.TABLET,
        label: 'Планшеты',
    },
];
export const advTargetItems = [
    {
        value: EAdvTarget.ACTIONS,
        label: 'Максимум целевых действий',
    },
    {
        value: EAdvTarget.TRANSITIONS,
        label: 'Максимум переходов',
    },
];
export const actionPriceItems = [
    {
        value: EActionPrice.UNLIMITED,
        label: 'Без ограничений',
    },
    {
        value: EActionPrice.FIXED,
        label: 'Фиксированная',
    },
    {
        value: EActionPrice.AVERAGE,
        label: 'Средняя за неделю',
    },
];
export const transitionPriceItems = [
    {
        value: ETransitionPriceType.UNLIMITED,
        label: 'Без ограничений',
    },
    {
        value: ETransitionPriceType.AVERAGE,
        label: 'Средняя за неделю',
    },
];
export const weeklyBudgetTypeItems = [
    {
        value: EWeeklyBudgetType.CHOOSE,
        label: 'Выбрать существующий план',
    },
    {
        value: EWeeklyBudgetType.MANUAL,
        label: 'Выбрать вручную',
    },
];
export const adFormModel = {
    title: {
        type: 'text',
        name: 'title',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Например, доставка цветов',
    },
    header: {
        type: 'text',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Напишите заголовок',
    },
    text: {
        type: 'text',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Напишите текст',
    },
    linkLink: {
        type: 'text',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Встаьте сюда ссылку',
    },
    linkDescription: {
        type: 'text',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Описание ссылки',
    },
    linkTitle: {
        type: 'text',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Название ссылки ',
    },
    dropdown: {
        isArrow: true,
        top: 0,
        left: 0,
        hide: true,
        paddingRight: 16,
    },
    manualHolidayTime: {
        name: 'manualHolidayTime',
        type: 'text',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        placeholder: 'Напишите заголовок',
    },
    transitionPrice: {
        type: 'number',
        inputType: 'text',
        colorType: InputColorType.LIGHT,
        name: 'transitionPrice',
        width: '100px',
    },
};
export const addFormInitialValues = {
    mainUrl: '',
    campaignName: '',
    title: '',
    headers: [
        {
            header: '',
        },
    ],
    texts: [
        {
            text: '',
        },
    ],
    images: [],
    videos: [],
    links: [
        {
            link: '',
            description: '',
            title: '',
            isOpen: true,
        },
    ],
    regions: [],
    showSchedule: EShowSchedule.EVERY_DAY,
    displayIntervals: [
        {
            days: [],
            from: '00:00',
            to: '24:00',
        },
    ],
    timezoneCategory: ETimezoneCategory.RUSSIA,
    timezone: '',
    holidaysSchedule: EHolidaysSchedule.RELEVANT_DAY,
    manualHolidaySchedule: {
        from: '12:00',
        to: '24:00',
    },
    audience: EAudience.OPTIMAL,
    usersGender: EUsersGender.ANY,
    usersAge: {
        maxAge: 99,
        minAge: 0,
    },
    selectedUsersDevices: [
        {
            device: EUserDevices.DESKTOP,
            selected: true,
        },
        {
            device: EUserDevices.TABLET,
            selected: true,
        },
        {
            device: EUserDevices.MOBILE,
            selected: true,
        },
    ],
    advTarget: EAdvTarget.ACTIONS,
    actionPrice: EActionPrice.AVERAGE,
    transitionPriceType: ETransitionPriceType.AVERAGE,
    transitionPrice: '',
    weeklyBudgetType: EWeeklyBudgetType.CHOOSE,
    chosenBudgetType: EBudgetItemType.MID,
    manualBudget: '',
};
export const addFormValidationSchema = Yup.object().shape({
    // [adFormModel.headers[0].header.name]: Yup.string().required(
    //   'Нужно написать заголовок'
    // ),
    //
    title: Yup.string().required('Введите категорию'),
    headers: Yup.array()
        .of(Yup.object().shape({
        header: Yup.string()
            .required('Header is required')
            .min(3, 'Header must be at least 3 characters'),
    }))
        .test('unique', 'Headers must be unique', function (headers = []) {
        const uniqueHeaders = new Set();
        let hasDuplicates = false;
        let lastDuplicateIndex = 0;
        headers.forEach((item, index) => {
            if (item.header) {
                if (uniqueHeaders.has(item.header)) {
                    hasDuplicates = true;
                    lastDuplicateIndex = index;
                }
                else {
                    uniqueHeaders.add(item.header);
                }
            }
        });
        return (!hasDuplicates ||
            this.createError({ path: `headers[${lastDuplicateIndex}].header` }));
    }),
    // test('unique', 'Only unique values allowed.', (value) =>
    //   value ? value.length === new Set(value.map((a) => a.header))?.size : true
    // ),
    texts: Yup.array().of(Yup.object().shape({
        text: Yup.string()
            .required('Text is required')
            .min(3, 'Text must be at least 3 characters'),
    })),
    transitionPrice: Yup.string().required(),
    manualBudget: Yup.string().required(),
    campaignName: Yup.string().required(),
    mainUrl: Yup.string().required(),
});
