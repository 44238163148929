import { InputSize } from 'common/input/types';
export const model = {
    firstName: {
        label: 'Employee Name',
        type: 'text',
        name: 'firstName',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Employee Name',
    },
    employeePosition: {
        label: 'Employee position',
        type: 'text',
        name: 'employeePosition',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Employee position',
    },
    email: {
        label: 'Employee Email address',
        type: 'email',
        withBorder: false,
        size: InputSize.SMALL,
        name: 'email',
        placeholder: 'Employee Email address',
    },
    company: {
        name: 'сompany',
        label: 'Company',
        type: 'text',
        placeholder: 'Select an access company',
        options: ['Value1', 'Value2', 'Value3', 'Value4'].map((label) => ({
            label,
            value: label,
        })),
    },
};
export const initialValues = {
    [model.email.name]: '',
    [model.firstName.name]: '',
    [model.employeePosition.name]: '',
};
