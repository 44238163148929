import { Icon } from 'common/icon';
import { zIndex } from 'constants/enums';
import styled, { css, keyframes } from 'styled-components';
import { smallMedium } from 'styles/mixins/fonts';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const DropdownContainer = styled.div.withConfig({ displayName: "DropdownContainer", componentId: "sc-85bmsx" }) `
  position: relative;
  display: inline-block;
  width: 100%;
  background: transparent;
  z-index: ${({ $zindex, $dropdownOpen }) => ($zindex !== null && $zindex !== void 0 ? $zindex : $dropdownOpen) ? zIndex.Extra : zIndex.Dropdown};
  transition: z-index 1s;
`;
export const DropdownButton = styled.button.withConfig({ displayName: "DropdownButton", componentId: "sc-tytcyr" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  //padding: 0px 4px 0px 10px;
  border-radius: 24px;

  background: inherit;

  @media screen and (max-width: 1024px) {
    padding: 8px 4px 8px 10px;
  }
`;
export const DropdownMenu = styled.ul.withConfig({ displayName: "DropdownMenu", componentId: "sc-hnsfxd" }) `
  position: absolute;
  top: ${({ $open, $top }) => ($open && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};
  width: ${({ $fullWidth }) => $fullWidth && '100%'};
  padding: 0.25rem;
  margin-top: 8px;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
  background: ${({ theme }) => theme.config.toggle.bg.active};
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  animation: ${({ $open }) => ($open ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.3s ease-in-out;
  overflow-y: auto;
  max-height: ${({ $open }) => ($open ? 'calc(100vh - 2rem)' : '10px')};
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
`;
export const DropdownMenuItem = styled.li.withConfig({ displayName: "DropdownMenuItem", componentId: "sc-1oz92zn" }) `
  ${smallMedium};
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  position: relative;
  z-index: ${zIndex.Extra};
  opacity: 1;
  margin-bottom: 4px;
  border-radius: 7px;
  transition: opacity 0.4s ease-in-out, background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.config.dropdown.background.hover};
  }

  ${({ $paddingRight }) => css `
    padding: 8px ${$paddingRight !== null && $paddingRight !== void 0 ? $paddingRight : 42}px 8px 16px;
  `};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-weo312" }) `
  margin: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.config.dropdown.border.line};
`;
export const Arrow = styled(Icon.ArrowDown).withConfig({ displayName: "Arrow", componentId: "sc-tacxoe" }) `
  ${({ $isOpen }) => css `
    transform: rotate(${$isOpen ? '180deg' : '0deg'});
    transition: transform 0.3s ease-in-out;
  `}

  margin-left: 2px;
`;
