import styled, { css, keyframes } from 'styled-components';
import { scrollMedium } from '../../styles/mixins/scroll';
const scaleDown = keyframes `
  from {
    transform: scale(.75)
  }
  to {
    transform: scale(1)
  }
`;
const slideOutDown = keyframes `
  to {
    transform: scale(.75)
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-hv6460" }) `
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  padding-top: ${({ $topMargin }) => ($topMargin ? $topMargin : '5vh')};
  display: flex;
  position: fixed;
  overflow: hidden;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in, backdrop-filter 0.1s ease-in,
    visibility 0.4s ease-in;
  backdrop-filter: blur(10px);

  ${({ $zIndex }) => $zIndex &&
    css `
      z-index: ${$zIndex};
    `}
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1mfeove" }) `
  gap: 10px;
  width: ${({ width }) => (width && width.length ? width : '1000px')};
  max-height: 80vh;
  height: fit-content;
  margin-top: 0;
  padding: 20px;
  padding-right: 0;
  overflow: hidden;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.config.background.secondary};
  transition: width 0.2s cubic-bezier(0.25, 0, 0.3, 1), animation 0.4s ease-in;

  animation: ${({ $isVisible }) => $isVisible
    ? css `
          ${scaleDown} 0.4s cubic-bezier(0.25, 0, 0.3, 1) forwards;
        `
    : css `
          ${slideOutDown} 0.3s cubic-bezier(0.25, 0, 0.3, 1) forwards;
        `};

  @media screen and (max-width: 1440px) {
    width: 60%;
    transition: width 0.2s cubic-bezier(0.25, 0, 0.3, 1);
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    transition: right 0.2s cubic-bezier(0.25, 0, 0.3, 1);
  }
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-oe482" }) `
  /*max-height: 100%;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-15ccql2" }) `
  height: 50px;
`;
export const ScrollContainer = styled.div.withConfig({ displayName: "ScrollContainer", componentId: "sc-1ztr2o" }) `
  max-width: 100%;
  max-height: calc(75vh - 50px);
  ${scrollMedium};

  overflow-y: scroll;
  overflow-x: hidden;

  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
// export const Header = styled.div`
//   display: flex;
//   flex-direction: column;
//
//   gap: 16px;
//   margin: 24px 0px;
// `;
export const Close = styled.div.withConfig({ displayName: "Close", componentId: "sc-fcuun0" }) `
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 20px;
  width: 45px;
  height: 45px;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.modal.bg.default};
  cursor: pointer;
  transition: background 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.config.modal.bg.hover};
  }

  @media screen and (max-width: 1440px) {
    right: 15%;
    transition: right 0.2s cubic-bezier(0.25, 0, 0.3, 1);
  }

  @media screen and (max-width: 768px) {
    right: 2%;
    transition: right 0.2s cubic-bezier(0.25, 0, 0.3, 1);
  }
`;
