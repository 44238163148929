import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ResultPage } from '../result-page';
export const YandexResultPage = ({ data }) => {
    const { t } = useTranslation();
    return (_jsx(ResultPage, { filterField: "name", title: (data === null || data === void 0 ? void 0 : data.parser_type) || 'Yandex', dataHeader: [
            t('Name'),
            t('Address'),
            t('Categories'),
            t('Description'),
            t('Phones'),
        ], data: data === null || data === void 0 ? void 0 : data.parser_data, dataRows: (row) => [
            row.name,
            row.address,
            row.categories.map((category) => category.name).join(', '),
            row.description,
            row.phone,
        ] }));
};
