import styled from 'styled-components';
import { Text as TextComponent } from 'common/text';
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-cv7zb2" }) ``;
export const Text = styled(TextComponent).withConfig({ displayName: "Text", componentId: "sc-1cp28" }) `
  color: ${({ theme }) => theme.config.text.primary};
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-153w7iw" }) `
  gap: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
`;
export const ContentToggle = styled.div.withConfig({ displayName: "ContentToggle", componentId: "sc-1kso5mg" }) `
  gap: 24px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  min-height: 50px;
`;
export const WrapperPosts = styled.div.withConfig({ displayName: "WrapperPosts", componentId: "sc-1qzhuwl" }) `
  grid-area: posts;
`;
export const WrapperGroups = styled.div.withConfig({ displayName: "WrapperGroups", componentId: "sc-13ydukc" }) `
  grid-area: groups;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const InfoContainer = styled.div.withConfig({ displayName: "InfoContainer", componentId: "sc-1frxdbt" }) `
  flex: 1;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;
export const ContentContainer = styled.div.withConfig({ displayName: "ContentContainer", componentId: "sc-tu54vh" }) `
  flex: 1;
  gap: 24px;
  display: flex;
  flex-direction: row;
`;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-zk0vzc" }) `
  flex: 1;
  display: flex;
  margin-top: 24px;
`;
export const WrapperLoader = styled.div.withConfig({ displayName: "WrapperLoader", componentId: "sc-zioxci" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-2okqei" }) `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 24px 0px 0px;
`;
export const FooterButton = styled.div.withConfig({ displayName: "FooterButton", componentId: "sc-1du7tl9" }) `
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  padding-top: 24px;
  border-top: ${({ theme }) => theme.config.switch.border.default};
`;
