import * as Yup from 'yup';
export const generateImageInitialValues = {
    image: null,
    key: '',
    scale: '',
    octave: '',
};
export const generateImageValidationSchema = Yup.object().shape({
    key: Yup.string().required('Ключ обязателен'),
    scale: Yup.string().required('Лад обязателен'),
    octave: Yup.string().required('Октава обязательна'),
});
export const scale_intervals = [1, 2, 3];
export const scales = [
    'AEOLIAN',
    'BLUES',
    'PHYRIGIAN',
    'CHROMATIC',
    'DORIAN',
    'HARMONIC_MINOR',
    'LYDIAN',
    'MAJOR',
    'MELODIC_MINOR',
    'MINOR',
    'MIXOLYDIAN',
    'NATURAL_MINOR',
    'PENTATONIC',
];
export const keys = [
    'A',
    'a',
    'B',
    'C',
    'c',
    'D',
    'd',
    'E',
    'F',
    'f',
    'G',
    'g',
];
