import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { InputField, Text, TextVariant } from 'common';
import { Checkbox } from 'common/checkbox';
import { Icon } from 'common/icon';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { initialValues, model, validationSchema } from './config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1hdcdtj" }) `
  margin-top: 40px;
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1i4q76" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1diwx3d" }) ``;
const WrapperCards = styled.div.withConfig({ displayName: "WrapperCards", componentId: "sc-1w50fpr" }) `
  position: relative;
  margin-bottom: 24px;
`;
const LeftCard = styled.div.withConfig({ displayName: "LeftCard", componentId: "sc-10pdn2h" }) `
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};
  border: 1px solid ${({ theme }) => theme.config.line.secondary};
  width: 400px;
  padding: 24px 36px 24px 24px;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  z-index: 100;
`;
const CVVField = styled(InputField).withConfig({ displayName: "CVVField", componentId: "sc-nv28ke" }) `
  max-width: 102px;
  border: 1px solid red;
  width: 10px !important;
`;
const RightCard = styled.div.withConfig({ displayName: "RightCard", componentId: "sc-inmlw2" }) `
  width: 400px;
  height: 236px;
  position: absolute;
  top: 20px;
  left: 150px;
  z-index: 1;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.config.line.secondary};

  @media screen and (max-width: 1440px) {
    left: 110px;
  }
`;
const WrapperLeft = styled.div.withConfig({ displayName: "WrapperLeft", componentId: "sc-7syy4o" }) `
  display: flex;
  flex-direction: column;

  gap: 24px;
`;
const IconCard = styled.div.withConfig({ displayName: "IconCard", componentId: "sc-13p2ufd" }) `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const WrapperRight = styled.div.withConfig({ displayName: "WrapperRight", componentId: "sc-feb3ge" }) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const WrapperCVV = styled.div.withConfig({ displayName: "WrapperCVV", componentId: "sc-1503d3s" }) `
  margin: 34px 16px 0px 0px;
  padding-left: 290px;

  @media screen and (max-width: 1440px) {
    margin: 34px 6px 0px 10px;
  }
`;
const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-1j28dpc" }) `
  height: 40px;
  margin-top: 38px;
  min-width: 350px;
  background: ${({ theme }) => theme.config.line.secondary};
  z-index: -1;
`;
export const Card = () => {
    const { t } = useTranslation();
    const onSubmit = () => { };
    return (_jsx(Formik, Object.assign({ initialValues: initialValues, validationSchema: validationSchema(t), onSubmit: onSubmit }, { children: ({}) => (_jsx(Container, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Card data'), ":"] })) }), _jsxs(WrapperCards, { children: [_jsxs(LeftCard, { children: [_jsxs(WrapperLeft, { children: [_jsx(InputField, Object.assign({}, model.numberCard)), _jsx(InputField, Object.assign({}, model.holderCard))] }), _jsxs(WrapperRight, { children: [_jsx(CVVField, Object.assign({}, model.expirationDate)), _jsx(IconCard, { children: _jsx(Icon.Mir, {}) })] })] }), _jsxs(RightCard, { children: [_jsx(Line, {}), _jsx(WrapperCVV, { children: _jsx(CVVField, Object.assign({}, model.cvv)) })] })] }), _jsx(Checkbox, { readOnly: true, label: "Remember" })] }) })) })));
};
