import { Link as ComponentLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { smallMedium } from 'styles/mixins/fonts';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-dcmslr" }) `
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  padding: 12px 20px 12px 13px;
  ${({ theme, $isActive }) => $isActive &&
    css `
      background: ${$isActive
        ? theme.config.sideLink.background.active
        : 'transparent'};
      box-shadow: ${theme.config.sideLink.shadow};
    `}

  cursor: pointer;
  margin-top: 10px;
`;
export const Link = styled(ComponentLink).withConfig({ displayName: "Link", componentId: "sc-oxkq7u" }) `
  ${smallMedium};

  flex: none;
  order: 1;
  flex-grow: 1;
  letter-spacing: -0.01em;

  ${({ theme, $isActive }) => css `
    color: ${$isActive
    ? theme.config.sideLink.text.hover
    : theme.config.sideLink.text.default};

    &:is(:hover, :focus) {
      text-decoration: none;
      color: ${theme.config.sideLink.text.hover};
    }
  `};
  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
export const IconLink = styled(ComponentLink).withConfig({ displayName: "IconLink", componentId: "sc-1gy0cka" }) ``;
