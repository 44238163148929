import { Button as ButtonComponent } from 'common';
import styled, { css } from 'styled-components';
import { smallReqular } from 'styles/mixins/fonts';
import { scrollMedium } from 'styles/mixins/scroll';
import { TableRowVariant } from '.';
export const Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-hw9r7p" }) `
  padding: 24px 12px;
  border-radius: 20px;
  border-collapse: separate;

  border: ${({ theme }) => theme.config.dropdown.border.all};
  background-color: ${({ theme }) => theme.config.background.tetriery};
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1um31rv" }) `
  width: 100%;

  ${({ $isScrollable }) => css `
    ${scrollMedium};
    overflow-y: ${$isScrollable ? 'scroll' : 'hidden'};
    max-height: ${$isScrollable ? 'calc(100vh - 230px)' : 'auto'};
    padding-right: ${$isScrollable ? '16px' : '0px'};
  `}
`;
export const TableHeader = styled.div.withConfig({ displayName: "TableHeader", componentId: "sc-18ib70" }) `
  display: flex;
  margin: 0px 64px 0px 0px;
  flex-direction: row;
  ${smallReqular};
`;
export const TableColumn = styled.div.withConfig({ displayName: "TableColumn", componentId: "sc-19iw07k" }) `
  position: relative;
  flex: none;
  display: flex;
  align-items: center;
  margin: 8px 0px;
  color: ${({ theme }) => theme.config.text.primary};
  text-align: ${({ $textAlign }) => $textAlign !== null && $textAlign !== void 0 ? $textAlign : 'left'};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  -moz-box-orient: vertical;

  padding-left: 16px;

  ${({ $isTertiary }) => $isTertiary
    ? css `
          &:nth-child(1),
          &:nth-child(2) {
            flex: none;
            margin-right: 28.5px;
          }

          &:nth-child(n + 3) {
            flex: 1;

            &:before {
              content: '';
              position: absolute;
              left: 5px;
              width: 0.5px;
              height: 20px;
              background-color: ${({ theme }) => theme.config.dropdown.border.default};
            }
          }
        `
    : css `
          &:first-child {
            flex: none;
            margin-right: 28.5px;
          }

          &:nth-child(n + 2) {
            flex: 1;

            &:before {
              content: '';
              position: absolute;
              left: 5px;
              width: 0.5px;
              height: 100%;
              background-color: ${({ theme }) => theme.config.dropdown.border.default};
            }
          }
        `}

  ${({ $isHiddenLast }) => $isHiddenLast &&
    css `
      &:last-child {
        /* flex: none; */

        &::before {
          content: '';
          height: 0px;
        }
      }
    `}
`;
export const TableRow = styled.div.withConfig({ displayName: "TableRow", componentId: "sc-180b37m" }) `
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  border-radius: 20px;
`;
export const TableRowContent = styled.div.withConfig({ displayName: "TableRowContent", componentId: "sc-fgtvu9" }) `
  ${smallReqular};
  flex: 1;
  display: flex;

  align-items: center;

  flex-direction: row;
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background-color: ${({ theme }) => theme.config.background.secondary};

  ${({ $rowVariant }) => {
    switch ($rowVariant) {
        case TableRowVariant.DEFAULT:
            return css `
          padding: 16px 0px;
        `;
        case TableRowVariant.SMALL:
            return css `
          padding: 0px;
        `;
    }
}}

  ${({ theme, checked }) => checked &&
    css `
      border: 1px solid ${theme.config.line.primary};
      box-shadow: ${theme.config.dropdown.boxShadow.default};
    `}
`;
export const CheckboxContainer = styled.div.withConfig({ displayName: "CheckboxContainer", componentId: "sc-17d07s4" }) `
  display: flex;
  align-items: center;
  margin-right: 16px;
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-oc7dwi" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  margin: 12px 0px 0px 16px;
  border-radius: 50%;

  ${({ theme }) => css `
    border: ${theme.config.uploadImage.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: ${theme.config.uploadImage.voiceImage.secondaryBoxShadow};
  `}
  &:hover {
    background: none;
  }
`;
export const WrapperTableItem = styled.div.withConfig({ displayName: "WrapperTableItem", componentId: "sc-1bxlmgm" }) `
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-1csdj4o" }) `
  position: relative;
`;
export const WrapperTableMenu = styled.div.withConfig({ displayName: "WrapperTableMenu", componentId: "sc-1fho34b" }) `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
`;
export const TableHeaderParserResult = styled.div.withConfig({ displayName: "TableHeaderParserResult", componentId: "sc-1jgrgru" }) `
  display: flex;
  flex-direction: row;

  margin-bottom: 12px;
  padding: 8px 0px 8px 24px;
`;
export const TableRowParserResult = styled.div.withConfig({ displayName: "TableRowParserResult", componentId: "sc-l8nyq1" }) `
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
export const WrapperTableItemParsingResult = styled.div.withConfig({ displayName: "WrapperTableItemParsingResult", componentId: "sc-1gx6kaf" }) `
  border-radius: 16px;
  padding: 16px 0px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background-color: ${({ theme }) => theme.config.background.secondary};
`;
export const TableRowContentParerResult = styled.div.withConfig({ displayName: "TableRowContentParerResult", componentId: "sc-d54i9k" }) `
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 12px 0px 12px 24px;

  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.config.input.green.hover};
  }

  ${({ $isShortParser }) => $isShortParser &&
    css `
      flex: none;
    `}
`;
export const TableColumnParserResult = styled.div.withConfig({ displayName: "TableColumnParserResult", componentId: "sc-8gqr2p" }) `
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;

  //overflow: hidden;
  //display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &:nth-child(n + 2) {
    padding-left: 16px;
    border-left: ${({ theme }) => theme.config.dropdown.border.all};
  }
`;
export const EmptyTableRow = styled.div.withConfig({ displayName: "EmptyTableRow", componentId: "sc-j2osa1" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
