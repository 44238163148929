import { createApi } from '@reduxjs/toolkit/query/react';
import { apiLoaderQuery } from 'store/query';
export const exampleApi = createApi({
    reducerPath: 'exampleApi',
    baseQuery: apiLoaderQuery,
    endpoints: (build) => ({
        sendMessage: build.mutation({
            query: ({ token, chatId, text }) => ({
                url: `${token}/sendMessage?chat_id=${chatId}&text=${text}`,
                method: 'POST',
            }),
        }),
    }),
});
export const { useSendMessageMutation } = exampleApi;
