import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Text, TextVariant } from '../../../../../common';
import * as Styles from './styles';
import { BlockHeader } from './styles';
export const SiteLinkBlock = ({ containerRef, setIsChangeUrl, campaignUrl, }) => {
    return (_jsx(Styles.BlockContainer, { children: _jsxs(Styles.Block, { children: [_jsx(BlockHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u0421\u0441\u044B\u043B\u043A\u0430 \u043D\u0430 \u0440\u0435\u043A\u043B\u0430\u043C\u0438\u0440\u0443\u0435\u043C\u0443\u044E \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443" })) }), _jsxs(Styles.BlockContent, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'tetriery' }, { children: campaignUrl })), _jsxs(Styles.LinkActions, { children: [_jsx(Styles.LinksButton, { text: 'Изменить ссылку', onClick: () => {
                                        var _a;
                                        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0 });
                                        setIsChangeUrl(true);
                                    }, variant: 'TETRIERY' }), _jsx(Styles.LinksButton, { onClick: () => { var _a; return (_a = window.open(`http://${campaignUrl}`)) === null || _a === void 0 ? void 0 : _a.focus(); }, text: 'Перейти на сайт', variant: 'TETRIERY' })] })] })] }) }));
};
