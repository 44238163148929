import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TabItems } from '../config';
import * as Styles from './styles';
export const RegisterForms = ({ tab, selectTab }) => {
    const { t } = useTranslation();
    const Content = useMemo(() => {
        return tab.Content;
    }, [tab]);
    return (_jsxs(Styles.Container, { children: [_jsx(Styles.ContentForm, { children: _jsx(Styles.ContainerHeader, { children: _jsx(Styles.TabHeader, { children: TabItems === null || TabItems === void 0 ? void 0 : TabItems.map((item) => (_jsx(Styles.Tab, Object.assign({ id: item.id, onClick: () => selectTab(item === null || item === void 0 ? void 0 : item.id), "$isActive": item.id === tab.id }, { children: _jsx(Styles.TabLabel, Object.assign({ "$isActive": item.id === tab.id }, { children: t(item.label) })) }), item.id.toString()))) }) }) }), _jsx(Content, Object.assign({}, tab.Content.defaultProps))] }));
};
