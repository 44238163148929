import styled from 'styled-components';
import IconWrapper from '../../common/icon-wrapper/icon-wrapper';
export const DevStubContainer = styled.div.withConfig({ displayName: "DevStubContainer", componentId: "sc-j89sns" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 450px;

  border-radius: 30px;
  margin: auto;

  padding: 10px;

  background: ${({ theme }) => theme.config.background.tetriery};

  box-shadow: -2px 2px 10px -5px ${({ theme }) => theme.colors.green.tetriery};
`;
export const DevStubWarningIconWrapper = styled(IconWrapper).withConfig({ displayName: "DevStubWarningIconWrapper", componentId: "sc-196vjh2" }) `
  width: 40px;
  height: 40px;
`;
export const DevStubTitle = styled.div.withConfig({ displayName: "DevStubTitle", componentId: "sc-fzqhyy" }) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;
export const DevStubDesc = styled.div.withConfig({ displayName: "DevStubDesc", componentId: "sc-c51v4l" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;
