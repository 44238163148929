import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import React from 'react';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import * as Styles from './styles';
const logoText = {
    first: 'Brend',
    second: 'Boost',
};
export const Logo = ({ onClick }) => {
    return (_jsxs(Styles.Container, Object.assign({ onClick: onClick }, { children: [_jsx(IconWrapper, Object.assign({ "$fillColor": "#22A913", "$height": "50px", "$width": "50px" }, { children: _jsx(Icon.LogoDashboard, {}) })), _jsxs(Styles.TextContainer, { children: [_jsx(Styles.LogoTextBold, { children: logoText.first }), _jsx(Styles.LogoTextLight, { children: logoText.second })] })] })));
};
