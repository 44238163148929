import * as Yup from 'yup';
export const model = {
    region: {
        name: 'region',
        label: 'Region',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a region',
        options: [
            'all',
            'moskovskaya_oblast',
            'moskva_i_mo',
            'sverdlovskaya_oblast',
            'rostovskaya_oblast',
            'chelyabinskaya_oblast',
            'samarskaya_oblast',
            'nizhegorodskaya_oblast',
            'kemerovskaya_oblast',
            'novosibirskaya_oblast',
            'volgogradskaya_oblast',
            'irkutskaya_oblast',
        ].map((label) => ({
            label,
            value: label,
        })),
    },
    city: {
        name: 'city',
        label: 'City',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a city',
        options: ['Value1', 'Value2', 'Value3', 'Value4'].map((label) => ({
            label,
            value: label,
        })),
    },
    category: {
        name: 'category',
        label: 'Сategory',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a category',
        options: [
            'transport',
            'nedvizhimost',
            'rabota',
            'lichnye_veschi',
            'hobbi_i_otdyh',
            'dlya_biznesa',
            'predlozheniya_uslug',
            'bytovaya_elektronika',
            'dlya_doma_i_dachi',
            'zapchasti_i_aksessuary',
            'tovary_dlya_detey_i_igrushki',
            'zhivotnye',
            'krasota_i_zdorove',
        ].map((label) => ({
            label,
            value: label,
        })),
    },
    limit: {
        name: 'limit',
        label: 'Limit',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a limit',
        options: [5, 10, 50, 100, 500, 1000, 2500, 5000, 10000, 20000].map((label) => ({
            label: label.toString(),
            value: label.toString(),
        })),
    },
    date_from: {
        name: 'date_from',
        label: '',
        placeholderText: 'From',
    },
    date_to: {
        name: 'date_to',
        label: '',
        type: 'date',
        placeholderText: 'To',
    },
    adName: {
        name: 'adName',
        label: 'The name of the ad contains',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Enter ad name',
    },
};
export const validationSchema = Yup.object().shape({
    [model.limit.name]: Yup.mixed().required(),
    [model.region.name]: Yup.mixed().required(),
    [model.category.name]: Yup.mixed().required(),
});
export const initialValues = {
    [model.city.name]: '',
    [model.date_from.name]: '',
    [model.limit.name]: '',
    [model.region.name]: '',
    [model.adName.name]: '',
    [model.category.name]: '',
    [model.date_to.name]: '',
};
