import styled, { css, keyframes } from 'styled-components';
import { Button } from 'common';
import { scrollMedium } from 'styles/mixins/scroll';
const fadeDown = keyframes `
  from {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(2%);
  }
  to {
    transform: translateY(0%);
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1nnn6ej" }) `
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidenav navbar'
    'sidenav main';
  min-height: 100vh;
  transition: grid-template-columns 0.3s ease-in-out;

  ${({ theme }) => css `
    background: ${theme.config.dashboard.background};
  `} @media screen and (
  max-width: 767px) {
    grid-template-columns: 105px 1fr;
    transition: grid-template-columns 0.3s ease-in-out;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'navbar'
      'main';
    transition: grid-template-columns 0.3s ease-in-out;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1oqg6f1" }) `
  //overflow-y: auto;
  ${scrollMedium};
`;
export const ButtonMenu = styled(Button).withConfig({ displayName: "ButtonMenu", componentId: "sc-a3yr1n" }) `
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999;

  display: none;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  place-items: center;

  padding: 28px;
  border-radius: 50%;
  max-width: 50px;
  max-height: 50px;

  background: ${({ theme }) => theme.config.toggle.menu};
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};

  will-change: transform;
  transition: transform 0.5s ease;

  &:is(:active, :focus) {
    background: ${({ theme }) => theme.config.toggle.menu};
  }

  ${({ open }) => css `
    animation: ${open && fadeDown} 0.3s ease-in-out;
  `} @media screen and (
  max-width: 499px) {
    display: flex;
    pointer-events: auto;
    bottom: 35px;
    right: 40px;
  }
`;
