import { Icon } from 'common/icon';
export const templatesModalText = [
    {
        id: 0,
        path: '/dashboard/home',
        title: 'Welcome to BREND Boost!',
        subTitle: 'We are here to help you take your brend to new heights. Whether you starting a new business or looking to revitalize your existing brend, we got you covered.',
    },
    {
        id: 1,
        path: '/dashboard/voice-robot',
        title: 'Welcome to Brend Boost Voice Robot Function!',
        subTitle: 'We are thrilled to introduce you to our innovative Voice Robot feature that will revolutionize how you interact with your brend.',
    },
];
export const VariantMenuItems = {
    Listen: 'listen',
    Download: 'download',
    Copy: 'copy',
    Smart: 'smart',
    Remove: 'remove',
    Repeat: 'repeat',
};
export const menuElements = [
    {
        icon: Icon.Sound,
        label: 'Listen to the recording',
        labelAction: 'listen',
    },
    {
        icon: Icon.DownloadSecondary,
        label: 'Download the recording',
        labelAction: 'download',
    },
    {
        icon: Icon.Copy,
        label: 'Copy',
        labelAction: 'copy',
    },
    {
        icon: Icon.CopyArrow,
        label: 'Smart copying',
        labelAction: 'smart',
    },
    {
        icon: Icon.Delete,
        label: 'Remove',
        labelAction: 'remove',
    },
];
