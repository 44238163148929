import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { InputField, Text, TextVariant } from 'common';
import { Checkbox } from 'common/checkbox';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { initialValues, model, validationSchema } from './config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-89jx5l" }) `
  margin-top: 40px;
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-vprfx1" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1ods7pt" }) ``;
const TitleEmail = styled.div.withConfig({ displayName: "TitleEmail", componentId: "sc-17b3vyg" }) `
  @media screen and (max-width: 500px) {
    padding-top: 6px;
  }
`;
const WrapperField = styled.div.withConfig({ displayName: "WrapperField", componentId: "sc-z4678b" }) `
  display: flex;
  align-items: center;

  gap: 16px;

  @media screen and (max-width: 500px) {
    align-items: flex-start;
  }
`;
const WrapperFieldRow = styled.div.withConfig({ displayName: "WrapperFieldRow", componentId: "sc-3u7npf" }) `
  display: flex;
  align-items: center;

  gap: 16px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-1ewz7sn" }) ``;
export const Yandex = () => {
    const { t } = useTranslation();
    const onSubmit = () => { };
    return (_jsx(Formik, Object.assign({ initialValues: initialValues, validationSchema: validationSchema, onSubmit: onSubmit }, { children: ({}) => (_jsx(Container, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: _jsxs(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: [t('Payers details'), ":"] })) }), _jsxs(WrapperField, { children: [_jsx(TitleEmail, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: t('Email') })) }), _jsxs(WrapperFieldRow, { children: [_jsx(InputField, Object.assign({}, model.email)), _jsx(Checkbox, { readOnly: true, label: "Remember" })] })] }), _jsx(Description, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallLight }, { children: t('To continue the payment, you will be redirected to the payment system website') })) })] }) })) })));
};
