var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Loader, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { Table } from 'components';
import { DropdownMenu } from 'components/dropdown/menu';
import { TableRowVariant } from 'components/table';
import { AppRoutes } from 'config';
import { ButtonTable } from 'constants/enums';
import { menuElements, VariantMenuItems } from 'constants/templates';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useDeleteArrayParserMutation, useDeleteByIdMutation, useListQuery, } from 'store/api/databases';
import { useDebounce } from 'usehooks-ts';
import { formatId } from 'utils/formatId';
import { handleMutation } from 'utils/handleMutation';
import { SearchInput } from '../../../../../common/fields/search-input';
import IconWrapper from '../../../../../common/icon-wrapper/icon-wrapper';
import * as Styles from './styles';
import { DeleteModal } from '../../../../../components/modal/delete-modal';
export const MyDatabases = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const { Home, MyDatabase } = AppRoutes.Dashboard;
    const { data, isFetching } = useListQuery();
    const [deleteId, mutation] = useDeleteByIdMutation();
    const [deleteArray, mutationArray] = useDeleteArrayParserMutation();
    const [checkedRows, setCheckedRows] = useState([]);
    const [isDeleteMailingVisible, setIsDeleteMailingVisible] = useState(false);
    const handleDeleteMailingVisible = () => {
        setIsDeleteMailingVisible(!isDeleteMailingVisible);
    };
    const [selectedItemMenuDelete, setSelectedItemMenuDelete] = useState();
    const [isDeleteMailingMenuVisible, setIsDeleteMailingMenuVisible] = useState(false);
    const handleDeleteMailingMenuVisible = () => {
        setIsDeleteMailingMenuVisible(!isDeleteMailingMenuVisible);
    };
    const onSuccess = useCallback(() => {
        alert.success(t('Success'));
    }, [alert]);
    const onSuccessArray = useCallback(() => {
        alert.success(t('Success'));
        setCheckedRows([]);
    }, [alert]);
    const onError = useCallback((error) => {
        var _a;
        alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutation), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, mutationArray), { onSuccess: onSuccessArray, onError }));
    const [filter, setFilter] = useState('');
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);
    const debounceValue = useDebounce(filter, 500);
    const filtered = useMemo(() => {
        if (!(debounceValue === null || debounceValue === void 0 ? void 0 : debounceValue.length)) {
            return data;
        }
        return data === null || data === void 0 ? void 0 : data.filter((row) => {
            var _a, _b;
            return (_b = (_a = row.parser_type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes(debounceValue.toLowerCase());
        });
    }, [debounceValue, data]);
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };
    const handleDropMenu = () => {
        setSelectedElementIndex(null);
    };
    const handleRowChecked = (index) => {
        if (checkedRows.includes(index)) {
            setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
        }
        else {
            setCheckedRows([...checkedRows, index]);
        }
    };
    const handleAllRowsChecked = () => {
        const isAllChecked = checkedRows.length === (filtered === null || filtered === void 0 ? void 0 : filtered.length);
        if (isAllChecked) {
            setCheckedRows([]);
        }
        else {
            if (filtered) {
                setCheckedRows(filtered.map((_, index) => index));
            }
        }
    };
    const handleButtonPress = (index, _, action) => {
        switch (action) {
            case ButtonTable.Delete:
                break;
            case ButtonTable.Menu:
                setSelectedElementIndex((prevIndex) => prevIndex === index ? null : index);
                break;
        }
    };
    const allArrayIdItems = useMemo(() => {
        return data === null || data === void 0 ? void 0 : data.map((item) => item._id);
    }, [data]);
    const handleInDevelopment = () => { };
    const handleDeleteDatabase = () => __awaiter(void 0, void 0, void 0, function* () {
        const valuesToDelete = data === null || data === void 0 ? void 0 : data.filter((_, index) => checkedRows.includes(index)).map((item) => item._id);
        if (valuesToDelete) {
            yield deleteArray({ parserIds: valuesToDelete });
        }
    });
    if (isFetching) {
        return _jsx(Loader, {});
    }
    if (!(data === null || data === void 0 ? void 0 : data.length)) {
        return null;
    }
    const renderButton = (databaseId) => {
        return (_jsx(Styles.WrapperButton, { children: _jsx(Styles.Button, { text: t('Go over'), variant: ButtonVariant.TETRIERY, to: Home + MyDatabase + `/${databaseId}` }) }));
    };
    const handleDropdownMenuItem = (action, id) => __awaiter(void 0, void 0, void 0, function* () {
        switch (action) {
            case VariantMenuItems.Remove:
                setSelectedItemMenuDelete(id);
                handleDropMenu();
                handleDeleteMailingMenuVisible();
                break;
            default:
                break;
        }
    });
    const handleDeleteItem = () => {
        if (selectedItemMenuDelete !== null &&
            selectedItemMenuDelete !== undefined) {
            deleteId({ id: selectedItemMenuDelete });
        }
    };
    const handleDropdownMenu = (selectedId, row, tableRef) => {
        return (_jsx(DropdownMenu, { tableRef: tableRef, isVisible: true, top: -30, left: 100, dataItem: row, items: menuElements.slice(-1), selectedId: selectedId, setVisible: handleDropMenu, onItemClick: handleDropdownMenuItem }));
    };
    return (_jsxs(Styles.Container, { children: [_jsxs(Styles.Wrapper, { children: [_jsxs(Styles.Header, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('My base') })) }), _jsxs(Styles.Settings, { children: [_jsxs(Styles.SectionContainer, { children: [_jsx(Styles.ButtonDelete, { text: t('Delete'), icon: _jsx(Icon.Delete, {}), disabled: checkedRows.length === 0, onClick: handleDeleteMailingVisible, variant: ButtonVariant.TETRIERY }), _jsx(Button, { text: t('Download'), onClick: handleInDevelopment, variant: ButtonVariant.LIGHT })] }), _jsx(Styles.SectionContainer, { children: _jsx(SearchInput, { search: filter, searchFilter: handleFilterChange }) })] })] }), _jsx(Styles.WrapperTable, { children: _jsx(Table, { isTertiary: true, isScrollable: true, isHiddenLast: true, hasItemCheckbox: true, hasHeaderCheckbox: true, allId: allArrayIdItems, checkedRows: checkedRows, onRowChecked: handleRowChecked, buttonAction: ButtonTable.Menu, handleButton: handleButtonPress, rowVariant: TableRowVariant.SMALL, selectedElement: selectedElementIndex, onAllRowsChecked: handleAllRowsChecked, handleDropdownMenu: handleDropdownMenu, icon: _jsx(IconWrapper, Object.assign({ "$isSecondaryIcon": true }, { children: _jsx(Icon.More, {}) })), dataHeader: [
                                'Id',
                                // t('Title'),
                                t('Source'),
                                t('Parsing date'),
                                '\u00A0',
                            ].filter((item) => item), data: (filtered === null || filtered === void 0 ? void 0 : filtered.map((row, index) => [
                                formatId(index + 1),
                                row.parser_type,
                                dayjs(row.created_at)
                                    .locale('ru')
                                    .format('HH:mm, D MMM, YYYY'),
                                renderButton(row._id),
                            ].filter((item) => item))) || [] }) })] }), _jsx(DeleteModal, { isVisible: isDeleteMailingVisible, onClick: handleDeleteMailingVisible, message: 'Вы точно хотите удалить результат(-ы) парсинга?', modalTitle: 'Удалить результаты', buttonText: 'Да, удалить', deleteFunc: handleDeleteDatabase }), _jsx(DeleteModal, { isVisible: isDeleteMailingMenuVisible, onClick: handleDeleteMailingMenuVisible, message: 'Вы точно хотите удалить выбранный результат парсинга?', modalTitle: 'Удалить результат', buttonText: 'Да, удалить', deleteFunc: handleDeleteItem })] }));
};
