import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Sortable from 'sortablejs';
import { InputField, Text, TextVariant } from '../../../../../../../common';
import { Close, Menu } from '../../../../../../../common/icon/icons';
import { adFormModel } from '../../config';
import * as Styles from './styles';
const ClosedLink = ({ link, index }) => {
    const { setFieldValue, values } = useFormikContext();
    const [isContainError, setIsContainError] = useState(false);
    useEffect(() => {
        if (!link.title.length || !link.title.length) {
            setIsContainError(true);
        }
    }, []);
    return (_jsxs(Styles.ClosedLinkContainer, Object.assign({ "$isContainError": isContainError, onDoubleClick: () => {
            setFieldValue(`links.${index}.isOpen`, true);
        } }, { children: [_jsx(Styles.ClosedLinkDragIconContainer, Object.assign({ "$isCursor": true }, { children: _jsx(Menu, {}) })), _jsxs(Styles.ClosedLinkInfo, { children: [_jsx(Styles.ClosedLinkTitle, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'primary' }, { children: link.link || 'Укажите корректную ссылку' })) }), _jsx(Styles.ClosedLinkLink, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": link.title ? 'tetriery' : 'primary' }, { children: (link === null || link === void 0 ? void 0 : link.title) || 'Укажите название' })) })] }), _jsx(Styles.ClosedLinkRemove, Object.assign({ "$isCursor": true, onClick: () => {
                    setFieldValue('links', values.links.filter((l) => l !== link));
                } }, { children: _jsx(Close, {}) }))] })));
};
const OpenedLink = ({ index }) => {
    const openLink = useRef(null);
    const { setFieldValue, values } = useFormikContext();
    useEffect(() => {
        const handler = (event) => {
            if (openLink.current) {
                if (!event.composedPath().includes(openLink.current)) {
                    if (!!values.links[index].link.length) {
                        setFieldValue(`links.${index}.isOpen`, false);
                    }
                }
            }
        };
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [index]);
    return (_jsxs(Styles.LinkFieldsWrapper, Object.assign({ ref: openLink }, { children: [_jsx(InputField, Object.assign({ name: `links.${index}.link` }, adFormModel.linkLink)), _jsx(InputField, Object.assign({ name: `links.${index}.title` }, adFormModel.linkTitle)), _jsx(InputField, Object.assign({ name: `links.${index}.description` }, adFormModel.linkDescription))] }), 'link' + index));
};
export const FastLinks = () => {
    const { values, setFieldValue } = useFormikContext();
    const test = useRef(null);
    useEffect(() => {
        if (test.current) {
            // List with handle
            Sortable.create(test.current, {
                handle: Styles.ClosedLinkDragIconContainer.name,
                animation: 300,
            });
        }
    }, [test.current]);
    useEffect(() => {
        if (!values.links.length) {
            setFieldValue('links', [
                {
                    link: '',
                    description: '',
                    title: '',
                    isOpen: true,
                },
            ]);
        }
    }, [values.links]);
    return (_jsx(FieldArray, Object.assign({ name: 'links' }, { children: ({ push }) => (_jsxs(Styles.LinksActions, { children: [_jsx(Styles.LinksContainer, Object.assign({ ref: test }, { children: values.links.length > 0 &&
                        values.links.map((link, index) => {
                            if (!link.isOpen) {
                                return (_jsx(ClosedLink, { link: link, index: index }, 'link' + index));
                            }
                            else {
                                return (_jsx(OpenedLink, { link: link, index: index }, 'link' + index));
                            }
                        }) })), _jsx(Styles.AddFieldButton, { variant: 'SECONDARY', text: 'Добавить ссылку', onClick: () => {
                        var _a;
                        if ((_a = values.links.at(-1)) === null || _a === void 0 ? void 0 : _a.link) {
                            setFieldValue(`links.${values.links.length - 1}.isOpen`, false);
                            push({
                                link: '',
                                description: '',
                                title: '',
                                isOpen: true,
                            });
                        }
                    } })] })) })));
};
