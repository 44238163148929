import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1jgqqud" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-11gq3hk" }) `
  display: flex;
  flex-direction: column;
  margin: 40px;
  gap: 24px;
`;
export const PageWrapper = styled.div.withConfig({ displayName: "PageWrapper", componentId: "sc-81ykgc" }) `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;
export const InputsWrapper = styled.div.withConfig({ displayName: "InputsWrapper", componentId: "sc-16h5dod" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 300px;
  flex-grow: 1;
  //width: calc(30vw - 5px);
  height: 100%;
`;
export const Generated = styled.div.withConfig({ displayName: "Generated", componentId: "sc-2tvcfb" }) `
  display: flex;
  min-width: 300px;
  width: 100%;
  flex-grow: 1;
`;
export const GeneratedText = styled.div.withConfig({ displayName: "GeneratedText", componentId: "sc-vvdiv0" }) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  white-space: pre-wrap;
  background: ${({ theme }) => theme.config.input.default.background};
  border-radius: 16px;
  padding: 15px;
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "sc-1wjrxda" }) `
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
export const DescriptionInput = styled.div.withConfig({ displayName: "DescriptionInput", componentId: "sc-1k4br75" }) `
  height: 100%;

  div,
  textarea {
    height: 100%;
  }
`;
export const LoaderContainer = styled.div.withConfig({ displayName: "LoaderContainer", componentId: "sc-8mvgh3" }) `
  padding-top: 100px;
`;
