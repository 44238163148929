import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { Button as ButtomComponent } from 'common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1wny9nf" }) `
  gap: 32px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  margin: 32px 24px 24px 0px;
  background-color: ${({ theme }) => theme.config.background.secondary};

  overflow-y: auto;

  @media screen and (max-width: 500px) {
    margin-left: 24px;
  }
`;
export const ScrollWrapper = styled.div.withConfig({ displayName: "ScrollWrapper", componentId: "sc-9b8kx1" }) `
  width: 100%;
  padding: 0px 48px;

  overflow-y: auto;
  max-height: calc(100vh - 150px);
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-av8niw" }) `
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 48px 110px;
  border-radius: 16px;
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.background.tetriery};
  gap: 100px;

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    gap: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 50px;
  }

  @media screen and (max-width: 400px) {
    padding: 24px 10px;
  }
`;
export const TextWrapper = styled.div.withConfig({ displayName: "TextWrapper", componentId: "sc-1j54ld" }) `
  margin: 40px 0px 32px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-d3mpvv" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const DatabaseWrapper = styled.div.withConfig({ displayName: "DatabaseWrapper", componentId: "sc-1yp9afw" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;
export const ItemWrapper = styled.div.withConfig({ displayName: "ItemWrapper", componentId: "sc-bq2unj" }) `
  display: grid;
  grid-template-columns: repeat(2, minmax(286px, 1fr));
  grid-template-rows: repeat(2, auto);
  gap: 16px;

  @media screen and (max-width: 1040px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
    gap: 16px;
  }

  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
    gap: 16px;
  }
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-pe3gf0" }) `
  display: flex;
  gap: 24px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 16px;
  }
`;
export const Button = styled(ButtomComponent).withConfig({ displayName: "Button", componentId: "sc-1sb4sdi" }) `
  border-radius: 8px;
  padding-inline: 90px;
  white-space: nowrap;
`;
