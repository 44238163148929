import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import { Route, Routes } from 'react-router-dom';
import { Mailing } from 'components';
import { MailingType } from 'constants/enums';
import { AudioclipsPage } from '../audio-clips';
export const VoiceRobotPage = () => {
    const { VoiceRobot } = AppRoutes.Dashboard;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: VoiceRobot.Mailing, element: _jsx(Mailing, { typeMailing: MailingType.VOICE_ROBOT }) }), _jsx(Route, { path: VoiceRobot.Audioclips, element: _jsx(AudioclipsPage, {}) })] }));
};
export default VoiceRobotPage;
