import { dispatchFormModel } from '../config';
import * as Yup from 'yup';
export const loginRegex = /^@([a-zA-Z0-9_]{4,})$/;
export const TelegramValidationSchema = (error) => Yup.object().shape({
    [dispatchFormModel.manualInput.name]: Yup.string().matches(loginRegex, error),
});
export function isValidLogin(login) {
    return loginRegex.test(login);
}
export const TelegramDispatchFormInitialValues = {
    chips: {
        inputChips: [],
        baseChips: [],
    },
    historyChips: [],
    dropdown: [],
    mailingText: '',
    mailingName: '',
    name: 'Telegram',
};
