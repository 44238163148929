import styled from 'styled-components';
import { Button, Text } from '../../../../../common';
import { Dropdown } from '../../../../../components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1t145dp" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-er4cvs" }) `
  display: flex;
  flex-direction: column;
  margin: 40px;
  gap: 24px;
`;
export const Generated = styled.div.withConfig({ displayName: "Generated", componentId: "sc-1sngmrk" }) `
  width: 500px;
  height: 40px;
`;
export const DownloadButtonWrapper = styled.div.withConfig({ displayName: "DownloadButtonWrapper", componentId: "sc-1p3ss9j" }) `
  display: flex;
  flex-direction: row;
  gap: 30px;
`;
export const DownloadButton = styled(Button).withConfig({ displayName: "DownloadButton", componentId: "sc-1s14zby" }) `
  width: fit-content;
  padding: 0 100px;
  text-decoration: none;
`;
export const ImageGenerateFormWrapper = styled.div.withConfig({ displayName: "ImageGenerateFormWrapper", componentId: "sc-1gidz49" }) `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const FullForm = styled.div.withConfig({ displayName: "FullForm", componentId: "sc-1o52xbf" }) `
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const FileInput = styled.input.withConfig({ displayName: "FileInput", componentId: "sc-fm1jdv" }) `
  width: 0;
  height: 0;
  opacity: 0;
  border: 0;
  padding: 0;
`;
export const UploadImageButton = styled(Button).withConfig({ displayName: "UploadImageButton", componentId: "sc-1ic0x8" }) `
  max-width: fit-content;
`;
export const ImageContainer = styled.div.withConfig({ displayName: "ImageContainer", componentId: "sc-1tvam5g" }) `
  display: flex;
  position: relative;
  height: 400px;
  width: 400px;
`;
export const ImagePreview = styled.img.withConfig({ displayName: "ImagePreview", componentId: "sc-q0sq4i" }) `
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;
export const ChangeImageButton = styled.div.withConfig({ displayName: "ChangeImageButton", componentId: "sc-1pohk5e" }) `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: opacity(0);
  transition: filter 0.1s ease-in-out, backdrop-filter 0.4s ease-in-out;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    filter: opacity(1);
    backdrop-filter: blur(3px) brightness(0.2);
  }
`;
export const ParamsContainer = styled.div.withConfig({ displayName: "ParamsContainer", componentId: "sc-1fhz17b" }) `
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const ParamRow = styled.div.withConfig({ displayName: "ParamRow", componentId: "sc-10v0pxi" }) `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
`;
export const ParamText = styled(Text).withConfig({ displayName: "ParamText", componentId: "sc-ti2wjw" }) `
  width: 200px;
`;
export const DropdownWrapper = styled(Dropdown).withConfig({ displayName: "DropdownWrapper", componentId: "sc-1c64e0o" }) `
  display: flex;
  flex-grow: 1;
  width: 400px;
  background: ${({ theme }) => theme.config.input.light};
  border-radius: 20px;
  position: relative;
`;
export const DropdownButton = styled.div.withConfig({ displayName: "DropdownButton", componentId: "sc-1m6nk4t" }) `
  display: flex;
  align-items: center;
  width: 300px;
  padding-left: 20px;
  height: 40px;
  background-color: ${({ theme }) => theme.config.input.light};
  border-radius: 10px;
`;
export const GenerateButton = styled(Button).withConfig({ displayName: "GenerateButton", componentId: "sc-1iersv0" }) `
  margin-top: 58px;
`;
export const ErrorText = styled.div.withConfig({ displayName: "ErrorText", componentId: "sc-1k40xzs" }) `
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.red.primary};
`;
