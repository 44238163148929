import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import { heading2Medium } from 'styles/mixins/fonts';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
import { useBackdropClick } from '../../../hooks/useBackdropClick';
const slideInUp = keyframes `
  from {
    transform: translateY(100%);
  }
`;
const slideOutDown = keyframes `
  to {
    transform: translateY(200%);
  }
`;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-p5u5m6" }) `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.config.modal.bgBlur.default};
  z-index: 1000;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.4s ease-in, backdrop-filter 0.1s ease-in,
    visibility 0.4s ease-in;
  backdrop-filter: blur(10px);
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1xwfgs5" }) `
  position: relative;
  padding: 40px 40px 32px;
  background: ${({ theme }) => theme.config.modal.bg.default};
  border-radius: 16px;
  flex-basis: ${({ $width }) => $width}px;
  width: 100%;
  box-shadow: ${({ theme }) => theme.config.modal.boxShadow};
  transition: animation 0.5s ease-in;
  animation: ${({ $isVisible }) => $isVisible
    ? css `
          ${slideInUp} 0.4s cubic-bezier(0.25, 0, 0.3, 1) forwards;
        `
    : css `
          ${slideOutDown} 0.3s cubic-bezier(0.25, 0, 0.3, 1) forwards;
        `};
`;
const Title = styled.header.withConfig({ displayName: "Title", componentId: "sc-bpmu0u" }) `
  ${heading2Medium};
`;
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-uoogrm" }) `
  margin: 32px 0px 40px;

  ${({ $isInfo }) => $isInfo &&
    css `
      text-align: center;
    `}
`;
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-1x5rqs0" }) `
  display: flex;

  gap: 24px;
  ${({ $isInfo }) => !$isInfo &&
    css `
      padding-top: 24px;
      border-top: ${({ theme }) => theme.config.modal.border.default};
    `}
`;
const Close = styled.div.withConfig({ displayName: "Close", componentId: "sc-16tws9i" }) `
  position: absolute;
  top: 0;
  right: -66px;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.modal.bg.default};
  cursor: pointer;
  transition: background 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.config.modal.bg.hover};
  }
`;
export const ModalMain = ({ width, title, isInfo, footer, onClick, children, isVisible, }) => {
    const { t } = useTranslation();
    const modalRef = useRef(null);
    useBackdropClick(modalRef, onClick, isVisible);
    return (_jsx(Container, Object.assign({ "$isVisible": isVisible }, { children: _jsxs(Wrapper, Object.assign({ "$isVisible": isVisible, "$width": width !== null && width !== void 0 ? width : 440, ref: modalRef }, { children: [_jsx(Title, { children: t(title) }), _jsx(Body, Object.assign({ "$isInfo": isInfo }, { children: children })), _jsx(Footer, Object.assign({ "$isInfo": isInfo }, { children: footer })), _jsx(Close, Object.assign({ onClick: onClick }, { children: _jsx(IconWrapper, { children: _jsx(Icon.Close, {}) }) }))] })) })));
};
