import * as Yup from 'yup';
import { InputColorType, InputForm, InputSize, } from '../../../../../common/input/types';
import { ItemStatusVariants } from '../../../../../components/item-status';
export const model = {
    call_name: {
        type: 'text',
        name: 'call_name',
        placeholder: 'Mailing name',
        colorType: InputColorType.DARK,
        size: InputSize.DEFAULT,
    },
    phoneNumbers: {
        type: 'text',
        name: 'phoneNumbers',
        containerStyle: { flex: 1 },
        placeholder: 'Enter the mailing number',
        size: InputSize.SMALL,
        form: InputForm.DEFAULT,
        colorType: InputColorType.DARK,
    },
    message: {
        type: 'text',
        name: 'message',
        inputType: 'textarea',
        style: { minHeight: 240 },
        placeholder: 'Message content',
    },
    clipboard: {
        type: 'text',
        withBorder: true,
        name: 'clipboard',
        inputType: 'textarea',
        style: { minHeight: 240 },
        placeholder: 'Add numbers for mailing',
    },
    tagsVoice: {
        name: 'tagsVoice',
        label: 'Tags for voice menu blocks',
        placeholder: 'Select tags',
        options: [
            'Voice command menu',
            'Interactive voice menu',
            'Voice-activated menu',
            'Voice navigation menu',
            'Automated voice menu',
            'Voice-driven menu system',
            'Vocal menu interface',
            'Hands-free menu control',
            'Speech-based menu selection',
            'Voice-responsive menu options',
        ].map((label) => ({
            label: label.toString(),
            value: label.toString(),
        })),
    },
};
export const phoneRegex = /^\+(375|7|380|8)\d{9,10}$/;
export const mailingModalValidationSchema = Yup.object().shape({
    // [model.phoneNumbers.name]: Yup.array()
    //   .of(
    //     Yup.string()
    //       .required()
    //       .matches(/^\d{10}$/, 'Invalid phone number format (10 digits only)')
    //   )
    //   .required()
    //   .min(1, 'At least one phone number is required'),
    [model.call_name.name]: Yup.string().required(),
    // [dispatchFormModel.manualInput.name]: Yup.string().matches(
    //   phoneRegex,
    //   'Номер должен быть в формате + Код страны 123456789'
    // ),
    audioClipId: Yup.string().required('Необходимо выбрать аудиоролик'),
});
export const mailingModalInitialValues = {
    manualInput: '',
    audioClipId: null,
    phoneNumbers: [],
    call_name: '',
    manualPhones: [],
    dbPhones: [],
};
export const fakeData = [
    {
        id: 0,
        cost: 0,
        name: 'Service 0',
        created: 'Service 0',
        started: '2023-01-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 1,
        cost: 25,
        name: 'Service 1',
        created: 'Service 1',
        started: '2023-01-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 2,
        cost: 78,
        name: 'Service 2',
        created: 'Service 2',
        started: '2023-02-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 3,
        created: 'Service 3',
        name: 'Service 3',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
        started: '2023-03-01',
        cost: 53,
    },
    {
        id: 4,
        cost: 92,
        name: 'Service 4',
        created: 'Service 4',
        started: '2023-04-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 5,
        created: 'Service 5',
        name: 'Service 5',
        started: '2023-05-01',
        cost: 39,
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 6,
        cost: 10,
        name: 'Service 6',
        created: 'Service 6',
        started: '2023-06-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 7,
        cost: 62,
        name: 'Service 7',
        created: 'Service 7',
        started: '2023-07-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
    {
        id: 8,
        created: 'Service 8',
        name: 'Service 8',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
        started: '2023-08-01',
        cost: 46,
    },
    {
        id: 9,
        cost: 84,
        name: 'Service 9',
        created: 'Service 9',
        started: '2023-09-01',
        status: {
            text: 'Updating',
            variant: ItemStatusVariants.Updating,
        },
    },
];
