import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from '../../../../config';
import { MailingEmail } from './email';
import { HomePage } from './home';
import { MailingTelegram } from './telegram';
import { MailingHistory } from './mailingHistory';
export const MailingContent = () => {
    const { Mailing } = AppRoutes.Dashboard;
    return (_jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(HomePage, {}) }), _jsx(Route, { path: Mailing.Email, element: _jsx(MailingEmail, {}) }), _jsx(Route, { path: Mailing.Telegram, element: _jsx(MailingTelegram, {}) }), _jsx(Route, { path: Mailing.History, element: _jsx(MailingHistory, {}) })] }));
};
