var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useField } from 'formik';
import { controlError } from 'utils/controlError';
import { useTranslation } from 'react-i18next';
import { DropdownTime } from 'components/dropdown/time';
export const SelectTimeDropDownField = (_a) => {
    var { name, isVisible, placeholder, setVisibility } = _a, props = __rest(_a, ["name", "isVisible", "placeholder", "setVisibility"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = t(controlError(meta, name, t(placeholder !== null && placeholder !== void 0 ? placeholder : '')));
    return (_jsx(DropdownTime, Object.assign({ error: error, isVisible: isVisible, setVisibility: setVisibility, selectedHour: field.value ? new Date(field.value) : new Date(), selectedMinute: field.value ? new Date(field.value) : new Date(), onChangeHour: (hour) => {
            helpers.setValue(hour.toISOString());
        }, onChangeMinute: (minute) => {
            helpers.setValue(minute.toISOString());
        } }, props)));
};
