import { useTranslation } from 'react-i18next';
export const parseAxiosError = (error) => {
    var _a, _b, _c, _d, _e, _f;
    const axiosError = ((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.msg) || ((_f = (_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.debug) === null || _f === void 0 ? void 0 : _f.message);
    if (axiosError) {
        return axiosError;
    }
    return error.message;
};
export const getErrorMessage = (error) => {
    var _a, _b, _c, _d, _e, _f;
    if (typeof error === 'string')
        return error;
    return (((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.msg) ||
        ((_f = (_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.debug) === null || _f === void 0 ? void 0 : _f.message) ||
        (error === null || error === void 0 ? void 0 : error.message) ||
        'Something went wrong');
};
export const controlError = (meta, name, label) => {
    const { t } = useTranslation();
    let error = meta && meta.touched && meta.error;
    if (!error)
        return undefined;
    if (error.split(name + ' ').find((text) => text === 'is a required field')) {
        return [
            label || 'Value',
            t(error.split(name + ' ').filter((f) => f)[0]),
        ].join(' ');
    }
    return error.replace(name, label || 'Value');
};
