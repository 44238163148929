export const staffFakeData = [
    {
        id: 0,
        position: 'Manager',
        name: 'John Doe',
        email: 'johndoe@example.com',
        accessToServices: 'Service A',
    },
    {
        id: 1,
        position: 'Supervisor',
        name: 'Jane Smith',
        email: 'janesmith@example.com',
        accessToServices: 'Service B',
    },
    {
        id: 2,
        position: 'Coordinator',
        name: 'Michael Johnson',
        email: 'michaeljohnson@example.com',
        accessToServices: 'Service C',
    },
    {
        id: 3,
        position: 'Assistant',
        name: 'Emily Davis',
        email: 'emilydavis@example.com',
        accessToServices: 'Service A',
    },
    {
        id: 4,
        position: 'Analyst',
        name: 'David Wilson',
        email: 'davidwilson@example.com',
        accessToServices: 'Service B',
    },
    {
        id: 5,
        position: 'Specialist',
        name: 'Olivia Martinez',
        email: 'oliviamartinez@example.com',
        accessToServices: 'Service C',
    },
    {
        id: 6,
        position: 'Administrator',
        name: 'Sophia Anderson',
        email: 'sophiaanderson@example.com',
        accessToServices: 'Service A',
    },
    {
        id: 7,
        position: 'Coordinator',
        name: 'William Thomas',
        email: 'williamthomas@example.com',
        accessToServices: 'Service B',
    },
    {
        id: 8,
        position: 'Manager',
        name: 'Ava Taylor',
        email: 'avataylor@example.com',
        accessToServices: 'Service C',
    },
    {
        id: 9,
        position: 'Supervisor',
        name: 'Ethan Harris',
        email: 'ethanharris@example.com',
        accessToServices: 'Service A',
    },
];
export const model = {
    changePassword: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'changePassword',
    },
    changeTariff: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'changeTariff',
    },
    changePayment: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'changePayment',
    },
    changeMailing: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'changeMailing',
    },
    changeReport: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'changeReport',
    },
    changeAvailable: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'changeAvailable',
    },
};
export const initialValues = {
    changePassword: true,
    changeTariff: true,
    changePayment: true,
    changeMailing: true,
    changeReport: true,
    changeAvailable: true,
};
