import styled, { css, keyframes } from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
import { InputField } from '../../../common';
const scaleDown = keyframes `
  from {
    transform: scale(.75)
  }
  to {
    transform: scale(1)
  }
`;
const slideOutDown = keyframes `
  to {
    transform: scale(.75)
  }
`;
export const ModalContainer = styled.div.withConfig({ displayName: "ModalContainer", componentId: "sc-105kxan" }) `
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  overflow-y: auto;
  ${scrollMedium};

  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in, backdrop-filter 0.1s ease-in,
    visibility 0.4s ease-in;
  backdrop-filter: blur(10px);
`;
export const ModalContent = styled.div.withConfig({ displayName: "ModalContent", componentId: "sc-1bbsqo9" }) `
  gap: 10px;
  width: 80%;
  max-width: 1000px;
  height: fit-content;
  margin: 40px;
  padding: 20px;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.config.background.secondary};

  transition: animation 0.4s ease-in;
  animation: ${({ $isVisible }) => $isVisible
    ? css `
          ${scaleDown} 0.4s cubic-bezier(0.25, 0, 0.3, 1) forwards;
        `
    : css `
          ${slideOutDown} 0.3s cubic-bezier(0.25, 0, 0.3, 1) forwards;
        `};
`;
export const Grid = styled.div.withConfig({ displayName: "Grid", componentId: "sc-172f1wp" }) `
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  grid-template-areas:
    'number audioclip'
    'footer footer';

  @media (max-width: 1124px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'number'
      'audioclip'
      'footer';
  }
`;
export const WrapperNumber = styled.div.withConfig({ displayName: "WrapperNumber", componentId: "sc-1towfbm" }) `
  grid-area: number;
  flex: 1;
`;
export const WrapperAudioClip = styled.div.withConfig({ displayName: "WrapperAudioClip", componentId: "sc-bi7dm6" }) `
  grid-area: audioclip;
`;
export const MailingNameInput = styled(InputField).withConfig({ displayName: "MailingNameInput", componentId: "sc-1g3rl0" }) `
  margin-bottom: 15px;
`;
export const WrapperFooter = styled.div.withConfig({ displayName: "WrapperFooter", componentId: "sc-12al9b4" }) `
  grid-area: footer;
`;
export const WrapperTags = styled.div.withConfig({ displayName: "WrapperTags", componentId: "sc-156fybg" }) `
  padding: 16px 0px 68px 0px;
  ${({ theme }) => css `
    border-top: ${theme.config.input.default.border.default};
  `}
`;
export const CreateButtonContainer = styled.div.withConfig({ displayName: "CreateButtonContainer", componentId: "sc-112cjsh" }) `
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const Close = styled.div.withConfig({ displayName: "Close", componentId: "sc-egbs74" }) `
  position: absolute;
  top: 10%;
  right: 5%;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.modal.bg.default};
  cursor: pointer;
  transition: background 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.config.modal.bg.hover};
  }
`;
