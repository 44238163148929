var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useField } from 'formik';
import { VoiceRecordingForm } from 'components/audioplayer';
import { useTranslation } from 'react-i18next';
import { controlError } from 'utils/controlError';
export const VoiceRecordingField = (_a) => {
    var { name } = _a, props = __rest(_a, ["name"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = t(controlError(meta, name, t('')));
    return (_jsx(VoiceRecordingForm, Object.assign({}, props, { error: error, value: field.value, onChange: (e) => {
            helpers.setValue(e !== null && e !== void 0 ? e : '');
        } })));
};
