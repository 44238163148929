import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import { Formik, useFormikContext } from 'formik';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Route, Routes, useParams } from 'react-router-dom';
import { Loader } from '../../../../../common';
import { CompanySpheres } from '../../../../../constants/enums';
import { useNavigate } from '../../../../../hooks';
import { useCompanyGetMutation, useCompanyUpdateMutation, } from '../../../../../store/api/companies';
import { handleMutation } from '../../../../../utils/handleMutation';
import { createCompanyValidationSchema, EActivityType, ESocialNetwork, } from '../../company/create/config';
import { CompanyDataPage } from './company-data';
import { Footer } from './footer';
import { HeaderMyCompany } from './header';
import { LegalDataPage } from './legal-data';
import * as Styles from './styles';
const getActivitySpheres = (company) => {
    const activitySpheres = [];
    if (company.technologies === 'True') {
        activitySpheres.push({
            label: t(CompanySpheres.Technology.toString()),
            value: CompanySpheres.Technology.toString(),
        });
    }
    if (company.health === 'True') {
        activitySpheres.push({
            label: t(CompanySpheres.Healthcare.toString()),
            value: CompanySpheres.Healthcare.toString(),
        });
    }
    if (company.education === 'True') {
        activitySpheres.push({
            label: t(CompanySpheres.Education.toString()),
            value: CompanySpheres.Education.toString(),
        });
    }
    if (company.transportation === 'True') {
        activitySpheres.push({
            label: t(CompanySpheres.Transportation.toString()),
            value: CompanySpheres.Transportation.toString(),
        });
    }
    if (company.production === 'True') {
        activitySpheres.push({
            label: t(CompanySpheres.Manufacturing.toString()),
            value: CompanySpheres.Manufacturing.toString(),
        });
    }
    return activitySpheres;
};
const getActivityTypes = (company) => {
    const activityTypes = [];
    if (company.services === 'True') {
        activityTypes.push(EActivityType.SERVICES);
    }
    if (company.goods === 'True') {
        activityTypes.push(EActivityType.PRODUCTS);
    }
    return activityTypes;
};
const DetailCompany = () => {
    const { CompanyData, LegalData } = AppRoutes.Dashboard.MyCompany;
    const { values } = useFormikContext();
    return (_jsxs(_Fragment, { children: [_jsx(HeaderMyCompany, { name: values.shortName }), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(_Fragment, {}) }), _jsx(Route, { path: CompanyData, element: _jsx(Styles.ContainerForm, { children: _jsx(CompanyDataPage, {}) }) }), _jsx(Route, { path: LegalData, element: _jsxs(Styles.ContainerForm, { children: [_jsx(LegalDataPage, {}), _jsx(Footer, {})] }) })] })] }));
};
export const DetailMyCompanyContent = () => {
    const [requestUpdate, responseUpdate] = useCompanyUpdateMutation();
    const alert = useAlert();
    const [requestCompany, responseCompany] = useCompanyGetMutation();
    const { id } = useParams();
    const [initialCompany, setInitialCompany] = useState();
    const navigate = useNavigate();
    const parseResponseData = (company) => {
        const additionalInfo = JSON.parse(company.official_requisites);
        setInitialCompany({
            activitySpheres: getActivitySpheres(company),
            activityTypes: getActivityTypes(company),
            companyWebsites: company.company_urls.split(',').map((url) => {
                return {
                    website: url,
                    connected: true,
                };
            }),
            legalInformation: {
                address: '',
                bankDetails: '',
            },
            //TODO FIX Location
            location: {
                country: {
                    value: 'qwe',
                    label: 'qwe',
                },
                region: {
                    value: 'qwe',
                    label: 'qwe',
                },
            },
            socialNetworks: [
                {
                    network: ESocialNetwork.FACEBOOK,
                    label: t('Facebook'),
                    connected: company.facebook !== 'False',
                    website: company.facebook !== 'False' ? company.facebook : '',
                },
                {
                    network: ESocialNetwork.VKONTAKTE,
                    label: t('VK'),
                    connected: company.vk !== 'False',
                    website: company.vk !== 'False' ? company.vk : '',
                },
                {
                    network: ESocialNetwork.TIKTOK,
                    label: t('TikTok'),
                    connected: company.tiktok !== 'False',
                    website: company.tiktok !== 'False' ? company.tiktok : '',
                },
                {
                    network: ESocialNetwork.TELEGRAM,
                    label: t('Telegram'),
                    connected: company.telegram !== 'False',
                    website: company.telegram !== 'False' ? company.telegram : '',
                },
                {
                    network: ESocialNetwork.INSTAGRAM,
                    label: t('Instagram'),
                    connected: company.instagram !== 'False',
                    website: company.instagram !== 'False' ? company.instagram : '',
                },
            ],
            shortName: company.company_name,
            fullName: additionalInfo.fullName,
            address: company.official_address,
            director: additionalInfo.director,
            inn: additionalInfo.inn,
            kpp: additionalInfo.kpp,
            ogrn: additionalInfo.ogrn,
        });
    };
    useEffect(() => {
        requestCompany({
            company_id: id.toString(),
        });
    }, []);
    const onSuccess = () => {
        if (responseCompany) {
            const responseData = responseCompany.data;
            parseResponseData(responseData);
        }
    };
    const onError = useCallback((error) => {
        var _a;
        try {
            alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
        }
        catch (_b) { }
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, responseCompany), { onSuccess,
        onError }));
    const onSuccessUpdate = () => {
        alert.success('Компания изменена успешно');
        navigate('/dashboard/my-company');
    };
    handleMutation(Object.assign(Object.assign({}, responseUpdate), { onSuccess: onSuccessUpdate, onError }));
    if (!initialCompany) {
        return _jsx(Loader, {});
    }
    const checkIsIncluded = (activitySpheres, checkedSphere) => {
        return activitySpheres.filter((sphere) => sphere.value === checkedSphere)
            .length === 0
            ? 'False'
            : 'True';
    };
    const checkIsConnectedSN = (socialNetworks, checkedSN) => {
        return socialNetworks
            .filter((searchSN) => searchSN.network === checkedSN)
            .map((SN) => (SN.connected ? SN.website : 'False'))[0];
    };
    const onSubmit = ({ companyWebsites, fullName, activityTypes, activitySpheres, location, socialNetworks, director, ogrn, inn, kpp, address, shortName, }) => {
        const correctFullName = fullName.replaceAll(`\"`, `\\"`);
        requestUpdate({
            company_urls: companyWebsites
                .filter((w) => w.connected)
                .map((w) => w.website)
                .join(','),
            company_city: location.country.value,
            company_name: shortName,
            company_region: location.region.value,
            education: checkIsIncluded(activitySpheres, 'Education'),
            goods: activityTypes.includes(EActivityType.PRODUCTS) ? 'True' : 'False',
            health: checkIsIncluded(activitySpheres, 'Healthcare'),
            official_address: address,
            inn,
            official_requisites: JSON.stringify({
                ogrn,
                inn,
                kpp,
                director,
                fullName: correctFullName,
            }),
            production: checkIsIncluded(activitySpheres, 'Manufacturing'),
            facebook: checkIsConnectedSN(socialNetworks, 'FACEBOOK'),
            vk: checkIsConnectedSN(socialNetworks, 'VKONTAKTE'),
            tiktok: checkIsConnectedSN(socialNetworks, 'TIKTOK'),
            telegram: checkIsConnectedSN(socialNetworks, 'TELEGRAM'),
            instagram: checkIsConnectedSN(socialNetworks, 'INSTAGRAM'),
            is_services: activityTypes.includes(EActivityType.SERVICES)
                ? 'True'
                : 'False',
            technologies: checkIsIncluded(activitySpheres, 'Technology'),
            transportation: checkIsIncluded(activitySpheres, 'Transportation'),
            company_id: id,
        });
    };
    return (_jsx(Styles.Container, { children: _jsx(Styles.ScrollContent, { children: _jsx(Styles.Wrapper, { children: _jsx(Formik, Object.assign({ onSubmit: onSubmit, initialValues: initialCompany, validationSchema: createCompanyValidationSchema }, { children: _jsx(DetailCompany, {}) })) }) }) }));
};
