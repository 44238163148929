import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant } from 'common';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconWrapper from '../../common/icon-wrapper/icon-wrapper';
import * as Styles from './styles';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../config';
import { RefillBalanceModal } from '../modal/refill-modal';
export const Balance = ({ top, left, items, button, onClick, isVisible, userBalance, }) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);
    const handleClickOutside = (event) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
            if (onClick) {
                onClick(isOpen);
            }
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsOpen(true);
        }
    }, [isVisible]);
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    return (_jsxs(Styles.Container, Object.assign({ ref: dropdownRef }, { children: [_jsxs(Styles.ButtonContainer, Object.assign({ "$isOpen": isVisible !== null && isVisible !== void 0 ? isVisible : isOpen }, { children: [button, _jsx(IconWrapper, Object.assign({ "$isCursor": true }, { children: _jsx(Styles.Arrow, { "$isOpen": isVisible !== null && isVisible !== void 0 ? isVisible : isOpen }) }))] })), _jsx(Styles.Wrapper, { children: _jsxs(Styles.MenuBalance, Object.assign({ "$isOpen": isVisible !== null && isVisible !== void 0 ? isVisible : isOpen, "$top": top, "$left": left }, { children: [_jsxs(Styles.Title, { children: [t('Current balance'), ":"] }), _jsx(Styles.WrapperMessage, { children: _jsx(Styles.ContainerMessage, { children: _jsxs(Styles.Message, { children: [userBalance, " \u20BD"] }) }) }, userBalance.toString()), _jsxs(Styles.Title, { children: [t('Current tariff'), ":"] }), items === null || items === void 0 ? void 0 : items.map((item) => (_jsx(Styles.WrapperMessage, { children: _jsxs(Styles.ContainerMessage, { children: [_jsx(Styles.Message, { children: t(item.label) }), _jsx(Styles.Coast, { children: item.coast })] }) }, item.id.toString()))), _jsxs(Styles.ButtonWrapper, { children: [_jsx(Styles.Button, { variant: ButtonVariant.LIGHT, text: t('Пополнить'), onClick: () => {
                                        setIsRefillModalOpen(true);
                                    } }), _jsx(Styles.Button, { variant: ButtonVariant.LIGHT, text: t('Change the tariff'), onClick: () => {
                                        navigate(AppRoutes.Dashboard.Home +
                                            AppRoutes.Dashboard.Profile.Home +
                                            AppRoutes.Dashboard.Profile.Tariff);
                                    } }), _jsx(Styles.Info, { children: _jsx(Styles.TextInfo, { children: t('More about tariffs') }) })] })] })) }), _jsx(RefillBalanceModal, { isOpen: isRefillModalOpen, closeModal: () => setIsRefillModalOpen(false) })] })));
};
