import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Formik } from 'formik';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Button, InputField, Text, TextVariant } from '../../../../../common';
import { AdvInfoBlock } from './AdvInfo/AdvInfoBlock';
import { AdvMetaBlock } from './AdvMeta/AdvMetaBlock';
import { AdvPreview } from './AdvPreview/AdvPreview';
import { ChangeUrlModal } from './ChangeUrlModal';
import { addFormInitialValues, addFormValidationSchema, EAdvTarget, } from './config';
import { MetricsCounterBlock } from './MetricsCounterBlock';
import { SiteLinkBlock } from './SiteLinkBlock';
import * as Styles from './styles';
import { AdvWrapper } from './styles';
import { TargetActionsBlock } from './TargetActionsBlock';
import { WeekBudgetBlock } from './WeekBudgetBlock';
export const AdContext = createContext({ platform: '' });
export const YandexDirect = ({ platform }) => {
    const [campaignUrl, setCampaignUrl] = useState('');
    const [isNextStep, setIsNextStep] = useState(true);
    const [isChangeUrl, setIsChangeUrl] = useState(false);
    const containerRef = useRef(null);
    const changeInputRef = useRef(null);
    const dateNow = new Date();
    useEffect(() => {
        if (isChangeUrl) {
            setTimeout(() => {
                window.addEventListener('click', (ev) => {
                    if (!ev.composedPath().includes(changeInputRef.current)) {
                        setIsChangeUrl(false);
                    }
                }, { once: true });
            });
        }
    }, [isChangeUrl]);
    if (!isNextStep) {
        return (_jsx(Styles.Container, { children: _jsxs(Styles.EmptyPageContainer, { children: [_jsx(Styles.PageTitle, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: "\u041D\u0430\u0447\u043D\u0438\u0442\u0435 \u0441\u043E\u0437\u0434\u0430\u0432\u0430\u0442\u044C \u0440\u0435\u043A\u043B\u0430\u043C\u0443" })) }), _jsxs(Styles.EmptyPageContent, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'secondary' }, { children: "\u0412\u0441\u0442\u0430\u0432\u044C\u0442\u0435 \u0441\u0441\u044B\u043B\u043A\u0443 \u043D\u0430 \u0441\u0430\u0439\u0442, \u0441\u043E\u0446\u0441\u0435\u0442\u044C, \u043C\u0430\u0440\u043A\u0435\u0442\u043F\u043B\u0435\u0439\u0441 \u0438\u043B\u0438 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u0438 \u043C\u044B \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0438\u043C \u0432\u0430\u043C \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \u0438 \u043D\u0430\u0439\u0434\u0451\u043C \u043F\u043E\u0434\u0445\u043E\u0434\u044F\u0449\u0443\u044E \u0430\u0443\u0434\u0438\u0442\u043E\u0440\u0438\u044E" })), _jsxs(Styles.EmptyPageActions, { children: [_jsx(Styles.UrlInput, { placeholder: 'https://', value: campaignUrl, onChange: (e) => setCampaignUrl(e.target.value) }), _jsx(Styles.UrlButton, { onClick: () => {
                                            setIsChangeUrl(false);
                                            setIsNextStep(true);
                                        }, text: campaignUrl ? 'Далее' : 'У меня нет сайта', variant: campaignUrl ? 'PRIMARY' : 'SECONDARY' })] })] })] }) }));
    }
    return (_jsxs(Styles.Container, Object.assign({ "$isScrollDisabled": isChangeUrl, ref: containerRef }, { children: [_jsxs(Styles.Header, { children: [platform === 'Direct' && (_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: "\u0414\u0438\u0440\u0435\u043A\u0442" }))), platform === 'Adwords' && (_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: "\u0410\u0434\u0432\u043E\u0440\u0434\u0441" })))] }), _jsx(Formik, Object.assign({ onSubmit: () => { }, validateOnChange: true, initialValues: addFormInitialValues, validationSchema: addFormValidationSchema }, { children: ({ values, setFieldValue }) => {
                    useEffect(() => {
                        if (!values.mainUrl && campaignUrl) {
                            setFieldValue('mainUrl', campaignUrl);
                        }
                        if (!values.campaignName && values.mainUrl.length) {
                            setFieldValue('campaignName', values.mainUrl +
                                ' от ' +
                                dateNow.toLocaleString('ru', {
                                    dateStyle: 'short',
                                }));
                        }
                    }, []);
                    return (_jsxs(Styles.Content, { children: [_jsx(InputField, { name: 'campaignName', placeholder: 'Укажите название кампании' }), _jsxs(AdContext.Provider, Object.assign({ value: { platform: platform } }, { children: [isChangeUrl && (_jsx(ChangeUrlModal, { campaignUrl: campaignUrl, changeInputRef: changeInputRef, setCampaignUrl: setCampaignUrl, setIsChangeUrl: setIsChangeUrl })), _jsxs(Styles.AdvContent, { children: [_jsxs(AdvWrapper, { children: [_jsx(SiteLinkBlock, { campaignUrl: campaignUrl, containerRef: containerRef, setIsChangeUrl: setIsChangeUrl }), _jsx(AdvInfoBlock, {})] }), _jsx(AdvPreview, {})] }), _jsx(AdvMetaBlock, {}), values.advTarget === EAdvTarget.ACTIONS && (_jsxs(_Fragment, { children: [_jsx(MetricsCounterBlock, {}), _jsx(TargetActionsBlock, {})] })), _jsx(WeekBudgetBlock, {}), _jsx(Button, { text: 'Создать кампанию' }), _jsx(Button, { text: 'Сохранить как черновик', variant: 'TETRIERY' })] }))] }));
                } }))] })));
};
