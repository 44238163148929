import styled, { css } from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1yrlt2l" }) `
  height: 100%;

  ${({ img }) => img &&
    css `
      background-image: url(${img});
      background-size: cover;
      background-position: center center;
    `}
`;
export const ColorContainer = styled.div.withConfig({ displayName: "ColorContainer", componentId: "sc-1gi8fft" }) `
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  align-items: center;
  padding-bottom: 256px;
  flex-direction: column;

  background: ${({ theme }) => theme.config.form.backgroundImage};
  backdrop-filter: blur(4px);
`;
export const FormContainerWrapper = styled.div.withConfig({ displayName: "FormContainerWrapper", componentId: "sc-1rcywtu" }) `
  margin-top: 70px;
`;
export const ContentForm = styled.div.withConfig({ displayName: "ContentForm", componentId: "sc-1eo9r98" }) `
  ${({ theme }) => css `
      border-radius: 16px;
      border-left: ${theme.isDarkTheme ? '1px' : '4px'} solid
        ${theme.config.line.primary};
      box-shadow: ${theme.isDarkTheme
    ? ''
    : '0px 32px 48px -4px rgba(0, 0, 0, 0.2), 0px 0px 24px 4px rgba(0, 0, 0, 0.05)'};
      background: ${theme.config.form.background};
    `}
`;
export const ContainerHeader = styled.div.withConfig({ displayName: "ContainerHeader", componentId: "sc-1aa86ag" }) `
  width: 580px;
  height: 48px;

  padding-top: 8px;

  ${({ theme }) => theme.responsive.isMobile &&
    css `
      margin: 1rem;
      width: auto;
      max-width: 100%;
    `}
`;
