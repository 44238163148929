import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant } from 'common';
import { Icon } from 'common/icon';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { ReactMic as ReactMicRaw } from 'react-mic';
import styled, { css } from 'styled-components';
import { DeleteButton } from './delete-button';
import { Visualization } from './visualization';
const ReactMic = ReactMicRaw;
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-rm5j8g" }) `
  width: 100%;
`;
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-rw2dwo" }) `
  display: flex;
  align-items: center;

  gap: 16px;
`;
const WrapperMic = styled.div.withConfig({ displayName: "WrapperMic", componentId: "sc-l6rc9t" }) `
  display: none;
`;
const MicButton = styled(Button).withConfig({ displayName: "MicButton", componentId: "sc-1qvfzha" }) `
  width: 48px;
  height: 48px;

  padding: 12px;
  border-radius: 50%;
  margin-top: 2px;

  ${({ theme }) => css `
    border: ${theme.config.input.default.border.default};
    background: ${theme.config.uploadImage.bg.default};
    box-shadow: none;
  `}
  &:hover {
    background: none;
  }
`;
const MicActive = styled(Icon.Mic).withConfig({ displayName: "MicActive", componentId: "sc-qeokdd" }) `
  ${({ theme, $isActive }) => css `
    path,
    fill {
      fill: ${$isActive
    ? theme.config.voiceRecorder.color.mic.active
    : theme.config.voiceRecorder.color.mic.default} !important;
    }
  `}
`;
export const VoiceRecordingForm = ({ value, onChange, }) => {
    const [isRecording, setIsRecording] = useState(false);
    const { setFieldValue } = useFormikContext();
    const onStop = (recordedData) => {
        const { blob } = recordedData;
        setFieldValue('voiceRecord', blob);
        const url = URL.createObjectURL(blob);
        onChange(url);
    };
    const deleteAudio = () => {
        setFieldValue('voiceRecord', null);
        onChange(undefined);
    };
    return (_jsx(Container, { children: _jsxs(Wrapper, { children: [_jsx(WrapperMic, { children: _jsx(ReactMic, { onStop: onStop, record: isRecording, mimeType: "audio/wav", strokeColor: "transparent", backgroundColor: "transparent", visualSetting: "frequencyBars" }) }), _jsx(MicButton, { text: "", variant: ButtonVariant.CANCEL, icon: _jsx(MicActive, { "$isActive": isRecording }), onClick: () => setIsRecording(!isRecording) }), _jsx(Visualization, { audioUrl: value }), _jsx(DeleteButton, { onDelete: deleteAudio })] }) }));
};
