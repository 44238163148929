var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, Text } from 'common';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { useDeleteMeMutation } from 'store/api/profile';
import { logout } from 'store/thunks/auth/auth';
import styled from 'styled-components';
import { handleMutation } from 'utils/handleMutation';
import { clearToken } from 'utils/storage';
import { ModalMain } from '../main';
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-1j3z5ws" }) ``;
export const DeleteProfile = ({ isVisible, onClick }) => {
    const { t } = useTranslation();
    const alert = useAlert();
    const dispatch = useAppDispatch();
    const [deleteMe, mutationDelete] = useDeleteMeMutation();
    const handleLogout = () => {
        clearToken();
        dispatch(logout());
    };
    const onSuccess = useCallback(() => {
        handleLogout();
    }, [alert]);
    const onError = useCallback((error) => {
        var _a;
        alert.error(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationDelete), { onSuccess,
        onError }));
    const deleteAccount = () => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteMe();
    });
    return (_jsx(ModalMain, Object.assign({ title: "Deleting a profile", isVisible: isVisible, onClick: onClick, footer: _jsxs(_Fragment, { children: [_jsx(Button, { padding: "24px", onClick: deleteAccount, text: t('Delete Profile'), variant: ButtonVariant.PRIMARY }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, onClick: onClick })] }) }, { children: _jsx(Body, { children: _jsx(Text, { children: t('Are you sure you want to delete the profile? This action will delete all your data.') }) }) })));
};
