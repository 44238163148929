import styled, { keyframes } from 'styled-components';
const spinner = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1wny4jb" }) `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  gap: 20px;

  @media screen and (max-width: 765px) {
    text-align: center;
  }
`;
export const Spinner = styled.div.withConfig({ displayName: "Spinner", componentId: "sc-pmfxsb" }) `
  display: flex;
  position: relative;
  width: 2rem;
  height: 2rem;

  /* border: 1px solid red; */
`;
export const SpinnerElement = styled.div.withConfig({ displayName: "SpinnerElement", componentId: "sc-ge01zc" }) `
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.1rem solid transparent;
  border-bottom-color: currentColor;
  transform-origin: center;
  animation: 1s ${spinner} linear infinite;
`;
export const SpinnerInner = styled(SpinnerElement).withConfig({ displayName: "SpinnerInner", componentId: "sc-1hdmb1g" }) `
  position: absolute;
  top: 10%;
  left: 10%;
  width: 1.5rem;
  height: 1.5rem;
  animation-direction: reverse;
`;
