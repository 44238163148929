import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from 'react-router-dom';
import ProfilePage from './home';
import { PaymentPage } from './payment';
import { AppRoutes } from 'config';
import { scrollMedium } from 'styles/mixins/scroll';
import styled from 'styled-components';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-fhusy6" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
    padding: 40px 16px 0px;
  }
`;
const ScrollWrapper = styled.div.withConfig({ displayName: "ScrollWrapper", componentId: "sc-bbnfva" }) `
  width: 100%;
  padding: 40px;
  padding-right: 16px;
  padding-bottom: 10px;

  overflow-y: auto;
  max-height: 100vh;

  ${scrollMedium}
`;
export const ProfileContent = () => {
    const { Profile } = AppRoutes.Dashboard;
    return (_jsx(Container, { children: _jsx(ScrollWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/*', element: _jsx(ProfilePage, {}) }), _jsx(Route, { path: Profile.Tariff + Profile.Payment.Home + '/*', element: _jsx(PaymentPage, {}) })] }) }) }));
};
