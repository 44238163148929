// import { CreateCompanyContent } from './create-company/home';
import { CreateCompanyContent } from './company/create';
import { DatabaseContent } from './database';
import { MainContent } from './main';
import { MarketplacesContent } from './marketplaces';
import { MyCompanyContent } from './my-company';
import { DetailMyCompanyContent } from './my-company/detail-company';
import { ParsingContent } from './parsing';
import { ProfileContent } from './profile';
import { ReportsContent } from './reports';
export const PageContent = {
    MainContent,
    ProfileContent,
    ParsingContent,
    DatabaseContent,
    MyCompanyContent,
    CreateCompanyContent,
    DetailMyCompanyContent,
    MarketplacesContent,
    ReportsContent,
};
