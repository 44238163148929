import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { useFormikContext } from 'formik';
import React, { useCallback, useRef } from 'react';
import { useAlert } from 'react-alert';
import { phoneRegex, } from '../../../../../../pages/dashboard/content/voice-robot/mailing/config';
import { PhoneChip, PhoneChipButton, PhonesChips, } from '../manual-input/styles';
import { handleCSVFile, handleXLSXFile } from './fileReader';
import * as Styles from './styles';
export function isValidPhone(login) {
    return phoneRegex.test(login);
}
export const DatabaseNumberForms = () => {
    const alert = useAlert();
    const fileInputRef = useRef(null);
    const { setFieldValue, values } = useFormikContext();
    // const [infoModalVisible, setInfoModalVisible] = useState(false);
    // const handleInfoModalVisible = () => {
    //   setInfoModalVisible(!infoModalVisible);
    // };
    const handleClick = () => {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleFileUpload = useCallback((e) => {
        var _a;
        const file = e.target.files && e.target.files[0];
        if (file) {
            const fileType = (_a = file.name.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            const reader = new FileReader();
            if (fileType === 'csv') {
                reader.onload = (event) => {
                    var _a;
                    if ((_a = event.target) === null || _a === void 0 ? void 0 : _a.result) {
                        const content = new Uint8Array(event.target.result);
                        const parsedData = handleCSVFile(content, isValidPhone);
                        setFieldValue('dbPhones', parsedData.map((n) => n.value));
                        alert.success('Номера из базы прочитаны!');
                    }
                };
                reader.readAsArrayBuffer(file);
            }
            else if (fileType === 'xlsx') {
                reader.onload = (event) => {
                    var _a;
                    if ((_a = event.target) === null || _a === void 0 ? void 0 : _a.result) {
                        const data = new Uint8Array(event.target.result);
                        const parsedItems = handleXLSXFile(data, isValidPhone);
                        setFieldValue('dbPhones', parsedItems.map((n) => n.value));
                        alert.success('Номера из базы прочитаны!');
                    }
                };
                reader.readAsArrayBuffer(file);
            }
            else if (fileType === 'xml') {
                alert.error('XML parse not ready :(');
            }
        }
    }, []);
    return (_jsxs(Styles.Container, { children: [_jsx(Styles.HiddenInput, { ref: fileInputRef, type: "file", accept: ".csv,.xlsx,.xml", onChange: handleFileUpload }), _jsxs(Styles.WrapperForm, { children: [_jsx(Styles.DownloadButton, { text: 'Загрузить', 
                        // onClick={handleInfoModalVisible}
                        onClick: handleClick, variant: 'DARK' }), !!values.dbPhones.length && (_jsx(PhonesChips, { children: values.dbPhones.map((number) => (_jsxs(PhoneChip, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: number })), _jsx(PhoneChipButton, { text: "", onClick: () => {
                                        setFieldValue('dbPhones', values.dbPhones.filter((n) => n !== number));
                                    }, icon: _jsx(Icon.Close, {}), variant: ButtonVariant.TRANSPARENT })] }, number))) }))] })] }));
};
