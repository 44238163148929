import { t } from 'i18next';
import * as Yup from 'yup';
export var EActivityType;
(function (EActivityType) {
    EActivityType["PRODUCTS"] = "PRODUCTS";
    EActivityType["SERVICES"] = "SERVICES";
})(EActivityType || (EActivityType = {}));
export var ESocialNetwork;
(function (ESocialNetwork) {
    ESocialNetwork["TIKTOK"] = "TIKTOK";
    ESocialNetwork["FACEBOOK"] = "FACEBOOK";
    ESocialNetwork["INSTAGRAM"] = "INSTAGRAM";
    ESocialNetwork["TELEGRAM"] = "TELEGRAM";
    ESocialNetwork["VKONTAKTE"] = "VKONTAKTE";
})(ESocialNetwork || (ESocialNetwork = {}));
export const activityTypeItems = [
    {
        label: 'Products',
        value: EActivityType.PRODUCTS,
    },
    {
        label: 'Services',
        value: EActivityType.SERVICES,
    },
];
export const createCompanyModel = {
    inn: {
        label: '',
        withBorder: false,
        name: 'inn',
        type: 'number',
        containerStyle: { flex: 1 },
        placeholder: 'Specify your INN',
    },
    company_name: {
        label: '',
        withBorder: false,
        name: 'company_name',
        containerStyle: { flex: 1 },
        placeholder: 'Taxpayer identification number',
    },
    companyWebsites: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'companyWebsites',
        placeholder: 'www.example.net',
    },
    companyChecked: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'companyChecked',
    },
    facebook: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'facebook',
    },
    facebookField: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'facebookField',
        placeholder: 'www.example.net',
    },
    vk: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'vk',
    },
    vkField: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'vkField',
        placeholder: 'www.example.net',
    },
    instagram: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'instagram',
    },
    instagramField: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'instagramField',
        placeholder: 'www.example.net',
    },
    telegram: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'telegram',
    },
    telegramField: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'telegramField',
        placeholder: 'www.example.net',
    },
    tiktok: {
        label: '',
        type: 'checkbox',
        withBorder: false,
        name: 'tiktok',
    },
    tiktokField: {
        label: '',
        type: 'url',
        withBorder: false,
        name: 'tiktokField',
        placeholder: 'www.example.net',
    },
    products: {
        label: 'Products',
        type: 'checkbox',
        withBorder: false,
        name: 'products',
    },
    services: {
        label: 'Services',
        type: 'checkbox',
        withBorder: false,
        name: 'services',
    },
    city: {
        name: 'city',
        label: 'Select a city',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a city',
        options: ['Value1', 'Value2', 'Value3', 'Value4'].map((label) => ({
            label,
            value: label,
        })),
    },
    region: {
        name: 'region',
        label: 'Select a region',
        type: 'text',
        containerStyle: { flex: 1 },
        placeholder: 'Select a region',
        options: ['Value1', 'Value2', 'Value3', 'Value4'].map((label) => ({
            label,
            value: label,
        })),
    },
    activitySpheres: {
        name: 'activitySpheres',
        label: 'Select spheres',
        placeholder: 'Select spheres',
        options: [
            'Technology',
            'Healthcare',
            'Education',
            'Manufacturing',
            'Transportation',
        ].map((label) => ({
            label: t(label.toString()),
            value: label.toString(),
        })),
    },
    legalAddress: {
        type: 'text',
        withBorder: true,
        name: 'legalInformation.address',
        inputType: 'textarea',
        style: { minHeight: 152 },
        placeholder: 'Specify the address of the legal registration',
    },
    bankDetails: {
        type: 'text',
        withBorder: true,
        name: 'legalInformation.bankDetails',
        inputType: 'textarea',
        style: { minHeight: 152 },
        placeholder: 'Specify the address bank details of the company',
    },
    kpp: {
        type: 'text',
        name: 'kpp',
        placeholder: 'Specify your KPP',
    },
    ogrn: {
        type: 'text',
        name: 'ogrn',
        placeholder: 'Specify your OGRN',
    },
};
export const createCompanyValidationSchema = Yup.object().shape({
    inn: Yup.number()
        .required('Введите ИНН')
        .test('len', 'Введите корректный ИНН', (val) => val.toString().length === 10),
    facebookField: Yup.string().url('Invalid website URL'),
    instagramField: Yup.string().url('Invalid website URL'),
    telegramField: Yup.string().url('Invalid website URL'),
    tiktokField: Yup.string().url('Invalid website URL'),
    vkField: Yup.string().url('Invalid website URL'),
    companyWebsite: Yup.array().of(Yup.string().url('Invalid website URL')),
});
export const createCompanyInitialValues = {
    activitySpheres: [],
    activityTypes: [],
    companyWebsites: [
        {
            website: '',
            connected: false,
        },
    ],
    legalInformation: {
        address: '',
        bankDetails: '',
    },
    location: {
        country: {
            value: '',
            label: '',
        },
        region: {
            value: '',
            label: '',
        },
    },
    socialNetworks: [
        {
            network: ESocialNetwork.FACEBOOK,
            label: 'Фейсбук',
            connected: false,
            website: '',
        },
        {
            network: ESocialNetwork.VKONTAKTE,
            label: 'Вконтакте',
            connected: false,
            website: '',
        },
        {
            network: ESocialNetwork.TIKTOK,
            label: 'ТикТок',
            connected: false,
            website: '',
        },
        {
            network: ESocialNetwork.TELEGRAM,
            connected: false,
            label: 'Телеграм',
            website: '',
        },
        {
            network: ESocialNetwork.INSTAGRAM,
            connected: false,
            label: 'Инстаграм',
            website: '',
        },
    ],
    fullName: '',
    shortName: '',
    address: '',
    director: {
        name: '',
        position: '',
    },
    inn: '',
    kpp: '',
    ogrn: '',
};
// export const createCompanyInitialValues: ICreateCompanyForm = {
//   ogrn: '5137746103677',
//   kpp: '770301001',
//   inn: '7716760301',
//   director: {
//     position: 'Генеральный директор',
//     name: 'Керефов Тембот Артурович',
//   },
//   address:
//     'г. Москва, пр-д 1-Й Красногвардейский, д.22, стр.1, эт.13, пом.13-40',
//   fullName: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЯНДЕКС.ДОСТАВКА"',
//   shortName: 'ООО "ЯНДЕКС.ДОСТАВКА"',
//   activitySpheres: [],
//   activityTypes: [],
//   companyWebsites: [
//     {
//       website: '',
//       connected: false,
//     },
//   ],
//   legalInformation: {
//     address: '',
//     bankDetails: '',
//   },
//   location: {
//     country: {
//       value: '',
//       label: '',
//     },
//     region: {
//       label: '',
//       value: '',
//     },
//   },
//   socialNetworks: [
//     {
//       network: ESocialNetwork.FACEBOOK,
//       label: 'Фейсбук',
//       connected: false,
//       website: '',
//     },
//     {
//       network: ESocialNetwork.VKONTAKTE,
//       label: 'Вконтакте',
//       connected: false,
//       website: '',
//     },
//     {
//       network: ESocialNetwork.TIKTOK,
//       label: 'ТикТок',
//       connected: false,
//       website: '',
//     },
//     {
//       network: ESocialNetwork.TELEGRAM,
//       connected: false,
//       label: 'Телеграм',
//       website: '',
//     },
//     {
//       network: ESocialNetwork.INSTAGRAM,
//       connected: false,
//       label: 'Инстаграм',
//       website: '',
//     },
//   ],
// };
