import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React from 'react';
import { Input, Text, TextVariant } from '../../../../../../common';
import * as Styles from './styles';
export const ManualHolidaySchedule = () => {
    const { values, setFieldValue } = useFormikContext();
    return (_jsxs(Styles.ManualHolidayTime, { children: [_jsx(Input, { colorType: 'LIGHT', fullWidth: true, value: values.manualHolidaySchedule.from, onChange: (e) => {
                    const newValue = e.target.value;
                    if (newValue.length < values.manualHolidaySchedule.from.length) {
                        setFieldValue('manualHolidaySchedule.from', newValue);
                    }
                    else if (newValue.length > 5 || isNaN(Number(newValue.slice(-1)))) {
                        return;
                    }
                    if (newValue.length === 3 && newValue.at(-1) !== ':') {
                        setFieldValue('manualHolidaySchedule.from', `${newValue.slice(0, -1)}:${newValue.slice(-1)}`);
                    }
                    else if (newValue.length === 5) {
                        const splittedTime = newValue.split(':');
                        const shouldRoundUp = Math.round(parseInt(splittedTime[1]) / 60) === 1;
                        const hours = Number(splittedTime[0]) + Number(shouldRoundUp);
                        const correctHours = () => {
                            const temp = hours > 22 ? 23 : hours;
                            if (temp < 10) {
                                return '0' + temp;
                            }
                            else {
                                return temp;
                            }
                        };
                        setFieldValue('manualHolidaySchedule.from', `${correctHours()}:00`);
                    }
                    else {
                        setFieldValue('manualHolidaySchedule.from', newValue);
                    }
                } }), _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "---" })), _jsx(Input, { colorType: 'LIGHT', fullWidth: true, value: values.manualHolidaySchedule.to, onChange: (e) => {
                    const newValue = e.target.value;
                    if (newValue.length < values.manualHolidaySchedule.to.length) {
                        setFieldValue('manualHolidaySchedule.to', newValue);
                    }
                    else if (newValue.length > 5 || isNaN(Number(newValue.slice(-1)))) {
                        return;
                    }
                    if (newValue.length === 3 && newValue.at(-1) !== ':') {
                        setFieldValue('manualHolidaySchedule.to', `${newValue.slice(0, -1)}:${newValue.slice(-1)}`);
                    }
                    else if (newValue.length === 5) {
                        const splittedTime = newValue.split(':');
                        const shouldRoundUp = Math.round(parseInt(splittedTime[1]) / 60) === 1;
                        const hours = Number(splittedTime[0]) + Number(shouldRoundUp);
                        const correctHours = () => {
                            const fromHours = Number(values.manualHolidaySchedule.from.split(':')[0]);
                            let temp = 0;
                            if (hours < Number(fromHours)) {
                                temp = fromHours + 1;
                            }
                            else {
                                temp = hours > 23 ? 0 : hours;
                            }
                            if (temp < 10) {
                                return '0' + temp;
                            }
                            else {
                                return temp;
                            }
                        };
                        setFieldValue('manualHolidaySchedule.to', `${correctHours()}:00`);
                    }
                    else {
                        setFieldValue('manualHolidaySchedule.to', newValue);
                    }
                } })] }));
};
