import styled from 'styled-components';
export const DropdownContainer = styled.div.withConfig({ displayName: "DropdownContainer", componentId: "sc-rfybc2" }) `
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;
export const DropdownWrapper = styled.div.withConfig({ displayName: "DropdownWrapper", componentId: "sc-vqtx4w" }) `
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  height: fit-content;
  min-height: 200px;
  max-height: 400px;

  background-color: ${({ theme }) => theme.config.background.secondary};
  box-shadow: 0 0 1px 1px ${({ theme }) => theme.config.background.secondary};
  border-radius: 10px;
  z-index: 10000;
`;
export const DropdownItem = styled.div.withConfig({ displayName: "DropdownItem", componentId: "sc-7ospil" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
`;
export const DropdownItemContent = styled.div.withConfig({ displayName: "DropdownItemContent", componentId: "sc-fn9qat" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
`;
export const ChevronContainer = styled.div.withConfig({ displayName: "ChevronContainer", componentId: "sc-fvf6gz" }) `
  width: 24px;
  height: 24px;
`;
export const ChipsContainer = styled.div.withConfig({ displayName: "ChipsContainer", componentId: "sc-e5lg33" }) `
  display: flex;
  align-items: flex-end;
  gap: 8px;

  flex-wrap: wrap;
`;
