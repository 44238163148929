import styled, { css } from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ru29wq" }) `
  display: flex;
  padding: 40px;
  width: 80%;
  overflow-y: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-1y89ti" }) `
  padding: 10px 10px 10px 15px;
  border-radius: 10px;
  background: ${({ theme }) => theme.config.background.tetriery};
`;
export const PostTextContainer = styled.div.withConfig({ displayName: "PostTextContainer", componentId: "sc-n9bbgu" }) `
  margin-top: 20px;
`;
export const Close = styled.div.withConfig({ displayName: "Close", componentId: "sc-1oj2047" }) `
  position: absolute;
  top: 10%;
  right: 5%;
  padding: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.modal.bg.default};
  cursor: pointer;
  transition: background 0.3s ease-in;

  &:hover {
    background: ${({ theme }) => theme.config.modal.bg.hover};
  }
`;
export const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "sc-92w36l" }) `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
export const ModalContainer = styled.div.withConfig({ displayName: "ModalContainer", componentId: "sc-b13fdw" }) `
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  overflow-y: auto;
  ${scrollMedium};

  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in, backdrop-filter 0.1s ease-in,
    visibility 0.4s ease-in;
  backdrop-filter: blur(10px);
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-cn3m1j" }) `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  max-height: fit-content;

  ${({ $isVisible }) => !$isVisible &&
    css `
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
      max-height: 15rem;
    `}
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-ju4qy" }) `
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  justify-content: space-between;
`;
export const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-19c098z" }) `
  margin-top: 24px;
`;
export const WrapperContent = styled.div.withConfig({ displayName: "WrapperContent", componentId: "sc-ya5wyt" }) `
  padding: 20px;
`;
