import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon as IconComponent } from 'common/icon';
import { positions, Provider as AlertProviderRaw, transitions, } from 'react-alert';
import { useTheme } from 'styled-components';
import IconWrapper from '../../common/icon-wrapper/icon-wrapper';
import { Icon } from './config';
import { AlertContainer, CloseButton, ContentContainer, IconContainer, TextContainer, } from './styles';
const alertOptions = {
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
    position: positions.TOP_LEFT,
};
const AlertProvider = AlertProviderRaw;
const Alert = ({ close, message, options, }) => {
    const { type } = options;
    const theme = useTheme();
    return (_jsxs(AlertContainer, Object.assign({ variant: type }, { children: [_jsxs(ContentContainer, { children: [type && _jsx(IconContainer, { children: Icon[type] }), _jsx(CloseButton, Object.assign({ type: "button", onClick: close }, { children: _jsx(IconWrapper, Object.assign({ "$fillColor": theme.config.alert.close }, { children: _jsx(IconComponent.Close, {}) })) }))] }), _jsx(TextContainer, { children: _jsx("div", { children: message }) })] })));
};
export const Provider = ({ children }) => (_jsx(AlertProvider, Object.assign({ template: Alert }, alertOptions, { children: children })));
export { Alert };
