import styled from 'styled-components';
import { Button as ButtonComponent } from '../../../common';
import { smallSemiBold } from '../../../styles/mixins/fonts';
export const DialogHeader = styled.div.withConfig({ displayName: "DialogHeader", componentId: "sc-1j5me9v" }) `
  width: 100%;
  height: fit-content;
`;
export const DialogContent = styled.div.withConfig({ displayName: "DialogContent", componentId: "sc-1mlrbem" }) `
  display: flex;
  flex-direction: column;
`;
export const ButtonWrapper = styled.div.withConfig({ displayName: "ButtonWrapper", componentId: "sc-udgvau" }) `
  margin: 16px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-1roy97w" }) `
  ${smallSemiBold}
  width: 260px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.01em;
`;
export const InputsWrapper = styled.div.withConfig({ displayName: "InputsWrapper", componentId: "sc-ww20cx" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 300px;
  flex-grow: 1;
  height: 100%;
  margin-top: 30px;
`;
