import styled, { css, keyframes } from 'styled-components';
const wipeInDown = keyframes `
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
`;
const wipeInUp = keyframes `
  from {
      clip-path: inset(100% 0 0 0);
  }
  to {
      clip-path: inset(0 0 0 0);
  }
`;
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-ao0ud6" }) `
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 20px;
  width: 70%;
  background-color: ${({ theme }) => theme.config.background.tetriery};
  border-radius: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  padding: 3px;
  align-items: center;
`;
export const IconWrapper = styled.img.withConfig({ displayName: "IconWrapper", componentId: "sc-i3b0ar" }) `
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
export const RoleNameWrapper = styled.div.withConfig({ displayName: "RoleNameWrapper", componentId: "sc-86pst7" }) ``;
export const ChevronWrapper = styled.div.withConfig({ displayName: "ChevronWrapper", componentId: "sc-iz71c0" }) ``;
export const AllRolesWrapper = styled.div.withConfig({ displayName: "AllRolesWrapper", componentId: "sc-1wdr99f" }) `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  //display: ${(props) => (props.$isVisible ? 'flex' : 'none')};
  //animation: ${wipeInDown} 1.5s cubic-bezier(0.25, 1, 0.3, 1) both;

  ${({ $isVisible }) => $isVisible
    ? css `
          display: flex;
          animation: ${wipeInDown} 1.5s cubic-bezier(0.25, 1, 0.3, 1) both;
        `
    : css `
          display: none; /* Прячем элемент после завершения анимации */
          animation: ${wipeInUp} 1.5s cubic-bezier(0.25, 1, 0.3, 1) both;
        `}
`;
export const RoleWrapper = styled.div.withConfig({ displayName: "RoleWrapper", componentId: "sc-davlwz" }) `
  position: relative; /* Убедитесь, что позиционирование относительно родителя */
  cursor: pointer;
  border-radius: 10px;
  width: 30%;
  margin: 10px;
  padding: 10px;
  background-color: ${({ theme }) => theme.config.background.tetriery};
  border: ${({ theme }) => theme.config.input.default.border.default};
  ${({ $isSelected }) => $isSelected &&
    css `
      border: ${({ theme }) => theme.config.input.default.border.greenDashed};
    `}

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  backdrop-filter: blur(113px);
  box-shadow: inset 2px 0 4px 0 hsla(0, 0%, 100%, 0.15),
    inset 0 0 68px 0 hsla(0, 0%, 100%, 0.05);
  background: hsla(0, 0%, 100%, 0.03);

  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: var(--y, 50%);
    left: var(--x, 50%);
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    filter: blur(10rem);
    border-radius: 50%;
    background: radial-gradient(
      circle,
      rgba(7, 212, 29, 0.8) 50%,
      rgba(0, 255, 38, 0.4) 80%
    );
    opacity: var(--opacity, 0);
    transition: opacity 0.3s ease, width 0.3s ease, height 0.3s ease;
    pointer-events: none;
    z-index: -1;
  }
  /* Non-prefixed version, currently
                               not supported by any browser */
`;
export const RoleBody = styled.div.withConfig({ displayName: "RoleBody", componentId: "sc-18l1v2d" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 30px;
`;
export const RoleIcon = styled.img.withConfig({ displayName: "RoleIcon", componentId: "sc-zt5fgc" }) `
  width: 70px !important;
  height: 70px !important;
  border-radius: 50% !important;
`;
export const RoleName = styled.div.withConfig({ displayName: "RoleName", componentId: "sc-1xr70iw" }) ``;
export const RoleDescriptionWrapper = styled.div.withConfig({ displayName: "RoleDescriptionWrapper", componentId: "sc-yp48d4" }) `
  margin-left: auto;
  margin-right: auto;
`;
