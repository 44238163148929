import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Text, TextVariant } from 'common';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AppRoutes } from '../../../../../config';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-xviq7w" }) `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Info = styled.div.withConfig({ displayName: "Info", componentId: "sc-1qnq2ro" }) `
  display: flex;
  align-items: center;
`;
// export const WrapperNumber = styled.div`
//   display: flex;
//   height: 100%;
//   align-items: center;
//   margin-left: 57px;
//
//   gap: 16px;
// `;
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//
//   gap: 8px;
//
//   &:nth-child(2) {
//     padding-left: 16px;
//     border-left: 1px solid ${({ theme }) => theme.config.line.secondary};
//   }
// `;
export const HeaderCompany = () => {
    const { t } = useTranslation();
    const { Home, CreateCompany } = AppRoutes.Dashboard;
    return (_jsxs(Container, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('My Companies') })), _jsx(Info, { children: _jsx(Button, { padding: "24px", text: t('Create a company'), to: Home + CreateCompany }) })] }));
};
