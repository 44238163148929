import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, TextVariant } from 'common';
import { AppRoutes } from 'config';
import { useTranslation } from 'react-i18next';
import { TabsFormsVkParser } from '../header-tabs';
import * as Styles from './styles';
export const VkontakteHomePage = ({ children }) => {
    const { t } = useTranslation();
    const { Home, Parsing } = AppRoutes.Dashboard;
    return (_jsx(Styles.Container, { children: _jsx(Styles.ScrollContent, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Styles.TextsContainer, { children: _jsx(Styles.Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('VKontakte Parser') })) }), _jsx(Styles.WrapperHeader, { children: _jsx(Styles.Header, { children: _jsx(TabsFormsVkParser, {}) }) }), children, _jsx(Styles.FooterButton, { children: _jsx(Button, { text: t('Cancel'), to: Home + Parsing.Home, variant: ButtonVariant.TETRIERY }) })] }) }) }));
};
