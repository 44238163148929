import { Button as ButtonComponent } from 'common';
import styled, { css } from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1gajc57" }) `
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 20px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 32px;

  transition: 0.3s ease-out;

  ${({ theme, selected }) => css `
    border-color: ${selected
    ? theme.colors.blue.primary
    : theme.colors.black.primary};
    background: ${theme.config.tags.background.default};
    color: ${theme.config.tags.text};
  `}
  &:hover {
    transition: 0.3s ease-out;
    transform: scale(105%, 105%);
  }
`;
export const Button = styled(ButtonComponent).withConfig({ displayName: "Button", componentId: "sc-1e1uu8y" }) `
  width: 16px;
  height: 16px;
  margin-left: 8px;
  padding: 9px;
`;
