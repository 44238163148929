import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, TextVariant } from 'common';
import { Icon as IconComponent } from 'common/icon';
import { SideLink } from 'common/side-link';
import { AppRoutes } from 'config';
import { linksVoiceRobot } from 'pages/dashboard/links';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as LinkComponent } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { smallMedium } from 'styles/mixins/fonts';
import IconWrapper from '../../../../common/icon-wrapper/icon-wrapper';
const BackMain = styled.div.withConfig({ displayName: "BackMain", componentId: "sc-32ssck" }) `
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  padding: 12px 20px 12px 13px;
  cursor: pointer;
  margin-top: 10px;
`;
const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-1t0ckhv" }) `
  margin-top: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.config.line.secondary};
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1icpedj" }) `
  margin-top: 16px;
  padding: 12px 0px;
  opacity: 0.75;
`;
const Link = styled(LinkComponent).withConfig({ displayName: "Link", componentId: "sc-1hsrggt" }) `
  ${smallMedium};

  flex: none;
  order: 1;
  flex-grow: 1;
  letter-spacing: -0.01em;

  ${({ theme }) => css `
    color: ${theme.config.sideLink.text.default};

    &:is(:hover, :focus) {
      text-decoration: none;
      color: ${theme.config.sideLink.text.hover};
    }
  `} @media screen and (
  max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 500px) {
    display: flex;
  }
`;
export const VoiceRobotAside = () => {
    const { t } = useTranslation();
    const { Main, Home } = AppRoutes.Dashboard;
    return (_jsxs(_Fragment, { children: [_jsx(Link, Object.assign({ to: Home + Main }, { children: _jsxs(BackMain, { children: [_jsx(IconWrapper, Object.assign({ "$fillColor": "none", "$sidenav": 'sidemenu' }, { children: _jsx(IconComponent.Back, {}) })), t('Main page')] }) })), _jsx(Line, {}), _jsx(Title, { children: _jsx(Text, Object.assign({ "$colorVariant": "secondary", "$variant": TextVariant.smallSemiBold }, { children: t('Voice Robot') })) }), linksVoiceRobot.map((link) => (_jsxs("div", { children: [_jsx(SideLink, { id: link.id, icon: link.icon, path: link.path, label: link.label, iconStyle: link.iconStyle }, link.id.toString()), link.id === 0 && _jsx(Line, {})] }, link.id)))] }));
};
