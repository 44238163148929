import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import AdvMockImage from '../../../../../../assets/images/AdvMockImage.png';
import { Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { More, Right } from '../../../../../../common/icon/icons';
import { PreviewContext } from './AdvPreview';
import * as Styles from './styles';
export const FirstVariant = () => {
    const contextValues = useContext(PreviewContext);
    const { values } = useFormikContext();
    return (_jsxs(Styles.FirstVariantContainer, { children: [_jsxs(Styles.FirstVariantImageContainer, { children: [_jsx(Styles.FirstVariantImage, { src: AdvMockImage }), _jsxs(Styles.FirstVariantMeta, { children: [_jsx(Styles.FirstVariantAdChip, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: "\u0420\u0435\u043A\u043B\u0430\u043C\u0430" })) }), _jsx(Styles.FirstVariantMoreInfoChip, { children: _jsx(IconWrapper, Object.assign({ "$strokeColor": 'white' }, { children: _jsx(More, {}) })) })] })] }), _jsxs(Styles.FirstVariantContent, { children: [!!(contextValues === null || contextValues === void 0 ? void 0 : contextValues.url.length) && (_jsxs(Styles.FirstVariantLinkContainer, { children: [_jsx(Styles.FirstVariantLinkFavicon, { src: contextValues === null || contextValues === void 0 ? void 0 : contextValues.faviconSrc }), _jsx(Text, Object.assign({ "$variant": TextVariant.smallLight }, { children: contextValues.url }))] })), _jsx(Styles.FirstVariantTitle, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: contextValues === null || contextValues === void 0 ? void 0 : contextValues.header })) }), _jsx(Styles.FirstVariantDescription, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: contextValues === null || contextValues === void 0 ? void 0 : contextValues.text })) }), values.links.find((link) => link.link.length && link.title.length) && (_jsx(Styles.FirstVariantSublinksContainer, { children: values.links.map((link, index) => {
                            return (_jsxs(_Fragment, { children: [_jsxs(Styles.FirstVariantSublink, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: link.title })), _jsx(IconWrapper, { children: _jsx(Right, {}) })] }), index !== values.links.length - 1 && (_jsx(Styles.FirstVariantSublinkSeparator, {}))] }));
                        }) })), _jsx(Styles.FirstVariantShowMoreButton, { text: 'Узнать больше', variant: 'TETRIERY' })] })] }));
};
