import styled from 'styled-components';
import { Send, User } from '../../../../../common/icon/icons';
import { Image as ImageComponent } from '../../../../../common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1e2yaj" }) `
  display: flex;
  overflow: auto;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const Chat = styled.div.withConfig({ displayName: "Chat", componentId: "sc-1gm5fnl" }) `
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-width: 300px;
  width: 100%;
  max-width: 1000px;
  height: 100%;
`;
export const ChatDialog = styled.div.withConfig({ displayName: "ChatDialog", componentId: "sc-1l0j8t2" }) `
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
  gap: 5px;
  margin-bottom: 70px;
  padding-bottom: 30px;
  padding-top: 20px;
  box-sizing: border-box;
`;
export const Message = styled.div.withConfig({ displayName: "Message", componentId: "sc-gk1np4" }) `
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  background: ${(props) => props.isChat ? 'rgba(140,135,135,0.24)' : 'transparent'};
  //  props.isChat ? 'rgba(0, 255, 255, 0.24)' : 'transparent'};
`;
export const MessageMeta = styled.div.withConfig({ displayName: "MessageMeta", componentId: "sc-1cc19cs" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
`;
export const MessageText = styled.div.withConfig({ displayName: "MessageText", componentId: "sc-1dojayl" }) `
  width: calc(100% - 40px);
`;
export const ChatInput = styled.div.withConfig({ displayName: "ChatInput", componentId: "sc-1de953a" }) `
  height: fit-content;
  min-width: 350px;
  width: 100%;
  max-width: 1000px;
  background: ${({ theme }) => theme.config.background.secondary};
  position: absolute;
  bottom: 0;
  padding: 15px 10px;
`;
export const AIIconWrapper = styled.div.withConfig({ displayName: "AIIconWrapper", componentId: "sc-lmvf94" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-top: -3px;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  :hover {
    background: ${(props) => props.disabled ? 'inherit' : 'rgba(255, 255, 255, 0.05)'};
  }
`;
export const UserIcon = styled(User).withConfig({ displayName: "UserIcon", componentId: "sc-1fx7f5h" }) `
  stroke: white;
  fill: none;
`;
export const SendIcon = styled(Send).withConfig({ displayName: "SendIcon", componentId: "sc-1m3lbtt" }) `
  fill: aliceblue;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
export const ClearHistory = styled.div.withConfig({ displayName: "ClearHistory", componentId: "sc-13jctk0" }) `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  width: fit-content;
  left: 40%;
  top: -25px;
  height: 30px;
  background: ${({ theme }) => theme.config.background.secondary};
  border: 1px solid rgba(255, 255, 255, 0.11);
  padding: 10px 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.config.background.tetriery};
    border-color: rgba(255, 255, 255, 0.2);
  }

  @media screen and (max-width: 500px) {
    left: 10px;

    > div {
      display: none;
    }
  }
`;
export const AITypingMessage = styled.div.withConfig({ displayName: "AITypingMessage", componentId: "sc-1sgkvpa" }) `
  position: absolute;
  width: fit-content;
  top: -10px;
  left: 30px;
`;
export const EmptyDialog = styled.div.withConfig({ displayName: "EmptyDialog", componentId: "sc-e1i8a" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
  height: 100%;
`;
export const Caret = styled.div.withConfig({ displayName: "Caret", componentId: "sc-1q948ny" }) `
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  display: inline;
  padding: 0 3px;
  background: white;
  width: 10px;
  height: 20px;
  color: white;
  animation: blink 1s infinite ease-in-out;
`;
export const Image = styled(ImageComponent).withConfig({ displayName: "Image", componentId: "sc-ynb76z" }) `
  cursor: default;
  object-fit: contain;
  border-radius: 50%;
`;
