var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Select, } from '../../../../../common/select';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-e9d334" }) `
  display: flex;

  ${({ $isColumn }) => $isColumn &&
    css `
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      flex-direction: column;
    `}
`;
export const SelectRecipients = (_a) => {
    var { items, label, onClose, onChange, components, isColumn = false } = _a, props = __rest(_a, ["items", "label", "onClose", "onChange", "components", "isColumn"]);
    const { t } = useTranslation();
    return (_jsx(Container, Object.assign({ "$isColumn": isColumn }, { children: _jsx(Select, Object.assign({ isMulti: true, isSearchable: true, value: items, defaultControl: true, noOptionsMessage: (e) => (e.inputValue ? t('No options') : null), components: {
                ClearIndicator: () => _jsx(_Fragment, {}),
                MultiValue: () => _jsx(_Fragment, {}),
            }, label: (label === null || label === void 0 ? void 0 : label.length) && (items === null || items === void 0 ? void 0 : items.length) ? `${label} (${items === null || items === void 0 ? void 0 : items.length})` : label, onChange: (option, action) => {
                if (onChange) {
                    onChange(option, action);
                }
            } }, props)) })));
};
