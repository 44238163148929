import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ResultPage } from 'components/parser-results/result-page';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '../../../../common';
import { useState } from 'react';
import { MembersDialog } from './membersDialog';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-165lyg7" }) `
  width: 80%;
  display: flex;
  align-items: center;
`;
export const VkGroupsResult = ({ data }) => {
    const { t } = useTranslation();
    const parsedData = data === null || data === void 0 ? void 0 : data.parser_data;
    const [selectedGroup, setSelectedGroup] = useState();
    const NO_INFO_STRING = 'Нет данных';
    const [isMoreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
    const handleOpenInfoModal = () => {
        setMoreInfoModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ResultPage, { filterField: "group_screen_name", title: (data === null || data === void 0 ? void 0 : data.parser_type) || 'VK Groups', dataHeader: [
                    t('Group name'),
                    t('Description'),
                    t('Articles number'),
                    t('Videos number'),
                    t('Country'),
                    t('Members count'),
                    parsedData[0].members_ids !== 'Элемент отсутствует'
                        ? 'Участники'
                        : '',
                ], data: parsedData, 
                // @ts-ignore
                dataRows: (row, index) => {
                    return [
                        _jsx(Link, Object.assign({ to: `https://vk.com/public${row.id}`, target: "_blank" }, { children: row.group_screen_name })),
                        row.group_description || NO_INFO_STRING,
                        row.group_articles_number
                            ? Number.isInteger(row.group_articles_number)
                                ? row.group_articles_number
                                : NO_INFO_STRING
                            : NO_INFO_STRING,
                        row.group_videos_number
                            ? Number.isInteger(row.group_videos_number)
                                ? row.group_videos_number
                                : NO_INFO_STRING
                            : NO_INFO_STRING,
                        row.group_country,
                        row.members_count,
                        parsedData[0].members_ids !== 'Элемент отсутствует' ? (_jsx(ButtonContainer, { children: _jsx(Button, { text: 'Просмотр', variant: ButtonVariant.LIGHT, onClick: () => {
                                    handleOpenInfoModal();
                                    setSelectedGroup(parsedData[index]);
                                    console.log(parsedData[index]);
                                } }, index) })) : null,
                    ];
                } }), _jsx(MembersDialog, { postName: selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.group_screen_name, isModalOpen: isMoreInfoModalOpen, setModalOpen: setMoreInfoModalOpen, membersData: selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.members_ids.split(',') })] }));
};
