import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputField, Text, TextVariant } from 'common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createCompanyModel } from '../../../company/create/config';
// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//
//   gap: 24px;
// `;
//
// const WrapperLegal = styled.div`
//   display: flex;
//   flex-direction: column;
//
//   gap: 12px;
// `;
//
// const WrapperBank = styled.div`
//   display: flex;
//   flex-direction: column;
//
//   gap: 8px;
// `;
//
// const WrapperField = styled.div`
//   display: flex;
//   flex-direction: column;
//
//   gap: 12px;
// `;
const InputsContainer = styled.div.withConfig({ displayName: "InputsContainer", componentId: "sc-f0x827" }) `
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
`;
const InputBlockContainer = styled.div.withConfig({ displayName: "InputBlockContainer", componentId: "sc-v4ootl" }) `
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 3px;
`;
export const LegalDataPage = () => {
    const { t } = useTranslation();
    return (_jsxs(InputsContainer, { children: [_jsxs(InputBlockContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "\u0418\u041D\u041D" })), _jsx(InputField, Object.assign({}, createCompanyModel.inn, { disabled: true }))] }), _jsxs(InputBlockContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: t('KPP') })), _jsx(InputField, Object.assign({}, createCompanyModel.kpp))] }), _jsxs(InputBlockContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "\u041E\u0413\u0420\u041D" })), _jsx(InputField, Object.assign({}, createCompanyModel.ogrn))] })] })
    // <Container>
    //   <WrapperLegal>
    //     <Text $variant={TextVariant.smallSemiBold}>
    //       {t('Legal address of the company')}
    //     </Text>
    //     <InputField {...model.legalAddressCompany} />
    //   </WrapperLegal>
    //
    //   <WrapperBank>
    //     <WrapperField>
    //       <Text $variant={TextVariant.smallSemiBold}>
    //         {t('Bank requisites')}
    //       </Text>
    //       <InputField {...model.bankRequisites} />
    //     </WrapperField>
    //
    //     <Text $colorVariant="tetriery" $variant={TextVariant.ultraSmallMedium}>
    //       {t(
    //         'The data is confidential and will be used to automate the processing of invoices and reports if necessary'
    //       )}
    //     </Text>
    //   </WrapperBank>
    // </Container>
    );
};
