import { InputSize } from 'common/input/types';
import * as Yup from 'yup';
export const model = {
    login: {
        label: '',
        type: 'text',
        name: 'login',
        withBorder: false,
        color: 'lightgreen',
        placeholder: 'Login',
        size: InputSize.SMALL,
    },
    lastName: {
        label: '',
        type: 'text',
        name: 'lastName',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Surname',
    },
    firstName: {
        label: '',
        type: 'text',
        name: 'firstName',
        withBorder: false,
        placeholder: 'Name',
        size: InputSize.SMALL,
    },
    phone: {
        label: '',
        name: 'phone',
        type: 'number',
        withBorder: false,
        placeholder: 'Phone',
        size: InputSize.SMALL,
    },
    email: {
        label: '',
        type: 'email',
        name: 'email',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'E-mail',
    },
    password: {
        label: '',
        type: 'password',
        name: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        placeholder: 'Password',
    },
    repeatPassword: {
        label: '',
        type: 'password',
        withBorder: false,
        size: InputSize.SMALL,
        name: 'repeatPassword',
        placeholder: 'Confirm Password',
    },
};
export const validationSchema = (t) => Yup.object().shape({
    [model.login.name]: Yup.string()
        .required(t('Login is required'))
        .matches(/^\w+$/, t('Invalid login')),
    [model.firstName.name]: Yup.string()
        .min(2, t('Invalid surname'))
        .required(t('Name is required'))
        .matches(/^[a-zA-Zа-яА-Я]+$/, t('Invalid name')),
    [model.lastName.name]: Yup.string()
        .min(2, t('Invalid surname'))
        .required(t('Surname is required'))
        .matches(/^[a-zA-Zа-яА-Я]+$/, t('Invalid surname')),
    [model.phone.name]: Yup.string()
        .required(t('Phone is required'))
        .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, t('Invalid phone format')),
    [model.email.name]: Yup.string()
        .required(t('Email is required'))
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, t('Invalid E-Mail format')),
    [model.password.name]: Yup.string()
        .required()
        .min(8)
        .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
        .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
        .matches(/^(?=.*\d)/, 'Password must contain at least one digit'),
    [model.repeatPassword.name]: Yup.string()
        .required(t('Password is required'))
        .test('passwords-match', t('Passwords do not match'), function (value) {
        return value === this.resolve(Yup.ref(model.password.name));
    }),
});
export const initialValues = {
    [model.login.name]: '',
    [model.password.name]: '',
    [model.repeatPassword.name]: '',
    [model.firstName.name]: '',
    [model.lastName.name]: '',
    [model.phone.name]: '',
    [model.email.name]: '',
};
