import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField, Text, TextVariant, } from '../../../../../../common';
import { Icon } from '../../../../../../common/icon';
import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { createCompanyModel } from '../../create/config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-25lih1" }) `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
`;
const WrapperFields = styled.div.withConfig({ displayName: "WrapperFields", componentId: "sc-1chugkq" }) `
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 1200px) {
    margin-top: 24px;
  }
`;
const FieldsWebsite = styled.div.withConfig({ displayName: "FieldsWebsite", componentId: "sc-1i2v4p9" }) `
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;
const ContainerWebsite = styled.div.withConfig({ displayName: "ContainerWebsite", componentId: "sc-3oibuc" }) `
  display: flex;
  align-items: center;
  width: fit-content;
  flex-direction: column;
  gap: 10px;
`;
const WrapperAddWebsite = styled.div.withConfig({ displayName: "WrapperAddWebsite", componentId: "sc-erpjyc" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
`;
const WrapperWebsite = styled.div.withConfig({ displayName: "WrapperWebsite", componentId: "sc-10m9iag" }) `
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 1900px) {
    align-items: center;
  }
`;
const WrapperCheck = styled.div.withConfig({ displayName: "WrapperCheck", componentId: "sc-ii06b6" }) `
  ${({ $isChecked }) => $isChecked &&
    css `
      opacity: 1;
    `}

  ${({ $isChecked }) => !$isChecked &&
    css `
      opacity: 0;
    `}

  transition: opacity 0.1s ease-out;
`;
const RemoveButton = styled.div.withConfig({ displayName: "RemoveButton", componentId: "sc-oi65g8" }) `
  cursor: pointer;
`;
export const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-1omevty" }) `
  margin: 12px 0px 24px;
`;
export const WebsitesCompanyForm = () => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext();
    return (_jsxs(Container, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Company websites') })), _jsx(Description, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallLight }, { children: t('Enter the URLs of the companys websites. They will be reflected in your Profile and for the operation of the portal services.') })) }), _jsx(WrapperFields, { children: _jsx(FieldArray, Object.assign({ name: 'companyWebsites' }, { children: ({ push, remove }) => {
                        var _a;
                        return (_jsx(FieldsWebsite, { children: (_a = values.companyWebsites) === null || _a === void 0 ? void 0 : _a.map((website, index) => (_jsxs(ContainerWebsite, { children: [_jsxs(WrapperWebsite, { children: [_jsx(InputField, Object.assign({ icon: _jsx(Icon.Web, {}) }, createCompanyModel.companyWebsites, { name: `companyWebsites.[${index}].website` })), _jsx(Button, { text: t('Save'), variant: ButtonVariant.TETRIERY, onClick: () => {
                                                    var _a;
                                                    return setFieldValue(`companyWebsites.[${index}].connected`, !((_a = values.companyWebsites.find((w) => w.website === website.website)) === null || _a === void 0 ? void 0 : _a.connected));
                                                } }), _jsx(WrapperCheck, Object.assign({ "$isChecked": website.connected }, { children: _jsx(Icon.CheckmarkDrop, {}) })), index !== 0 && (_jsx(RemoveButton, { children: _jsx(IconWrapper, { children: _jsx(Icon.Delete, { onClick: () => remove(index) }) }) }))] }), index === values.companyWebsites.length - 1 && (_jsx(WrapperAddWebsite, Object.assign({ onClick: () => push({
                                            website: '',
                                            connected: false,
                                        }) }, { children: _jsx(IconWrapper, { children: _jsx(Icon.AddFill, {}) }) })))] }, index))) }));
                    } })) })] }));
};
