import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useTheme } from 'styled-components';
import { Input, Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { Checkmark, Trash } from '../../../../../../common/icon/icons';
import { Dropdown } from '../../../../../../components';
import { adFormModel, intervalDaysItems } from '../config';
import * as BaseStyles from '../styles';
import * as Styles from './styles';
export const DisplayIntervals = () => {
    const { values, setFieldValue } = useFormikContext();
    const theme = useTheme();
    return (_jsx(Styles.DisplayIntervalsContainer, { children: _jsx(FieldArray, Object.assign({ name: 'displayIntervals' }, { children: ({ remove, push }) => (_jsxs(_Fragment, { children: [values.displayIntervals.map((interval, index) => (_jsxs(Styles.IntervalItem, { children: [_jsx(BaseStyles.DropdownWrapper, { children: _jsx(Dropdown, Object.assign({}, adFormModel.dropdown, { fullWidthItems: true, items: intervalDaysItems.map((day) => {
                                        const currentDay = interval.days.find((selectedDay) => selectedDay === day.short);
                                        return {
                                            label: day.long,
                                            onClick: () => setFieldValue(`displayIntervals.${index}.days]`, currentDay
                                                ? interval.days.filter((d) => d !== currentDay)
                                                : [...interval.days, day.short]),
                                            rightIcon: interval.days.includes(currentDay) && (_jsx(IconWrapper, Object.assign({ "$strokeColor": theme.config.text.primary }, { children: _jsx(Checkmark, {}) }))),
                                        };
                                    }), button: _jsx(BaseStyles.DropdownButton, { children: interval.days
                                            .sort((a, b) => intervalDaysItems.find((i) => a === i.short)
                                            .order -
                                            intervalDaysItems.find((i) => b === i.short)
                                                .order)
                                            .join(', ') || 'Выберете день' }) })) }), _jsxs(Styles.IntervalTimeContainer, { children: [_jsx(Input, { colorType: 'LIGHT', value: values.displayIntervals[index].from, onChange: (e) => {
                                            const newValue = e.target.value;
                                            if (newValue.length <
                                                values.displayIntervals[index].from.length) {
                                                setFieldValue(`displayIntervals.${index}.from`, newValue);
                                            }
                                            else if (newValue.length > 5 ||
                                                isNaN(Number(newValue.slice(-1)))) {
                                                return;
                                            }
                                            if (newValue.length === 3 && newValue.at(-1) !== ':') {
                                                setFieldValue(`displayIntervals.${index}.from`, `${newValue.slice(0, -1)}:${newValue.slice(-1)}`);
                                            }
                                            else if (newValue.length === 5) {
                                                const splittedTime = newValue.split(':');
                                                const shouldRoundUp = Math.round(parseInt(splittedTime[1]) / 60) === 1;
                                                const hours = Number(splittedTime[0]) + Number(shouldRoundUp);
                                                const correctHours = () => {
                                                    const temp = hours > 22 ? 23 : hours;
                                                    if (temp < 10) {
                                                        return '0' + temp;
                                                    }
                                                    else {
                                                        return temp;
                                                    }
                                                };
                                                setFieldValue(`displayIntervals.${index}.from`, `${correctHours()}:00`);
                                            }
                                            else {
                                                setFieldValue(`displayIntervals.${index}.from`, newValue);
                                            }
                                        } }), _jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular }, { children: "-" })), _jsx(Input, { colorType: 'LIGHT', value: values.displayIntervals[index].to, onChange: (e) => {
                                            const newValue = e.target.value;
                                            if (newValue.length <
                                                values.displayIntervals[index].to.length) {
                                                setFieldValue(`displayIntervals.${index}.to`, newValue);
                                            }
                                            else if (newValue.length > 5 ||
                                                isNaN(Number(newValue.slice(-1)))) {
                                                return;
                                            }
                                            if (newValue.length === 3 && newValue.at(-1) !== ':') {
                                                setFieldValue(`displayIntervals.${index}.to`, `${newValue.slice(0, -1)}:${newValue.slice(-1)}`);
                                            }
                                            else if (newValue.length === 5) {
                                                const splittedTime = newValue.split(':');
                                                const shouldRoundUp = Math.round(parseInt(splittedTime[1]) / 60) === 1;
                                                const hours = Number(splittedTime[0]) + Number(shouldRoundUp);
                                                const correctHours = () => {
                                                    const fromHours = Number(values.displayIntervals[index].from.split(':')[0]);
                                                    let temp = 0;
                                                    if (hours < Number(fromHours)) {
                                                        temp = fromHours + 1;
                                                    }
                                                    else {
                                                        temp = hours > 23 ? 0 : hours;
                                                    }
                                                    if (temp < 10) {
                                                        return '0' + temp;
                                                    }
                                                    else {
                                                        return temp;
                                                    }
                                                };
                                                setFieldValue(`displayIntervals.${index}.to`, `${correctHours()}:00`);
                                            }
                                            else {
                                                setFieldValue(`displayIntervals.${index}.to`, newValue);
                                            }
                                        } })] }), _jsx(IconWrapper, Object.assign({ "$strokeColor": 'red', "$fillColor": 'transparent', "$isCursor": true, onClick: () => remove(index) }, { children: _jsx(Trash, {}) }))] }, index))), _jsx(Styles.AddIntervalButton, Object.assign({ onClick: () => push({
                            days: [],
                            from: '00:00',
                            to: '24:00',
                        }) }, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.bodySemiBold }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" })) }))] })) })) }));
};
