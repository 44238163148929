import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';
const TABS_MODAL = [
    {
        value: 1,
        label: 'Manual input',
    },
    {
        value: 2,
        label: 'Database numbers',
    },
];
export const TabsFormsNumberCreateMailing = ({ step, onStepChange, }) => {
    const { t } = useTranslation();
    const isActive = useMemo(() => {
        return (TABS_MODAL === null || TABS_MODAL === void 0 ? void 0 : TABS_MODAL.filter((item) => item.value === step).length) > 0; // Check if the current step matches any tab's value
    }, [step]);
    return (_jsx(Styles.Container, { children: _jsxs(Styles.TabsHeader, { children: [TABS_MODAL === null || TABS_MODAL === void 0 ? void 0 : TABS_MODAL.map((item) => (_jsx(Styles.TabLink, Object.assign({ id: item.value.toString(), "$isActive": isActive, onClick: () => {
                        onStepChange(item.value);
                    } }, { children: _jsx(Styles.Label, Object.assign({ "$isActive": isActive }, { children: t(item.label) })) }), item.value.toString()))), _jsx(Styles.TabBackground, { "$step": step })] }) }));
};
