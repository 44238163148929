import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant, Text, TextVariant } from 'common';
import { Button } from 'common/button';
import { Icon } from 'common/icon';
import { useTranslation } from 'react-i18next';
import TimePicker from 'react-time-picker';
import styled from 'styled-components';
import IconWrapper from '../icon-wrapper/icon-wrapper';
import 'react-time-picker/dist/TimePicker.css';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1m4asu5" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
  margin: 16px;
`;
export const WrapperMain = styled.div.withConfig({ displayName: "WrapperMain", componentId: "sc-w2d6u5" }) `
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 20px;
`;
export const HourColumn = styled.div.withConfig({ displayName: "HourColumn", componentId: "sc-1dghctu" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const MinuteColumn = styled.div.withConfig({ displayName: "MinuteColumn", componentId: "sc-1vlo6c3" }) `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledTimePickerWrapper = styled.div.withConfig({ displayName: "StyledTimePickerWrapper", componentId: "sc-1t6eqkd" }) `
  display: inline-block;
  align-items: center;
  justify-content: center;

  .react-time-picker__button {
    background-color: #fff;
    color: #333;
    border: 1px solid red;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
    border-radius: 26px;
    /* border: 1px solid red; */
    background: ${({ theme }) => theme.config.time.background.default};
  }

  .react-time-picker__inputGroup {
    min-width: 0;
    flex-grow: 1;
    padding: 14px 31px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
  }

  .react-time-picker__inputGroup__input {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.54em;
    text-align: center;
    width: 100%;
    height: 100%;
    background: none;
    color: currentColor;
    font: inherit;
    border: 1px solid transparent;
    border-bottom: none;
  }

  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
`;
const WrapperArrowUp = styled.div.withConfig({ displayName: "WrapperArrowUp", componentId: "sc-1ttz7ou" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  margin-bottom: 8px;
  cursor: pointer;
`;
const WrapperArrowDown = styled.div.withConfig({ displayName: "WrapperArrowDown", componentId: "sc-tknukf" }) `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  cursor: pointer;
`;
const WrapperHeader = styled.div.withConfig({ displayName: "WrapperHeader", componentId: "sc-1srkzli" }) `
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
const ButtonRandom = styled(Button).withConfig({ displayName: "ButtonRandom", componentId: "sc-npz9q5" }) `
  color: ${({ theme }) => theme.config.text.secondary};
  border: ${({ theme }) => theme.config.dropdown.border.line};

  &:hover {
    color: ${({ theme }) => theme.config.text.secondary};
  }
`;
export const SelectTime = ({ selectedHour, selectedMinute, onChangeHour, onChangeMinute, }) => {
    const { t } = useTranslation();
    const handleHourChange = (hour) => {
        if (hour) {
            const newSelectedHour = new Date(selectedHour);
            newSelectedHour.setHours(parseInt(hour));
            onChangeHour(newSelectedHour);
        }
    };
    const handleMinuteChange = (minute) => {
        if (minute) {
            const newSelectedMinute = new Date(selectedMinute);
            newSelectedMinute.setMinutes(parseInt(minute));
            onChangeMinute(newSelectedMinute);
        }
    };
    const handleHourBackward = () => {
        const newSelectedHour = new Date(selectedHour);
        newSelectedHour.setHours(newSelectedHour.getHours() - 1);
        onChangeHour(newSelectedHour);
    };
    const handleHourForward = () => {
        const newSelectedHour = new Date(selectedHour);
        newSelectedHour.setHours(newSelectedHour.getHours() + 1);
        onChangeHour(newSelectedHour);
    };
    const handleMinuteBackward = () => {
        const newSelectedMinute = new Date(selectedMinute);
        newSelectedMinute.setMinutes(newSelectedMinute.getMinutes() - 1);
        onChangeMinute(newSelectedMinute);
    };
    const handleMinuteForward = () => {
        const newSelectedMinute = new Date(selectedMinute);
        newSelectedMinute.setMinutes(newSelectedMinute.getMinutes() + 1);
        onChangeMinute(newSelectedMinute);
    };
    const handleRandomTime = () => {
        const randomHour = Math.floor(Math.random() * 24);
        const randomMinute = Math.floor(Math.random() * 60);
        const newSelectedHour = new Date(selectedHour);
        newSelectedHour.setHours(randomHour);
        onChangeHour(newSelectedHour);
        const newSelectedMinute = new Date(selectedMinute);
        newSelectedMinute.setMinutes(randomMinute);
        onChangeMinute(newSelectedMinute);
    };
    return (_jsxs(Container, { children: [_jsxs(WrapperHeader, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular }, { children: t('Select time') })), _jsx(ButtonRandom, { text: t('Any'), onClick: handleRandomTime, variant: ButtonVariant.TRANSPARENT })] }), _jsxs(WrapperMain, { children: [_jsx(HourColumn, { children: _jsxs(StyledTimePickerWrapper, { children: [_jsx(WrapperArrowUp, Object.assign({ onClick: handleHourBackward }, { children: _jsx(IconWrapper, { children: _jsx(Icon.ArrowDown, {}) }) })), _jsx(TimePicker, { onChange: handleHourChange, value: selectedHour, format: "HH", disableClock: true, clearIcon: null }), _jsx(WrapperArrowDown, Object.assign({ onClick: handleHourForward }, { children: _jsx(IconWrapper, { children: _jsx(Icon.ArrowDown, {}) }) }))] }) }), _jsx(MinuteColumn, { children: _jsxs(StyledTimePickerWrapper, { children: [_jsx(WrapperArrowUp, Object.assign({ onClick: handleMinuteBackward }, { children: _jsx(IconWrapper, { children: _jsx(Icon.ArrowDown, {}) }) })), _jsx(TimePicker, { onChange: handleMinuteChange, value: selectedMinute, format: "mm", disableClock: true, clearIcon: null }), _jsx(WrapperArrowDown, Object.assign({ onClick: handleMinuteForward }, { children: _jsx(IconWrapper, { children: _jsx(Icon.ArrowDown, {}) }) }))] }) })] })] }));
};
