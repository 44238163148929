import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, ButtonVariant, InputField } from 'common';
import { ModalMain } from '../main';
import { useTranslation } from 'react-i18next';
import { model } from './config';
import { SelectField } from 'common/fields/select-field/select';
import styled from 'styled-components';
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-1058t6b" }) `
  display: flex;
  flex-direction: column;

  gap: 24px;
`;
const FieldsRow = styled.div.withConfig({ displayName: "FieldsRow", componentId: "sc-x1gdiw" }) `
  display: flex;
  flex-direction: row;

  gap: 24px;
`;
export const EmployeeInvitation = ({ width, onClick, isVisible, }) => {
    const { t } = useTranslation();
    return (_jsx(ModalMain, Object.assign({ width: width, title: "Employee invitation", isVisible: isVisible, onClick: onClick, footer: _jsxs(_Fragment, { children: [_jsx(Button, { padding: "24px", text: t('Invite an employee'), variant: ButtonVariant.PRIMARY }), _jsx(Button, { text: t('Cancel'), variant: ButtonVariant.TETRIERY, onClick: onClick })] }) }, { children: _jsxs(Body, { children: [_jsxs(FieldsRow, { children: [_jsx(InputField, Object.assign({}, model.firstName)), _jsx(InputField, Object.assign({}, model.employeePosition)), _jsx(InputField, Object.assign({}, model.email))] }), _jsx(FieldsRow, { children: _jsx(SelectField, Object.assign({}, model.company)) })] }) })));
};
