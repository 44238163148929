import * as Yup from 'yup';
import { InputColorType } from '../../../../common/input/types';
export const dispatchFormModel = {
    manualInput: {
        type: 'text',
        name: 'manualInput',
        inputType: 'text',
        colorType: InputColorType.DARK,
    },
    mailingText: {
        type: 'text',
        name: 'mailingText',
        inputType: 'textarea',
        style: { minHeight: 200 },
        placeholder: 'Текст для рассылки',
    },
    mailingName: {
        type: 'text',
        inputType: 'text',
        name: 'mailingName',
    },
    dropdown: {
        name: 'dropdown',
        placeholder: 'Добавить из базы',
        containerStyle: {
            height: 40,
        },
    },
};
export const addManualInputInitialValues = {
    manualInput: '',
    chips: {
        inputChips: [],
        baseChips: [],
    },
};
export const validationSchema = (textError, nameError, maxLengthError) => Yup.object().shape({
    [dispatchFormModel.mailingText.name]: Yup.string()
        .required(textError)
        .max(30840, maxLengthError),
    [dispatchFormModel.mailingName.name]: Yup.string().required(nameError),
});
