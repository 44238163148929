import styled, { css } from 'styled-components';
export const ContainerTooltip = styled.div.withConfig({ displayName: "ContainerTooltip", componentId: "sc-qoo18v" }) `
  position: relative;
`;
export const WrapperTooltip = styled.p.withConfig({ displayName: "WrapperTooltip", componentId: "sc-sjxr38" }) `
  position: absolute;
  z-index: 1;
  width: max-content;
  max-width: 25ch;

  font-size: 1rem;
  font-weight: normal;
  line-height: initial;
  will-change: filter;

  padding: 12px 6px;
  border-radius: 5px;

  box-shadow: ${({ theme }) => theme.config.tooltip.shadow};
  background: ${({ theme }) => theme.config.tooltip.background};

  ${({ $position, $isVisible, $top, $right, $bottom, $left, $translateX, $translateY, }) => {
    if ($position === 'top') {
        return css `
        bottom: calc(100% + ${$bottom || '50px'});
        transform: translateX(${$translateX || '-50%'})
          translateY(${$isVisible ? '-10px' : '0'});
        right: ${$right || '50%'};
      `;
    }
    else if ($position === 'right') {
        return css `
        top: ${$top || '50%'};
        left: calc(100% + ${$left || '50px'});
        transform: translateY(${$translateY || '-50%'})
          translateX(${$isVisible ? '10px' : '0'});
      `;
    }
    else if ($position === 'bottom') {
        return css `
        top: calc(100% + ${$top || '50px'});
        left: ${$left || '50%'};
        transform: translateX(${$translateX || '-50%'})
          translateY(${$isVisible ? '10px' : '0'});
      `;
    }
    else if ($position === 'left') {
        return css `
        top: ${$top || '50%'};
        right: calc(100% + ${$right || '50px'});
        transform: translateY(${$translateY || '-50%'})
          translateX(${$isVisible ? '-10px' : '0'});
      `;
    }
    else {
        return null;
    }
}}

  ${({ $isVisible }) => css `
      opacity: ${$isVisible ? 1 : 0};
      pointer-events: ${$isVisible ? 'auto' : 'none'};
      transition: opacity 0.3s linear, transform 0.3s linear;
    `}
`;
