import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React from 'react';
import { Text, TextVariant } from '../../../../../../common';
import { Dropdown } from '../../../../../../components';
import { adFormModel, EHolidaysSchedule, EShowSchedule, ETimezoneCategory, holidaysScheduleItems, RussiaTimezones, SNGTimezones, timezoneCategoryItems, WorldTimezones, } from '../config';
import * as BaseStyles from '../styles';
import { DisplayIntervals } from './DisplayIntervals';
import { ManualHolidaySchedule } from './ManualHolidaySchedule';
import * as Styles from './styles';
export const ShowSchedule = () => {
    var _a, _b;
    const { values, setFieldValue } = useFormikContext();
    const timezoneOptions = () => {
        let timezones = [''];
        switch (values.timezoneCategory) {
            case ETimezoneCategory.SNG:
                timezones = SNGTimezones;
                break;
            case ETimezoneCategory.RUSSIA:
                timezones = RussiaTimezones;
                break;
            case ETimezoneCategory.WORLD:
                timezones = WorldTimezones;
                break;
        }
        return timezones.map((timezone) => {
            return {
                label: timezone,
                onClick: () => setFieldValue('timezone', timezone),
            };
        });
    };
    return (_jsxs(Styles.ShowScheduleContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'tetriery' }, { children: "\u0427\u0430\u0441\u043E\u0432\u043E\u0439 \u043F\u043E\u044F\u0441" })), _jsxs(Styles.TimezonesContainer, { children: [_jsx(BaseStyles.DropdownWrapper, { children: _jsx(Dropdown, Object.assign({}, adFormModel.dropdown, { items: timezoneCategoryItems.map((item) => {
                                return {
                                    label: item.label,
                                    onClick: () => setFieldValue('timezoneCategory', item.value),
                                };
                            }), button: _jsx(BaseStyles.DropdownButton, { children: (_a = timezoneCategoryItems.find((item) => item.value === values.timezoneCategory)) === null || _a === void 0 ? void 0 : _a.label }) })) }), _jsx(BaseStyles.DropdownWrapper, { children: _jsx(Dropdown, Object.assign({}, adFormModel.dropdown, { items: timezoneOptions(), button: _jsx(BaseStyles.DropdownButton, { children: !!values.timezone ? values.timezone : 'Выберите часовой пояс' }) })) })] }), values.showSchedule == EShowSchedule.MANUAL && (_jsxs(Styles.DisplayIntervalsWrapper, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'tetriery' }, { children: "\u0418\u043D\u0442\u0435\u0440\u0432\u0430\u043B\u044B \u043F\u043E\u043A\u0430\u0437\u043E\u0432" })), _jsx(DisplayIntervals, {})] })), _jsxs(Styles.HolidaysContainer, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.bodyRegular, "$colorVariant": 'tetriery' }, { children: "\u0412 \u043F\u0440\u0430\u0437\u0434\u043D\u0438\u0447\u043D\u044B\u0435 \u0434\u043D\u0438" })), _jsx(BaseStyles.DropdownWrapper, { children: _jsx(Dropdown, Object.assign({}, adFormModel.dropdown, { items: holidaysScheduleItems.map((item) => {
                                return {
                                    label: item.label,
                                    onClick: () => setFieldValue('holidaysSchedule', item.value),
                                };
                            }), button: _jsx(BaseStyles.DropdownButton, { children: (_b = holidaysScheduleItems.find((item) => item.value === values.holidaysSchedule)) === null || _b === void 0 ? void 0 : _b.label }) })) }), values.holidaysSchedule === EHolidaysSchedule.MANUAL && (_jsx(ManualHolidaySchedule, {}))] })] }));
};
