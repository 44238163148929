import styled from 'styled-components';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1747haw" }) ``;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-pxhfg9" }) ``;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-1d27gxg" }) `
  margin-bottom: 16px;
`;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-i8w2uo" }) `
  margin: 24px 0px;

  display: flex;
  align-items: flex-start;
`;
