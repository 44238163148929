import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ResultPage } from '../result-page';
import { Button } from '../../../common';
import { Link } from 'react-router-dom';
export const AvitoResultPage = ({ data }) => {
    const { t } = useTranslation();
    return (_jsx(ResultPage, { filterField: "description", title: (data === null || data === void 0 ? void 0 : data.parser_type) || 'Avito', dataHeader: [
            t('Created'),
            t('Name'),
            t('Address'),
            t('Cost'),
            t('Category'),
            t('Description'),
            t('Views'),
            'Объявление',
        ], data: data === null || data === void 0 ? void 0 : data.parser_data, dataRows: (row) => {
            var _a;
            return [
                row.created_at,
                row.item_title,
                row.location,
                ((_a = row.price) === null || _a === void 0 ? void 0 : _a.length) ? row.price : '—',
                row.category,
                row.description,
                row.number_of_views,
                _jsx(Link, Object.assign({ to: row.item_url, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(Button, { text: 'Перейти' }) })),
            ];
        } }));
};
