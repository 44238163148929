import { Button as ButtonComponent, InputField as InputFieldComponent, } from 'common';
import styled from 'styled-components';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-bvjhie" }) `
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const LoadingWrapper = styled.div.withConfig({ displayName: "LoadingWrapper", componentId: "sc-17s9vss" }) `
  gap: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-17i2636" }) `
  width: 100%;
  padding: 0px 48px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;
  max-height: 100vh;
  ${scrollMedium};
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-tf9240" }) `
  flex: 1;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding-bottom: 72px;
`;
export const ButtonContainer = styled.div.withConfig({ displayName: "ButtonContainer", componentId: "sc-t0jah6" }) `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 24px;

  padding-top: 24px;
  border-top: ${({ theme }) => theme.config.dropdown.border.all};

  @media screen and (max-width: 500px) {
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 8px;
  }
`;
export const TextsContainer = styled.div.withConfig({ displayName: "TextsContainer", componentId: "sc-cs15s8" }) `
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1s3dm0g" }) `
  gap: 24px;
  flex: 1;
  display: flex;
  padding-right: 48px;
  flex-direction: column;

  @media screen and (max-width: 1300px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0px;
  }
`;
export const InfoContainer = styled.div.withConfig({ displayName: "InfoContainer", componentId: "sc-1h18mzh" }) `
  flex: 1;
  gap: 32px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1300px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
export const FormRow = styled.div.withConfig({ displayName: "FormRow", componentId: "sc-11p386a" }) `
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: 1300px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const FormColumn = styled.div.withConfig({ displayName: "FormColumn", componentId: "sc-2j6l95" }) `
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const FormInputField = styled(InputFieldComponent).withConfig({ displayName: "FormInputField", componentId: "sc-pvdfs" }) `
  @media screen and (max-width: 500px) {
    min-width: 320px;
  }
`;
export const ButtonCancel = styled(ButtonComponent).withConfig({ displayName: "ButtonCancel", componentId: "sc-1eemew4" }) `
  box-shadow: ${({ theme }) => theme.config.button.primary.shadow.secondary};
`;
