import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant, Text, TextVariant } from 'common';
import { AppRoutes } from 'config';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';
export const HomePage = () => {
    const { t } = useTranslation();
    const { Home, Mailing } = AppRoutes.Dashboard;
    return (_jsx(Styles.Container, { children: _jsxs(Styles.ScrollWrapper, { children: [_jsx(Styles.TextWrapper, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: t('Mailing') })) }), _jsx(Styles.Wrapper, { children: _jsxs(Styles.MailingWrapper, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h3Medium }, { children: t('Create Mailing') })) }), _jsxs(Styles.WrapperButton, { children: [_jsx(Styles.Button, { text: t('Telegram'), variant: ButtonVariant.SECONDARY, to: Home + Mailing.Home + Mailing.Telegram }), _jsx(Styles.Button, { text: t('E-mail'), variant: ButtonVariant.SECONDARY, to: Home + Mailing.Home + Mailing.Email }), _jsx(Styles.Button, { text: t('Mailing history'), variant: ButtonVariant.SECONDARY, to: Home + Mailing.Home + Mailing.History })] })] }) })] }) }));
};
