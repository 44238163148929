import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant, InputField, Text, TextVariant, } from '../../../../../../common';
import { Icon } from '../../../../../../common/icon';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { ThemeContext } from '../../../../../../context';
import { ESocialNetwork } from '../../create/config';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-dcu5k5" }) ``;
const Description = styled.div.withConfig({ displayName: "Description", componentId: "sc-7eshvb" }) ``;
const WrapperSettings = styled.div.withConfig({ displayName: "WrapperSettings", componentId: "sc-1ccbw0i" }) `
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 20px;
  margin-top: 24px;
`;
const WrapperField = styled.div.withConfig({ displayName: "WrapperField", componentId: "sc-b992zv" }) `
  width: 100%;
  display: flex;
  align-items: center;
`;
const WrapperCheck = styled.div.withConfig({ displayName: "WrapperCheck", componentId: "sc-17iuthz" }) `
  ${({ $isChecked }) => $isChecked &&
    css `
      opacity: 1;
    `}

  ${({ $isChecked }) => !$isChecked &&
    css `
      opacity: 0;
    `}

  transition: opacity 0.1s ease-out;
`;
const RemoveButton = styled.div.withConfig({ displayName: "RemoveButton", componentId: "sc-c8vo2q" }) `
  visibility: hidden;
  user-select: none;
  pointer-events: none;

  ${({ $isVisible }) => $isVisible &&
    css `
      visibility: visible;
      pointer-events: auto;
      cursor: pointer;
    `}
`;
const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-r2x1kh" }) `
  flex: 1;
  display: flex;
  align-items: flex-start;
`;
const Gap = styled.div.withConfig({ displayName: "Gap", componentId: "sc-n0btuj" }) `
  display: flex;
  align-items: center;

  gap: 16px;
`;
export const SocialNetworksForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const { isDarkTheme } = useContext(ThemeContext);
    const renderIcon = (type) => {
        switch (type) {
            case ESocialNetwork.VKONTAKTE:
                return _jsx(Icon.Vk, {});
            case ESocialNetwork.FACEBOOK:
                return _jsx(Icon.Facebook, {});
            case ESocialNetwork.INSTAGRAM:
                return _jsx(Icon.Instagram, {});
            case ESocialNetwork.TELEGRAM:
                return _jsx(Icon.Telegram, {});
            case ESocialNetwork.TIKTOK:
                return isDarkTheme ? _jsx(Icon.TiktokLight, {}) : _jsx(Icon.TiktokBlack, {});
        }
    };
    return (_jsxs(Container, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('Connecting social networks') })), _jsx(Description, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallLight }, { children: t('If you want to create newsletters on social networks or moderate posts, you can synchronize your companys official profiles. Select the social networks you are interested in') })) }), _jsx(WrapperSettings, { children: values.socialNetworks.map((item, index) => {
                    return (_jsxs(WrapperField, { children: [_jsx(Column, { children: renderIcon(item.network) }), _jsx(Column, { children: item.label }), _jsxs(Gap, { children: [_jsx(InputField, { style: { height: '52px' }, icon: _jsx(Icon.Web, {}), disabled: false, name: `socialNetworks.[${index}].website`, placeholder: 'yoursite.com/@yourid' }), _jsx(Button, { text: t('Save'), variant: ButtonVariant.TETRIERY, disabled: values.socialNetworks[index].website === '', onClick: () => {
                                            setFieldValue(`socialNetworks.[${index}].connected`, !item.connected);
                                        } }), _jsx(WrapperCheck, Object.assign({ "$isChecked": item.connected }, { children: _jsx(Icon.CheckmarkDrop, {}) })), _jsx(RemoveButton, Object.assign({ onClick: () => {
                                            setFieldValue(`socialNetworks.[${index}]`, Object.assign(Object.assign({}, item), { connected: false, website: '' }));
                                        }, "$isVisible": !!item.website }, { children: _jsx(IconWrapper, { children: _jsx(Icon.Delete, {}) }) }))] })] }, item.network));
                }) })] }));
};
