import axios from 'axios';
import { setLoading } from 'store/slices/loader';
import { getToken } from 'utils/storage';
import { BASE_AI_URL, BASE_AUDIO_CLIPS_URL, BASE_BALANCE_URL, BASE_COMPANIES_URL, BASE_MAILING_HISTORY_URL, BASE_MAILING_URL, BASE_NOTIFICATION_URL, BASE_PARSER_URL, BASE_URL, BASE_VOICE_ROBOT_URL, } from './constants';
export const apiInstance = axios.create({ baseURL: BASE_URL });
export const apiParserInstance = axios.create({ baseURL: BASE_PARSER_URL });
export const apiLoaderInstance = axios.create({ baseURL: BASE_URL });
export const apiAIInstance = axios.create({ baseURL: BASE_AI_URL });
export const apiMailingInstance = axios.create({ baseURL: BASE_MAILING_URL });
export const apiMailingHistoryInstance = axios.create({
    baseURL: BASE_MAILING_HISTORY_URL,
});
export const apiCompaniesInstance = axios.create({
    baseURL: BASE_COMPANIES_URL,
});
export const apiVoiceRobotInstance = axios.create({
    baseURL: BASE_VOICE_ROBOT_URL,
});
export const apiProjectsInstance = axios.create({
    baseURL: BASE_URL,
});
export const apiAudioClipsInstance = axios.create({
    baseURL: BASE_AUDIO_CLIPS_URL,
});
export const apiNotificationInstance = axios.create({
    baseURL: BASE_NOTIFICATION_URL,
});
export const apiBalanceInstance = axios.create({
    baseURL: BASE_BALANCE_URL,
});
const configRequest = (axiosConfig) => {
    var _a;
    const token = getToken();
    const userId = localStorage.getItem('userId');
    const config = Object.assign({}, axiosConfig);
    if (token) {
        let headers = Object.assign({}, config.headers);
        headers.Authorization = `Bearer ${token}`;
        config.headers = headers;
        config.params = Object.assign(Object.assign({}, config.params), { user_id: userId });
    }
    else {
        (_a = config.headers) === null || _a === void 0 ? true : delete _a.Authorization;
    }
    return config;
};
const handleResponseError = (dispatch, error) => {
    if (error && error.response && error.response.status === 401) {
    }
    return Promise.reject(error);
};
apiInstance.interceptors.request.use(configRequest);
apiParserInstance.interceptors.request.use(configRequest);
apiProjectsInstance.interceptors.request.use(configRequest);
apiMailingInstance.interceptors.request.use(configRequest);
apiMailingHistoryInstance.interceptors.request.use(configRequest);
apiCompaniesInstance.interceptors.request.use(configRequest);
apiAudioClipsInstance.interceptors.request.use(configRequest);
apiNotificationInstance.interceptors.request.use(configRequest);
apiBalanceInstance.interceptors.request.use(configRequest);
export const interceptor = (dispatch) => {
    apiLoaderInstance.interceptors.request.use((config) => {
        dispatch(setLoading(true));
        return configRequest(config);
    }, (error) => {
        dispatch(setLoading(false));
        return error;
    });
    apiLoaderInstance.interceptors.response.use((response) => {
        dispatch(setLoading(false));
        return response;
    }, (error) => {
        if (error && error) {
            // dispatch(logout({}));
        }
        dispatch(setLoading(false));
        return handleResponseError(dispatch, error);
    });
    apiInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiAIInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiVoiceRobotInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiParserInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiProjectsInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiMailingInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiMailingHistoryInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiCompaniesInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiAudioClipsInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiNotificationInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
    apiBalanceInstance.interceptors.response.use((response) => response, (error) => {
        return handleResponseError(dispatch, error);
    });
};
