import styled from 'styled-components';
import { Button as ButtonContainer } from 'common';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-g6t52q" }) `
  display: flex;
  padding: 40px;
  overflow-y: auto;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  height: calc(100vh - 150px);
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1u5ma37" }) `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SectionContainer = styled.div.withConfig({ displayName: "SectionContainer", componentId: "sc-100399z" }) `
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-182483i" }) `
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  justify-content: space-between;
`;
export const TableContainer = styled.div.withConfig({ displayName: "TableContainer", componentId: "sc-1llymvw" }) `
  margin-top: 24px;
`;
export const ButtonDelete = styled(ButtonContainer).withConfig({ displayName: "ButtonDelete", componentId: "sc-1bdc0zq" }) `
  color: ${({ theme }) => theme.config.text.primary};
`;
