import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant, Text, TextVariant } from 'common';
import { AppRoutes } from 'config';
import { useTranslation } from 'react-i18next';
import { MyDatabases } from '../my-databases';
import * as Styles from './styles';
export const HomePage = () => {
    const { t } = useTranslation();
    const { Home, Parsing } = AppRoutes.Dashboard;
    return (_jsx(Styles.Container, { children: _jsxs(Styles.ScrollWrapper, { children: [_jsx(Styles.TextWrapper, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h1Medium }, { children: t('Databases and parsers') })) }), _jsx(Styles.Wrapper, { children: _jsxs(Styles.DatabaseWrapper, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$colorVariant": "primary", "$variant": TextVariant.h3Medium }, { children: t('Online parser') })) }), _jsxs(Styles.WrapperButton, { children: [_jsx(Styles.Button, { text: t('Avito'), variant: ButtonVariant.SECONDARY, to: Home + Parsing.Home + Parsing.Avito }), _jsx(Styles.Button, { text: t('Vkontakte'), variant: ButtonVariant.SECONDARY, to: Home +
                                            Parsing.Home +
                                            Parsing.Vkontakte.Home +
                                            Parsing.Vkontakte.VkontakteGroups }), _jsx(Styles.Button, { text: t('Yandex'), variant: ButtonVariant.SECONDARY, to: Home + Parsing.Home + Parsing.Yandex })] })] }) }), _jsx(MyDatabases, {})] }) }));
};
