import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from 'common/icon';
import { LogoDashboard } from 'common/icon/icons';
import { AppRoutes } from 'config';
import { useQueryNavigate } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import IconWrapper from '../../common/icon-wrapper/icon-wrapper';
import * as Styles from './styles';
export const Header = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useQueryNavigate();
    const location = useLocation();
    return (_jsx(Styles.Container, { children: _jsxs(Styles.ContentInner, { children: [_jsxs(Styles.LeftContent, { children: [_jsx(Styles.Logo, { children: _jsx(LogoDashboard, {}) }), _jsxs(Styles.TextContainer, { children: [_jsx(Styles.LogoTextBold, { children: t('Brend') }), _jsx(Styles.LogoTextLight, { children: t('Boost') })] }), _jsx(Styles.MenuIconContainer, { children: _jsx(IconWrapper, Object.assign({ "$isCursor": true }, { children: _jsx(Icon.Menu, {}) })) })] }), _jsx(Styles.RightContent, { children: _jsx(Styles.Button, { variant: 'TETRIERY', text: !theme.responsive.isMobile
                            ? location.pathname == '/auth/login'
                                ? t('Registration')
                                : t('Sign In')
                            : '', onClick: () => location.pathname == '/auth/login'
                            ? navigate(AppRoutes.Auth.Home + AppRoutes.Auth.SignUp, {
                                replace: true,
                            })
                            : navigate(AppRoutes.Auth.Home + AppRoutes.Auth.Login, {
                                replace: true,
                            }) }) })] }) }));
};
