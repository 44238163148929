import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import AdvMockImage from '../../../../../../assets/images/AdvMockImage.png';
import { Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { More } from '../../../../../../common/icon/icons';
import { PreviewContext } from './AdvPreview';
import * as Styles from './styles';
export const ThirdVariant = () => {
    const contextValues = useContext(PreviewContext);
    return (_jsxs(Styles.ThirdVariantContainer, { children: [_jsxs(Styles.ThirdVariantImageContainer, { children: [_jsx(Styles.ThirdVariantImage, { src: AdvMockImage }), _jsxs(Styles.ThirdVariantMeta, { children: [_jsx(Styles.ThirdVariantAdvChip, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: "\u0420\u0435\u043A\u043B\u0430\u043C\u0430" })) }), _jsx(Styles.ThirdVariantMoreInfo, { children: _jsx(IconWrapper, Object.assign({ "$strokeColor": 'white' }, { children: _jsx(More, {}) })) })] }), !!(contextValues === null || contextValues === void 0 ? void 0 : contextValues.url.length) && (_jsxs(Styles.ThirdVariantSiteChip, { children: [_jsx(Styles.ThirdVariantFavicon, { src: contextValues === null || contextValues === void 0 ? void 0 : contextValues.faviconSrc }), _jsx(Text, Object.assign({ "$variant": TextVariant.smallMedium }, { children: contextValues === null || contextValues === void 0 ? void 0 : contextValues.url }))] }))] }), _jsxs(Styles.ThirdVariantInfoContainer, { children: [_jsx(Styles.ThirdVariantHeader, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: contextValues === null || contextValues === void 0 ? void 0 : contextValues.header })) }), _jsx(Styles.ThirdVariantInfoSeparator, {}), _jsx(Styles.ThirdVariantArrowContainer, { children: _jsx(IconWrapper, Object.assign({ "$isCursor": true, "$height": '40px', "$width": '40px' }, { children: _jsx(Styles.ThirdVariantArrow, {}) })) })] })] }));
};
