import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonVariant, ErrorLabel, Text, TextVariant } from 'common';
import { Icon } from 'common/icon';
import { SelectTime } from 'common/select-time';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';
export const DropdownTime = ({ error, isVisible, selectedHour, onChangeHour, setVisibility, selectedMinute, onChangeMinute, }) => {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const [menuTimeTop, setMenuTimeTop] = useState(undefined);
    const handleButtonTime = () => {
        setVisibility(!isVisible);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current &&
                !dropdownRef.current.contains(event.target)) {
                setVisibility(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const bottomSpace = window.innerHeight - rect.bottom;
            if (bottomSpace) {
                setMenuTimeTop(-260);
            }
            else {
                setMenuTimeTop(undefined);
            }
        }
    }, []);
    return (_jsxs(Styles.DropdownContainer, Object.assign({ ref: dropdownRef }, { children: [_jsxs(Styles.DropdownButton, Object.assign({ onClick: handleButtonTime }, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.smallReqular }, { children: t('Time') })), _jsx(Styles.ButtonTime, { text: t('Time'), icon: _jsx(Icon.ArrowDown, {}), variant: ButtonVariant.SECONDARY })] })), _jsx(Styles.DropdownTime, Object.assign({ "$open": isVisible, "$top": menuTimeTop }, { children: _jsx(SelectTime, { selectedHour: selectedHour, selectedMinute: selectedMinute, onChangeHour: onChangeHour, onChangeMinute: onChangeMinute }) })), error && _jsx(ErrorLabel, { text: error })] })));
};
