import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import IconWrapper from '../../common/icon-wrapper/icon-wrapper';
import * as Styles from './styles';
export const Carousel = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1));
        }, 6666);
        return () => clearInterval(interval);
    }, [activeIndex]);
    const handleNext = () => {
        setActiveIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    };
    const handlePrev = () => {
        setActiveIndex((prev) => (prev === 0 ? data.length - 1 : prev - 1));
    };
    const renderSlide = () => {
        return data === null || data === void 0 ? void 0 : data.map((slide, index) => {
            const { id, imageUrl } = slide;
            return (_jsx(Styles.Slide, Object.assign({ "$isActive": index === activeIndex, "$activeIndex": activeIndex }, { children: _jsx(Styles.ImageWrapper, { src: imageUrl }) }), id));
        });
    };
    const renderDots = () => {
        return (_jsx(Styles.DotsContainer, { children: data === null || data === void 0 ? void 0 : data.map((slide, index) => (_jsx(Styles.DotsWrapper, { children: _jsx(Styles.Dot, { "$isActive": index === activeIndex }) }, slide.id))) }));
    };
    const renderLeft = () => {
        return (_jsx(IconWrapper, Object.assign({ "$fillColor": "#141718", "$isCursor": true }, { children: _jsx(Styles.Left, { width: 32, height: 32, onClick: handlePrev }) })));
    };
    const renderRight = () => {
        return (_jsx(IconWrapper, Object.assign({ "$fillColor": "#141718", "$isCursor": true }, { children: _jsx(Styles.Right, { width: 32, height: 32, onClick: handleNext }) })));
    };
    return (_jsxs(Styles.Container, { children: [_jsx(Styles.CarouselWrapper, { children: renderSlide() }), _jsxs(Styles.ArrowsContainer, { children: [renderLeft(), " ", renderDots(), " ", renderRight()] })] }));
};
