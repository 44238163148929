import { Button as ButtonComponent } from 'common';
import { Icon } from 'common/icon';
import { zIndex } from 'constants/enums';
import styled, { css, keyframes } from 'styled-components';
import { smallSemiBold } from 'styles/mixins/fonts';
const fadeIn = keyframes `
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeOut = keyframes `
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
export const DropContainer = styled.div.withConfig({ displayName: "DropContainer", componentId: "sc-4ax55e" }) `
  position: relative;
  display: inline-block;
  width: 100%;
  border: transparent;
  z-index: ${zIndex.Dropdown};
`;
export const SelectButton = styled.div.withConfig({ displayName: "SelectButton", componentId: "sc-18xrv8l" }) `
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 8px 8px 8px 16px;

  @media screen and (max-width: 1023px) {
    display: none;
    margin: 0px;
    transition: all 0.3s ease-in-out;
  }
`;
export const CreateButton = styled(ButtonComponent).withConfig({ displayName: "CreateButton", componentId: "sc-1rmenzh" }) `
  ${smallSemiBold};

  white-space: nowrap;
  letter-spacing: -0.01em;
`;
export const DropMenu = styled.ul.withConfig({ displayName: "DropMenu", componentId: "sc-kyaf5v" }) `
  position: absolute;
  top: ${({ $isOpen, $top }) => ($isOpen && $top ? `${$top}px` : '100%')};
  left: ${({ $left }) => ($left ? `${$left}px` : '0%')};

  padding: 16px;
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow};
  border: ${({ theme }) => theme.config.dropdown.border.all};
  background: ${({ theme }) => theme.config.toggle.bg.active};
  border-radius: 16px;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  animation: ${({ $isOpen }) => ($isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  transition: visibility 0.3s ease-in-out, max-height 0.5s ease-in-out;

  overflow-y: auto;
  z-index: ${zIndex.Dropdown};
  box-shadow: ${({ theme }) => theme.config.dropdown.boxShadow.default};
`;
export const DropMenuItem = styled.li.withConfig({ displayName: "DropMenuItem", componentId: "sc-1d3nui5" }) `
  padding: 8px 28px 8px 16px;
  border-radius: 8px;
  flex: 1;

  ${({ theme, $isActive }) => $isActive &&
    css `
      border: ${theme.config.dropdown.border.green};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  &:hover {
    background: ${({ theme }) => theme.config.dropdown.background.default};
  }
`;
export const DropMenuMock = styled.div.withConfig({ displayName: "DropMenuMock", componentId: "sc-e37am4" }) `
  padding: 8px 0px;
  border-radius: 8px;
  flex: 1;
  text-align: center;
  cursor: default;
`;
export const WrapperDrop = styled.div.withConfig({ displayName: "WrapperDrop", componentId: "sc-qaryta" }) ``;
export const Line = styled.div.withConfig({ displayName: "Line", componentId: "sc-1f2ye76" }) `
  flex: 1;
  width: 100%;
  padding: 8px 16px;
  border-bottom: ${({ theme }) => theme.config.dropdown.border.all};
`;
export const WrapperItem = styled.div.withConfig({ displayName: "WrapperItem", componentId: "sc-b6s7eu" }) `
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;
  width: 100%;
  cursor: pointer;
`;
export const Check = styled(Icon.CheckmarkDrop).withConfig({ displayName: "Check", componentId: "sc-1p15zwq" }) ``;
export const WrapperIcon = styled.div.withConfig({ displayName: "WrapperIcon", componentId: "sc-xnor2f" }) `
  margin-right: 8px;
  visibility: hidden;

  ${({ $isActive }) => $isActive &&
    css `
      visibility: visible;
      fill: ${({ theme }) => theme.config.line.primary};
    `}
`;
export const Arrow = styled(Icon.ArrowDown).withConfig({ displayName: "Arrow", componentId: "sc-zgwx5v" }) `
  ${({ $isOpen }) => css `
    transform: rotate(${$isOpen ? '180deg' : '0deg'});
    transition: transform 0.25s ease-in;
  `}
`;
export const WrapperButton = styled.div.withConfig({ displayName: "WrapperButton", componentId: "sc-177jiqb" }) `
  display: flex;
  align-items: center;

  opacity: 0.5;
  border-radius: 30px;
  padding-right: 16px;
  background: ${({ theme }) => theme.config.toggle.wrapper};
  transition: padding 0.3s ease-in-out;

  ${({ theme, $isActive }) => $isActive &&
    css `
      opacity: 1;
      box-shadow: ${theme.config.dropdown.boxShadow.active};
    `};
  @media screen and (max-width: 1023px) {
    padding: 4px;
    border-radius: 10px;
    transition: padding 0.3s ease-in-out;
  }
`;
export const ButtonsContainer = styled.div.withConfig({ displayName: "ButtonsContainer", componentId: "sc-15xreek" }) `
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  gap: 10px;
`;
