import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppRoutes } from 'config';
import { useTranslation } from 'react-i18next';
import { Text, TextVariant } from '../../../../../../common';
import IconWrapper from '../../../../../../common/icon-wrapper/icon-wrapper';
import { useNavigate } from '../../../../../../hooks';
import { useCompaniesListForMainQuery } from '../../../../../../store/api/companies';
import * as Styles from './styles';
export const Company = () => {
    const { t } = useTranslation();
    const { Home, MyCompany } = AppRoutes.Dashboard;
    const { data: companies } = useCompaniesListForMainQuery();
    const navigate = useNavigate();
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Styles.Title, { children: "\u041C\u043E\u0438 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" }), _jsxs(Styles.BottomContent, { children: [_jsxs(Styles.WrapperButton, { children: [companies === null || companies === void 0 ? void 0 : companies.companies.map((com) => {
                                    var _a, _b;
                                    return (_jsxs(Styles.ContainerButton, Object.assign({ onClick: () => {
                                            navigate(Home +
                                                MyCompany.Home +
                                                `/${com.company_id}` +
                                                MyCompany.CompanyData);
                                        } }, { children: [_jsxs(Styles.Left, { children: [_jsx(IconWrapper, Object.assign({ "$fillColor": "#FF6700", "$isCursor": true }, { children: _jsx(Styles.IconBefore, {}) })), _jsx(Styles.Company, { children: com === null || com === void 0 ? void 0 : com.company_name }, (_a = com.company_id) === null || _a === void 0 ? void 0 : _a.toString())] }), _jsx(IconWrapper, Object.assign({ "$fillColor": "#141718", "$isCursor": true }, { children: _jsx(Styles.IconAfter, {}) }))] }), (_b = com.company_id) === null || _b === void 0 ? void 0 : _b.toString()));
                                }), !(companies === null || companies === void 0 ? void 0 : companies.companies.length) && (_jsx(Styles.TextContainer, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h3Medium }, { children: t('No companies available') })) }))] }), _jsx(Styles.BottomRight, { children: _jsx(Styles.Button, { text: t('All companies'), to: Home + MyCompany.Home }) })] })] }) }));
};
