import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Text } from 'common';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ModalMain } from '../main';
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-1ymdhkl" }) `
  display: flex;
  flex-direction: column;

  gap: 24px;
`;
export const Info = ({ isVisible, closeModal, openForm }) => {
    const { t } = useTranslation();
    return (_jsx(ModalMain, Object.assign({ isInfo: true, title: t(''), isVisible: isVisible, onClick: closeModal, footer: _jsx(_Fragment, {}) }, { children: _jsxs(Body, { children: [_jsx(Text, { children: t('Your request has been received and is being processed. A confirmation code will be sent to your registered email address shortly.') }), _jsx(Button, { text: t('Send code'), variant: 'PRIMARY', onClick: () => {
                        closeModal();
                        openForm();
                    } })] }) })));
};
