var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_AI_URL } from '../../../../../api/constants';
import { Loader, Text, TextVariant } from '../../../../../common';
import { Form } from '../../../../../components/form/Form';
import { LoaderContainer } from '../generate-post/styles';
import { generateImageInitialValues, generateImageValidationSchema, } from './config';
import * as Styles from './styles';
const RemoveBGForm = ({ isLoading }) => {
    const { t } = useTranslation();
    const { values, setFieldValue, submitForm } = useFormikContext();
    const fileInputRef = useRef(null);
    const uploadPhoto = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (_jsxs(Styles.ImageGenerateFormWrapper, { children: [_jsx(Text, Object.assign({ "$variant": TextVariant.h1Medium }, { children: t('Remove background') })), !values.image && (_jsx(Styles.UploadImageButton, { text: t('Upload a photo'), disabled: isLoading, onClick: () => uploadPhoto(), variant: 'DARK' })), _jsx(Styles.FileInput, { type: "file", accept: "image/*", ref: fileInputRef, onChange: (event) => {
                    var _a, _b;
                    if ((_a = event.target.files) === null || _a === void 0 ? void 0 : _a.item(0)) {
                        setFieldValue('image', (_b = event.target.files) === null || _b === void 0 ? void 0 : _b.item(0));
                    }
                } }), values.image && (_jsxs(Styles.FullForm, { children: [_jsxs(Styles.ImageContainer, { children: [_jsx(Styles.ImagePreview, { src: URL.createObjectURL(values.image), alt: 'qwe' }), _jsx(Styles.ChangeImageButton, Object.assign({ onClick: () => uploadPhoto() }, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0444\u043E\u0442\u043E" })) }))] }), _jsx(Styles.ParamsContainer, { children: _jsx(Styles.GenerateButton, { text: 'Удалить фон', onClick: () => submitForm() }) })] }))] }));
};
export const RemoveBackground = () => {
    const [lastImage, setLastImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [generatedImages, setGeneratedImages] = useState([]);
    const removeBG = ({ image }) => __awaiter(void 0, void 0, void 0, function* () {
        if (lastImage !== image) {
            setGeneratedImages([]);
            const url = `${BASE_AI_URL}/remove_background`;
            setLastImage(image);
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', image);
            try {
                const response = (yield fetch(url, {
                    method: 'post',
                    body: formData,
                }));
                const file = yield response.blob();
                setGeneratedImages([...generatedImages, URL.createObjectURL(file)]);
            }
            catch (_) {
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const downloadImage = (url) => {
        const aElement = document.createElement('a');
        const time = new Date();
        aElement.setAttribute('download', `${time.getTime()}.png`);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    };
    return (_jsx(Styles.Container, { children: _jsxs(Styles.Wrapper, { children: [_jsx(Form, Object.assign({ initialValues: generateImageInitialValues, validationSchema: generateImageValidationSchema, onSubmit: removeBG }, { children: _jsx(RemoveBGForm, { isLoading: isLoading }) })), isLoading && (_jsx(LoaderContainer, { children: _jsx(Loader, { text: '' }) })), generatedImages && (_jsx(Styles.Generated, { children: generatedImages.map((image) => {
                        return (_jsxs(Styles.ImageContainer2, { children: [_jsx(Styles.ImagePreview, { src: image, alt: 'image after removing background' }), _jsx(Styles.ChangeImageButton, Object.assign({ onClick: () => downloadImage(image) }, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0444\u043E\u0442\u043E" })) }))] }));
                    }) }))] }) }));
};
