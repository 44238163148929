import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ModalMain } from '../main';
import { Button, ButtonVariant, Text } from '../../../common';
import React, { useEffect, useState } from 'react';
import banner4 from '../../../assets/images/img_2.png';
import banner5 from '../../../assets/images/img_3.png';
import * as Styles from './styles';
import IconWrapper from '../../../common/icon-wrapper/icon-wrapper';
const data = [
    {
        id: 0,
        bannerUrl: banner4,
    },
    {
        id: 1,
        bannerUrl: banner5,
    },
];
export const UploadInfoCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1));
        }, 6666);
        return () => clearInterval(interval);
    }, [activeIndex]);
    const handleNext = () => {
        setActiveIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    };
    const handlePrev = () => {
        setActiveIndex((prev) => (prev === 0 ? data.length - 1 : prev - 1));
    };
    const renderSlide = () => {
        return data === null || data === void 0 ? void 0 : data.map((slide, index) => {
            const { id, bannerUrl } = slide;
            return (_jsx(Styles.Slide, Object.assign({ "$isActive": index === activeIndex, "$activeIndex": activeIndex }, { children: _jsx(Styles.ImageWrapper, { src: bannerUrl }) }), id));
        });
    };
    const renderDots = () => {
        return (_jsx(Styles.DotsContainer, { children: data === null || data === void 0 ? void 0 : data.map((slide, index) => (_jsx(Styles.DotsWrapper, { children: _jsx(Styles.Dot, { "$isActive": index === activeIndex }) }, slide.id))) }));
    };
    const renderLeft = () => {
        return (_jsx(IconWrapper, Object.assign({ "$fillColor": "#141718", "$isCursor": true }, { children: _jsx(Styles.Left, { width: 32, height: 32, onClick: handlePrev }) })));
    };
    const renderRight = () => {
        return (_jsx(IconWrapper, Object.assign({ "$fillColor": "#141718", "$isCursor": true }, { children: _jsx(Styles.Right, { width: 32, height: 32, onClick: handleNext }) })));
    };
    return (_jsxs(Styles.Container, { children: [_jsx(Styles.CarouselWrapper, { children: renderSlide() }), _jsxs(Styles.ArrowsContainer, { children: [renderLeft(), " ", renderDots(), " ", renderRight()] })] }));
};
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-ylpan" }) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;
export const UploadInfoModal = ({ isVisible, onClick, message, modalTitle, uploadFunc, buttonText, }) => {
    const { t } = useTranslation();
    return (_jsx(ModalMain, Object.assign({ title: modalTitle, isVisible: isVisible, onClick: onClick, width: 600, footer: _jsxs(_Fragment, { children: [_jsx(Button, { padding: "24px", onClick: () => alert('Скоро добавим!'), text: t(buttonText), variant: ButtonVariant.PRIMARY }), _jsx(Button, { padding: "24px", text: 'Загрузить файл', variant: ButtonVariant.PRIMARY, onClick: () => {
                        onClick();
                        uploadFunc();
                    } })] }) }, { children: _jsxs(Body, { children: [_jsx(Text, { children: t(message) }), _jsx(UploadInfoCarousel, {})] }) })));
};
