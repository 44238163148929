var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, InputField, Text, TextVariant } from 'common';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../../../components/form/Form';
import { initialValues, model, validationSchema } from './config';
import * as Styles from './styles';
import { SwitchField } from '../../../../../../../common/fields/switch-field';
import { SelectField } from '../../../../../../../common/fields/select-field/select';
import React from 'react';
const VkParserGroupsForm = () => {
    const { dirty, submitForm, isSubmitting, values, isValid, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    return (_jsx(Styles.Content, { children: _jsxs(Styles.WrapperGroups, { children: [_jsxs(Styles.InfoContainer, { children: [_jsx(Styles.Title, { children: _jsx(Text, Object.assign({ "$variant": TextVariant.h2Medium }, { children: t('Groups') })) }), _jsx(Styles.ContentContainer, { children: _jsxs(Styles.Content, { children: [_jsx(InputField, Object.assign({}, model.countGroups)), _jsx(InputField, Object.assign({}, model.searchPromt)), _jsx(SelectField, Object.assign({}, model.groupCountry, { isSearchable: true, noOptionsMessage: (e) => e.inputValue ? t('No options') : null, name: "groupCountry", components: {
                                            ClearIndicator: () => _jsx(_Fragment, {}),
                                            MultiValue: () => _jsx(_Fragment, {}),
                                        }, value: values.groupCountry, onChange: (o) => setFieldValue('groupCountry', o) })), _jsxs(Styles.ContentToggle, { children: [_jsx(SwitchField, Object.assign({}, model.hasUsersLimit)), values.hasUsersLimit && _jsx(InputField, Object.assign({}, model.countUsers))] })] }) })] }), _jsx(Styles.Footer, { children: _jsx(Button, { padding: "24px 24px", onClick: submitForm, text: t('Prepare the database'), disabled: !dirty ||
                            isSubmitting ||
                            !isValid ||
                            (values.hasUsersLimit && values.countUsers.length === 0) }) })] }) }));
};
export const FormsVkParserGroups = ({ parse }) => {
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { countGroups, countUsers, hasUsersLimit, searchPromt, groupCountry, } = values;
        yield parse({
            key: 'groups',
            count: countGroups,
            country_id: groupCountry.value,
            searchPromt,
            is_members_ids: countUsers,
            hasUsersLimit,
        });
    });
    return (_jsx(Form, Object.assign({ onSubmit: onSubmit, initialValues: initialValues, validationSchema: validationSchema }, { children: _jsx(VkParserGroupsForm, {}) })));
};
