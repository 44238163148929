var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonVariant } from 'common';
import { AppRoutes } from 'config';
import { useFormikContext } from 'formik';
import { useQueryNavigate } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useRegisterLegalPersonMutation, useRegisterPrivatePersonMutation, } from 'store/api/auth';
import { handleMutation } from 'utils/handleMutation';
import { Form } from '../../../../components/form/Form';
import { RegisterTypes, TabItems, validationSchema } from './config';
import { RegisterForms } from './forms';
import { initialValues as individualInitialValues } from './forms/individual/config';
import { initialValues as legalInitialValues } from './forms/legal-steps/config';
import * as Styles from './styles';
const RegisterForm = ({ activeTab, selectTab }) => {
    const { dirty, isSubmitting, validateForm, handleSubmit } = useFormikContext();
    const { t } = useTranslation();
    return (_jsxs(Styles.FormContainer, { children: [_jsxs(Styles.Header, { children: [_jsx(Styles.Title, { children: t('Welcome!') }), _jsx(Styles.SubTitle, { children: t('Join the Brend Boost Community!') })] }), _jsx(RegisterForms, { tab: activeTab, selectTab: selectTab }), _jsx(Styles.ButtonContainer, { children: _jsx(Button, { text: t('Registration'), variant: ButtonVariant.PRIMARY, disabled: isSubmitting || !dirty, onClick: (e) => {
                        e.stopPropagation();
                        validateForm();
                        handleSubmit();
                    } }) }), _jsx("div", { style: { marginBlockEnd: '32px' } })] }));
};
export const RegisterPage = () => {
    const alert = useAlert();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(TabItems[0]);
    const navigate = useQueryNavigate();
    const { Home, Login } = AppRoutes.Auth;
    const [registerPersonal, mutationPersonal] = useRegisterPrivatePersonMutation();
    const [registerLegal, mutationLegal] = useRegisterLegalPersonMutation();
    const onSuccess = useCallback(() => {
        alert.success(t('Success'));
        navigate(Home + Login, { replace: true });
    }, [alert]);
    const onError = useCallback((error) => {
        alert.error((error === null || error === void 0 ? void 0 : error.data) || t('Error'));
    }, [alert]);
    handleMutation(Object.assign(Object.assign({}, mutationPersonal), { onSuccess,
        onError }));
    handleMutation(Object.assign(Object.assign({}, mutationLegal), { onSuccess,
        onError }));
    const selectTab = useCallback((tabId) => {
        const selectedTab = TabItems.find((item) => item.id === tabId);
        if (selectedTab) {
            setActiveTab(selectedTab);
        }
    }, []);
    const initialValues = useMemo(() => {
        if ((activeTab === null || activeTab === void 0 ? void 0 : activeTab.id) === RegisterTypes.Individual) {
            return individualInitialValues;
        }
        if ((activeTab === null || activeTab === void 0 ? void 0 : activeTab.id) === RegisterTypes.Legal) {
            return legalInitialValues;
        }
        return {};
    }, []);
    const onSubmit = useCallback((values, { resetForm }) => __awaiter(void 0, void 0, void 0, function* () {
        if ((activeTab === null || activeTab === void 0 ? void 0 : activeTab.id) === RegisterTypes.Individual) {
            const response = yield registerPersonal(values);
            if ((response === null || response === void 0 ? void 0 : response.error) === undefined) {
                resetForm();
            }
        }
        if ((activeTab === null || activeTab === void 0 ? void 0 : activeTab.id) === RegisterTypes.Legal) {
            const response = yield registerLegal(values);
            if ((response === null || response === void 0 ? void 0 : response.error) === undefined) {
                resetForm();
            }
        }
    }), [activeTab === null || activeTab === void 0 ? void 0 : activeTab.id]);
    return (_jsx(Styles.Container, { children: _jsx(Form, Object.assign({ onSubmit: onSubmit, initialValues: initialValues, validationSchema: () => validationSchema({
                tab: activeTab === null || activeTab === void 0 ? void 0 : activeTab.id,
                t,
            }) }, { children: _jsx(RegisterForm, { activeTab: activeTab, selectTab: selectTab }) })) }));
};
