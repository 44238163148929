import styled from 'styled-components';
import { Text as TextComponent } from 'common/text';
import { scrollMedium } from 'styles/mixins/scroll';
export const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-15dq72a" }) `
  gap: 16px;
  display: flex;
  flex: 1;
  border-radius: 20px;
  flex-direction: column;
  margin: 32px 24px 24px 0px;
  overflow-y: auto;
  position: relative;
  background-color: ${({ theme }) => theme.config.background.secondary};

  @media screen and (max-width: 500px) {
    margin: 32px 24px 24px;
  }
`;
export const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-191xpu0" }) `
  flex: 1;
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  overflow-y: scroll;
  padding-bottom: 32px;
`;
export const ScrollContent = styled.div.withConfig({ displayName: "ScrollContent", componentId: "sc-1smfg87" }) `
  width: 100%;
  padding: 0px 48px;

  position: absolute;
  right: 0;

  overflow-y: auto;
  max-height: calc(100vh - 150px);
  ${scrollMedium};
`;
export const TextsContainer = styled.div.withConfig({ displayName: "TextsContainer", componentId: "sc-1iczuo7" }) `
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-hxa6fe" }) ``;
export const WrapperHeader = styled.div.withConfig({ displayName: "WrapperHeader", componentId: "sc-pe1kj7" }) `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-ipvbi" }) `
  width: 100%;
  max-width: 600px;
`;
export const Text = styled(TextComponent).withConfig({ displayName: "Text", componentId: "sc-4shh0i" }) `
  color: ${({ theme }) => theme.config.text.primary};
`;
export const FooterButton = styled.div.withConfig({ displayName: "FooterButton", componentId: "sc-95ysz6" }) `
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  padding-top: 24px;
  border-top: ${({ theme }) => theme.config.switch.border.default};
`;
