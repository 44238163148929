var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useField } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { controlError } from 'utils/controlError';
import { DatePicker } from '../../date-picker';
import { Error } from './styles';
export const DatePickerField = (_a) => {
    var { name, label, placeholder, selectsRange } = _a, props = __rest(_a, ["name", "label", "placeholder", "selectsRange"]);
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const error = t(controlError(meta, name, t(placeholder !== null && placeholder !== void 0 ? placeholder : '')));
    const value = field.value;
    const inputProps = useMemo(() => {
        if (selectsRange) {
            const range = value;
            return {
                startDate: range === null || range === void 0 ? void 0 : range.from,
                endDate: range === null || range === void 0 ? void 0 : range.to,
                onChange: (dates) => __awaiter(void 0, void 0, void 0, function* () {
                    const [from, to] = dates;
                    yield helpers.setValue({
                        from,
                        to,
                    });
                    helpers.setTouched(true);
                }),
            };
        }
        else {
            return {
                selected: value,
                onChange: (date) => __awaiter(void 0, void 0, void 0, function* () {
                    yield helpers.setValue(date);
                    helpers.setTouched(true);
                }),
            };
        }
    }, [value, selectsRange]);
    return (_jsxs("div", { children: [_jsx(DatePicker, Object.assign({ selectsRange: selectsRange, label: label }, field, props, inputProps)), error && _jsx(Error, { children: error })] }));
};
