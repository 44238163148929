import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { URLS } from '../../api/constants';
import { apiVoiceRobotQuery } from '../query';
export const VoiceRobotApi = createApi({
    reducerPath: 'VoiceRobotApi',
    baseQuery: apiVoiceRobotQuery,
    endpoints: (build) => ({
        audioCreate: build.mutation({
            query: ({ text, language }) => {
                return {
                    url: URLS.VOICE_ROBOT_AUDIO_CREATE +
                        `?text=${text}&language=${language}`,
                    method: 'GET',
                };
            },
        }),
    }),
});
export const { useAudioCreateMutation } = VoiceRobotApi;
